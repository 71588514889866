// External
import React from 'react';

// Internal
import SalonPriceHeading from './SalonPriceHeading';
import PriceListSection from './PriceListSection';
import SalonPriceListBottomSection from './SalonPriceListBottomSection/SalonPriceListBottomSection';
import Breadcrumb from '../../components/BreadCrumb/Index';

import {
	beautyData,
	colourData,
	eventsData,
	extensionsData,
	hairTreatmentsData,
	stylingData,
} from '../../constants/salon-price-list';
import { EXPERTS_SECTION_BREADCRUMB } from '../../constants/breadcrumbs';

import './SalonPriceList.scss';

const SalonPriceList = ({ headerText }) => (
	<div className='salon-price-page'>
		<Breadcrumb section={EXPERTS_SECTION_BREADCRUMB} />
		<SalonPriceHeading headerText={headerText} />
		<PriceListSection title='Colour' data={colourData} />
		<PriceListSection title='Styling' data={stylingData} />
		<SalonPriceListBottomSection title='Extensions' data={extensionsData} />
		<SalonPriceListBottomSection
			title='Hair Treatments'
			data={hairTreatmentsData}
		/>
		<SalonPriceListBottomSection title='Beauty' data={beautyData} />
		<SalonPriceListBottomSection
			title='Events, Weddings, and Shoots'
			data={eventsData}
		/>
	</div>
);

export default SalonPriceList;
