import React from 'react';

import ProductClientsSlider from './ProductClientsSlider';

import { getMetafield, getMetafieldRaw } from '../../../utils/helpers';

const ProductClientSliderContainer = ({ product }) => {
	const review = {
		title: getMetafield(product, 'review_title_1') ?? '',
		text: getMetafieldRaw(product, 'what_our_clients_say') ?? '',
		name: getMetafield(product, 'client_name_1') ?? '',
	};
	return <ProductClientsSlider review={review} />;
};

export default ProductClientSliderContainer;
