import React from 'react';
import loadable from '@loadable/component';

const FooterLeftSide = loadable(() => import('./FooterLeftSide'));
const FooterList = loadable(() => import('./FooterList'));
const FooterTop = ({ menu, location }) => {
	const collectionsPreviews = [
		{
			handle: 'blonde-hair-colour',
			image: 'footer/collection-1.png',
			title: 'Products for blonde hair',
			index: 0,
		},
		{
			handle: 'brown-hair-colour',
			image: 'footer/collection-2.webp',
			title: 'Products for brown hair',
			index: 1,
		},
		{
			handle: 'grey-coverage',
			image: 'footer/collection-3.png',
			title: 'Products for grey hair',
			index: 2,
		},
	];

	return (
		<div className='footer__top container'>
			<FooterLeftSide socials={menu?.Socials} location={location} />
			<div className='footer__content'>
				<FooterList
					title='shop'
					list={menu?.ShopMenu}
					collectionsPreviews={collectionsPreviews}
				/>
				<FooterList title='about' list={menu?.Footer} />
				<FooterList
					title='customer service'
					list={menu?.CustomerService}
				/>
			</div>
		</div>
	);
};

export default FooterTop;
