import React from 'react';

const SalonInfo = () => (
	<section className='salon-info'>
		<div className='container'>
			<div className='salon-info__content'>
				<div className='salon-info__text'>
					<p>
						Nestled in a mews house in London’s Holland Park, the
						Josh Wood Colour Atelier is home to Josh and his
						handpicked, dream team of colourists and stylists, famed
						for their ability to create personalised colour,
						tailored for the individual.
					</p>
				</div>
			</div>
		</div>
	</section>
);
export default SalonInfo;
