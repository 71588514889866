import React from 'react';

import { getPrice } from '../../../utils/helpers';
import isAWholeNumber from '../../../helpers/isAWholeNumber';

const CartItemPrice = ({
	item,
	discountPrice,
	cart,
	withoutDiscountPrice,
	compareAtPrice,
	recurringPrice,
}) => {
	let fullPrice = withoutDiscountPrice;
	let actualPrice =
		recurringPrice || discountPrice
			? getPrice(
					recurringPrice ?? discountPrice ?? withoutDiscountPrice,
					cart,
					2
			  )
			: 'Free';
	compareAtPrice && (fullPrice = compareAtPrice);
	compareAtPrice &&
		!discountPrice &&
		(actualPrice = getPrice(withoutDiscountPrice, cart, 2));
	actualPrice =
		actualPrice.split('.')[1] === '00'
			? actualPrice.split('.')[0]
			: actualPrice;
	const discountedSubscriptionPrice =
		recurringPrice > 0 &&
		discountPrice > 0 &&
		item.node.sellingPlanAllocation.priceAdjustments[0].price.amount -
			item.node.discountAllocations[0].discountedAmount.amount;

	recurringPrice > 0 &&
		discountPrice > 0 &&
		(actualPrice = getPrice(discountedSubscriptionPrice, cart, 2));

	const isFullPriceAWholeNumber = isAWholeNumber(fullPrice);
	const numberOfDecimalsOfFullPrice = isFullPriceAWholeNumber ? 0 : 2;

	const isWithoutDiscountPriceAWholeNumber =
		isAWholeNumber(withoutDiscountPrice);
	const numberOfDecimalsOfWithoutDiscountPrice =
		isWithoutDiscountPriceAWholeNumber ? 0 : 2;

	const productHasDiscount =
		(item.node.discountAllocations?.length > 0 &&
			Number(item.node.discountAllocations[0]?.discountedAmount.amount) >
				0) ||
		recurringPrice ||
		(compareAtPrice &&
			Number(compareAtPrice) !== fullPrice &&
			Number(compareAtPrice) !== withoutDiscountPrice) ||
		(discountPrice && discountPrice < withoutDiscountPrice) ||
		withoutDiscountPrice < compareAtPrice;

	return (
		<>
			<span className='cart-item__price'>
				{productHasDiscount ? (
					<>
						<span className='cart-item-price__order'>
							{getPrice(
								fullPrice,
								cart,
								numberOfDecimalsOfFullPrice
							)}
						</span>
						<span className='cart-item-price__actual'>{`${
							recurringPrice || discountPrice || compareAtPrice
								? getPrice(
										discountPrice > 0
											? discountPrice
											: recurringPrice ??
													withoutDiscountPrice,
										cart,
										isAWholeNumber(
											discountPrice > 0
												? discountPrice
												: recurringPrice ??
														withoutDiscountPrice
										)
											? 0
											: 2
								  )
								: 'Free'
						} `}</span>
					</>
				) : (
					getPrice(
						withoutDiscountPrice,
						cart,
						numberOfDecimalsOfWithoutDiscountPrice
					)
				)}
			</span>
		</>
	);
};

export default CartItemPrice;
