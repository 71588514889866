// External
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import cn from 'classnames';

// Internal
import Button from '../../../../components/Button';
import ProductRecommended from '../ProductRecommended';
import ProductSelectionLevels from './ProductSelectionLevels';
import DeliveryRadio from '../../../../components/DeliveryRadio';
import ProductGiftCard from '../ProductGiftCard';
import ProductMiracleActions from './ProductSelectionLevels/ProductMiracleActions';
import { useHideIsFooter } from '../../../../utils/hooks';
import {
	checkOption,
	getMetafield,
	isAvailable,
} from '../../../../utils/helpers';

import './ProductBundlesSection.scss';

const ProductBundlesSection = (props) => {
	const [availableForSale, setAvailableForSale] = useState(true);
	const linkRef = useRef();
	const isBundle = checkOption(props.product, 'bundle');
	const isMiracle = checkOption(props.product, 'miracle');
	const isHide = useHideIsFooter(linkRef);
	const isVegan = getMetafield(props.product, 'is_vegan_society');
	const levelNumber = parseInt(
		getMetafield(props.product, 'number_of_selection_levels')
	);
	const classnames = cn({
		'pdp-info__button--hide': isHide,
		'pdp-wrapper-button':
			!isBundle ||
			(!isMiracle && (props.product?.variants || []).length > 1),
	});

	const [variantID, setVariantID] = useState(null);

	useEffect(() => {
		const handleVariantID = () => {
			if (props.activeVariant && props.activeVariant.storefrontId)
				setVariantID(props.activeVariant.storefrontId);
		};
		handleVariantID();
	}, [props]);

	useEffect(() => {
		setAvailableForSale(
			props.isVariant && props.variantSelected
				? props.variantSelected.availableForSale
				: isAvailable(props.product)
		);
	}, [props.variantSelected]);

	const isBundleAndSaveProduct = props.product.tags.find(
		(tag) => tag === 'Bundle & Save'
	);

	return (
		<>
			{levelNumber > 0 && (
				<ProductSelectionLevels
					{...props}
					levelNumber={levelNumber}
					activeFirstLevel={props.activeVariant}
					setActiveFirstLevel={props.setActiveVariant}
				/>
			)}
			{props.isGiftCard && (
				<ProductGiftCard
					{...props}
					availableForSale={availableForSale}
				/>
			)}
			{(!levelNumber || levelNumber === 0) && !props.isGiftCard && (
				<>
					{isVegan && (
						<div className='pdp-info__vegan'>
							<LazyLoadImage
								src='/images/vegan.webp'
								alt='Vegan'
								effect='blur'
							/>
						</div>
					)}
					{props.canSubscribe &&
					availableForSale &&
					!isBundleAndSaveProduct ? (
						<>
							<DeliveryRadio
								activeRadio={props.activeRadio}
								setActiveRadio={props.setActiveRadio}
								classname='pdp-miracle-radios'
								discountPercentageWithSub={
									props.discountPercentageWithSub
								}
								product={props.selectedVariant || props.product}
							/>
							<div className='flex align-items'>
								<ProductMiracleActions
									product={props.product}
									canSubscribe={props.canSubscribe}
									discountPrice={props.discountPrice}
									selectedValue={props.selectedValue}
									optionsObjects={props.optionsObjects}
									setSelectedValue={props.setSelectedValue}
									sellingPlanGroup={props.sellingPlanGroup}
									activeRadio={props.activeRadio}
									activeSecondLevel={props.activeVariant ?? 0}
									recommendedProduct={
										props.recommendedProduct
									}
									isRecommendedSelected={
										props.isRecommendedSelected
									}
									origin='desktop'
								/>
							</div>
						</>
					) : (
						<div
							ref={linkRef}
							className={`pdp-info__button ${classnames}`}
						>
							{props.recommendedProductHandle &&
								props.windowWidth >= 991 && (
									<ProductRecommended {...props} />
								)}
							<Button
								product={props.product}
								isDark
								isPdp
								variantId={variantID}
								recommendedProduct={props.recommendedProduct}
								isRecommendedSelected={
									props.isRecommendedSelected
								}
								availableForSale={availableForSale}
								isVariant={props.isVariant}
							/>
							{props.isHintActive && (
								<Button
									isAction
									isPink
									isNotProduct
									buttonText='Send a hint'
									icon='gift.svg'
									handleClick={() => {
										props.openModalHint();
									}}
								/>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ProductBundlesSection;
