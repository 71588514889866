import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useProductByHandle from '../../../utils/useProductByHandle';

const AlternativeLooksItem = ({ handle }) => {
	const product = useProductByHandle(handle);

	return (
		<Link
			to={`/products/${handle}`}
			className='pdp-alternative-look__product swiper-slide'
		>
			{product && product?.images?.length && (
				<div className='pdp-alternative-look__img'>
					{product.images[0].gatsbyImageData ? (
						<GatsbyImage
							loading='lazy'
							alt={product.title}
							image={getImage(product.images[0].gatsbyImageData)}
						/>
					) : (
						<LazyLoadImage
							src={getImage(product.images[0].originalSrc)}
							alt={product.title}
							effect='blur'
						/>
					)}
				</div>
			)}
		</Link>
	);
};

export default AlternativeLooksItem;
