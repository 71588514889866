import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './MiracleModal.scss';

const MiracleModalSteps = ({ steps, activeStep, activeSteps }) => (
	<div className='miracle-modal__steps miracle-modal-steps'>
		{Array.isArray(steps) &&
			steps.map((item, i) => {
				const renderGreenCheckmark =
					activeSteps.includes(i + 1) && activeStep !== i + 1;
				return (
					<div
						key={`mms${steps[i]}`}
						className={`miracle-modal-steps__item 
                    ${
						activeSteps.includes(i + 1) &&
						'miracle-modal-steps__item--active'
					}`}
					>
						<p className='miracle-steps-item__title'>{item}</p>
						{renderGreenCheckmark && (
							<div className='miracle-steps-item__icon'>
								<LazyLoadImage
									src='/images/correct-green.svg'
									alt={item}
									effect='blur'
								/>
							</div>
						)}
					</div>
				);
			})}
	</div>
);

export default MiracleModalSteps;
