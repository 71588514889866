export const SORT_RELEVANCE = 'Relevance';
export const SORT_TITLE_ASCENDENT = 'Title A-Z';
export const SORT_TITLE_DESCENDENT = 'Title Z-A';
export const SORT_LOW_HIGH = 'Price Low to High';
export const SORT_HIGH_LOW = 'Price High to Low';

export const CURRENT_SORT_OPTIONS = [
	SORT_RELEVANCE,
	SORT_TITLE_ASCENDENT,
	SORT_TITLE_DESCENDENT,
	SORT_LOW_HIGH,
	SORT_HIGH_LOW,
];
