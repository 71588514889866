// External
import React from 'react';

// Internal
import SEO from '../../components/SEO';
import ExpertsPage from './ExpertsPage';

const headerText = 'Meet Josh Wood Atelier Experts';

const ExpertsPageContainer = ({ location, pageContext: { experts, page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<ExpertsPage {...{ experts }} headerText={headerText} />
	</>
);

export default ExpertsPageContainer;
