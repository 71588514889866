import React from 'react';
import Button from '../Button';

import './EmptyCollection.scss';

const EmptyCollection = () => (
	<div className='non-page'>
		<h2 className='non-page__title'>Collection is empty</h2>
		<p className='non-page__text'>This collection has no products</p>
		<Button
			isLink
			isDark
			buttonText='Continue shopping'
			linkUrl='/'
			buttonClasses='empty-collection-link'
		/>
	</div>
);

export default EmptyCollection;
