import React from 'react';

import Video from '../../Video';

import { getMetafield, getVideoId } from '../../../utils/helpers';

import './ModalShadeShot.scss';

const ModalShadeShot = ({ modal, showVideo }) => {
	const videoId =
		getVideoId(getMetafield(modal.product || {}, 'youtube_video_link')) ||
		'bdXVq9yy-tg';
	return (
		<div className='modal-shade-shot'>
			<h3 className='modal-shade-shot__title'>
				Personalise your colour with Shade Shot
			</h3>
			<p className='modal-shade-shot__text'>
				Shade Shot enhances the tones for a colour finish as unique as
				you are, when mixed into your Permanent Colour Kit.
			</p>
			{showVideo ? (
				<div className='modal-shade-shot__video'>
					<Video videoId={videoId} videoTitle='Shade Shot' />
				</div>
			) : null}
		</div>
	);
};

export default ModalShadeShot;
