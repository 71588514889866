import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ModalUpsellProduct = ({ product, text, image, handleClick, price }) => {
	const handleBagClick = () => {
		handleClick(product.variants[0].storefrontId, product.title, product);
	};

	return (
		<div className='modal-upsell__product modal-upsell-product'>
			<div className='modal-upsell-product__img'>
				<LazyLoadImage
					src={image.filename}
					alt={product.title}
					effect='blur'
				/>
			</div>
			<div className='modal-upsell-product__info'>
				<span className='modal-upsell-product__title'>
					{product && product.title}
				</span>
				<span className='modal-upsell-product__price'>
					{price.split('.')[0]}
				</span>
				{text !== '' && (
					<span className='modal-upsell-product__text'>
						<strong>{text}</strong>
					</span>
				)}
				<button
					className='modal-upsell-product__button'
					type='button'
					onClick={handleBagClick}
				>
					<span>Yes please! Add to my order</span>
				</button>
			</div>
		</div>
	);
};

export default ModalUpsellProduct;
