// External
import { useState, useEffect } from 'react';

// Internal
import {
	BLOG_SECTION_BREADCRUMB,
	PRODUCTS_BREADCRUMBS,
	PRODUCT_SECTION_BREADCUMB,
	EXPERTS_BREADCRUMBS,
	EXPERTS_SECTION_BREADCRUMB,
} from '../constants/breadcrumbs';

const useBreadcrumb = (section, article, product) => {
	const [breadcrumb, setBreadcrumb] = useState('');
	const [breadcrumbLink, setBreadcrumbLink] = useState('');
	const [currentSection, setCurrentSection] = useState('');

	const capitalizeFirstLetters = (tag) => {
		if (typeof tag !== 'string' || tag.length <= 0) return;
		let prettyTag = '';
		const tagWords = tag.split(' ');
		for (const word of tagWords) {
			prettyTag += `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
		}
		return prettyTag;
	};

	useEffect(() => {
		const handleBreadcrum = () => {
			if (!section) return;
			const currentPathname = window.location.pathname;
			if (section === PRODUCT_SECTION_BREADCUMB && product) {
				if (!product.productType) return;
				setBreadcrumb(product.productType);
				setBreadcrumbLink(currentPathname);
				setCurrentSection(PRODUCT_SECTION_BREADCUMB);
			} else if (section === PRODUCT_SECTION_BREADCUMB) {
				for (const collectionKeyUrl in PRODUCTS_BREADCRUMBS) {
					if (currentPathname.includes(collectionKeyUrl)) {
						setBreadcrumb(
							PRODUCTS_BREADCRUMBS[collectionKeyUrl]?.breadcrumb
						);
						setBreadcrumbLink(collectionKeyUrl);
						setCurrentSection(
							PRODUCTS_BREADCRUMBS[collectionKeyUrl]?.section
						);
					}
				}
			} else if (section === EXPERTS_SECTION_BREADCRUMB) {
				for (const collectionKeyUrl in EXPERTS_BREADCRUMBS) {
					if (currentPathname.includes(collectionKeyUrl)) {
						setBreadcrumb(
							EXPERTS_BREADCRUMBS[collectionKeyUrl]?.breadcrumb
						);
						setBreadcrumbLink(collectionKeyUrl);
						setCurrentSection(
							EXPERTS_BREADCRUMBS[collectionKeyUrl]?.section
						);
					}
				}
			} else if (section === BLOG_SECTION_BREADCRUMB && article) {
				if (!Array.isArray(article.tags)) return;
				const articleTag = capitalizeFirstLetters(article.tags[0]);
				const breadcrumUrl = window.location.pathname;
				setBreadcrumb(articleTag);
				setBreadcrumbLink(breadcrumUrl);
			}
		};
		handleBreadcrum();
	}, []);

	return {
		breadcrumb,
		breadcrumbLink,
		currentSection,
	};
};

export default useBreadcrumb;
