/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const MiracleAboutHeroBanner = ({ windowWidth }) => (
	<div className='miracle-about-page-banner'>
		<StaticImage
			src='../../../static/images/miracle-about/banner.jpg'
			alt='text'
			className='miracle-about-page-banner__image'
			objectPosition="top"
		/>

		<div className='miracle-about-page-banner__container container'>
			<div className='miracle-about-page-banner__top'>
				{windowWidth >= 750 ? (
					<div className='miracle-about-page-banner__info'>
						<h1>
							Strengthen your hair <br /> with The Miracle System{' '}
						</h1>
						<p>
							Designed to cover your greys, whilst treating <br />
							and protecting your hair from damage.
						</p>
					</div>
				) : (
					<div className='miracle-about-page-banner__info'>
						<h1>
							Strengthen your hair <br /> with The Miracle System{' '}
						</h1>
						<p>
							Designed to cover your greys, <br />
							whilst treating and protecting <br />
							your hair from damage.
						</p>
					</div>
				)}
				<div className='miracle-about-page-banner__row'>
					<Link to='/miracle-steps'>
						<span>Get started</span>
					</Link>
				</div>
			</div>
		</div>
	</div>
);

export default MiracleAboutHeroBanner;
