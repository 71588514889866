import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './DropdownContent.scss';

const DropdownContent = ({ title, children }) => {
	const [showContent, setShowContent] = useState(false);

	return (
		<div
			className={`dropdown-content ${showContent ? 'dropdown-open' : ''}`}
		>
			<div
				className='dropdown-content-header'
				onClick={() => setShowContent((show) => !show)}
			>
				<span>{title}</span>
				<LazyLoadImage
					src='/images/arrow-up.svg'
					alt='Arrow up'
					effect='blur'
				/>
			</div>
			<div className='dropdown-content-children'>{children}</div>
		</div>
	);
};

export default DropdownContent;
