import React from 'react';
import LocalImage from '../LocalImage';

const Password = ({ password, setPassword, handleSubmitForm }) => (
	<div className='password'>
		<div className='container'>
			<form
				className='password-form'
				onSubmit={handleSubmitForm}
				style={{ display: 'none' }}
			>
				<input
					type='text'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder='Enter a password please...'
				/>
				<button type='submit'>
					<span>Enter</span>
				</button>
			</form>
			<div className='password-info'>
				<div className='password-info__logo'>
					<LocalImage image='logo.png' alt='Logo' />
				</div>
				<div className='password-info__text'>
					<p>
						We’re working on some improvements to make your website
						experience with us even better.
						<br />
						Please check back again with us in a few hours.
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default Password;
