import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'swiper/swiper-bundle.min.css';

import { useWindowWidth } from '../../../../utils/hooks';
import './style.scss';

SwiperCore.use([Autoplay]);

const AsSeenIn = ({ slider = false }) => {
	const windowWidth = useWindowWidth();

	return (
		<section className='as-seen-in'>
			<div className='as-seen-in__wrap'>
				<h2 className='as-seen-in__title'>As seen in...</h2>
				<div className='as-seen-in__container'>
					<div className='as-seen-in__img'>
						{slider ? (
							<Swiper
								loop
								autoplay={{
									delay: 2500,
									disableOnInteraction: false,
								}}
								slidesPerView={2}
							>
								<SwiperSlide>
									<LazyLoadImage
										src='/images/pdp/partner-1.webp'
										alt='Partner 1'
										effect='blur'
									/>
								</SwiperSlide>
								<SwiperSlide>
									<LazyLoadImage
										src='/images/pdp/partner-2.webp'
										alt='Partner 2'
										effect='blur'
									/>
								</SwiperSlide>
							</Swiper>
						) : (
							<>
								{windowWidth > 750 ? (
									<LazyLoadImage
										src='/images/home-page/as-seen-desktop.webp'
										alt='Salon Quality colour'
										effect='blur'
									/>
								) : (
									<LazyLoadImage
										src='/images/home-page/as-seen-mobile.webp'
										alt='Salon Quality colour'
										effect='blur'
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default AsSeenIn;
