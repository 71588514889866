// External
import React, { Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import DropdownContent from '../../DropdownContent';
import {
	HAIR_CONCERNS_FILTER,
	RATING_FILTER,
	HAIR_COLOUR_FILTER,
	productsFilters,
} from '../../../constants/productsFilters';
import Loader from '../../Loader';

const StarRating = React.lazy(() => import('react-svg-star-rating'));

const FilterForm = ({ activeFilters, setActiveFilters, onClose }) => {
	const { register, handleSubmit, getValues, setValue } = useForm({
		defaultValues: activeFilters,
	});

	const currentActiveFilters = Object.keys(activeFilters).filter(
		(key) => activeFilters?.[key] !== ''
	);

	const getFilterTitle = () => {
		let filterTitle = 'No filters';
		if (currentActiveFilters.length === 1) {
			filterTitle = '1 Filter';
		} else if (currentActiveFilters.length > 1) {
			filterTitle = `${currentActiveFilters.length} Filters`;
		}
		return filterTitle;
	};

	const getOptionLabel = (name, value) => {
		const filter = productsFilters.find((filter) => filter.name === name);
		const label = filter?.options?.find(
			(option) => option.value === value
		)?.label;

		return label;
	};

	const onSubmit = (values) => {
		setActiveFilters(values);
		onClose();
	};

	const onReset = () => {
		currentActiveFilters.forEach((filter) => setValue(filter, ''));
		onSubmit(getValues());
	};

	const getFilterNameClass = (filterName) => {
		if (filterName === RATING_FILTER) return 'rating-options';
		if (filterName === HAIR_CONCERNS_FILTER) return 'hair-concerns';
		if (filterName === HAIR_COLOUR_FILTER) return 'hair-colour';
	};

	return (
		<div className='filter-form'>
			<div className='filter-form-header'>
				<h5>{getFilterTitle()}</h5>
				<button onClick={onClose}>
					<LazyLoadImage
						src='/images/modal-close.svg'
						alt='close'
						effect='blur'
					/>
				</button>
			</div>
			{currentActiveFilters.length > 0 && (
				<div className='filter-form-tags-container'>
					{currentActiveFilters.map((tag) => (
						<div className='filter-form-tag' key={tag}>
							<span>
								{getOptionLabel(tag, activeFilters[tag])}
							</span>
							<button
								onClick={() => {
									setValue(tag, '');
									onSubmit(getValues());
								}}
							>
								<LazyLoadImage
									src='/images/close-icon-white.svg'
									alt='close'
									effect='blur'
								/>
							</button>
						</div>
					))}
				</div>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				{productsFilters.map((filter) => (
					<DropdownContent title={filter.label} key={filter.name}>
						<div
							className={`filter-options-container ${getFilterNameClass(
								filter.name
							)}`}
						>
							{filter.options.map((option, index) => (
								<label key={option.value}>
									<input
										{...register(filter.name)}
										type='radio'
										value={option.value}
									/>
									{filter.name === 'rating' && (
										<div className='form-rating-stars'>
											<Suspense fallback={<Loader />}>
												<StarRating
													starClassName='rating-star'
													containerClassName='stars-container'
													initialRating={4 - index}
													roundedCorner='false'
													activeColor='#000'
													emptyColor='#fff'
													isReadOnly='true'
													unit='float'
												/>
											</Suspense>
										</div>
									)}
									<span>{option.label}</span>
								</label>
							))}
						</div>
					</DropdownContent>
				))}
				<div className='filter-form-buttons-container'>
					<button
						className='filter-form-reset-button'
						type='reset'
						onClick={onReset}
					>
						Clear All
					</button>
					<button className='filter-form-submit-button' type='submit'>
						Show Results
					</button>
				</div>
			</form>
		</div>
	);
};

export default FilterForm;
