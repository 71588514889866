import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'gatsby';
import { useClickOutside } from '../../../../utils/hooks';

const ItemGalleryActions = ({ handleClose, showInfo, setShowInfo }) => {
	const helpBloc = useRef(null);
	useClickOutside(helpBloc, () => setShowInfo(false));

	return (
		<div className='permanent-gallery-actions'>
			<div
				role='button'
				className='permanent-gallery-actions__item permanent-gallery__close'
				onClick={handleClose}
			>
				<LazyLoadImage
					src='/images/miracle/steps/nav/miracle-close.svg'
					alt='close'
					effect='blur'
				/>
			</div>
			<div className='permanent-gallery-actions__help'>
				<div
					ref={helpBloc}
					className='permanent-gallery-actions__item permanent-gallery-actions__item--grey'
					onClick={() => setShowInfo(!showInfo)}
				>
					<span>need help?</span>
				</div>
				<div
					className={`permanent-gallery-actions__item permanent-gallery-actions__item--purple ${
						!showInfo && 'permanent-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/hair-colour-care-quiz/'>Take a quiz</Link>
				</div>
				<div
					className={`permanent-gallery-actions__item permanent-gallery-actions__item--pink ${
						!showInfo && 'permanent-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/book-a-video-hair-consultation/'>
						Book a video consultation
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ItemGalleryActions;
