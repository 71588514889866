import React from 'react';
import ProductListInfo from '../collections/ProductListInfo';
import Filter from '../../components/Filter';
import ProductsListGridV2 from './ProductsListGridV2';
import CollectionGrid from '../../components/CollectionGrid';
import EmptyCollection from '../../components/EmptyCollection';
import hideFiltersCollection from '../../constants/hide-filters-collection';
import FAQTitle from '../../components/Storyblok/FAQs/faqTitle';
import FAQSection from '../../components/Storyblok/FAQs/faqSection';

const ProductsListPage = ({
	productsList,
	cart,
	collection,
	filter,
	collectionFilters,
	collectionSorts,
	allSellingPlanGroups,
	collectionFAQ
}) => {
	const hideFilters = hideFiltersCollection.includes(collection.handle);
	const collectionFAQTitle = collectionFAQ?.content?.items?.find(
		(blok) => blok.component === 'faqTitle'
	);
	const collectionFAQSections = collectionFAQ?.content?.items?.filter(
		(blok) => blok.component === 'faqSection'
	);
	return (
		<div className='plp-page'>
			<div className='container'>
				<ProductListInfo {...collection} />
				{!productsList.length ? (
					<EmptyCollection />
				) : (
					<>
						{!hideFilters && (
							<Filter
								query={filter}
								list={productsList}
								collectionFilters={collectionFilters}
								collectionSorts={collectionSorts}
							/>
						)}
						<ProductsListGridV2
							collection={collection}
							productsList={productsList}
							cart={cart}
							filter={filter}
							allSellingPlanGroups={allSellingPlanGroups}
						/>
							{collectionFAQTitle &&
							collectionFAQSections &&
							collectionFAQSections.length > 0 && (
								<>
									<FAQTitle blok={collectionFAQTitle} />
									{collectionFAQSections.map((faqSection) => (
										<FAQSection blok={faqSection} />
									))}
								</>
							)}
						<CollectionGrid collectionPageV2 />
					</>
				)}
			</div>
		</div>
	);
};

export default ProductsListPage;
