// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import { StoreContext } from '../../../context/store-context';

import ProductListItemContainerV4 from '../../../components/ProductListItemV4/ProductListItemContainerV4';
import FilterAndSort from '../../../components/FilterAndSort';
import SortProducts from '../../../components/ProductSort/Index';

import {
	getFilteredTags,
	getSortedKeys,
	sendPlpOrSearchEvent,
} from '../../../utils/helpers';

import './ProductsListGridV4.scss';

const ProductsListGridV4 = ({
	productsList,
	cart,
	filter,
	collection,
	allSellingPlanGroups,
	showFilters = true,
}) => {
	const { filterTags, sortKeys } = useContext(StoreContext);

	useEffect(() => {
		sendPlpOrSearchEvent(cart, productsList, 'dl_view_item_list');
	}, []);

	const [allProductsList, setAllProductsList] = useState(productsList);

	let filteredList = getFilteredTags(filterTags, allProductsList);

	const newTagsArray = (filter || '').replace('-', ' ').split();

	filteredList = filter
		? getFilteredTags(newTagsArray, filteredList)
		: filteredList;

	getSortedKeys(sortKeys, filteredList);

	return (
		<>
			{showFilters && (
				<div className='products-filters-container'>
					<FilterAndSort
						allProducts={productsList}
						setFilteredProducts={setAllProductsList}
					/>
					<SortProducts
						allProducts={allProductsList}
						setFilteredProducts={setAllProductsList}
					/>
				</div>
			)}
			<div className='products-list-grid-container'>
				<ul className='products-list-grid'>
					{Array.isArray(filteredList) && filteredList.length > 0 ? (
						<>
							{filteredList.map((item, index) => (
								<ProductListItemContainerV4
									index={index + 1}
									key={item.id}
									product={item}
									cart={cart}
									collection={collection}
									allSellingPlanGroups={allSellingPlanGroups}
									isHoverButton
								/>
							))}
						</>
					) : (
						<div className='collection-products-empty'>
							<p>No Products found!</p>
						</div>
					)}
				</ul>
			</div>
		</>
	);
};

export default ProductsListGridV4;
