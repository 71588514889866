// External
import fetch from 'node-fetch';

// Internal
import { submitErrorToSlack } from '../utils/slackMessage';
import HttpError from '../utils/httpError';

export default async (req, res) => {
	let handle = req.query.handle;
	const url = `https://${process.env.SHOPIFY_STORE_URL}/admin/api/2024-04/products.json?handle=${handle}`;
	const header = {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Authorization: `Basic ${process.env.SHOPIFY_ADMIN_ACCESS_TOKEN}`,
	};
	return fetch(url, {
		method: 'GET',
		headers: header,
	})
		.then((response) => {
			if (!response.ok) {
				throw new HttpError(response?.status, 'API request failed - getProductsByHandle');
			}
			return response.json();
		})
		.then((data) => {
			res.status(200).json(data.products);
		})
		.catch((error) => {
			res.status(422).send(String(error));
			if (error?.statusCode >= 500) {
				submitErrorToSlack(url, error, 'GET');
			}
			console.error(error);
			return error;
		});
};
