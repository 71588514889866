import React from 'react';

import SalonPriceListBottomSectionItem from './SalonPriceListBottomSectionItem';

const SalonPriceListBottomSection = ({ title, data }) => (
	<section className='salon-price-list-bottom-section'>
		<div className='container'>
			<div className='salon-price-list-bottom-section__content'>
				<h3
					className={`salon-price-list-bottom-section__title 
                salon-price-page__title`}
				>
					{title}
				</h3>
				<>
					{(data || []).map((item, i) => (
						<>
							{item.prices ? (
								<>
									<div className='salon-price-list-bottom-section-item__person'>
										{item.title}
									</div>
									{(item.prices || []).map((price, i) => (
										<SalonPriceListBottomSectionItem
											/* eslint-disable-next-line */
											key={i}
											title={price.title}
											price={price.price}
											info={price.info}
										/>
									))}
								</>
							) : (
								<SalonPriceListBottomSectionItem
									/* eslint-disable-next-line */
									key={i}
									title={item.title}
									price={item.price}
									info={item.info}
								/>
							)}
						</>
					))}
				</>
			</div>
		</div>
	</section>
);
export default SalonPriceListBottomSection;
