import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, {
	EffectFade,
	Lazy,
	Navigation,
	Pagination,
	Virtual,
	Zoom,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import MiracleGalleryActions from './MiracleGalleryActions';
import MiracleGalleryItem from './MiracleGalleryItem';

import { StoreContext } from '../../../context/store-context';
import { useWindowWidth } from '../../../utils/hooks';

import './MiracleGallery.scss';

SwiperCore.use([Navigation, Pagination, Virtual, Lazy, EffectFade]);

const MiracleGallery = ({ step, handleClose }) => {
	const {
		setMiracleGallerySwiper,
		miracleGalleryImages,
		miracleGallerySwiper,
		isShowGallery,
	} = useContext(StoreContext);

	const windowWidth = useWindowWidth();

	const [showInfo, setShowInfo] = useState(false);

	useEffect(() => {
		if (miracleGallerySwiper) {
			miracleGallerySwiper.update();
			if (miracleGallerySwiper.navigation) {
				miracleGallerySwiper.navigation.update();
			}
		}
	}, [miracleGalleryImages, miracleGallerySwiper]);

	return (
		<div
			className={`miracle-gallery ${
				isShowGallery && 'miracle-gallery--active'
			}`}
		>
			<Swiper
				onSwiper={setMiracleGallerySwiper}
				slidesPerView={1}
				simulateTouch
				updateOnWindowResize
				observer='true'
				fadeEffect={{
					crossFade: true,
				}}
				watchOverflow
				watchSlidesVisibility
				preloadImages='false'
				lazy='true'
				virtual
				toggle='false'
				initialSlide={1}
				navigation={{
					prevEl: '.miracle-gallery-prev',
					nextEl: '.miracle-gallery-next',
				}}
				pagination={{
					el: '.miracle-gallery-fraction',
					clickable: true,
					type: 'bullets',
				}}
				breakpoints={{
					360: {
						effect: 'fade',
					},
					750: {
						effect: 'coverflow',
					},
					991: {
						slidesPerView: 2,
						initialSlide: 0,
						zoom: false,
						allowTouchMove: true,
					},
				}}
			>
				{(miracleGalleryImages || []).map((img, _) => (
					<SwiperSlide
						/* eslint-disable-next-line */
						key={_}
						virtualIndex={_}
					>
						<MiracleGalleryItem
							{...{
								idx: _,
								img,
								windowWidth,
								step,
							}}
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<div className='miracle-gallery-prev swiper-button-prev' />
			<div className='miracle-gallery-next swiper-button-next' />
			<div className='miracle-gallery-fraction' />

			<MiracleGalleryActions
				{...{ showInfo, setShowInfo, handleClose, windowWidth }}
				swiper={miracleGallerySwiper}
			/>
		</div>
	);
};

export default MiracleGallery;
