/**
 * Accepts environment variable (key) and value
 * Returns true if key and value match (else false),
 * fallback value if no env variable exists (defaulting to false)
 *
 * @param key
 * @param value
 * @param fallback
 * @returns {boolean}
 */
/* eslint-disable-next-line */
module.exports.getFeatureFlag = (key, value, fallback = false) => {
	return key ? key.toLowerCase() === value.toLowerCase() : fallback;
};

/**
 * Accepts product ID and selling plan data;
 * if selling plan groups exist, searches these for attached products
 * Returns last group with the specified product (or empty object)
 *
 * @param id
 * @param data
 * @returns {{}}
 */
module.exports.getSellingPlanGroup = (id, data) => {
	let found = {};
	if (data.length < 1) return found;
	data.forEach((item) => {
		item.products.edges.forEach((edge) => {
			if (edge.node.id === id) found = item;
		});
	});
	return found;
};
