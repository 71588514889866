import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const GiftCardPageBanner = () => (
	<div className='gift-card-page-banner'>
		<LazyLoadImage
			src='/images/gift/gift-banner.jpg'
			alt='Gift'
			effect='blur'
		/>
	</div>
);

export default GiftCardPageBanner;
