import React from 'react';
import miracle from '../../../constants/miracle';
import './MiracleStepsItems.scss';

const MiracleStepsItems = ({ activeSteps, handlePrevStep }) => {
	const handleStepsNav = (isActive, step) => {
		if (isActive) {
			const currentIdx = activeSteps.indexOf(step) + 1;
			if (activeSteps.length === currentIdx) return;
			handlePrevStep(currentIdx);
		}
	};

	return (
		<div className='miracle-steps-items'>
			<div className='container miracle-steps-items__container'>
				{(miracle.steps || []).map((item, i) => {
					const isActive = activeSteps.includes(item.toLowerCase());
					return (
						<div
							key={`miraclestepsitems${item}`}
							role='button'
							className={`miracle-steps-item
								${
									activeSteps[activeSteps.length - 1] ===
										item.toLowerCase() &&
									'miracle-steps-item--active'
								}`}
							onClick={() =>
								handleStepsNav(isActive, item.toLowerCase())
							}
						>
							<span className='miracle-steps-item__title'>
								<span className='miracle-steps-item__number'>
									{i + 1}.{' '}
								</span>
								{item}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MiracleStepsItems;
