// External
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

// Internal
import LocalImage from '../../../components/LocalImage';
import Breadcrumb from '../../../components/BreadCrumb/Index';

import { PRODUCT_SECTION_BREADCUMB } from '../../../constants/breadcrumbs';
import { useWindowWidth } from '../../../utils/hooks';

import './ProductListInfo.scss';

const ProductListInfo = ({ title, descriptionHtml, handle }) => {
	const [isMoreOpened, setMoreOpened] = useState(false);
	const width = useWindowWidth();

	const [isExpanded, setIsExpanded] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const contentRef = useRef(null);

	useEffect(() => {
		if (contentRef.current) {
			const lineHeight = parseFloat(
				getComputedStyle(contentRef.current).lineHeight
			);
			const maxHeight = lineHeight *5;
			if (contentRef.current.scrollHeight > maxHeight) {
				setShowButton(true);
			}
		}
	}, [descriptionHtml]);

	const descriptionSize = descriptionHtml.length >= 460;
	return (
		<div className='product-list-info plp-page__info'>
			<div className='product-list-info__container'>
				<Breadcrumb section={PRODUCT_SECTION_BREADCUMB} />
				<h1 className='product-list-info__title'>{title}</h1>
				<div className='product-list-info__flex'>
					{width <= 550 ? (
						<div
							className={`product-list-info__text ${
								descriptionSize &&
								'product-list-info__text--height'
							} ${
								isMoreOpened &&
								'product-list-info__text--active'
							}`}
							dangerouslySetInnerHTML={{
								__html: descriptionHtml,
							}}
						/>
					) : (
						<div className='description'>
							<div
								ref={contentRef}
								className={`content product-list-info__text ${
									isExpanded ? 'expanded' : ''
								}`}
								style={{
									maxHeight: isExpanded
										? 'none'
										: `${5 * 1.5}em`,
								}}
								dangerouslySetInnerHTML={{
									__html: descriptionHtml,
								}}
							/>
						</div>
					)}

					{handle.includes('gloss') && (
						<Link
							to='/blogs/community/the-glaze-craze'
							className='classic-link classic-link--margin'
						>
							Find out more about the Gloss and Glaze Collection
						</Link>
					)}
				</div>
				{showButton && width > 550 && (
					<button
						onClick={() => setIsExpanded(!isExpanded)}
						className='product-list-info__read-more'
					>
						{isExpanded ? 'read less' : 'read more'}
					</button>
				)}
				{width <= 550 && (
					<button
						onClick={() => setMoreOpened(!isMoreOpened)}
						className='product-list-info__read-more'
					>
						{!isMoreOpened ? 'read more' : 'read less'}
					</button>
				)}
			</div>
			{handle !== 'colour' &&
				handle !== 'gloss-and-glaze' &&
				handle !== 'all-products' &&
				handle !== 'root-touch-up' &&
				handle !== 'gifts' &&
				handle !== 'care' &&
				handle !== 'accessories' && (
					<div className='product-list-info__img'>
						<LocalImage
							alt={title}
							image={`collections/${handle}.jpg`}
						/>
					</div>
				)}
		</div>
	);
};

export default ProductListInfo;
