import isAWholeNumber from './isAWholeNumber';

function getTotalPrice(isDiscount, productPrice, discountPercentageWithSub) {
	if (isDiscount) {
		const discountPrice =
			productPrice - productPrice * (discountPercentageWithSub / 100);
		return isAWholeNumber(discountPrice)
			? `£ ${discountPrice}`
			: `£ ${discountPrice.toFixed(2)}`;
	}
	return isAWholeNumber(productPrice)
		? `£ ${productPrice}`
		: `£ ${productPrice.toFixed(2)}`;
}

const getVariantPrice = (
	product,
	isDiscount,
	discountPercentageWithSub = 0
) => {
	if (!product) return '';
	if (Array.isArray(product?.variants)) {
		const productPrice = Number(product?.variants[0].price);
		return getTotalPrice(
			isDiscount,
			productPrice,
			discountPercentageWithSub
		);
	}
	if (product?.price) {
		const productPrice = Number(product?.price);
		return getTotalPrice(
			isDiscount,
			productPrice,
			discountPercentageWithSub
		);
	}
};

export default getVariantPrice;
