import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';
import { useWindowWidth } from '../../utils/hooks';

const ButtonOutStock = ({ product, isPlp, plpVersion }) => {
	const { setModal, showModal } = useContext(StoreContext);
	const showKlaviyoModal = () => {
		setModal(product, 'klaviyo', null);
		showModal(true);
	};
	const width = useWindowWidth();
	return (
		<>
			{plpVersion !== '2' && (
				<p className={`button-stock ${isPlp && 'button-no-margin'}`}>
					Out of Stock
				</p>
			)}

			<button
				className={`button--out ${
					isPlp && `button-plp-version-${plpVersion}`
				}`}
				onClick={showKlaviyoModal}
			>
				{(isPlp && width > 499) || !isPlp ? (
					<span>
						{width > 499 ? 'Notify Me When Available' : 'Notify Me'}
					</span>
				) : (
					<span>Notify Me</span>
				)}
			</button>
		</>
	);
};

export default ButtonOutStock;
