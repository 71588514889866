import React from 'react';
import FAQQuestion from './faqQuestion';
import './style.scss';

const FAQTitle = ({ blok }) => {
	const { title } = blok;

	return <h1 className='faq__title'>{title}</h1>;
};

export default FAQTitle;
