import React from 'react';
import { ReactSVG } from 'react-svg';

import LocalImage from '../../../LocalImage';

const ModalRedLeft = ({ title }) => {
	const img = `red/${title.toLowerCase()}-gloss.png`;
	return (
		<div className='modal-red__left'>
			<div className='modal-red__img'>
				<LocalImage image={img} alt={title} />
			</div>
			<p className='modal-red__titles'>Your shade before</p>
			<div className='modal-red__arrow'>
				<ReactSVG src='/images/down-arrow.svg' wrapper='span' />
			</div>
			<p className='modal-red__titles'>Your shade after</p>
		</div>
	);
};

export default ModalRedLeft;
