import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWindowWidth } from '../../../utils/hooks';

import ProductListItemContainer from '../../../components/ProductListItem/ProductListItemContainer';

import './ProductPairWith.scss';

const ProductPairWith = ({ products, cart }) => {
	const windowWidth = useWindowWidth(),
		sliderSettings = {
			slidesPerView: 2,
			spaceBetween: 10,
			onInit: (slider) => {
				if (slider.slides.length <= 2) {
					slider.el.style.paddingRight = '0px';
					slider.update();
				}
			},
		};
	return (
		<div
			className={`pdp-pair-with${
				products.length < 2 ? ' pdp-pair-with--small' : ''
			}`}
		>
			<div className='container'>
				<h2 className='pdp-pair-with__title bold'>Pairs Well With</h2>
				{windowWidth > 768 ? (
					<ul className='pdp-pair-with__grid'>
						{products?.map((product) => (
							<ProductListItemContainer
								key={product.id}
								classname='pdp-pair-with__item'
								product={product}
								cart={cart}
							/>
						))}
					</ul>
				) : (
					<Swiper
						{...sliderSettings}
						className='pdp-pair-with__slider'
					>
						{products?.map((product) => (
							<SwiperSlide key={product.id}>
								<ProductListItemContainer
									classname='pdp-pair-with__item'
									product={product}
									cart={cart}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</div>
		</div>
	);
};

export default ProductPairWith;
