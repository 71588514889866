// External
import React from 'react';

// Internal
import SalonHeader from './SalonHeader/SalonHeader';
import SalonInfo from './SalonInfo';
import SalonGallery from './SalonGallery/SalonGallery';
import SalonMap from './SalonMap';

import './SalonPage.scss';

const SalonPage = ({ headerText, allHours }) => (
	<div className='salon-page'>
		<SalonHeader headerText={headerText} />
		<SalonInfo allHours={allHours} />
		<SalonGallery />
		<SalonMap allHours={allHours} />
	</div>
);
export default SalonPage;
