import React, { useContext } from 'react';
import ProductRecommendedItem from './ProductRecommendedItem';
import { StoreContext } from '../../../../context/store-context';
import { getMetafield } from '../../../../utils/helpers';
import './ProductRecommended.scss';

const ProductRecommended = ({
	product,
	recommendedProductHandle,
	recommendedTag,
	recommendedText,
	recommendedTextMobile,
	setRecommended,
	isRecommendedSelected,
	setRecommendedSelected,
}) => {
	const { setModal, showModal } = useContext(StoreContext),
		recommendedVideo =
			product && getMetafield(product, 'recommended_video'),
		handleClick = () => {
			setModal(product, 'recommended-video', recommendedVideo);
			showModal(true);
		};

	return (
		<div className='recommended-product'>
			<div className='recommended-product__row'>
				<button
					onClick={() =>
						setRecommendedSelected(!isRecommendedSelected)
					}
					className={`recommended-product__checkbox ${
						isRecommendedSelected &&
						'recommended-product__checkbox--active'
					}`}
					type='button'
				/>
				<div className='recommended-product__label'>
					<span>{recommendedTag || 'Recommended'}</span>
				</div>
				{recommendedText && recommendedTextMobile && (
					<div
						className={`recommended - product__text
                     recommended-product-text`}
					>
						{!recommendedVideo ? (
							<>
								<span
									className='recommended-product-text__element
                                    recommended-product-text__element--mobile'
								>
									{recommendedTextMobile}
								</span>
								<span
									className='recommended-product-text__element
                                      recommended-product-text__element--desktop' // eslint-disable-line
								>
									{recommendedText}
								</span>
							</>
						) : (
							<>
								<button
									onClick={handleClick}
									className='recommended-product-text__element recommended-product-text__element--button recommended-product-text__element--mobile' //eslint-disable-line
								>
									{recommendedTextMobile}
								</button>
								<button
									onClick={handleClick}
									className='recommended-product-text__element recommended-product-text__element--button recommended-product-text__element--desktop' //eslint-disable-line
								>
									{recommendedText}
								</button>
							</>
						)}
					</div>
				)}
				<ProductRecommendedItem
					recommendedProductHandle={recommendedProductHandle}
					setRecommended={setRecommended}
					isRecommendedSelected={isRecommendedSelected}
				/>
			</div>
		</div>
	);
};

export default ProductRecommended;
