import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlogGridPageItem from '../../templates/blogs/BlogPageGrid/BlogPageGrigItem';

import './articleRelatedArticles.scss';
import { useWindowWidth } from '../../utils/hooks';

SwiperCore.use([Pagination]);

const ArticleRelatedArticles = ({ relatedArticles }) => {
	const width = useWindowWidth();
	return (
		<div className='related-articles__section'>
			<h3 className='related-articles__title'>Related articles</h3>

			<Swiper
				simulateTouch
				initialSlide={0}
				spaceBetween={8}
				slidesPerView={1}
				pagination={{
					el: '.related-articles__section--pagin',
					clickable: true,
					type: 'bullets',
				}}
				breakpoints={{
					425: {
						slidesPerView: 1.4,
					},
					767: {
						slidesPerView: 2.1,
					},
					1023: {
						slidesPerView: 3.1,
					},
					1279: {
						slidesPerView: 4.1,
					},
				}}
				className='related-articles__section--swiper'
			>
				{relatedArticles?.map((article) => (
					<SwiperSlide key={article.id}>
						<BlogGridPageItem key={article.id} article={article} />
					</SwiperSlide>
				))}
			</Swiper>
			{width < 767 && (
				<div className='related-articles__section--pagin swiper-pagination' />
			)}
		</div>
	);
};

export default ArticleRelatedArticles;
