import React, { useEffect, useState } from 'react';

import { useWindowWidth } from '../../../utils/hooks';

const MiracleToneTooltip = ({ tooltip }) => {
	const width = useWindowWidth(),
		[show, setShow] = useState(width >= 768);
	useEffect(() => {
		const time1 = setTimeout(() => setShow(true), 200),
			time2 =
				width < 768
					? setTimeout(() => setShow(false), 4000)
					: setTimeout(() => {}, 0);
		return () => {
			clearTimeout(time1);
			clearTimeout(time2);
		};
	}, [tooltip]);

	return <>{show && <div className='miracle-tone-tooltip'>{tooltip}</div>}</>;
};
export default MiracleToneTooltip;
