import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../../context/store-context';

import ProductsList from './ProductList';
import FrequentlyInfo from './FrequentlyInfo';

import './ProductFrequently.scss';

const ProductFrequently = ({
	frequentlyMainProductHandle,
	frequentlyProductsHandles,
}) => {
	const { addMultiVariantsToCart } = useContext(StoreContext),
		[selectedProductsIds, setSelectedProductsIds] = useState([]),
		[variantsForCart, setVariantsForCart] = useState([]),
		[variantsTotalSum, setVariantsTotalSum] = useState(null);

	useEffect(() => {
		if (selectedProductsIds.length > 0) {
			const arrayForCart = selectedProductsIds.map((item) => ({
					variantId: item.variantId,
					quantity: 1,
				})),
				arrayForSum = selectedProductsIds.map((item) =>
					parseInt(item.variantPrice)
				);
			setVariantsForCart(arrayForCart);
			setVariantsTotalSum(arrayForSum.reduce((a, b) => a + b));
		}
	}, [selectedProductsIds]);

	const addToCart = () => {
		addMultiVariantsToCart(variantsForCart);
	};

	return (
		<section className='frequently-section'>
			<div className='frequently-section__container container'>
				{frequentlyMainProductHandle && frequentlyProductsHandles ? (
					<ProductsList
						frequentlyMainProductHandle={
							frequentlyMainProductHandle
						}
						frequentlyProductsHandles={frequentlyProductsHandles}
						setSelectedProductsIds={setSelectedProductsIds}
						selectedProductsIds={selectedProductsIds}
					/>
				) : null}
				<FrequentlyInfo
					addToCart={addToCart}
					variantsTotalSum={variantsTotalSum}
				/>
			</div>
		</section>
	);
};

export default ProductFrequently;
