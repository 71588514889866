import { useContext, useEffect, useRef, useState } from 'react';
import { filterByTones, setScript } from './helpers';
import fetchMetaObjects from './fetchMetaObjects';
import fetchMetaImage from './fetchMetaImage';
import { StoreContext } from '../context/store-context';
import { submitErrorToSlack } from './slackMessage';
import fetchMetaImages from './fetchMetaImages';

export const useMetaObject = (metaObjectGlobalId) => {
	const [metaObject, setMetaObject] = useState(null);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const getMetaObject = async () => {
			try {
				const metaObjectResult = await fetchMetaObjects(
					metaObjectGlobalId
				);
				if (metaObjectResult) {
					setMetaObject(metaObjectResult);
				}
			} catch (error) {
				console.error(error);
				submitErrorToSlack(
					`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
					error,
					'GET'
				);
			}
		};
		getMetaObject();
	}, [update]);

	return { metaObject, setUpdate };
};


export const useMetaImages = (metaImagesID) => {
	const [metaImages, setMetaImages] = useState(null);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const getMetaImages = async () => {
			try {
				const metaImageResult = await fetchMetaImages(metaImagesID);
				if (metaImageResult) {
					setMetaImages(metaImageResult);
				}
			} catch (error) {
				console.error(error);
				submitErrorToSlack(
					`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
					error,
					'GET'
				);
			}
		};
		getMetaImages();
	}, [update]);
	return { metaImages, setUpdate };
};


export const useMetaImage = (metaImageGlobalId) => {
	const [metaImage, setMetaImage] = useState(null);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const getMetaImage = async () => {
			try {
				const metaImageResult = await fetchMetaImage(metaImageGlobalId);
				if (metaImageResult) {
					setMetaImage(metaImageResult);
				}
			} catch (error) {
				console.error(error);
				submitErrorToSlack(
					`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
					error,
					'GET'
				);
			}
		};
		getMetaImage();
	}, [update]);

	return { metaImage, setUpdate };
};

const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export const useWindowWidth = () => {
	const isWindowExist = typeof window !== 'undefined';

	const [width, setWidth] = useState(isWindowExist ? window.innerWidth : 0);

	useEffect(() => {
		if (!isWindowExist) return;

		const handleResize = debounce(() => {
			setWidth(window.innerWidth);
		}, 100);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isWindowExist]);

	return width;
};

export const useWindowHeight = () => {
	const isWindowExist = typeof window !== 'undefined',
		[height, setHeight] = useState(isWindowExist && window.innerHeight);

	useEffect(() => {
		const handleResize = () =>
			setHeight(isWindowExist && window.innerHeight);
		isWindowExist && window.addEventListener('resize', handleResize);
		return () => {
			isWindowExist && window.removeEventListener('resize', handleResize);
		};
	});

	return height;
};

export const useScroll = () => {
	const [lastScrollTop, setLastScrollTop] = useState(0),
		[bodyOffset, setBodyOffset] = useState(
			document.body.getBoundingClientRect()
		),
		[scrollY, setScrollY] = useState(bodyOffset.top),
		[scrollX, setScrollX] = useState(bodyOffset.left),
		[scrollDirection, setScrollDirection] = useState(),
		listener = (e) => {
			setBodyOffset(document.body.getBoundingClientRect());
			setScrollY(-bodyOffset.top);
			setScrollX(bodyOffset.left);
			setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
			setLastScrollTop(-bodyOffset.top);
		};

	useEffect(() => {
		window.addEventListener('scroll', listener);
		return () => {
			window.removeEventListener('scroll', listener);
		};
	});

	return {
		scrollY,
		scrollX,
		scrollDirection,
	};
};

const safeDocument = typeof document !== 'undefined' ? document : {};

export const useScrollBlock = () => {
	const scrollBlocked = useRef(),
		html = safeDocument.documentElement,
		{ body } = safeDocument,
		blockScroll = () => {
			if (!body || !body.style || scrollBlocked.current) return;

			const scrollBarWidth = window.innerWidth - html.clientWidth,
				bodyPaddingRight =
					parseInt(
						window
							.getComputedStyle(body)
							.getPropertyValue('padding-right')
					) || 0;

			html.style.overflow = 'hidden';
			body.style.overflow = 'hidden';
			body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

			scrollBlocked.current = true;
		},
		allowScroll = () => {
			if (!body || !body.style || !scrollBlocked.current) return;

			html.style.position = '';
			html.style.overflow = '';
			body.style.position = '';
			body.style.overflow = '';
			body.style.paddingRight = '';

			scrollBlocked.current = false;
		};

	return [blockScroll, allowScroll];
};

export const useHasMounted = () => {
	const [hasMounted, setHasMounted] = useState(false);
	useEffect(() => {
		setHasMounted(true);
	}, []);
	return hasMounted;
};

export const useQuantity = () => {
	const { cart } = useContext(StoreContext),
		items = cart ? cart.lines.edges : [],
		total = items.reduce((acc, item) => acc + item.node.quantity, 0);
	return [total !== 0, total];
};

export const useClickOutside = (ref, clickOutsideCallback) => {
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				clickOutsideCallback();
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [ref]);
};

export const useHideIsFooter = (linkRef) => {
	const [isHide, setIsHide] = useState(false),
		[scrollPosition, setScrollPosition] = useState(0),
		handleScroll = () => {
			if (linkRef.current) {
				const position = window.pageYOffset;
				setScrollPosition(position - linkRef.current.clientHeight);
			}
		};

	useEffect(() => {
		setIsHide(
			scrollPosition + 1000 >
				(document.querySelector('.footer')
					? document.querySelector('.footer').offsetTop
					: 0)
		);
	}, [scrollPosition]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return isHide;
};

export const useSeoData = (handle) => {
	const [seoData, setSeoData] = useState({});
	const isHome = handle && handle.length === 1;

	const cleanHandle =
		handle[handle.length - 1] === '/' ? handle.slice(0, -1) : handle;

	const newHandle = handle ? cleanHandle : '';

	const getSEOData = async () => {
		try {
			const response = await fetch(
				'https://cdn.shopify.com/s/files/1/0266/7223/4576/files/seo.json?v=1629796355'
			);

			if (!response.ok) {
				throw new Error('Failed to fetch SEO data');
			}
			const data = await response.json();

			if (!data) return;
			const obj = data[isHome ? handle : newHandle] || {
				description: newHandle,
				ogDescription: newHandle,
				ogTitle: newHandle,
			};

			setSeoData(obj);
		} catch (error) {
			console.error(error);
			submitErrorToSlack(process.env.SEO_JSON_LINK, error, 'GET');
		}
	};
	useEffect(() => {
		getSEOData();
	}, [newHandle]);

	return seoData;
};

export const useFilters = (products, tones, type) => {
	const [filterProducts, setFilterProducts] = useState(...products);
	useEffect(() => {
		let productsCopy = [...products];
		if (type) {
			productsCopy = productsCopy.filter((item) =>
				item.tags.includes(type)
			);
		}
		if (tones.length) {
			productsCopy = filterByTones(productsCopy, tones);
		}
		setFilterProducts(productsCopy);
	}, [products, tones, type]);
	return filterProducts;
};

export const getTag = (term, tags) => {
	for (let i = 0; i < tags.length; i++) {
		if (tags[i].includes(term)) return tags[i];
	}
	return false;
};

export const usePermWithShadeByTag = (product, list, title) => {
	const [shots, setShots] = useState([]);
	const { tags } = product;
	let withShadeShot = [];

	if (tags && tags.length) {
		const pcTag = getTag('pc_', tags) || getTag('pcss_', tags);

		if (pcTag) {
			const colourNumber = pcTag.split('_')[1];
			withShadeShot = list.filter(
				(item) =>
					getTag('pcss_', item.tags) &&
					item.title.includes(colourNumber)
			)[0];
		}
	}

	useEffect(() => {
		withShadeShot?.variants?.length &&
			setShots(
				withShadeShot.variants.filter(
					(item) => !item.title.toLowerCase().includes('shot')
				)
			);
	}, [product, title, withShadeShot]);

	return shots;
};

export const captureAttributionData = () => {
	const utmAndClid = [
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term',
		'gclid',
		'fbclid',
		'ttclid',
		'irclickid',
	];

	useEffect(() => {
		const url = window.location.href;

		const urlWithoutOrigin = url.substring(url.indexOf('?') + 1);

		const queryParams = new URLSearchParams(urlWithoutOrigin);

		const params = {};

		for (const [key, value] of queryParams.entries()) {
			if (utmAndClid.includes(key)) {
				params[key] = value;
			}
		}

		if (Object.keys(params).length > 0) {
			const fbcTtcAndIrcValidation = Object.keys(params).includes(
				'fbclid' || 'ttclid' || 'irclickid'
			);
			const utmValidation = !Object.keys(params).includes(
				'utm_source' ||
					'utm_medium' ||
					'utm_campaign' ||
					'utm_content' ||
					'utm_term'
			);
			const keysValidation = fbcTtcAndIrcValidation && utmValidation;

			if (keysValidation) {
				const existingAttributionData = JSON.parse(
					window.localStorage.getItem('attributionData') || '{}'
				);

				const mergedParams = {
					...existingAttributionData,
					...params,
				};

				window.localStorage.setItem(
					'attributionData',
					JSON.stringify(mergedParams)
				);
			} else {
				window.localStorage.setItem(
					'attributionData',
					JSON.stringify(params)
				);
			}
		}
	}, []);
};
