/* eslint-disable */
import React from 'react';
import { navigate } from 'gatsby';
import { ReactSVG } from 'react-svg';

import './MiracleStepsNav.scss';

const MiracleStepsNav = (props) => {
	const handleReset = () => {
			props.setIsSteps(false);
			props.setCurrentButtonText(1);
			props.setShotItem(null);
			props.setColourItem(null);
			props.setStep(1);
			props.setActiveSteps(['colour']);
			navigate('/');
		},
		backStep = () => {
			if (props.step === 1) {
				handleReset();
				return;
			}
			props.setStep((prevState) => {
				const newStep = prevState - 1;
				props.setCurrentButtonText(newStep);
				if (newStep !== 3) {
					props.setActiveItem(null);
				}
				props.handlePrevStep(props.step - 1);
				return newStep;
			});
		};

	return (
		<div className='miracle-steps-nav'>
			<div className='miracle-steps-nav__container container'>
				<button
					className='miracle-steps-nav__return'
					type='button'
					onClick={backStep}
				>
					<ReactSVG src='/images/miracle/steps/nav/miracle-left.svg' />
				</button>
				<span>
					{props.step} of {props.allSteps}
				</span>
				<button
					className='miracle-steps-nav__close'
					type='button'
					onClick={handleReset}
				>
					<ReactSVG src='/images/miracle/steps/nav/miracle-close.svg' />
				</button>
			</div>
		</div>
	);
};

export default MiracleStepsNav;
