import React from 'react';

import { isBrowser } from '../../../../utils/helpers';

import ModalRedListImg from './ModalRedListImg';

const ModalRedList = ({ list, title, type }) => (
	<>
		<div className='modal-red__list modal-red-list'>
			{isBrowser && window.outerWidth < 767 && type !== 'text' && (
				<div className='modal-red-list__item modal-red-list__item--text'>
					Your shade {type}
				</div>
			)}

			{
				/* eslint-disable-next-line no-confusing-arrow */
				(list || []).map((item) =>
					type !== 'text' ? (
						<div
							className='modal-red-list__img modal-red-list__item'
							key={item}
						>
							<ModalRedListImg
								title={title}
								type={type}
								item={item}
							/>
						</div>
					) : (
						<div
							className='modal-red-list__item modal-red-list__info'
							key={item}
						>
							<p className='modal-red-list__text'>{item}</p>
						</div>
					)
				)
			}
		</div>
	</>
);
export default ModalRedList;
