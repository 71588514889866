import React, { useState, useEffect } from 'react';

const ProductInfoDescription = ({ product }) => {
	const [isShow, setIsShow] = useState(false);
	const [hasSingleParagraph, setHasSingleParagraph] = useState(false);
	const btnText = isShow ? 'Read less' : 'Read more';
	const isLongDescription =
		product?.descriptionHtml?.replace(/<[^>]*>?/gm, '').length > 370

	useEffect(() => {
		const wrapper = document.createElement('div');
		wrapper.innerHTML = product?.descriptionHtml?.trim() || '';
		const paragraphs = wrapper.querySelectorAll('p');
		setHasSingleParagraph(paragraphs.length >= 1);

		const descriptionElement = document.querySelector(
			'.pdp-info__description'
		);
		if (descriptionElement) {
			const images = descriptionElement.querySelectorAll('img');
			images.forEach((img) => {
				const height = img.getAttribute('height');
				const width = img.getAttribute('width');
				if (height) img.style.height = `${height}px`;
				if (width) img.style.width = `${width}px`;
			});
		}
	}, [product?.descriptionHtml]);

	return (
		<>
			<div
				className={`pdp-info__description ${
					!isShow &&
					isLongDescription &&
					'pdp-info__description--overflow'
				} ${
					!isShow &&
					hasSingleParagraph &&
					'pdp-info__description--single-line'
				}`}
				dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }}
			/>
			{isLongDescription && (
				<button
					className='pdp-info__more-btn'
					onClick={() => setIsShow(!isShow)}
				>
					{btnText}
				</button>
			)}
		</>
	);
};

export default ProductInfoDescription;
