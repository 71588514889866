const miracle = {
	system: ['Colour', 'Tone', 'Condition'],
	howItWorks: {
		title: 'How it works',
		slides: [
			`Josh's unique innovation gives you the ultimate home hair colour, leaving your hair stronger and shinier than before colouring`,
			`Permanent hair colour opens hair cuticles to allow colour to penetrate, then locks the colour into the hair shaft. Traditionally conditioners are added after this process to the surface of the hair, where it can easily wash away.`,
			`The Miracle Shot is added during the colour process allowing conditioning particles to enter the hair shaft with the colour before the follicle closes. This unique process creates longer lasting hair colour, deeper condition and stronger hair than ever before.`,
		],
	},
	claims: ['62% less breakage', '2.7 times stronger', '38% less colour fade'],
	aboutJosh: {
		title: 'Meet Josh',
		text: `With over thirty years in the industry, Josh is called on by celebrities, clients and fashion designers around the world, for his genius with hair colour. He’s bottled his passion and expertise to allow everyone to create personalised, glossy colour that cares, wherever they are.`,
	},
	reviews: [
		{
			text: "'The entire product had no chemical smell, felt very luxurious in texture, was simple and provided a beautiful colour. My hair also felt soft and conditioned afterwards without the harsh box colour’ at home feeling'",
			author: 'Clodagh C',
		},
		{
			text: "'Simple application, no mess and it's done in no time. I personally love the natural colour result and would go as far as to say Josh Wood's home colouring system is fool proof'",
			author: 'Sharon B',
		},
		{
			text: "'I didn't think it was possible for my hair to feel any softer or look any shinier but it does.'",
			author: 'Beverley S',
		},
	],
	steps: ['Colour', 'Tone', 'Condition', 'Delivery'],
	stepsAccordion: {
		tone: {
			title: 'What’s a Shade Shot?',
			content:
				'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor.',
			video: {
				id: 'bdXVq9yy-tg',
				classname: 'miracle-tone-shot__video',
				title: 'What’s a Shade Shot?',
			},
		},
		condition: {
			title: 'Subscription FAQ',
			content: [
				{
					title: 'Lorem ipsum dolor sit amet?',
					text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, similique?',
				},
				{
					title: 'Lorem ipsum dolor sit amet?',
					text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, similique?',
				},
				{
					title: 'Lorem ipsum dolor sit amet?',
					text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, similique?',
				},
			],
		},
		delivery: {
			title: 'Auto-delivery FAQ',
			content: [
				{
					title: 'How much does the auto-delivery cost?',
					text: 'Miracle System costs £28.90 with a 15% discount, auto-delivery shipping is free',
				},
				{
					title: 'Can I amend my auto-delivery?',
					text: "It's super easy to amend your auto-delivery. You set your delivery schedule, and can adjust, skip, pause or cancel after the minimum orders are reached",
				},
				{
					title: 'Can I cancel my auto-delivery?',
					text: 'You can cancel anytime after the minimum orders are reached',
				},
			],
		},
	},
	buttonText: {
		1: 'Confirm base colour',
		2: 'Confirm shade shot',
		3: 'Select delivery option',
	},
};

export default miracle;
