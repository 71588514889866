// External
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import TextBlock from '../blocks/TextBlock';
import ButtonLink from '../ButtonLink';
import Breadcrumb from '../../BreadCrumb/Index';

import { EXPERTS_SECTION_BREADCRUMB } from '../../../constants/breadcrumbs';

import './style.scss';

const SalonBlock = ({ blok }) => {
	const {
		title,
		description,
		cTA,
		image,
		containImage,
		reverse,
		maxWidth,
		breadcumb,
	} = blok;
	const hasImage = image.filename !== '' && image.filename !== null;
	const style = hasImage ? {} : { maxWidth: `${maxWidth}px` };
	return (
		<section id='salon-block'>
			{breadcumb && <Breadcrumb section={EXPERTS_SECTION_BREADCRUMB} />}

			<div
				className={`salon-block salon-block--${
					hasImage ? 'has-image' : 'no-image'
				}`}
				style={style}
			>
				{hasImage && (
					<div
						className={`salon-block__img${
							containImage ? ' salon-block__contained-img' : ''
						}${reverse ? ' salon-block--reverse' : ''}`}
					>
						<LazyLoadImage
							src={image.filename}
							alt={image.alt}
							effect='blur'
						/>
					</div>
				)}
				<div className='salon-block__container'>
					<div className='salon-block__content'>
						<div className='salon-block__header'>
							<h1 className='salon-block__title'>{title}</h1>
							<div className='salon-block__description'>
								<TextBlock text={description} />
							</div>
						</div>
						{cTA[0] && <ButtonLink blok={cTA[0]} />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SalonBlock;
