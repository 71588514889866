import React from 'react';
import Video from '../../components/Video';

const SubHeaderSection = () => (
	<section className='about-page-sub-header'>
		<div className='about-page-sub-header__container container'>
			<div className='about-page-sub-header__text'>
				Creating beautiful, natural hair colour for clients has been my
				passion for over 30 years and I know the power it has to make us
				all feel good. I've bottled my expertise to allow everyone to
				create personalised, glossy colour wherever they are.
			</div>
			<div className='about-page-sub-header__video'>
				<Video videoId='_m2NhagIZbU' videoTitle='About video section' />
			</div>
		</div>
	</section>
);

export default SubHeaderSection;
