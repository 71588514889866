// External
import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

// Internal
import SalonHeaderButtons from './SalonHeaderButtons';
import Breadcrumb from '../../../components/BreadCrumb/Index';

import { EXPERTS_SECTION_BREADCRUMB } from '../../../constants/breadcrumbs';

import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';
import LocalImageOptimized from '../../../components/LocalImage/LocalImageOptimized';

const SalonHeader = ({ headerText }) => {
	SwiperCore.use([Autoplay, EffectFade]);

	return (
		<section className='salon-header'>
			<Breadcrumb section={EXPERTS_SECTION_BREADCRUMB} />
			<Swiper
				slidesPerView='1'
				effect='fade'
				autoplay={{ delay: 5000 }}
				className='salon-header--bg'
			>
				<SwiperSlide>
					<LocalImageOptimized
						imageUrl='salon/salon-bg-new-1.jpg'
						alt='Main salon background'
						loadingType='eager'
					/>
				</SwiperSlide>
				<SwiperSlide>
					<LocalImageOptimized
						imageUrl='salon/salon-bg-new-2.jpg'
						alt='Main salon background'
						loadingType='eager'
					/>
				</SwiperSlide>
				<SwiperSlide>
					<LocalImageOptimized
						imageUrl='salon/salon-bg-new-3.jpg'
						alt='Main salon background'
						loadingType='eager'
					/>
				</SwiperSlide>
			</Swiper>
			<div className='container'>
				<div className='salon-header__content'>
					<h1 className='salon-header__title'>
						{`${headerText.split(':')[0]}:`}
						<br />
						{headerText.split(':')[1]}
					</h1>
					<span className='salon-header__subtitle'>
						The Times 2020
					</span>
				</div>
				<SalonHeaderButtons />
			</div>
		</section>
	);
};

export default SalonHeader;
