import cartData from './fragments';
import request from '../request';

export default async (cartId, lines) => {
	if (!cartId) {
		throw new Error('Missing cartId in addToCart');
	}
	if (!lines) {
		throw new Error('Missing lines in addToCart');
	}

	const variables = {
		cartId,
		lines,
	};
	const query = `mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
        cartLinesAdd(lines: $lines, cartId: $cartId) {
            cart {
                ${cartData}
            }
        }
    }`;
	return request(query, variables);
};
