import React, { useEffect } from 'react';

import './InstagramFeed.scss';

const InstagramFeed = () => {
	useEffect(() => {
		const container = document.querySelector(
			'.elfsight-app-57555c7d-f312-42e9-9f2d-40291cbc5781'
		);

		if (container) {
			const observer = new MutationObserver(() => {
				const anchors = container.querySelectorAll('a');
				if (anchors.length > 0) {
					const lastAnchor = anchors[anchors.length - 1];
					lastAnchor.remove();
					observer.disconnect();
				}
			});
			observer.observe(container, { childList: true, subtree: true });
		}
	}, []);
	return (
		<div
			className='elfsight-app-57555c7d-f312-42e9-9f2d-40291cbc5781'
			data-elfsight-app-lazy
		/>
	);
};

export default InstagramFeed;
