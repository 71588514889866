import React from 'react';
import FooterForm from './FooterForm';

const FooterMiddle = () => (
	<div className='footer__middle'>
		<div className='footer__newsletter'>
			<div className='footer__newsletter-text'>
				<FooterForm />
			</div>
		</div>
	</div>
);

export default FooterMiddle;
