// External
import React, { useContext, useEffect } from 'react';

// Internal
import TextBlock from '../blocks/TextBlock';
import Klaviyo from '../../Klaviyo';
import Breadcrumb from '../../BreadCrumb/Index';
import { BLOG_SECTION_BREADCRUMB } from '../../../constants/breadcrumbs';

import '../BlogStyles.scss';
import ArticleFeaturedProducts from '../../ArticleFeaturedProducts';
import useFetchAllproducts from '../../../utils/useFetchAllproducts';
import ArticleRelatedArticles from '../../ArticleRelatedArticles/ArticleRelatedArticles';
import BlogRelatedProducts from '../../BlogRelatedProducts/Index';
import { StoreContext } from '../../../context/store-context';

const StoryblokBlog = ({ blok }) => {
	const {
		title,
		creationDate,
		content,
		relatedArticles,
		relatedProducts,
		author,
	} = blok;
	const { articles, setArticles } = useContext(StoreContext);
	const products = useFetchAllproducts();
	const formattedDate = new Date(creationDate).toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
	const fetchBlogs = async () => {
		const response = await fetch(
			`https://api.storyblok.com/v2/cdn/stories?starts_with=blogs/&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}`
		);

		const blogs = await response.json();

		setArticles(blogs);
	};

	useEffect(() => {
		if (articles.length === 0) {
			fetchBlogs();
		}
	}, []);

	const blogsRelated = articles?.stories
		?.filter((blog) => relatedArticles.includes(blog.uuid))
		.map((article) => ({
			id: article.id,
			full_slug: article.full_slug,
			image: article.content.body[0].featuredImage.filename,
			imageAlt: article.content.body[0].featuredImage.alt,
			title: article.content.body[0].title,
			storyblok_blog: true,
			publishedAt: article.created_at,
			tags: article.tag_list,
			content: article.content.body[0].content,
		}));

	return (
		<>
			<section className='blog-container'>
				<article className='article'>
					<Breadcrumb section={BLOG_SECTION_BREADCRUMB} />
					<h1 className='article__title'> {title} </h1>
					<h3 className='article__created'>
						{author && `By ${author}. `}
						Created on {formattedDate}
					</h3>
					<div>
						<TextBlock text={content} />
					</div>
					<div className='custom-klaviyo blog-klaviyo'>
						<Klaviyo id={process.env.KLAVIYO_FOOTER_FORM_ID} />
					</div>
				</article>
			</section>
			{blogsRelated?.length > 0 && (
				<ArticleRelatedArticles relatedArticles={blogsRelated} />
			)}
			{relatedProducts ? (
				<BlogRelatedProducts relatedProducts={relatedProducts} />
			) : (
				<ArticleFeaturedProducts article={blok} products={products} />
			)}
		</>
	);
};

export default StoryblokBlog;
