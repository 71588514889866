import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.scss';

const Guarantee = () => (
	<section className='guarantee'>
		<div className='guarantee__wrap'>
			<div className='guarantee__left'>
				<div className='guarantee__content'>
					<div className='guarantee__img'>
						<LazyLoadImage
							src='images/home-page/ellipse.webp'
							alt='Salon Quality colour'
							effect='blur'
						/>
					</div>
					<span>100%</span>
					<br />
					<strong>Money Back</strong>
					<br />
					Guarantee
				</div>
			</div>
			<div className='guarantee__right'>
				<h2 className='guarantee__title'>100% Money Back Guarantee</h2>
				<p className='guarantee__subtitle'>
					If you don’t get 100% feel-good hair results, we’ll do
					everything we can to help, including a full refund and
					support from our experts.
				</p>
			</div>
		</div>
	</section>
);

export default Guarantee;
