// External
import React, { useContext, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';

// Internal
import Fields from '../../../components/Fields';
import AccountForgotPassword from '../../../templates/account/AccountForgotPassword';
import { StoreContext } from '../../../context/store-context';
import { submitErrorToSlack } from '../../../utils/slackMessage';
import {
	setHrefLangTag,
	removeHrefLangTag,
} from '../../../helpers/setHrefLang';

import { goToCheckout } from '../../../utils/helpers';
import redirectToCheckoutPage from '../../../helpers/checkout';

const AccountLogin = () => {
	const { setAuthorized, cart } = useContext(StoreContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [forgotPassForm, setForgotPassForm] = useState(false);
	const [errorText, setErrorText] = useState('');
	const showReject = (errors) => {
		if (errors[0].code && errors[0].code === 'UNIDENTIFIED_CUSTOMER') {
			setErrorText('Incorrect email or password');
		}
	};
	const success = (data) => {
		setAuthorized(data.accessToken, email);
		if (redirectToCheckoutPage()) {
			goToCheckout(cart); 
		} else {
			navigate('/account');
		}
	};

	const sendForm = async (e) => {
		e.preventDefault();
		const url =
			`https://${process.env.SHOPIFY_STORE_URL}/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);

		const myHeaders = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		const graphql = JSON.stringify({
			query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
				customerAccessTokenCreate(input: $input) {
				  customerUserErrors {
					code
					field
					message
				  }
				  customerAccessToken {
					accessToken
					expiresAt
				  }
				}
			  }`,
			variables: { input: { email, password } },
		});
		try {
			const response = await axios.post(url, graphql, {
				headers: myHeaders,
			});

			if (!response?.data) {
				throw new Error('API request failed');
			}

			const customer = response.data?.data?.customerAccessTokenCreate;

			if (customer.customerUserErrors.length > 0) {
				return showReject(customer.customerUserErrors);
			}
			return success(customer.customerAccessToken);
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	useEffect(() => {
		setHrefLangTag('login-hreflang');

		return () => {
			removeHrefLangTag('login-hreflang');
		};
	}, []);

	return forgotPassForm ? (
		<>
			<AccountForgotPassword />
			<div className='signIn-form__resetPass'>
				<button onClick={() => setForgotPassForm(false)} type='button'>
					Cancel
				</button>
			</div>
		</>
	) : (
		<form
			onSubmit={sendForm}
			action='/'
			name='signin'
			className='account__login-form signIn-form'
		>
			<h1 className='signIn-form__title'>Login</h1>
			{errorText.length > 0 && (
				<div
					className={`account__message 
                form-message form-message--error`}
				>
					<h2 className='h3 form-message__title ' data-form-status=''>
						Please adjust the following:
					</h2>
					<div className='errors'>
						<ul>
							<li>{errorText}</li>
						</ul>
					</div>
				</div>
			)}
			<Fields.Email setValue={setEmail} value={email} />
			<Fields.Password setValue={setPassword} value={password} />
			<div className='signIn-form__resetPass'>
				<button onClick={() => setForgotPassForm(true)} type='button'>
					Forgot your password?
				</button>
			</div>

			<button
				type='submit'
				className='signIn-form__button signIn-form-button button button--dark'
			>
				<span className='signIn-form-button__text'>sign in</span>
			</button>

			<div className='signIn-form__create'>
				<Link to='/account/register'>Create account</Link>
			</div>
		</form>
	);
};

export default AccountLogin;
