import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import LocalImage from '../../components/LocalImage';

const AboutTheMiracleSystem = ({ windowWidth }) => (
	<div className='about-the-miracle-system'>
		<div className='about-the-miracle-system__text'>
			<h2 className='about-the-miracle-system__title'>
				The Miracle System
			</h2>
			{windowWidth >= 750 ? (
				<h3 className='about-the-miracle-system__subtitle'>
					Unlock the best shade of you with the POWER OF 3:
				</h3>
			) : (
				<h3 className='about-the-miracle-system__subtitle'>
					Unlock the best shade of you with <br />
					the POWER OF 3:
				</h3>
			)}
			<div className='about-the-miracle-system__list'>
				<div className='about-the-miracle-system__list__item'>
					<LocalImage image='miracle-about/kind-permanent-colour.png' />
					<p className='about-the-miracle-system__list__item__desc'>
						<strong>Kind Permanent Colour</strong>
						<br />
						100% grey coverage. No ammonia, smells great, colour
						that cares.
					</p>
				</div>
				<div className='about-the-miracle-system__list__item'>
					<LocalImage image='miracle-about/toning-shade-shot.png' />
					<p className='about-the-miracle-system__list__item__desc'>
						<strong>Toning Shade Shot</strong>
						<br />
						Glossy toner for personalised results to suit your skin
						and eyes.
					</p>
				</div>
				<div className='about-the-miracle-system__list__item'>
					<LocalImage image='miracle-about/repairing-miracle-shot.png' />
					<p className='about-the-miracle-system__list__item__desc'>
						<strong>Repairing Miracle Shot</strong>
						<br />
						Lock-in vitamins as you colour for softness, shine and
						strength
					</p>
				</div>
			</div>
			<div className='about-the-miracle-system__button'>
				<Link to='/miracle-steps'>
					<span>Find My Shade</span>
				</Link>
			</div>
		</div>
		<div className='about-the-miracle-system__image'>
			<StaticImage
				src='../../../static/images/home-page/image1.webp'
				className='about-the-miracle-static-image'
				height={600}
			/>
		</div>
	</div>
);

export default AboutTheMiracleSystem;
