import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { StoreContext } from '../context/store-context';
import usePreviewableStoryblokState from '../utils/usePreviewableStoryblokState';
import MiracleSteps from '../templates/miracle-steps';
import { submitErrorToSlack } from '../utils/slackMessage';

import { setHrefLangTag, removeHrefLangTag } from '../helpers/setHrefLang';

const StoryblokEntry = ({ data, location }) => {
	const [allSellingPlanGroups, setAllSellingPlanGroups] = useState({
		nodes: [],
	});
	const { sbClient } = useContext(StoreContext);

	const { story } = usePreviewableStoryblokState(
		sbClient,
		data.storyblokEntry
	);
	const isPage = story?.content?.component?.toLowerCase() === 'page';

	const schema =
		story?.full_slug === 'faq'
			? { itemScope: 'itemscope', itemType: 'https://schema.org/FAQPage' }
			: {};

	useEffect(() => {
		const fetchSellingPlanGroups = async () => {
			try {
				const response = await axios.get('/api/getSellingPlanGroups', {
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (!response.data) {
					throw new Error('API request failed');
				}

				const { data } = response.data;
				setAllSellingPlanGroups(data);
			} catch (error) {
				const status = error?.response?.status;
				if (status >= 500) {
					submitErrorToSlack(
						'/api/getSellingPlanGroups',
						error,
						'GET'
					);
				}
				console.error(error);
				return error;
			}
		};
		fetchSellingPlanGroups();

		setHrefLangTag('storyblok-hreflang');

		return () => {
			removeHrefLangTag('storyblok-hreflang');
		};
	}, []);

	if (story && story.full_slug === 'miracle-steps') {
		return (
			<MiracleSteps
				location={location}
				allSellingPlanGroups={allSellingPlanGroups}
				storyblokEntry={data.storyblokEntry}
			/>
		);
	}

	if (isPage) {
		return (
			<div
				className='landing'
				{...schema}
				{...storyblokEditable(story?.content)}
			>
				{story?.content?.body?.map((blok) => (
					<StoryblokComponent
						blok={blok}
						key={blok._uid}
						location={location}
						pageCollections={data.shopifyCollection}
						allSellingPlanGroups={allSellingPlanGroups}
					/>
				))}
			</div>
		);
	}
	return false;
};

export default StoryblokEntry;

export const query = graphql`
	query ($full_slug: String!) {
		storyblokEntry(full_slug: { eq: $full_slug }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		shopifyCollection(handle: { eq: $full_slug }) {
			handle
			title
			products {
				...ProductCardLanding
			}
		}
	}
`;
