import React from 'react';

const MiracleStepHeader = ({ title, text }) => (
	<div className='miracle-step-header'>
		<h4 className='miracle-step-header__title'>{title}</h4>
		<div className='miracle-step-header__text'>{text}</div>
	</div>
);

export default MiracleStepHeader;
