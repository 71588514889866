import React from 'react';

import LocalImage from '../../../../components/LocalImage';

import './ProductOneTestimonial.scss';

const ProductOneTestimonial = ({ isMobile = false, isHomePage = false }) => (
	<div className={`testimonial ${isHomePage && 'spacing-homepage'}`}>
		{isMobile && (
			<div className='testimonial__rating'>
				<span className='testimonial__star'>★★★★★</span>
			</div>
		)}
		<p className='testimonial__quote'>
			“I am always satisfied with the Miracle System, the kit is easy to
			use every time and provides shine and super soft hair plus it covers
			all my white hairs
			<span role='img' aria-label='emoji'>
				😌
			</span>
			. The color is what I need and absolutely fabulous for me. Josh Wood
			never disappoints!! Thank you so much.”
		</p>
		<div className='testimonial__author'>
			{!isMobile && (
				<LocalImage image='women-review.png' alt='Alexandra N.' />
			)}
			<div className='testimonial__details'>
				<span className='testimonial__name bold'>Alexandra N.</span>
				<span className='testimonial__verified'>Verified Buyer</span>
				{!isMobile && (
					<div className='testimonial__rating'>
						<span className='testimonial__star'>★★★★★</span>
					</div>
				)}
			</div>
		</div>
	</div>
);

export default ProductOneTestimonial;
