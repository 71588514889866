import React, { useContext, useEffect, useState } from 'react';
import ProductListItemV2 from './ProductListItemV2';

import { getMetafield, isAvailable, isShadeShot } from '../../utils/helpers';
import { StoreContext } from '../../context/store-context';

const { getSellingPlanGroup } = require('../../utils/node-helpers');

const ProductListItemContainerV2 = ({
	product,
	classname,
	cart,
	isHoverButton,
	withoutButton,
	collection,
	allSellingPlanGroups,
}) => {
	const { subscriptionsEnabled } = useContext(StoreContext),
		[sellingPlanGroup, setSellingPlanGroup] = useState(
			subscriptionsEnabled &&
				allSellingPlanGroups &&
				getSellingPlanGroup(
					product.storefrontId,
					allSellingPlanGroups.nodes
				)
		),
		[available, setAvailable] = useState(null),
		isRecommended = !!getMetafield(product, 'set_as_recommended'),
		isHide = getMetafield(product, 'is_hide'),
		isShot = isShadeShot(product);

	useEffect(() => {
		if (subscriptionsEnabled && allSellingPlanGroups) {
			setSellingPlanGroup(
				getSellingPlanGroup(
					product.storefrontId,
					allSellingPlanGroups.nodes
				)
			);
		}
	}, [allSellingPlanGroups]);

	useEffect(() => {
		setAvailable(isAvailable(product));
	}, []);

	return (
		<>
			{!product.title.includes('z_DISCONTINUED') &&
			!isHide &&
			product.handle !== 'permanent-colour-8-5' &&
			!product.handle.includes('_b') &&
			product.handle !== 'miracle-shot' &&
			product.handle !== 'chestnut-brunette-miracle-shot' &&
			!product.handle.includes('system-old') ? (
				<ProductListItemV2
					collectionName={collection?.title || ''}
					available={available}
					isShot={isShot}
					classname={classname}
					cart={cart}
					isHoverButton={isHoverButton}
					withoutButton={withoutButton}
					isRecommended={isRecommended}
					product={product}
					sellingPlanGroup={sellingPlanGroup}
					subscriptionsEnabled={subscriptionsEnabled}
				/>
			) : null}
		</>
	);
};

export default ProductListItemContainerV2;