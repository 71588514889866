/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { APIProvider, Map, useApiIsLoaded } from '@vis.gl/react-google-maps';

import { storeSorting } from '../../helpers/storeSorting';
import MapHandler from '../../components/StoreFinder/Map';
import PlaceAutocomplete from '../../components/StoreFinder/PlaceAutocomplete';
import MarkerWithInfowindow from '../../components/StoreFinder/Map/MarkerWithInfowindow';
import Directions from '../../components/StoreFinder/Directions';
import ListFinderItem from '../../components/StoreFinder/ListFinderItem';

import './StoreFinder.scss';

const tabsFinder = [
	{
		id: 'store',
		label: 'In Store',
	},
	{
		id: 'online',
		label: 'Online',
	},
];

const StoreFinder = ({ pageContext: { stores, storesOnline } }) => {
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [directions, setDirections] = useState(null);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [tabSelected, setTabSelected] = useState('store');
	const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);
	const storesLocations = stores && JSON.parse(stores);
	const storesOnlineLocations = storesOnline && JSON.parse(storesOnline);

	const coordinatesPlace = selectedPlace?.geometry?.location?.lat() !==
		undefined &&
		selectedPlace?.geometry?.location?.lng() !== undefined && {
			latitude: selectedPlace.geometry.location.lat(),
			longitude: selectedPlace.geometry.location.lng(),
		};

	const coordinatesToOrder = coordinatesPlace || {
		latitude: directions?.coordinates?.latitude,
		longitude: directions?.coordinates?.longitude,
	};

	const storesToShow = storeSorting(storesLocations, coordinatesToOrder);

	const apiIsLoaded = useApiIsLoaded();

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setCurrentLocation({
						lat: latitude,
						lng: longitude,
						initial: true,
					});
				},
				(err) => {
					console.error(err);
				}
			);
		}
	}, []);

	useEffect(() => {
		// eslint-disable-next-line no-useless-return
		if (!apiIsLoaded) return;
	}, [apiIsLoaded]);

	return (
		<div className='store-finder'>
			<div className='store-finder__initial'>
				<h2>Where to find us</h2>
			</div>
			<APIProvider apiKey='AIzaSyDMdjS9CYeYALeY4v-O2KbX-NwjfGISYIU'>
				<div className='store-finder__content'>
					<div className='store-finder__locations'>
						<div className='store-finder__locations--tabs'>
							{tabsFinder.map((tab) => (
								<button
									className={`store-finder__locations--tabs__item ${
										tabSelected === tab.id && 'active--item'
									}`}
									key={tab.id}
									onClick={() => setTabSelected(tab.id)}
								>
									{tab.label}
								</button>
							))}
						</div>

						{tabSelected === 'store' && (
							<>
								<div className='container-form-store'>
									<div className='label-form-store'>
										Enter a location
									</div>
									<div className='autocomplete-control'>
										<PlaceAutocomplete
											onPlaceSelect={setSelectedPlace}
											currentLocation={currentLocation}
											setCurrentLocation={
												setCurrentLocation
											}
											setDirections={setDirections}
											storesToShow={storesToShow}
											setIsAutocompleteVisible={
												setIsAutocompleteVisible
											}
										/>
									</div>
								</div>
								<div
									className={`store-finder__locations__list ${
										isAutocompleteVisible && 'menu-open'
									}`}
								>
									{storesToShow && storesToShow.length > 0 && (
										<>
											{storesToShow.map(
												(store, index) => (
													<ListFinderItem
														key={`${
															store?.store_number_id
														} - ${index + 1}`}
														store={store}
														selectedPlace={
															selectedPlace
														}
														setSelectedPlace={
															setSelectedPlace
														}
														setDirections={
															setDirections
														}
													/>
												)
											)}
										</>
									)}
								</div>
							</>
						)}

						{tabSelected === 'online' && (
							<div className='store-finder__locations__list'>
								{storesOnlineLocations.map((store, index) => (
									<ListFinderItem
										key={`${store?.store_number_id} - ${
											index + 1
										}`}
										store={store}
									/>
								))}
							</div>
						)}
					</div>
					<div className='store-finder__map'>
						<Map
							mapId='bf51a910020fa25a'
							defaultZoom={5}
							defaultCenter={{
								lat: currentLocation?.lat || 51.507351,
								lng: currentLocation?.lng || -0.127758,
							}}
							gestureHandling='greedy'
							streetViewControl={false}
							mapTypeControlOptions={{
								mapTypeIds: ['coordinate', 'roadmap'],
							}}
						>
							{storesLocations &&
								storesLocations.length > 0 &&
								storesLocations.map((store, index) => (
									<MarkerWithInfowindow
										key={`${store?.store_number_id} - ${
											index + 1
										}`}
										lat={store?.coordinates?.latitude || 0}
										lng={store?.coordinates?.longitude || 0}
										title={store?.store_name}
										{...store}
									/>
								))}
							{directions && currentLocation && (
								<Directions
									position={directions}
									current={currentLocation}
								/>
							)}
						</Map>

						<MapHandler
							place={selectedPlace}
							current={currentLocation}
						/>
					</div>
				</div>
			</APIProvider>
		</div>
	);
};

export default StoreFinder;
