/* eslint-disable */
import React, { useEffect } from 'react';

import '../ModalPermanentDesktop.scss';
import LocalImage from '../../../LocalImage';

const CurrentChoice = ({ selectedBaseColour, selectedShot }) => {
	return (
		<div className='modal-permanent__current-choice modal-permanent-current-choice'>
			{selectedBaseColour.image && (
				<div className='modal-permanent-current-choice__image'>
					<LocalImage
						alt={selectedBaseColour.description}
						image={selectedBaseColour.image}
					/>
				</div>
			)}
			<div className='modal-permanent-current-choice__text'>
				{selectedBaseColour.title && (
					<span>{selectedBaseColour.title}</span>
				)}
				{selectedBaseColour.description && (
					<h3>{selectedBaseColour.description}</h3>
				)}
				{selectedShot.title && ` + ${selectedShot.title}`}
			</div>
		</div>
	);
};

export default CurrentChoice;
