/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import ColourNavigation from '../Colour/ColourNavigation';
import SliderItem from './SliderItem';

import './Slider.scss';

const MiracleSlider = ({
	items,
	type,
	settings,
	query,
	onClick,
	selectedBase,
	selectedTone,
}) => {
	const [swiper, setSwiper] = useState(null);

	useEffect(() => {
		if (swiper) {
			swiper.slideTo(settings.initialSlide);
		}
	}, [swiper]);
	
	const extractNumber = str => {
		const regex = /(\d+\.\d+)/;
		const match = str.match(regex);
		return match ? parseFloat(match[1]) : 0;
	};
	
	const itemsSort = items?.sort((a, b) => extractNumber(a.title) - extractNumber(b.title));
	
	return (
		<>
			<div
				className={`animate permanent-slider permanent-colour-list permanent-slider--animate"`}
			>
				<Swiper onSwiper={setSwiper} {...settings}>
					{itemsSort?.map((item, i) => {
						const image =
							type === 'colour'
								? `pdp/base/base-${item.handle.split('-')[2]
								}-${item.handle.split('-')[3]}.webp`
								: `pdp/${selectedBase.replace('-', '.')}/${item.title === 'Default Title'
									? 'black-onyx'
									: item.title
										.toLowerCase()
										.replace(' ', '-')
								}.webp`,
							title =
								type === 'colour'
									? parseFloat(
										item.title.match(
											/[-+]?([0-9]*\.[0-9]+|[0-9]+)/
										)
									).toFixed(1)
									: item.title === 'Default Title'
										? 'Black Onyx'
										: item.title.split(' - ')[0],
							description =
								type === 'colour'
									? item.title.split(' - ')[1]
									: null;
						return (
							<SwiperSlide
								key={i}
								className={type === 'colour' && 'active-slide'}
								onClick={() => onClick(item.title)}
							>
								<SliderItem
									{...{
										type,
										item,
										image,
										title,
										description,
										query,
										selectedBase,
									}}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
				<div className='pdp-swiper-prev swiper-button-prev' />
				<div className='pdp-swiper-next swiper-button-next' />
			</div>
		</>
	);
};
export default MiracleSlider;