import React, { useState } from 'react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useWindowWidth } from '../../../utils/hooks';
import TrustPilot from '../../../components/TrustPilot';
import LocalImage from '../../../components/LocalImage';

import 'swiper/swiper-bundle.min.css';

import './ProductCustomers.scss';

SwiperCore.use([Navigation, A11y]);

const images = [
	'pdp/customers/woman-1.png',
	'pdp/customers/woman-2.png',
	'pdp/customers/woman-3.png',
	'pdp/customers/woman-4.png',
	'pdp/customers/woman-5.png',
	'pdp/customers/woman-6.png',
	'pdp/customers/woman-7.png',
	'pdp/customers/woman-8.png',
	'pdp/customers/woman-1.png',
	'pdp/customers/woman-2.png',
	'pdp/customers/woman-3.png',
	'pdp/customers/woman-4.png',
	'pdp/customers/woman-5.png',
];

const ProductCustomers = () => {
	const windowWidth = useWindowWidth();

	const [swiper, setSwiper] = useState(null);

	const goNext = () => {
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	const isMobile = windowWidth < 750;
	return (
		<div className='pdp-customers'>
			<h2>
				Check out what other customers are loving about their results
			</h2>

			<TrustPilot id='5419b6ffb0d04a076446a9af' height='25px' />
			<Swiper
				breakpoints={{
					320: {
						spaceBetween: 20,
						slidesPerView: 1.5,
						centeredSlides: true,
					},
					750: {
						spaceBetween: 10,
						slidesPerView: 5,
					},
					1320: {
						spaceBetween: 20,
						slidesPerView: 6,
					},
					1500: {
						spaceBetween: 20,
						slidesPerView: 6,
					},
				}}
				navigation={{
					prevEl: '#custom-prev',
					nextEl: '#custom-next',
				}}
				onSwiper={(swiper) => setSwiper(swiper)}
				loop
			>
				{images.map((src, index) => (
					<SwiperSlide key={`img-${src}-${index + 1}`}>
						<LocalImage image={src} alt={`Customer ${index}`} />
					</SwiperSlide>
				))}
			</Swiper>
			{!isMobile && (
				<div className='custom-navigation'>
					<button
						id='custom-prev'
						className='nav-button-prev'
						onClick={goPrev}
					/>

					<button
						id='custom-next'
						className='nav-button-next'
						onClick={goNext}
					/>
				</div>
			)}
		</div>
	);
};

export default ProductCustomers;
