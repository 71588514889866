import React, { useState } from 'react';
import cn from 'classnames';

import './DeliverySubscriptionSelect.scss';

const DeliverySubscriptionSelect = ({
	options = [],
	selectedValue,
	setSelectedValue,
}) => {
	const [openedSelect, setOpenedSelect] = useState(false),
		handleClick = (val) => {
			setSelectedValue(val);
			setOpenedSelect(false);
		};

	return (
		<div className='delivery-select'>
			<div
				className={cn('subscription-select', {
					'subscription-select--opened': openedSelect,
				})}
			>
				<div
					role='button'
					tabIndex={0}
					onKeyPress={() => setOpenedSelect(!openedSelect)}
					onClick={() => setOpenedSelect(!openedSelect)}
					className={`subscription-select__value 
                    subscription-select-value`}
				>
					<div className='subscription-select-value__label'>
						<span>{options[selectedValue]}</span>
					</div>
				</div>
				<ul className='subscription-select__list'>
					{(options || []).map((text, i) => (
						<li
							key={`miracle-steps-${text}`}
							role='option'
							aria-selected={selectedValue && selectedValue === i}
							onClick={() => handleClick(i)}
							className={cn(
								'subscription-select__option subscription-select-option',
								{
									'subscription-select-option--active':
										selectedValue && selectedValue === i,
								}
							)}
						>
							<div className='subscription-select-option__label'>
								<span>{text}</span>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default DeliverySubscriptionSelect;
