import Cookies from 'js-cookie';
import { useEffect } from 'react';
import addItemsQuizToCart from '../helpers/addItemsQuizToCart';

const useAskFlowAddToCartButtons = (
	addVariantToCart,
	addMultiVariantsToCart
) => {
	useEffect(() => {
		const handleListenMessages = async (event) => {
			const { type, askflowId, product, products } = event.data;
			const askflowAttribute = [{ key: 'askflowId', value: askflowId }];

			if (type === 'ASKFLOW_ADD_PRODUCT_TO_CART' && product?.variantId) {
				const variantId = `gid://shopify/ProductVariant/${product.variantId}`;

				addItemsQuizToCart(product.variantId);
				addVariantToCart(variantId, 1, askflowAttribute);
			} else if (
				type === 'ASKFLOW_ADD_ALL_PRODUCTS_TO_CART' &&
				products
			) {
				const userId = Cookies.get('_sc_uuid');
				const testNumber = Cookies.get('_sc_test');
				const testVariant = Cookies.get(`_sc_${testNumber}`);
				const shipscoutAttribute = [
					{
						key: '_sc',
						value: `${userId}_${testNumber}_${testVariant}`,
					},
				];

				const lineItemsToAdd = products.map((product) => {
					if (product?.variantId) {
						addItemsQuizToCart(product.variantId);
						return {
							variantId: `gid://shopify/ProductVariant/${product.variantId}`,
							attributes: [...shipscoutAttribute, ...askflowAttribute],
						};
					}
					return null;
				});

				addMultiVariantsToCart(lineItemsToAdd);
			}
		};

		window.addEventListener('message', handleListenMessages);

		return () => {
			window.removeEventListener('message', handleListenMessages);
		};
	}, []);
};

export default useAskFlowAddToCartButtons;
