import React, { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { StoreContext } from '../../../context/store-context';

import './ModalCustomerInfo.scss';
import { submitErrorToSlack } from '../../../utils/slackMessage';

const ModalCustomerInfo = ({ modal }) => {
	const { showModal } = useContext(StoreContext);
	const addressesId = modal.info.item.id;
	const refetch = modal.info.refetch;
	const [addressData, setAddressData] = useState({});
	const [isOpenSelect, setOpenSelect] = useState(false);
	const [errorText, setErrorText] = useState(null);
	const countriesCodes = ['GB', 'US'];
	const { token } = useContext(StoreContext);
	const select = useRef();
	const updateAddress = async (body) => {
			const url =
				`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
					/\s+/g,
					''
				);

			try {
				const response = await axios.post(url, body, {
					headers: {
						'X-Shopify-Storefront-Access-Token':
							process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
						'Content-Type': 'application/json',
					},
				});

				if (!response.data) {
					throw new Error('API request failed');
				}

				const data = response.data;

				if (data.errors) {
					if (
						data.errors.province &&
						data.errors.province[0] === 'is not valid'
					) {
						setErrorText('Change the postcode for this region');
					} else {
						setErrorText('Please fill in at least one field');
					}
				} else {
					refetch();
					showModal();
					setErrorText(null);
				}
			} catch (error) {
				setErrorText('Something was wrong');
				const status = error?.response?.status;
				if (status >= 500) {
					submitErrorToSlack(url, error, 'POST');
				}
				console.error(error);
				return error;
			}
		},
		handleClickOutside = (e) => {
			if (!select.current.contains(e.target)) {
				setOpenSelect(false);
			}
		};

	useEffect(() => {
		if (isOpenSelect) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpenSelect]);

	useEffect(() => {
		if (modal.info.item) {
			const { id, ...rest } = modal.info.item;
			setAddressData(rest);
		}
		return () => {
			setAddressData({});
			setOpenSelect(false);
			setErrorText(null);
		};
	}, [modal.info.item]);

	const chooseCountry = (item) => {
		if (item.toLowerCase().replace(/ /g, '_') !== 'select_country') {
			setAddressData({
				...addressData,
				country: item,
			});
			setOpenSelect(false);
		}
	};
	const submit = (e) => {
		e.preventDefault();
		const graphql = JSON.stringify({
			query: `mutation customerAddressUpdate(
                    $customerAccessToken: String!, 
                    $id: ID!, 
                    $address: MailingAddressInput!
				) {
                    customerAddressUpdate(
                      customerAccessToken: $customerAccessToken
                      id: $id
                      address: $address
                    ) {
                      customerAddress {
                        id
                      }
                      customerUserErrors {
                        code
                        field
                        message
                      }
                    }
                  }
                `,
			variables: {
				customerAccessToken: token,
				id: addressesId,
				address: addressData,
			},
		});
		updateAddress(graphql);
		refetch();
	};

	return (
		<div className='modal-customer-info'>
			<div className='modal-customer-info__title'>Changes address</div>
			{errorText && (
				<div className='modal-customer-info__error'>{errorText}</div>
			)}
			<form onSubmit={submit} className='modal-customer-info__form'>
				<div
					className={`modal-customer-info__item 
					modal-customer-info-item`}
				>
					<div className='modal-customer-info-item__label'>
						First name (optional)
					</div>
					<input
						onChange={(e) =>
							setAddressData({
								...addressData,
								firstName: e.target.value,
							})
						}
						className='modal-customer-info-item__input'
						type='text'
						value={addressData.firstName || ''}
					/>
				</div>
				<div
					className={`modal-customer-info__item 
					modal-customer-info-item`}
				>
					<div className='modal-customer-info-item__label'>
						Last name
					</div>
					<input
						onChange={(e) =>
							setAddressData({
								...addressData,
								lastName: e.target.value,
							})
						}
						className='modal-customer-info-item__input'
						type='text'
						value={addressData.lastName || ''}
					/>
				</div>
				<div
					className={`modal-customer-info__item 
					modal-customer-info-item`}
				>
					<div className='modal-customer-info-item__label'>
						Address
					</div>
					<input
						onChange={(e) =>
							setAddressData({
								...addressData,
								address1: e.target.value,
							})
						}
						className='modal-customer-info-item__input'
						type='text'
						value={addressData.address1 || ''}
					/>
				</div>
				<div
					className={`modal-customer-info__item 
                    modal-customer-info-item`}
				>
					<div className='modal-customer-info-item__label'>City</div>
					<input
						onChange={(e) =>
							setAddressData({
								...addressData,
								city: e.target.value,
							})
						}
						className='modal-customer-info-item__input'
						type='text'
						value={addressData.city || ''}
					/>
				</div>
				<div
					className={`modal-customer-info__item 
                    modal-customer-info-item`}
				>
					<div className='modal-customer-info-item__label'>
						Postcode
					</div>
					<input
						onChange={(e) =>
							setAddressData({
								...addressData,
								zip: e.target.value,
							})
						}
						className='modal-customer-info-item__input'
						type='text'
						value={addressData.zip || ''}
					/>
				</div>
				<div
					ref={select}
					className={cn(
						'modal-customer-info__item modal-customer-info-item modal-customer-info-select', // eslint-disable-line
						{ 'modal-customer-info-select--opened': isOpenSelect }
					)}
				>
					<div
						onClick={() => setOpenSelect(!isOpenSelect)}
						className='modal-customer-info-select__open'
					/>
					{isOpenSelect && (
						<ul className='modal-customer-info-select__list'>
							{countriesCodes.length &&
								countriesCodes.map((item) => (
									/* eslint-disable-next-line */
									<li
										onClick={() => chooseCountry(item)}
										key={item}
									>
										{item}
									</li>
								))}
						</ul>
					)}
					<div className='modal-customer-info-item__label'>
						Country/region
					</div>
					<input
						className='modal-customer-info-item__input'
						type='text'
						disabled
						value={addressData.country || 'Select country'}
					/>
				</div>
				<button
					className='modal-customer-info-item__submit button'
					type='submit'
				>
					Save address
				</button>
			</form>
		</div>
	);
};

export default ModalCustomerInfo;
