import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useWindowWidth } from '../../../utils/hooks';
import { StoreContext } from '../../../context/store-context';
import './PermanentSelection.scss';

const PermanentSelection = ({
	allBaseColours,
	product,
	productsList,
	miracleSystem,
	shadeShotsProducts,
	withShadeShot,
	sellingPlanGroup,
	options,
}) => {
	const windowWidth = useWindowWidth(),
		{ setModal, showModal  } =
			useContext(StoreContext),
		[isOpen, setIsOpen] = useState(false),
		handleClick = () => {
			if (windowWidth > 750) {
				setModal(null, 'permanent-selection', null, {
					allBaseColours,
					product,
					productsList,
					shadeShotsProducts,
				});
				showModal(true);
			} else {
				setModal(null, 'permanent-selection-mobile', null, {
					allBaseColours,
					product,
					shadeShotsProducts,
					miracleSystem,
					withShadeShot,
					sellingPlanGroup,
					options,
				});
				showModal(true);
			}
		};
	useEffect(() => {
		setIsOpen(true);
	}, []);


	return (
		<div
			onClick={handleClick}
			className={cn('permanent-selection', {
				'permanent-selection--active': isOpen,
			})}
		>
			<span className='permanent-selection__text'>
				See other {windowWidth < 400 && <br />} colours
			</span>
		</div>
	);
};

export default PermanentSelection;
