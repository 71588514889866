import React, { useEffect, useState, useRef } from 'react';
import SelectList from './SelectList';
import RightSide from './RightSide/RightSide';
import { usePermWithShadeByTag } from '../../../utils/hooks';
import './ModalPermanentDesktop.scss';

const ModalPermanentDesktop = ({
	modal,
	currentStep,
	setCurrentStep,
	activeModal,
	handleClose,
}) => {
	const { allBaseColours, product, productsList, shadeShotsProducts } = modal.info;

	const [selectedBaseProduct, setSelectedBaseProduct] = useState({});
	const [selectedBaseColour, setSelectedBaseColour] = useState({});
	const [selectedShot, setSelectedShot] = useState({});
	const [baseColours, setBaseColours] = useState(allBaseColours)
	const productContainsShadeShot = product.title.includes('Shade Shot');

	const shots = usePermWithShadeByTag(selectedBaseProduct, productsList);

	const previousUrl = useRef(window.location.pathname);

	const currentUrl = window.location.pathname;

	const isSelectionFromCart =
		modal.type === 'permanent-selection-from-cart' &&
		Array.isArray(shots) &&
		shots.length > 0;

	useEffect(() => {
		localStorage.setItem(
			'modalPermanentData',
			JSON.stringify({ allBaseColours, product })
		);
	}, [allBaseColours, product]);

	useEffect(() => {
		isSelectionFromCart ? setCurrentStep(2) : setCurrentStep(1);
	}, [isSelectionFromCart, activeModal]);

	useEffect(() => {
		setSelectedShot(!activeModal);
	}, [activeModal]);

	useEffect(()=>{
		if(productContainsShadeShot){
			const baseColoursHasShade = baseColours.filter((item) => {
				const hasProductShade = shadeShotsProducts.some((shade) => {
					const isTitleInclude = shade.title.includes(item.title)
					return isTitleInclude;
				});
				return hasProductShade;
			});
			setBaseColours(baseColoursHasShade);
		}else{
			setBaseColours(allBaseColours);
		}
	}, [productContainsShadeShot]);

	useEffect(() => {
		setSelectedBaseProduct(
			isSelectionFromCart ? modal.info.upsellBaseColour : product
		);

		isSelectionFromCart
			? setSelectedBaseColour({
					id: modal.info.upsellBaseColour.storefrontId,
					title: parseFloat(
						modal.info.upsellBaseColour.product.title.match(
							/[-+]?([0-9]*\.[0-9]+|[0-9]+)/
						)
					).toFixed(1),
					description: product.handle
						.split('-')
						.filter((_i, index) => index === 4 || index === 5)
						.join(' '),
					image: `pdp/base/base-${
						modal.info.upsellBaseColour.product.handle.split('-')[2]
					}-${
						modal.info.upsellBaseColour.product.handle.split('-')[3]
					}.webp`,
			  })
			: setSelectedBaseColour({
					id: product.variants[0].storefrontId,
					title: parseFloat(
						product.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
					).toFixed(1),
					description: product.handle
						.split('-')
						.filter((_i, index) => index === 4 || index === 5)
						.join(' '),
					image: `pdp/base/base-${product.handle.split('-')[2]}-${
						product.handle.split('-')[3]
					}.webp`,
			  });
	}, [isSelectionFromCart, product]);

	useEffect(() => {
		if (previousUrl.current !== currentUrl) handleClose();
	}, [currentUrl]);

	return (
		<div className='modal-permanent'>
			<div className='modal-permanent__selection modal-permanent-selection'>
				<h3 className='modal-permanent-selection__title'>
					{currentStep === 1
						? 'Choose your base colour'
						: 'Choose your tone'}
				</h3>
				<SelectList
					data={
						currentStep === 1
							? baseColours.filter(
									(item) => !item.title.includes('8.5') 
							  )
							: shots
					}
					currentStep={currentStep}
					productHandle={product}
					setSelectedBaseProduct={setSelectedBaseProduct}
					selectedBaseColour={selectedBaseColour}
					setSelectedBaseColour={setSelectedBaseColour}
					selectedShot={selectedShot}
					setSelectedShot={setSelectedShot}
					productsList={productsList}
					handleClose={handleClose}
					setCurrentStep={setCurrentStep}
				/>
			</div>
			<RightSide
				setCurrentStep={setCurrentStep}
				currentStep={currentStep}
				selectedShot={selectedShot}
				selectedBaseColour={selectedBaseColour}
				productsList={productsList}
				product={productContainsShadeShot}
				handleClose={handleClose}
			/>
		</div>
	);
};

export default ModalPermanentDesktop;