import cartData from './fragments';
import request from '../request';

export default async (cartId, attributes) => {
	const variables = {
		cartId,
		attributes,
	};
	const query = `mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
        cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
            cart {
                ${cartData}
            }
        }
    }`;
	return request(query, variables);
};
