import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ModalStepsFirst = ({ modal }) => {
	const { title, text, subtext, image } = modal.info;

	return (
		<div className='modal-steps-first'>
			<div className='modal-steps-first__content'>
				<h3 className='modal-steps-first__title'>{title}</h3>
				<p className='modal-steps-first__text'>{text}</p>
				<p className='modal-steps-first__info'>{subtext}</p>
			</div>
			<div className='modal-steps-first__image'>
				<LazyLoadImage
					src={image.filename}
					alt={image.alt}
					effect='blur'
					wrapperClassName='lazyLoad-wrapper-img'
				/>
			</div>
		</div>
	);
};
export default ModalStepsFirst;
