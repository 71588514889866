import React, { useState } from 'react';
import { homePage } from '../../../../constants/home-page';
import Button from '../../../Button';

const SectionAbout = () => {
	const about = homePage.about;
	const [isOpenText, setOpenText] = useState(false),
		shortText = about.text.substr(0, 200),
		restOfText = about.text.substr(200),
		currentString = `<div class="landing-about__desc--visible">${shortText}</div><div class="${
			isOpenText
				? 'landing-about__desc--visible'
				: 'landing-about__desc--invisible'
		}">${restOfText}</div>`;

	return (
		<div className='landing-about'>
			<h2 className='landing-about__title'>{about.title}</h2>
			<div className='landing-about__desc'>
				<div
					className='previous-name'
					dangerouslySetInnerHTML={{ __html: currentString }}
				/>
				{!isOpenText && (
					<span
						onClick={() => setOpenText(true)}
						className='discover-color-section__load-more'
					>
						Read more
					</span>
				)}
				{isOpenText && (
					<span
						onClick={() => setOpenText(false)}
						className='landing-about__read-more'
					>
						Read less
					</span>
				)}
			</div>
			<p className='landing-about__quote'>
				Shop the UK's best home hair colour.
			</p>
			<Button
				isNotProduct
				buttonText='Shop All'
				isLink
				isDark
				isHalfWidth
				linkUrl='/collections/all-products'
			/>
		</div>
	);
};

export default SectionAbout;
