import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import TimelyButton from '../../components/TimelyButton';

const SalonPriceHeading = ({ headerText }) => (
	<section className='salon-price-heading'>
		<div className='salon-price-heading__container container'>
			<div className='salon-price-heading__row'>
				<div className='salon-price-heading__info'>
					<h1 className='salon-price-heading__title'>{headerText}</h1>
					<p className='salon-price-heading__text'>
						Nestled in a mews house in London’s Holland Park, the
						Josh Wood Colour Atelier is home to Josh and his
						handpicked, dream team of colourists and stylists. Famed
						for their ability to create personalised colour,
						tailored for the individual.
					</p>
					<a
						className={`salon-price-heading__link 
                        salon-price-heading__link--mail`}
						href='mailto:reservations@joshwoodcolour.com'
					>
						reservations@joshwoodcolour.com
					</a>
					<a
						className='salon-price-heading__link'
						href='tel:+442033930977'
					>
						+44 20 3393 0977
					</a>
					<div
						className={`salon-price-heading__buttons 
                        salon-price-heading-buttons`}
					>
						<div className='salon-price-heading-buttons__item'>
							<TimelyButton
								html={
									'<script>new timelyButton("joshwoodcolour", {"style":"dark"});</script>'
								}
							/>
						</div>
						<div className='salon-price-heading-buttons__item'>
							<Link
								className={`button button--light 
                                button--hover-pink`}
								to='/pages/salon#map'
							>
								visit the atelier
							</Link>
						</div>
					</div>
				</div>
				<LazyLoadImage
					className='salon-price-heading__image'
					src='/images/salon_price_list/heading-image.jpg'
					alt='heading'
					effect='blur'
					wrapperClassName=''
				/>
			</div>
		</div>
	</section>
);
export default SalonPriceHeading;
