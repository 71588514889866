/* eslint-disable */
import React from 'react';

import { useWindowWidth } from '../../../utils/hooks';

const MiracleDeliveryInfo = () => {
	const width = useWindowWidth();

	return (
		<div className='miracle-delivery__info miracle-step__info'>
			<h3 className='miracle-delivery__title miracle-step__title'>
				Select delivery options
			</h3>
			<p className='miracle-delivery__text'>
				The more you use the Miracle System the better the colour and
				the better the condition. {width >= 768 && <br />}Colour with
				confidence and convenience every time with Subscribe & Save
				service.
			</p>
			<div className='miracle-delivery__recommends'>
				Josh recommends colouring every 4-6 weeks
			</div>
			{/* <div className="miracle-delivery__book-appointment"> */}
			{/*    <Link to="/pages/book-a-video-chat">Book a Video Consultation</Link> */}
			{/* </div> */}
		</div>
	);
};

export default MiracleDeliveryInfo;
