import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import upsellSellingGroups from '../../../utils/sellingPlanGroups/upsellSellingGroups';

const UpsellBundlePreview = ({
	upsellBundleResultHandle,
	upsellBundleViewProductHandle,
	upsellBundleResultVariantTitle,
	upsellBundleViewDescription,
	upsellBundleProductPrice,
	frequencyText,
	allProducts,
	closeCart,
	replaceCartItem,
	item,
	cart,
}) => {
	const upsellBundleResult = allProducts.find(
		(item) => item.handle === upsellBundleResultHandle
	);
	const upsellBundleViewProduct = allProducts.find(
		(item) => item.handle === upsellBundleViewProductHandle
	);
	const upsellBundleResultVariantId = upsellBundleResultVariantTitle
		? upsellBundleResult.variants.find(
				(variant) => variant.title === upsellBundleResultVariantTitle
		  )?.storefrontId
		: upsellBundleResult.variants[0].storefrontId;
	const isSubscriptionUpsell = item.node.sellingPlanAllocation !== null;
	const discount =
		upsellBundleProductPrice &&
		upsellBundleViewProduct &&
		Math.floor(
			(1 -
				Number(upsellBundleProductPrice) /
					Number(upsellBundleViewProduct.variants[0].price)) *
				100
		);

	const addUpsell = async () => {
		const allSellingPlanGroups = await upsellSellingGroups(
				upsellBundleResult.storefrontId
			),
			sellingPlanGroups =
				allSellingPlanGroups.product.sellingPlanGroups.edges[0] &&
				allSellingPlanGroups.product.sellingPlanGroups.edges[0].node
					.sellingPlans.edges,
			correctSellingPlan =
				sellingPlanGroups &&
				sellingPlanGroups.filter(
					(item) => item.node.name === frequencyText
				).length
					? sellingPlanGroups.filter(
							(item) => item.node.name === frequencyText
					  )
					: null;

		replaceCartItem(
			cart.id,
			item,
			item.node.merchandise.id,
			item.node.id,
			upsellBundleResultVariantId,
			[],
			item.quantity,
			correctSellingPlan ? correctSellingPlan[0].node.id : null
		);
	};

	if (!upsellBundleResultVariantId || !upsellBundleViewProduct) {
		return <></>;
	}
	return (
		<span className='cart-item cart-item-bundle'>
			{upsellBundleViewProduct.media[0] && (
				<Link
					to={`/products/${upsellBundleViewProductHandle}`}
					className='cart-item__img'
					onClick={closeCart}
				>
					<LazyLoadImage
						src={
							upsellBundleViewProduct.media[0].preview.image
								.originalSrc
						}
						alt={upsellBundleViewProduct.title}
						effect='blur'
					/>
				</Link>
			)}
			<div className='cart-item__content'>
				<div className='cart-item-bundle__subtitle'>
					Josh recommends to boost hair health
				</div>
				<div className='cart-item-bundle__title'>
					Add a {upsellBundleViewProduct.title} £{' '}
					{isSubscriptionUpsell
						? upsellBundleProductPrice
						: upsellBundleViewProduct.variants[0].price}{' '}
					{isSubscriptionUpsell && discount > 0 && (
						<span className='cart-item-bundle__title__discount'>
							SAVE {discount}%
						</span>
					)}
				</div>
				<div className='cart-item-bundle__description'>
					{upsellBundleViewDescription}
				</div>
				<button
					onClick={addUpsell}
					className={`cart-item-bundle__button button ${
						isSubscriptionUpsell ? 'button--purple' : 'button--dark'
					}`}
				>
					{isSubscriptionUpsell
						? 'add to my subscription'
						: 'add to bag'}
				</button>
			</div>
		</span>
	);
};

export default UpsellBundlePreview;
