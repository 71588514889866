import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Link } from 'gatsby';
import TimelyButton from '../../../components/TimelyButton';
import { useWindowWidth } from '../../../utils/hooks';

const SalonGalleryThirdSection = () => {
	const windowWidth = useWindowWidth();
	return (
		<div className='salon-gallery__info salon-gallery__info--margin'>
			<p className='salon-gallery__info-paragraph'>
				”Despite the fact the Josh Wood Atelier does the hair of
				EVERYONE, you feel as though you’re visiting a friend. There is
				a real warmth to the people and to the space, which is exactly
				why we keep coming back.”
			</p>
			<span className='salon-gallery__subtitle'>Erica Davies</span>
			<div className='salon-gallery__row'>
				<div className='salon-gallery__img salon-gallery__img--width'>
					<LazyLoadImage
						src='/images/salon/gallery-2.jpg'
						alt='salon gallery'
						effect='blur'
					/>
					{windowWidth > 750 && (
						<div className='salon-gallery__gift'>
							<p className='salon-gallery__text'>
								We now offer Gift Cards for salon services
							</p>
							<Link
								className='salon-header-button salon-header-button--gift shb--order-4' // eslint-disable-line
								to='/pages/gift-card'
							>
								<span className='salon-header-button__content'>
									Gift Cards
								</span>
							</Link>
						</div>
					)}
				</div>
				<div className='salon-gallery__content'>
					<p>
						Think of the Josh Wood Colour Atelier as a member’s
						club, where your name is always on the guest list and
						all your hair needs are catered for.
					</p>
					<TimelyButton
						html={
							'<script>new timelyButton("joshwoodcolour", {"style":"dark"});</script>'
						}
						classNames='timely-button--medium'
					/>
					<div className='salon-gallery__img'>
						<LazyLoadImage
							src='/images/salon/gallery-3.jpg'
							alt='salon gallery'
							effect='blur'
						/>
					</div>
					{windowWidth <= 750 && (
						<div className='salon-gallery__gift'>
							<p className='salon-gallery__text'>
								We now offer Gift Cards for salon services
							</p>
							<Link
								className='salon-header-button salon-header-button--gift shb--order-4' // eslint-disable-line
								to='/collections/gifts'
							>
								<span className='salon-header-button__content'>
									Gift Cards
								</span>
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default SalonGalleryThirdSection;
