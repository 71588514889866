// External
import React from 'react';

// Internal
import Klaviyo from '../../Klaviyo';

const FooterForm = () => (
	<div className='footer__form custom-klaviyo'>
		<Klaviyo id={process.env.KLAVIYO_MAIN_FORM_ID} inFooter />
	</div>
);

export default FooterForm;
