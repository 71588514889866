/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, {
	EffectFade,
	Lazy,
	Navigation,
	Pagination,
	Virtual,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StoreContext } from '../../../../../context/store-context';
import ItemGalleryActions from './ItemGalleryActions';
import CurrentGalleryItem from './CurrentGalleryItem';
import './Gallery.scss';

SwiperCore.use([Navigation, Pagination, Virtual, Lazy, EffectFade]);

const Index = ({ selectedBase, selectedShot }) => {
	const { setMiracleGallerySwiper, miracleGallerySwiper } =
		useContext(StoreContext);

	const [itemImages, setItemImages] = useState([]);
	const [showInfo, setShowInfo] = useState(false);

	useEffect(() => {
		if (selectedBase) {
			const result = [],
				flag = !selectedShot.title
					? selectedBase
					: `${selectedShot.title
							.toLowerCase()
							.replace(' ', '-')}-${selectedBase}`,
				mainPath = !selectedShot.title ? 'pdp/colours/' : 'pdp/tones/';
			for (let i = 1; i <= 3; i++) {
				result.push(`${mainPath + flag}-${i}.webp`);
			}
			setItemImages(result);
		}
	}, [selectedBase, selectedShot]);

	return (
		<div className='miracle-gallery miracle-gallery--active'>
			<Swiper
				onSwiper={setMiracleGallerySwiper}
				slidesPerView={1}
				observer='true'
				watchOverflow
				watchSlidesVisibility
				preloadImages='false'
				lazy='true'
				virtual
				toggle='false'
				initialSlide={1}
				navigation={{
					prevEl: '.miracle-gallery-prev',
					nextEl: '.miracle-gallery-next',
				}}
			>
				{(itemImages || []).map((img, _) => (
					<SwiperSlide key={_} virtualIndex={_}>
						<CurrentGalleryItem
							{...{
								idx: _,
								img,
							}}
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<div className='miracle-gallery-prev swiper-button-prev' />
			<div className='miracle-gallery-next swiper-button-next' />
			<div className='miracle-gallery-fraction' />

			<ItemGalleryActions
				{...{ showInfo, setShowInfo }}
				swiper={miracleGallerySwiper}
			/>
		</div>
	);
};

export default Index;
