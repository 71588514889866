import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { StoreContext } from '../../context/store-context';
import { createArray, getMetafield, isAvailable } from '../../utils/helpers';
import Button from '../Button';

const CartRecommendedProducts = () => {
	const { allProducts, closeCart, cart } = useContext(StoreContext);
	const handleProducts = cart?.lines?.edges.map(
		({ node }) => node.merchandise.product.handle
	);
	const matchedProducts =
		allProducts?.filter((product) =>
			handleProducts?.includes(product.handle)
		) || [];
	const allProductsPairHandle =
		matchedProducts?.map((product) =>
			createArray(getMetafield(product, 'pair_with'))
		) || [];

	const allProductsPair = allProductsPairHandle?.flatMap((item) => item);
	const uniqueProductsPair = [...new Set(allProductsPair)].filter(
		(item) => !handleProducts.includes(item)
	);
	const productsPair =
		allProducts?.filter(
			(product) =>
				uniqueProductsPair?.includes(product.handle) &&
				isAvailable(product)
		) || [];

	return (
		<>
			{allProductsPairHandle?.length > 0 && (
				<div className='recommend-products' style={{ display: 'none' }}>
					<h3>Products we think you'll love</h3>

					{productsPair?.map((product) => (
						<div
							className='recommend-products__item'
							key={product.handle}
						>
							{product.media[0].preview?.image.originalSrc && (
								<Link
									to={`/products/${product.handle}`}
									className='recommend-products__item__img'
									onClick={closeCart}
								>
									<LazyLoadImage
										alt={product.title}
										effect='blur'
										src={
											product.media[0].preview?.image
												.originalSrc
										}
									/>
								</Link>
							)}

							<div className='recommend-products__item-content'>
								<div className='recommend-products__item__header'>
									<p className='recommend-products__item__title'>
										{product.title}
									</p>
								</div>

								<div className='recommend-products__item__info'>
									<p
										dangerouslySetInnerHTML={{
											__html: product.descriptionHtml,
										}}
									/>
								</div>

								<div className='recommend-products__item__action'>
									<div className='recommend-products__item__action-button'>
										<Button
											buttonText='Add to bag'
											product={product}
											isLink={false}
											availableForSale={isAvailable(
												product
											)}
											isPinkBasket
											isButtonCart
										/>
									</div>
									<div className='recommend-products__item__action-price'>
										<p>£{product.variants[0].price}</p>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default CartRecommendedProducts;
