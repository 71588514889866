const SLACK_URL =
	'https://hooks.slack.com/services/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';

const submitErrorToSlack = async (endpoint, error, fetchType) => {
	if (process.env.NODE_ENV !== 'test') {
		const currentDate = new Date(Date.now());
		const userEmail = sessionStorage.getItem('userEmail') || '';
		const userAgent = navigator.userAgent;
		const browserInfo = userAgent.match(
			/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
		);
		const browser = browserInfo ? browserInfo[1] : 'Unknown';
		const version = browserInfo ? browserInfo[2] : 'Unknown';

		const osInfo = userAgent.match(
			/(win|mac|linux|android|iphone|ipad|ipod)/i
		);
		const os = osInfo ? osInfo[1] : 'Unknown';

		const networkConnection = window.navigator.connection;

		const connectionType = networkConnection
			? networkConnection.effectiveType
			: 'Unknown';
		const rtt = networkConnection ? networkConnection.rtt : 'N/A';
		const downlink = networkConnection ? networkConnection.downlink : 'N/A';

		const message = `
	🚧 ${currentDate.toString()}
	${userEmail ? `- Email: (${userEmail})` : ''}
        - URL: ${typeof window !== 'undefined' && window.location}
        - Browser: ${browser}  ${version}
        - OS: ${os}
        - Network Connection: ${connectionType}
      - Round-Trip Time: ${rtt} ms
      - Downlink Speed: ${downlink} Mbps
	- Endpoint: ${fetchType} : ${endpoint}
	- Error: ${error.message}`;

		console.error(message);

		if (process.env.NODE_ENV === 'production') {
			try {
				await fetch(SLACK_URL, {
					method: 'POST',
					mode: 'no-cors',
					body: JSON.stringify({ text: message }),
					headers: {
						content_type: 'application/json',
					},
				});
			} catch (error) {
				console.error(
					'There is an error sending error log to slack. The error is:',
					error
				);
			}
		}
	}
};

const alertErrorToSlack = async (error) => {
	if (process.env.NODE_ENV !== 'test') {
		const currentDate = new Date(Date.now());
		const userEmail = sessionStorage.getItem('userEmail') || '';
		const userAgent = navigator.userAgent;
		const browserInfo = userAgent.match(
			/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
		);
		const browser = browserInfo ? browserInfo[1] : 'Unknown';
		const version = browserInfo ? browserInfo[2] : 'Unknown';

		const osInfo = userAgent.match(
			/(win|mac|linux|android|iphone|ipad|ipod)/i
		);
		const os = osInfo ? osInfo[1] : 'Unknown';

		const networkConnection = window.navigator.connection;

		const connectionType = networkConnection
			? networkConnection.effectiveType
			: 'Unknown';
		const rtt = networkConnection ? networkConnection.rtt : 'N/A';
		const downlink = networkConnection ? networkConnection.downlink : 'N/A';

		const message = `
        🚨 ${currentDate.toString()}
        ${userEmail ? `- Email: (${userEmail})` : ''}
        - URL: ${typeof window !== 'undefined' && window.location}
        - Browser: ${browser}  ${version}
        - OS: ${os}
        - Network Connection: ${connectionType}
      - Round-Trip Time: ${rtt} ms
      - Downlink Speed: ${downlink} Mbps
        - Error: ${error.stack?.split('(http')[0]} 	
        - App Crashed! 
        `;

		console.error(message);

		if (process.env.NODE_ENV === 'production') {
			await fetch(SLACK_URL, {
				method: 'POST',
				mode: 'no-cors',
				body: JSON.stringify({ text: message }),
				headers: {
					content_type: 'application/json',
				},
			});
		}
	}
};

const submitBuildTimeToSlack = async (time) => {
	if (process.env.NODE_ENV !== 'test') {
		const message = `❌ netlify build took ${time} seconds! 👀`;
		await fetch(SLACK_URL, {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify({ text: message }),
			headers: {
				content_type: 'application/json',
			},
		});
	}
};

module.exports = {submitBuildTimeToSlack, submitErrorToSlack, alertErrorToSlack};
