// External
import React, { useEffect } from 'react';

// Internal
import { useMetaObject, useMetaImages } from '../../../../utils/hooks';

const ProductHighlights = ({ productMetafield }) => {
	const { metaObject } = useMetaObject(productMetafield?.variant_highlights);

	const handleMetaProperties = (isMetaImages = true) => {
		if (!metaObject || metaObject.type !== 'highlights_for_variant')
			return [];
		for (const field of metaObject.fields) {
			if (isMetaImages && field.key === 'images_highlights') {
				return JSON.parse(field.value);
			}
			if (!isMetaImages && field.key === 'text_highlights') {
				return field.value;
			}
		}
	};

	const { metaImages, setUpdate } = useMetaImages(handleMetaProperties());

	const getText = (text) => {
		if (!text || typeof text !== 'string') return [];
		const sectionsText = text.split(', ');
		return sectionsText;
	};
	const isValidToShow = (array) => {
		if (
			array &&
			Array.isArray(array) &&
			array.length === metaImages?.length
		)
			return true;
	};

	const sectionText = getText(handleMetaProperties(false));

	const hasImages =
		metaImages && Array.isArray(metaImages) && metaImages.length > 0;

	useEffect(() => {
		setUpdate((prev) => !prev);
	}, [productMetafield, metaObject]);

	return (
		<section className='highlights-section'>
			{hasImages && (
				<>
					<h3>Highlights</h3>
					<div>
						{metaImages.map((picture, index) => (
							<div key={picture}>
								<img
									src={picture}
									alt='models who uses Josh Wood Colour products'
								/>
								{isValidToShow(sectionText) ? (
									<p>{sectionText[index]}</p>
								) : (
                                    <p>{sectionText[0]}</p>
                                )}
							</div>
						))}
					</div>
				</>
			)}
		</section>
	);
};

export default ProductHighlights;
