import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CloseIcon = ({ handleClose, classname }) => (
	<button
		className={`icon-close ${classname && classname}`}
		aria-label='close icon'
		onClick={handleClose}
	>
		<LazyLoadImage
			className='icon-close__img'
			src='/images/modal-close.svg'
			alt='close'
			effect='blur'
		/>
	</button>
);

export default CloseIcon;
