import React from 'react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Video from '../Video';

import './InfoVideoBlock.scss';

const InfoVideoBlock = (props) => {
	const classes = [
		{ 'flex--center': !props.text },
		{ 'flex-reverse': props.reverse },
	];

	return (
		<div className={cn('info-video-block flex', classes)}>
			<div className='info-video-block__video'>
				{!props.img || (props.img && props.videoId) ? (
					<Video
						videoId={props.videoId}
						videoTitle={props.videoTitle}
					/>
				) : null}
				{props.img && !props.videoId && (
					<LazyLoadImage
						src={props.img}
						alt={props.title}
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				)}
			</div>
			{props.text && (
				<div className='info-video-block__text'>
					{props.title && (
						<h2 className='info-video-block__title bold'>
							{props.title}
						</h2>
					)}
					<p>{props.text}</p>
					{props.title === 'How to use' ? (
						<button
							className='info-video-block__link'
							onClick={props.openModal}
						>
							Worth noting
						</button>
					) : (
						props.link && (
							<a
								className='info-video-block__link'
								href={props.link.url}
							>
								{props.link.text}
							</a>
						)
					)}
				</div>
			)}
		</div>
	);
};

export default InfoVideoBlock;
