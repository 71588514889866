// External
import React from 'react';

// Internal
import alertSelect from '../../../../../../static/images/alert-select.png';

const ProductSelectionLevelHeader = ({
	title,
	name,
	description,
	errorLevel,
	showTitle = true,
}) => {
	const hasErrorLevel = errorLevel();
	return (
		<div
			className='pdp-selection-level__header
            pdp-selection-level-header'
		>
			<span className='pdp-selection-level-header__title'>
				{`${title && title}:`}
			</span>
			<span
				className={`pdp-selection-level-header__name ${
					hasErrorLevel
						? 'pdp-selection-level-header__name--error'
						: ''
				}`}
			>
				{hasErrorLevel ? (
					<>
						<img
							src={alertSelect}
							alt='alert when selection was not done'
						/>
						{hasErrorLevel}
					</>
				) : (
					<>{showTitle && name}</>
				)}
			</span>
			<span className='pdp-selection-level-header__description'>
				{description && description}
			</span>
		</div>
	);
};

export default ProductSelectionLevelHeader;
