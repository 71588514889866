import { useContext } from 'react';
import { StoreContext } from '../context/store-context';

const useProductByHandle = (handle, isArray = false) => {
	const { allProducts } = useContext(StoreContext);

	const product = !Array.isArray(handle)
		? allProducts?.find((product) => product.handle === handle)
		: allProducts?.find((product) => handle.includes(product.handle));

	return !Array.isArray(handle) ? [product] : product;
};

export default useProductByHandle;
