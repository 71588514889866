import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { customerQuery } from '../../api/queries/customer';
import { submitErrorToSlack } from '../slackMessage';

const doDataLayerUserEvent = async (id, cart, eventType) => {
	const url = '/api/request';
	try {
		const header = {
			'Content-Type': 'application/json',
		};

		const res = await axios.post(url, JSON.stringify(customerQuery(id)), {
			headers: header,
		});

		if (!res.data) {
			throw new Error('API request failed');
		}

		const data = res.data;

		window.dataLayer.push({
			event: eventType,
			event_id: uuid(),
			cart_total: cart?.cost?.totalAmount.amount,
			user_properties: {
				visitor_type: 'logged_in',
				customer_id: data.customer.id,
				customer_email: data.customer.email,
				customer_order_count: data.customer.orders.edges.length,
				customer_total_spent: data.customer.amountSpent,
			},
		});
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'POST');
		}
		console.error(error);
		return error;
	}
};

export default doDataLayerUserEvent;
