import React from 'react';

const AccountBanner = () => (
	<div className='account-page__banner'>
		<p>
			Thank you for your patience while we make a few changes to our
			website - if you have any issues making changes to your account
			please reach out through live chat or email{' '}
			<a href='mailto:expert@joshwoodcolour.com'>
				expert@joshwoodcolour.com
			</a>
		</p>
	</div>
);

export default AccountBanner;
