import React, { createRef, useRef, useState } from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { useWindowWidth } from '../../../../utils/hooks';

SwiperCore.use([Pagination, Autoplay]);

const LandingReviews = ({ blok }) => {
	const { reviews } = blok;
	const windowWidth = useWindowWidth();
	const [showContents, setShowContents] = useState([]);
	const elRefs = useRef([]);

	if (elRefs.current.length !== reviews.length) {
		elRefs.current = Array(reviews.length)
			.fill()
			.map((_, i) => elRefs.current[i] || createRef());
	}

	const handleShowContents = (index) => {
		showContents.includes(index)
			? setShowContents(showContents.filter((item) => item !== index))
			: setShowContents([...showContents, index]);
	};

	return (
		<section className='reviews-carousel miracle-review'>
			<div className='miracle-review__container container'>
				<div className='miracle-review__items swiper-container'>
					<Swiper
						slidesPerView={1.7}
						simulateTouch
						centeredSlides
						spaceBetween={40}
						initialSlide={1}
						speed={700}
						loop
						autoplay={{ delay: 5000 }}
						pagination={{
							el: '.miracle-review__pagin',
							clickable: true,
							type: 'bullets',
						}}
						navigation={{
							nextEl: '.miracle-review-next',
							prevEl: '.miracle-review-prev',
						}}
						breakpoints={{
							750: {
								centeredSlides: false,
								slidesPerView: 1,
							},
						}}
					>
						{Array.isArray(reviews) &&
							reviews.map((review, index) => {
								const isBigHeight =
									elRefs.current[index].current &&
									elRefs.current[index].current.clientHeight >
										155;
								return (
									<SwiperSlide
										key={review.reviewText}
										className='miracle-review__item'
									>
										<div className='miracle-review__avatar'>
											<LazyLoadImage
												src={review.image.filename}
												alt='miracle review avatar'
												effect='blur'
												wrapperClassName='lazyLoad-wrapper-img'
											/>
										</div>
										<div className='miracle-review__info'>
											{windowWidth <= 750 && (
												<span className='miracle-review__author'>
													{review.reviewerName}
												</span>
											)}
											<p
												ref={elRefs.current[index]}
												className={cn(
													'miracle-review__text',
													{
														'miracle-review__text--hidden':
															windowWidth < 991 &&
															isBigHeight &&
															!showContents.includes(
																index
															),
													}
												)}
											>
												"{review.reviewText}"
											</p>
											{isBigHeight && windowWidth < 991 && (
												<span
													onClick={() =>
														handleShowContents(
															index
														)
													}
													className='miracle-review__more'
												>
													{showContents.includes(
														index
													)
														? 'show less'
														: 'show more'}
												</span>
											)}
											{windowWidth > 750 && (
												<span className='miracle-review__author'>
													{review.reviewerName}
												</span>
											)}
										</div>
									</SwiperSlide>
								);
							})}
					</Swiper>
					<div className='miracle-review__pagin swiper-pagination' />
				</div>
				<div className='miracle-review-prev swiper-button-prev' />
				<div className='miracle-review-next swiper-button-next' />
			</div>
		</section>
	);
};

export default LandingReviews;
