module.exports.getArticleFeaturedItems = (
	article,
	featuredItems,
	items,
	arrayLength = 8
) => {
	let itemsToReturn = featuredItems ?? [];

	if (itemsToReturn.length < arrayLength) {
		const itemsFilteredByTags =
			items
				?.filter((item) =>
					item.tags?.some((tag) =>
						JSON.stringify(article?.tags)?.includes(tag)
					)
				)
				?.filter((item) => !itemsToReturn.includes(item)) ?? [];
		itemsToReturn = [...itemsToReturn, ...itemsFilteredByTags];
	}

	if (itemsToReturn.length < arrayLength) {
		const defaultItems =
			items?.filter((item) => !itemsToReturn.includes(item)) ?? [];
		itemsToReturn = [...itemsToReturn, ...defaultItems];
	}
	return itemsToReturn.slice(0, arrayLength);
};
