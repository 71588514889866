import React from 'react';
import classNames from 'classnames';
import Video from '../../Video';

const StoryblokVideo = ({ blok }) => {
	const { videoId, videoTitle, isLarge } = blok;

	const classes = classNames('video-section', {
		'video-section--large': isLarge,
	});
	return (
		<section className={classes}>
			<div className='video-section__iframe'>
				<Video videoId={videoId} videoTitle={videoTitle} />
			</div>
		</section>
	);
};

export default StoryblokVideo;
