import React from 'react';

import LocalImage from '../../components/LocalImage';

const HowItWorksPageBanner = ({ headerText }) => (
	<div className='how-it-works-banner'>
		<LocalImage
			alt='hot it works'
			image='how_it_works/how-it-works-banner.jpg'
		/>
		<div className='how-it-works-banner__container container'>
			<div className='how-it-works-banner__info'>
				<h1 className='how-it-works-banner__title'>{headerText}</h1>
			</div>
		</div>
	</div>
);

export default HowItWorksPageBanner;
