import React, { useContext, useEffect } from 'react';
import SwiperCore, { Scrollbar, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getPrice, goToCheckout, isAvailable } from '../../../utils/helpers';
import { useWindowWidth } from '../../../utils/hooks';
import ProductListItemV2 from '../../ProductListItemV2/ProductListItemV2';
import ModalUpsellProduct from './ModalUpsellProduct';

import { StoreContext } from '../../../context/store-context';
import 'swiper/components/scrollbar/scrollbar.scss';
import './ModalUpsell.scss';

SwiperCore.use([Scrollbar, Pagination, Navigation]);

const ModalUpsell = ({ modal }) => {
	const handlesArray = modal.product.map((item) => item.handle);
	const { title, text, subtext } = modal.info;
	const width = useWindowWidth();
	const {
		cart,
		showModal,
		setNotificationText,
		addVariantToCart,
		setUpsellModalWasOpened,
		allProducts: allProductsContext,
	} = useContext(StoreContext);

	useEffect(() => {
		setUpsellModalWasOpened(true);
	}, []);

	const allProductsByHandle = allProductsContext
		.filter((product) => handlesArray.includes(product.handle))
		.sort(
			(a, b) =>
				handlesArray.indexOf(a.handle) - handlesArray.indexOf(b.handle)
		)
		.map((product) => {
			const imageStoryblok =
				modal.product.find((item) => item.handle === product.handle)
					.image || null;

			return {
				...product,
				imageFromStoryblok: imageStoryblok.id ? imageStoryblok : null,
			};
		});
	const oneProduct = {
		product: allProductsByHandle[0],
		dataPopup: modal.product[0],
	};

	const handleClick = (id, title, product) => {
		const note = {
			key: 'note_added_modal_upsell',
			value: 'Added item from preCheckoutModal',
		};
		addVariantToCart(id, 1, [], true, product, null, false, note)
			.then(() => {
				setNotificationText(title);
				showModal(false);
			})
			.then(() => goToCheckout(cart));
	};

	const isMobile = width < 750 || width < 1200;
	return (
		<div className='modal-upsell'>
			<div className='modal-upsell__header'>
				<h3 className='modal-upsell__title'>{title}</h3>
				{text && <p className='modal-upsell__text'>{text}</p>}
				{subtext && <p className='modal-upsell__text'>{subtext}</p>}
			</div>
			<div
				className={`modal-upsell__products ${
					(allProductsByHandle || []).length < 3 &&
					'modal-upsell__products--center'
				}`}
			>
				{(allProductsByHandle || []).length >= 1 && (
					<>
						<Swiper
							slidesPerView={
								width > 750 ? (width > 1200 ? 3 : 2) : 1
							}
							spaceBetween={10}
							simulateTouch
							pagination={{ clickable: true }}
							navigation={{
								prevEl: '.swiper-button-prev-ms',
								nextEl: '.swiper-button-next-ms',
							}}
							initialSlide={0}
							id='various-products'
						>
							{(allProductsByHandle || []).map((product) => (
								<SwiperSlide
									key={`modalUpsell${product.handle}`}
								>
									<ul className='products-list-grid products-list-upsell'>
										<ProductListItemV2
											key={`modalUpsell${product.handle}`}
											product={product}
											cart={cart}
											isModalUpSell
											available={isAvailable(product)}
											isPinkBasket
											classname='product-list-item-upsell'
										/>
									</ul>
								</SwiperSlide>
							))}
						</Swiper>

						{isMobile && (
							<>
								<div
									id='custom-prev'
									className='swiper-button-prev-ms'
								/>

								<div
									id='custom-next'
									className='swiper-button-next-ms'
								/>
							</>
						)}
					</>
				)}

				{oneProduct && (
					<div style={{ display: 'none' }} id='one-product'>
						<ModalUpsellProduct
							key={`modalUpsell${oneProduct.product.id}`}
							product={oneProduct.product}
							text={subtext}
							image={oneProduct.dataPopup.image}
							price={getPrice(
								oneProduct.product.priceRangeV2.minVariantPrice
									.amount,
								cart,
								2
							)}
							handleClick={handleClick}
						/>
					</div>
				)}
			</div>
			<div className='modal-upsell__footer modal-upsell-footer'>
				<button
					type='button'
					className='modal-upsell-footer__continue'
					onClick={() => goToCheckout(cart)}
				>
					<span>No thanks, go to checkout</span>
				</button>
			</div>
		</div>
	);
};

export default ModalUpsell;
