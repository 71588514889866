import React from 'react';
import { Link } from 'gatsby';

import LocalImage from '../../components/LocalImage';
import LocalVideo from '../../components/LocalVideo';
import video from '../../../static/miracle-videos/how-it-works.mp4';

const MiracleBenefits = ({ windowWidth }) => (
	<div className='miracle-benefits'>
		<div className='miracle-benefits__top'>
			<div className='miracle-benefits__top__item'>
				<LocalImage image='miracle-about/less-fade.jpg' />
				<div className='miracle-benefits__top__item__text'>
					<div className='miracle-benefits__top__item__text__top'>
						<h2 className='miracle-benefits__top__item__text__title'>
							38%
						</h2>
						<h3 className='miracle-benefits__top__item__text__subtitle'>
							Less fade
						</h3>
					</div>
					<p className='miracle-benefits__top__item__text__desc'>
						Over 12 washes vs permanent colour alone
					</p>
				</div>
			</div>
			<div className='miracle-benefits__top__item'>
				<LocalImage image='miracle-about/times-stronger.jpg' />
				<div className='miracle-benefits__top__item__text'>
					<div className='miracle-benefits__top__item__text__top'>
						<h2 className='miracle-benefits__top__item__text__title'>
							2.7
						</h2>
						<h3 className='miracle-benefits__top__item__text__subtitle'>
							Times Stronger
						</h3>
					</div>
					<p className='miracle-benefits__top__item__text__desc'>
						for greater tensile strength
					</p>
				</div>
			</div>
			<div className='miracle-benefits__top__item'>
				<LocalImage image='miracle-about/less-breakage.jpg' />
				<div className='miracle-benefits__top__item__text'>
					<div className='miracle-benefits__top__item__text__top'>
						<h2 className='miracle-benefits__top__item__text__title'>
							62%
						</h2>
						<h3 className='miracle-benefits__top__item__text__subtitle'>
							Less breakage
						</h3>
					</div>
					<p className='miracle-benefits__top__item__text__desc'>
						than untreated hair
					</p>
				</div>
			</div>
		</div>
		<div className='miracle-benefits__body'>
			<div className='miracle-benefits__body__img'>
				<LocalVideo
					source={video}
					name='how-it-works-video'
					className='how-to-use__top'
				/>
			</div>
			<div className='miracle-benefits__body__text'>
				<h2 className='miracle-benefits__body__text__title'>
					Colour with confidence
				</h2>
				<p className='miracle-benefits__body__text__desc'>
					Protecting hair? Great. Improving your hair’s quality every
					time you colour? Amazing. That’s the power of{' '}
					<strong>The Miracle System.</strong>
				</p>
				<p className='miracle-benefits__body__text__desc'>
					Josh’s unique formula that penetrates deep into each and
					every hair deposits and locks in nourishment with{' '}
					<strong>vitamins B3, B5</strong> and{' '}
					<strong>Omegas 3 & 6</strong>, to deliver a result that’s
					clinically proven to strengthen hair by{' '}
					<strong>2.7 x</strong>. And, like any multi-vitamin, it only
					gets better with time.
				</p>
				<p className='miracle-benefits__body__text__desc'>
					Now, for the first time, every time you colour, hair is
					rejuvenated, repaired and reinforced.
				</p>
				<div className='about-the-miracle-system__button'>
					<Link to='/miracle-steps'>
						<span>Find My Shade</span>
					</Link>
				</div>
			</div>
		</div>
		<div className='miracle-benefits__bottom'>
			{windowWidth >= 750 && (
				<div className='miracle-benefits__bottom__background' />
			)}
			<div className='miracle-benefits__bottom__img'>
				<div className='miracle-benefits__bottom__img__item'>
					{windowWidth <= 750 && (
						<div className='miracle-benefits__bottom__background' />
					)}
					<div className='miracle-benefits__bottom__img__item__pic'>
						<LocalImage image='miracle-about/permanent-colour-1.jpg' />
						<span className='miracle-benefits__bottom__img__item__pic__number'>
							1
						</span>
					</div>
					<div className='miracle-benefits__bottom__img__item__text'>
						<p>
							<strong>Permanent Colour</strong>
							<br />
							100% grey coverage with colour that cares.
						</p>
					</div>
					{windowWidth <= 750 && (
						<div className='miracle-benefits__bottom__img__item__plus'>
							<span>+</span>
						</div>
					)}
				</div>
				{windowWidth >= 750 && (
					<div className='miracle-benefits__bottom__img__item__plus'>
						<span>+</span>
					</div>
				)}
				<div className='miracle-benefits__bottom__img__item'>
					{windowWidth <= 750 && (
						<div className='miracle-benefits__bottom__background' />
					)}
					<div className='miracle-benefits__bottom__img__item__pic'>
						<LocalImage image='miracle-about/shade-shot-2.jpg' />
						<span className='miracle-benefits__bottom__img__item__pic__number'>
							2
						</span>
					</div>
					<div className='miracle-benefits__bottom__img__item__text'>
						<p>
							<strong>Shade Shot</strong>
							<br />
							Fully personalised colour to suit your skin tone.
						</p>
					</div>
					{windowWidth <= 750 && (
						<div className='miracle-benefits__bottom__img__item__plus'>
							<span>+</span>
						</div>
					)}
				</div>
				{windowWidth >= 750 && (
					<div className='miracle-benefits__bottom__img__item__plus'>
						<span>+</span>
					</div>
				)}
				<div className='miracle-benefits__bottom__img__item'>
					{windowWidth <= 750 && (
						<div className='miracle-benefits__bottom__background miracle-benefits__bottom__background__last' />
					)}
					<div className='miracle-benefits__bottom__img__item__pic'>
						<LocalImage image='miracle-about/miracle-shot-3.jpg' />
						<span className='miracle-benefits__bottom__img__item__pic__number'>
							3
						</span>
					</div>
					<div className='miracle-benefits__bottom__img__item__text'>
						<p>
							<strong>Miracle Shot</strong>
							<br />
							Conditions from within for longer lasting shine and
							strength.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default MiracleBenefits;
