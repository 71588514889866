// External
import React, { useContext } from 'react';

// Internal
import ExpertsGridItem from './ExpertsGridItem/ExpertsGridItem';

import { StoreContext } from '../../../context/store-context';
import { getFilteredTags } from '../../../utils/helpers';

import './ExpertsGrid.scss';

const ExpertsGrid = ({ experts }) => {
	const { filterTags } = useContext(StoreContext),
		filteredList = getFilteredTags(filterTags, experts, true);

	return (
		<ul className='experts-grid'>
			{Array.isArray(filteredList) &&
				filteredList.map((expert) => (
					<ExpertsGridItem key={expert.handle} {...{ expert }} />
				))}
		</ul>
	);
};

export default ExpertsGrid;
