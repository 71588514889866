import React from 'react';
import cn from 'classnames';

import ProductSelectionLevelHeader from './ProductSelectionLevelHeader';
import LevelListItem from './LevelListItem';
import { getMetafield } from '../../../../../utils/helpers';
import PreviewLevels from './PreviewSecondLevel';

const previewSecondLevel = [
	'black-onyx-gloss',
	'smoky-brunette-gloss',
	'champagne-blonde-gloss',
	'icy-blonde-gloss',
	'chestnut-brunette-gloss',
	'copper-gold-gloss',
	'chocolate-gloss',
];

const ProductSelectionGloss = ({
	isFilterActive,
	items,
	errorSelectLevel,
	setActiveThirdLevel,
	activeFirstLevel,
	activeSecondLevel,
	activeThirdLevel,
	isMobile = false,
	activeStep = false,
	nextStep = false,
	product,
}) => {
	const title = activeThirdLevel && activeThirdLevel.title;

	const isMiracleSystemUltimate = JSON.parse(
		getMetafield(product, 'is_miracle_system_ultimate')
	);

	const element = items?.map((item) => (
		<LevelListItem
			isFilterActive={isFilterActive}
			activeStep={activeStep}
			nextStep={nextStep}
			isThirdLevel
			key={item.storefrontId}
			item={item}
			setActiveLevel={setActiveThirdLevel}
			activeLevel={activeThirdLevel}
		/>
	));

	const levelErrorName = () => {
		if (title) return false;
		if (
			activeSecondLevel &&
			!activeThirdLevel &&
			errorSelectLevel?.errorLevelThree
		)
			return errorSelectLevel.errorLevelThree;
	};

	return (
		<div
			className={cn('pdp-selection-level', {
				'pdp-selection-level--disabled': !activeSecondLevel,
			})}
		>
			{!isMobile && (
				<ProductSelectionLevelHeader
					title='Select gloss'
					name={
						isMiracleSystemUltimate && title
							? ` x2 ${title}`
							: title && ` ${title}`
					}
					errorLevel={levelErrorName}
					showTitle
				/>
			)}
			<div className='pdp-selection-level__row pdp-selection-level-row'>
				{!activeSecondLevel ? (
					<PreviewLevels
						items={previewSecondLevel}
						path='miracle/steps/gloss/pdp/'
						format='webp'
					/>
				) : (
					element
				)}
			</div>
		</div>
	);
};

export default ProductSelectionGloss;
