import React from 'react';

import ProductAwardsV2 from './ProductAwardsV2';
import { getMetafield, getMetafieldRaw } from '../../../utils/helpers';

const ProductAwardsContainer = ({ product, isHomepage = false }) => {
	const awards = [
		{
			icon: getMetafield(product, 'box_1_icon'),
			title: getMetafield(product, 'box_1_title'),
			text: getMetafieldRaw(product, 'box_1_description')
				? getMetafieldRaw(product, 'box_1_description')
				: '',
			link: {
				url: getMetafield(product, 'box_1_link_url'),
				text: getMetafield(product, 'box_1_link_text'),
			},
		},
		{
			icon: getMetafield(product, 'box_2_icon'),
			title: getMetafield(product, 'box_2_title'),
			text: getMetafield(product, 'box_2_description')
				? getMetafield(product, 'box_2_description')
				: '',
			link: {
				url: getMetafield(product, 'box_2_link_url'),
				text: getMetafield(product, 'box_2_link_text'),
			},
		},
		{
			icon: getMetafield(product, 'box_3_icon'),
			title: getMetafield(product, 'box_3_title'),
			text: getMetafield(product, 'box_3_description')
				? getMetafield(product, 'box_3_description')
				: '',
			link: {
				url: getMetafield(product, 'box_3_link_url'),
				text: getMetafield(product, 'box_3_link_text'),
			},
		},
		{
			icon: getMetafield(product, 'box_4_icon'),
			title: getMetafield(product, 'box_4_title'),
			text: getMetafield(product, 'box_4_description')
				? getMetafield(product, 'box_4_description')
				: '',
			link: {
				url: getMetafield(product, 'box_4_link_url'),
				text: getMetafield(product, 'box_4_link_text'),
			},
		},
	];

	return (
		<ProductAwardsV2
			awards={awards}
			product={product}
			isHomepage={isHomepage}
		/>
	);
};

export default ProductAwardsContainer;
