/* eslint-disable */
import React, { useContext } from 'react';

import BlogGridPageItem from './BlogPageGrigItem';

import { StoreContext } from '../../../context/store-context';
import { getFilteredTags } from '../../../utils/helpers';

import './BlogPageGrid.scss';

const BlogPageGrid = ({ articles }) => {
	const { filterTags } = useContext(StoreContext);
	const filteredArticles = getFilteredTags(filterTags, articles);

	return (
		<ul className='blog-grid'>
			{filteredArticles?.map(({ node: article }) => (
				<BlogGridPageItem key={article.id} article={article} />
			))}
		</ul>
	);
};

export default BlogPageGrid;
