import React from 'react';

const MiracleStepsButton = ({ handleSetStep, buttonText, activeItem }) => (
	<div className='miracle-steps-buttons'>
		<button
			className={`miracle-steps-button ${
				!activeItem && 'miracle-steps-button--opacity'
			}`}
			onClick={handleSetStep}
		>
			<p className='miracle-steps-button__text'>{buttonText}</p>
		</button>
	</div>
);

export default MiracleStepsButton;
