import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import SEO from '../../../components/SEO';
import MiracleAboutHeroBanner from '../../../templates/miracle-about/MiracleAboutHeroBanner';
import AboutTheMiracleSystem from '../../../templates/miracle-about/AboutTheMiracleSystem';
import WorriedAbout from '../../../templates/miracle-about/WorriedAbout';
import MiracleBenefits from '../../../templates/miracle-about/MiracleBenefits';
import HowToUseMiracle from '../../../templates/miracle-about/HowToUseMiracle';
import CombineWithCollections from '../../../components/Storyblok/Homepage/CombineWithCollections';
import Yotpo from '../../../components/Yotpo';
import YotpoCarousel from '../../../components/Yotpo/YotpoCarousel';
import { useWindowWidth } from '../../../utils/hooks';
import './MiracleAboutPage.scss';

const MiracleAboutPage = ({
	pageContext: { page, rating, productId },
	location,
}) => {
	const headerRef = useRef();
	const [scrollY, setScrollY] = useState(0);
	const windowWidth = useWindowWidth();
	const yotpoRef = useRef(null);
	const scrollToReviews = () =>
		yotpoRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});

	const handleScroll = () => {
		if (headerRef.current) {
			const position = window.scrollY;
			setScrollY(position);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<SEO page={page} location={location} />
			<div ref={headerRef} className='miracle-about-page'>
				<div
					className={`miracle-about-page__header${
						scrollY > 70
							? ' miracle-about-page__header--scrolled'
							: ''
					}`}
				>
					<div className='miracle-about-page__header__button'>
						<Link to='/miracle-steps'>
							<span>Subscribe Now</span>
						</Link>
					</div>
					<p className='miracle-about-page__header-text'>
						<strong>15% off</strong> every order + free gifts
					</p>
				</div>
				<MiracleAboutHeroBanner windowWidth={windowWidth} />
				<div className='miracle-about-page__rating'>
					<Yotpo
						rating={rating}
						size={14}
						showCount
						scrollToReviews={scrollToReviews}
					/>
				</div>
				<AboutTheMiracleSystem windowWidth={windowWidth} />
				<WorriedAbout windowWidth={windowWidth} />
				<MiracleBenefits windowWidth={windowWidth} />
				<HowToUseMiracle windowWidth={windowWidth} />
				<div className='miracle-about-yotpo-container' ref={yotpoRef}>
					<YotpoCarousel productId={productId} />
				</div>
				<CombineWithCollections />
			</div>
		</>
	);
};

export default MiracleAboutPage;
