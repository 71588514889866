import { useState, useEffect } from 'react';
import { isStoryblokEditionView } from '../helpers/storyblok';

const loadBridge = (callback) => {
	const existingScript = document.getElementById('storyblokBridge');

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
		script.id = 'storyblokBridge';
		document.body.appendChild(script);
		script.onload = () => {
			callback();
		};
	} else {
		callback();
	}
};

const parseStory = (story) => {
	if (typeof story?.content === 'string') {
		return { ...story, content: JSON.parse(story.content) };
	}
	return story;
};

function usePreviewableStoryblokState(sbClient, initialStory) {
	const [story, setStory] = useState(parseStory(initialStory));

	useEffect(() => {
		const isPreviewTimestampValid = isStoryblokEditionView();
		if (!isPreviewTimestampValid) {
			return;
		}

		const loadBridgeCallback = () => {
			const { StoryblokBridge, location } = window;
			const storyblokInstance = new StoryblokBridge();
			const storyId = story?.internalId || story?.full_slug;

			storyblokInstance.on(['published', 'change'], (event) => {
				if (!event.slugChanged) {
					location.reload();
				}
			});

			storyblokInstance.on('input', (event) => {
				setStory(parseStory(event.story));
			});

			if (storyId) {
				sbClient
					.get(`cdn/stories/${storyId}`, {
						version: 'draft',
					})
					.then(({ data }) => {
						setStory(parseStory(data.story));
					})
					.catch((error) => {
						console.error(error);
					});
			}
		};
		loadBridge(loadBridgeCallback);

		if (typeof story.content === 'string') {
			setStory(parseStory(story));
		}
	}, []);

	return { story };
}

export default usePreviewableStoryblokState;
