/* eslint-disable */
import React from 'react';

const ModalExpert = ({ modal: { product: expert } }) => (
	<div className='modal-expert'>
		<iframe src={expert.link} frameBorder='0' />
	</div>
);

export default ModalExpert;
