import React, { useEffect, useState } from 'react';
import { decodeBase64 } from '../../../utils/helpers';
import { submitErrorToSlack } from '../../../utils/slackMessage';

const ModalKlaviyo = ({ modal, handleClose }) => {
	const [inputVal, setInputVal] = useState('');
	const [varId, setVarId] = useState(null);
	const [requestStatus, setRequestStatus] = useState('');
	const [isMessageVisible, setMessageVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		const storefrontId = modal.product.variants[0].storefrontId;
		const id = storefrontId.includes('gid://shopify')
			? storefrontId.replace('gid://shopify/ProductVariant/', '')
			: decodeBase64(storefrontId);
		setVarId(id);
	}, []);
	const closeModalKlavio = () => {
		setMessageVisible(false);
		setInputVal('');
		handleClose();
	};
	const notifyHandle = async () => {
		const payload = {
			data: {
				type: 'back-in-stock-subscription',
				attributes: {
					profile: {
						data: {
							type: 'profile',
							attributes: {
								email: inputVal,
							},
						},
					},
					channels: ['EMAIL'],
				},
				relationships: {
					variant: {
						data: {
							type: 'catalog-variant',
							id: `$shopify:::$default:::${varId}`,
						},
					},
				},
			},
		};

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				revision: '2024-06-15',
			},
			body: JSON.stringify(payload),
		};

		const url = `https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${process.env.KLAVIYO_ID}`;
		try {
			const response = await fetch(url, requestOptions);

			if (!response.ok && response.status !== 400) {
				throw new Error('API request failed');
			}

			setMessageVisible(true);

			if (response.ok) {
				setRequestStatus('success');
			} else if (response.status === 400) {
				setRequestStatus('error');
				setErrorMessage('Make sure you have entered a valid email.');
			}
		} catch (error) {
			setMessageVisible(true);
			setRequestStatus('error');
			setErrorMessage('Something was wrong.');
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	return (
		<div className='modal-klaviyo'>
			<h3 className='modal-klaviyo__title'>{modal.product.title}</h3>
			<p className='modal-klaviyo__text'>
				Register to receive a notification when this item comes back in
				stock.
			</p>
			<input
				type='text'
				name='email'
				placeholder='Email'
				className='modal-klaviyo__input'
				value={inputVal}
				onChange={(e) => setInputVal(e.target.value)}
			/>
			{isMessageVisible ? (
				<>
					{requestStatus === 'success' ? (
						<div
							className={`modal-klaviyo__message 
                            modal-klaviyo__message--success`}
						>
							You're in! We'll let you know when it's back.
						</div>
					) : (
						<div
							className={`modal-klaviyo__message 
                            modal-klaviyo__message--error`}
						>
							{errorMessage}
						</div>
					)}
				</>
			) : null}
			{requestStatus !== 'success' ? (
				<button
					className='modal-klaviyo__button button button--dark'
					onClick={notifyHandle}
				>
					<span>notify me when available</span>
				</button>
			) : null}
		</div>
	);
};

export default ModalKlaviyo;
