import axios from 'axios';

import { submitErrorToSlack } from './slackMessage';

async function getProductInformation(productGids) {
	const query = `
        query {
            nodes(ids: [${productGids.map((gid) => `"${gid}"`).join(',')}]) {
                ... on Product {
                    id
                    title
                    handle
                    availableForSale
                    options (first: 250) {
                        id
                        name
                        values
                    }
                    priceRange {
                        minVariantPrice {
                            amount
                            currencyCode
                        }
                        maxVariantPrice {
                            amount
                            currencyCode
                        }
                    }
                    variants(first: 250) {
                        edges {
                            node {
                                id
                                title
                                price {
                                    amount
                                }
                            }
                        }
                    }
                    images(first: 1) {
                        edges {
                            node {
                                originalSrc
                            }
                        }
                    }
                }
            }
        }
    `;

	const url = `https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`;
	try {
		const response = await axios.post(
			url,
			{
				query,
				variables: {},
			},
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Shopify-Storefront-Access-Token':
						process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
					Authorization: `Basic ${process.env.SHOPIFY_ADMIN_ACCESS_TOKEN}`,
				},
			}
		);
		const productImages = response?.data?.data?.nodes?.map((node) => {
			if (node.images.edges.length > 0) {
				return {
					...node,
					image: node.images.edges[0].node.originalSrc,
                    storefrontId: node.id,
					variants: node.variants.edges.map((variantEdge) => ({
						...variantEdge.node,
						storefrontId: variantEdge.node.id,
					})),
                    priceRangeV2: node.priceRange, 
				};
			}
			throw new Error(
				'Fetching the product images was not possible, null/undefined returned - getProductImages'
			);
		});
		return productImages;
	} catch (error) {
		if (error?.statusCode >= 500) {
			submitErrorToSlack(url, error, 'GET');
		}
		console.error(error);
		return error;
	}
}

export default getProductInformation;
