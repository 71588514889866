export default [
	{
		title: 'How much does the Subscribe & Save cost?',
		text: 'Miracle System costs £28.90 with a 15% discount, Subscribe & Save shipping is free',
	},
	{
		title: 'Can I amend my Subscribe & Save?',
		text: 'It’s super easy to amend your Subscribe & Save. You set your delivery schedule, and can adjust, skip, pause or cancel at any time.',
	},
	{
		title: 'Can I cancel my Subscribe & Save?',
		text: 'You can cancel your Subscribe & Save at any time.',
	},
];
