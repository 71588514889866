import React from 'react';

import './klaviyo-sign-up.scss';

const KlaviyoSignUp = () => (
	<section className='klaviyo-sign-up-container'>
		<div className='klaviyo-form-WjYYsi' />
	</section>
);

export default KlaviyoSignUp;
