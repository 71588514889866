import React from 'react';

import './ProductClientSlider.scss';
import { delShopSymbols } from '../../../utils/helpers';
/* eslint-disable-next-line no-confusing-arrow */
const ProductClientsSlider = ({ review }) =>
	review.text ? (
		<div className='pdp-clients'>
			<div className='container'>
				<h3 className='pdp-clients__title bold'>
					What our clients say
				</h3>
				<div className='pdp-clients__slider'>
					<div className='pdp-clients__slide'>
						{review.title && (
							<h3 className='pdp-clients__title'>
								{review.title}
							</h3>
						)}
						<p className='pdp-clients__text'>
							{delShopSymbols(review.text)}
						</p>
						<span className='pdp-clients__author'>
							{' '}
							{review.name}
						</span>
					</div>
				</div>
			</div>
		</div>
	) : null;

export default ProductClientsSlider;
