import React, { useContext, useState } from 'react';

import Video from '../../../components/Video';

import { getValByKey } from '../../../utils/helpers';

import miracle from '../../../constants/miracle';
import { StoreContext } from '../../../context/store-context';

import './MiracleStepsAccordion.scss';

const MiracleStepsAccordion = ({ type, classname }) => {
	const [isActive, setIsActive] = useState(false),
		{ isShowGallery } = useContext(StoreContext),
		accordion = getValByKey(type, miracle.stepsAccordion),
		{ setModal, showModal } = useContext(StoreContext),
		handleClick = () => {
			if (type === 'tone') {
				setModal({ type: 'shade-shot' }, 'modal-steps');
				showModal(true);
			}
		};

	/* eslint-disable */
	return (
		<div
			onClick={handleClick}
			className={`miracle-steps-accordion ${classname && classname} ${
				isShowGallery && 'miracle-tone-shot--hidden'
			}`}
		>
			<div
				role='button'
				className='miracle-steps-accordion__header'
				onClick={() => setIsActive(!isActive)}
			>
				<h3 className='miracle-steps-accordion__title'>
					{accordion.title}
				</h3>
			</div>
			<div className='miracle-steps-accordion__info'>
				{typeof accordion.content === 'string' && (
					<p className=''>{accordion.content}</p>
				)}
				{accordion.video && (
					<Video
						videoTitle={accordion.video.title}
						classname={accordion.video.classname}
						videoId={accordion.video.id}
					/>
				)}
				{Array.isArray(accordion.content) &&
					(accordion.content || []).map((item) => (
						<div
							className='miracle-steps-accordion__item'
							key={item.title}
						>
							<h4>{item.title}</h4>

							<p>{item.text}</p>
						</div>
					))}
			</div>
		</div>
	);
};

export default MiracleStepsAccordion;
