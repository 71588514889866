/* eslint-disable */
import React from 'react';

import SEO from '../../../components/SEO';
/* eslint-disable-next-line */
import BookChat from './BookChat';

const headerText = 'Want advice from the world’s best colourists?';

const BookChatPage = ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<BookChat headerText={headerText} />
	</>
);

export default BookChatPage;
