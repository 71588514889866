const filters = {
	articles: [
		'colour',
		'covering roots',
		'grey stories',
		'haircare',
		'trend',
		'hair styling',
	],
	collections: {
		'all-products': [
			'light hair',
			'dark hair',
			'grey coverage',
			'brights',
			'naturals',
		],
		'gloss-and-glaze': ['light hair', 'dark hair', 'red hair'],
		colour: ['light hair', 'dark hair'],
		'root-touch-up': ['light hair', 'dark hair', 'grey coverage'],
		gloss: ['light hair', 'dark hair', 'brights', 'naturals'],
		care: ['light hair', 'dark hair'],
		accessories: ['light hair', 'dark hair', 'grey coverage'],
		'root-smudger': ['dark hair', 'grey coverage'],
		shampoos: ['light hair', 'dark hair'],
		conditioners: ['light hair', 'dark hair'],
		'root-touch-up-brushes': ['light hair', 'dark hair', 'grey coverage'],
		'root-marker-pens': ['light hair', 'dark hair', 'grey coverage'],
		red: [
			'light hair',
			'dark hair',
			'grey coverage',
			'brights',
			'naturals',
		],
		'grey-coverage': ['light hair', 'dark hair', 'grey coverage'],
		'shade-shot': ['light hair', 'dark hair'],
	},
};

export default filters;
