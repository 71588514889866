// External
import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

// Internal
import { StoreContext } from '../../context/store-context';
import './Notification.scss';

export default () => {
	const { notificationText, setNotificationText, isItemAddedToBag, setIsItemAddedToBag } = useContext(StoreContext),
		[show, setShow] = useState(false),
		[timer, setTimer] = useState(null);

	useEffect(() => {
		setShow(true);

		if (timer) {
			clearTimeout(timer);
		}

		const timerId = setTimeout(() => {
			setShow(false);
			setNotificationText('');
			setIsItemAddedToBag(false)
		}, 3000);
		setTimer(timerId);
	}, [notificationText]);

	return (
		<>
			{notificationText && isItemAddedToBag && (
				<div className={cn('notification', { active: show })}>
					<div className='notification-container'>
						<span className='notification-icon'>
							<ReactSVG src='/images/notification.svg' />
						</span>
						<p className='notification-text'>
							{notificationText} added to bag
						</p>
					</div>
				</div>
			)}
		</>
	);
};
