/* eslint-disable */
import React from 'react';

import SEO from '../../../components/SEO';
import AboutPageHeroBanner from '../../../templates/about-josh/AboutPageHeroBanner';
import SubHeaderSection from '../../../templates/about-josh/SubHeaderSection';
import AboutSection from '../../../templates/about-josh/AboutSection';

import './AboutPage.scss';

const AboutPage = ({ location, pageContext: { page } }) => {
	const data = [
		{
			title: 'Colour consultations by video',
			text: 'Josh with the help of his team, pioneers the launch of 1 to 1 video consultations, connecting the Atelier experts with our community to give personalised recommendation and advice.',
			imgUrl: '/images/about/about-01.jpg',
			buttons: [
				{
					title: 'start consultation',
					url: '/pages/consultation',
				},
			],
		},
		{
			reverse: true,
			title: 'The red expert',
			text: "From the striking, high vis red created by Josh and the team and showcased at the Alexander McQueen AW20 show, to the myriad of natural flattering reds created at the Atelier, Josh is the leading expert on red for the fashion world and clients alike. \"Listening to our the needs of our redhead community, and to the blondes and brunettes who'd love to try out red, has prompted several years of work to create our latest red innovations coming this summer. It's the year we're going to paint the town red,\" says Josh. Subscribe to our mailing list to be the first in the know or get in touch at red@joshwoodcolour.com",
			imgUrl: '/images/about/about-02.jpg',
			buttons: [
				{
					title: 'read all about it',
					url: '/blogs/community',
				},
			],
		},
		{
			title: 'Personalised colour becomes reality',
			text: "A first of its kind innovation, Shade Shot, created with Josh's expertise and insight, allows you to personalise your permanent colour. Mixed and applied with the Permanent Colour Kit, it creates an array of natural, multi-dimensional tones that are unique to your hair.",
			imgUrl: '/images/about/about-03.jpg',
			buttons: [
				{
					title: 'explore Shade Shot',
					url: '/collections/shade-shot',
				},
			],
		},
		{
			column: true,
			reverse: true,
			title: 'Live on This Morning',
			imgUrl: '/images/about/about-04.jpg',
			text: 'From the latest trends such as Champage Blonde to tips on at home hair colour for viewers at home, Josh is a regular sharing his expertise on the morning show.',
		},
		{
			reverse: true,
			title: 'The king of pastels',
			text: 'Josh cemented pastel hair as a fashion statement, collaborating with designer Marc Jacobs and hair stylist Guido Palau, to create a catwalk colour moment in history. All models at the Marc Jacobs S/S 19 show  sported custom created, pastel hair from baby blue to a soft candy pink and aquamarine green.',
			imgUrl: '/images/about/about-05.jpg',
			buttons: [
				{
					title: 'Visit the Salon',
					url: '/pages/josh-wood-colour-hair-salon-london',
				},
			],
		},
		{
			title: 'The Josh Wood Colour system launches',
			text: 'Josh shares his expertise with his innovative, at home, hair colour range. His aim? To redefine your home hair colour experience with easy to use permanent colours, root touch ups and a shade-specific care range',
			imgUrl: '/images/about/about-06.jpg',
			buttons: [
				{
					title: 'shop the range',
					url: '/collections/all-products',
				},
				{
					title: 'how it works',
					url: '/pages/how-it-works',
				},
			],
		},
		{
			column: true,
			title: 'Josh joins Redken as global colour creative director',
			imgUrl: '/images/about/about-07.jpg',
			text: "Appointed by the professional hair brand as their global colour expert, Josh joined the brand's family of hair experts including visionary hair stylist Guido Palau. As two fashion world leaders known for their statement-making, runway hair, Josh and Guido create the style and colour trends, then work with the Redken community of artists and educators to bring the trends to salons and consumers.",
		},
		{
			title: 'First colourist to win Creative Head Hair Icon Award',
			imgUrl: '/images/about/about-08.jpg',
			text: "In 2016 Josh received the coveted Creative Head Most Wanted Hair Icon Award – awarded by his peers it's a true recognition of his work within the industry.",
		},
		{
			column: true,
			reverse: true,
			title: "Josh's Prada moment",
			imgUrl: '/images/about/about-09.jpg',
			text: "Colouring the entire cast of 47 models for the Prada catwalk show, cemented the elevation of colour on the catwalk. It's reflective of Josh's position as the go-to for the fashion world, with designer's calling on his vision with colour for campaigns, ready-to-wear and couture collections. His colour has been seen on the catwalks of Celine, Louis Vuitton, Valentino, Gucci, Marc Jacobs, Alexander McQueen, Alexander Wang, Missoni, Dior and Versace.",
		},
		{
			reverse: true,
			title: 'The doors to the Atelier open',
			/* eslint-disable-next-line */
			text: "A first in the hair world, the Atelier in London's Holland Park opened in 2011. It pioneered the 'creative hub' salon concept, quickly becoming a leading global destination and best kept secret for clients seeking the best talent the hair world has to offer and remains so today.",
			imgUrl: '/images/about/about-10.jpg',
			buttons: [
				{
					title: 'visit the salon',
					url: '/pages/salon',
				},
			],
		},
		{
			reverse: true,
			title: "Josh's start in hairdressing",
			imgUrl: '/images/about/about-11.jpg',
			text: 'Josh’s career started with a Saturday job at a local salon in his home town of Barnsley. It wasn’t long before he found himself training to be a hairdresser at Vidal Sassoon in Leeds. As soon as he qualified he made his way to London, where he found his hair colour calling…',
		},
	];

	const headerText = 'Meet Josh Wood';

	return (
		<>
			<SEO page={page} location={location} title={headerText} />
			<div className='about-page'>
				<AboutPageHeroBanner headerText={headerText} />
				<SubHeaderSection />
				{data?.map((item, i) => (
					<AboutSection
						column={item.column}
						reverse={item.reverse}
						/* eslint-disable-next-line */
						key={i}
						title={item.title}
						text={item.text}
						imgUrl={item.imgUrl}
						buttons={item.buttons}
					/>
				))}
			</div>
		</>
	);
};

export default AboutPage;
