import cartData from './fragments';
import request from '../request';

export default async (id) => {
	const query = `query {
		cart(id: "${id}") {
			${cartData}
		}
	}`;
	return request(query);
};
