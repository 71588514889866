import React, { useEffect } from 'react';

import MiracleConditionList from './MiracleConditionList';

import { isBrowser, sendViewItemEvent } from '../../../utils/helpers';

import './MiracleCondition.scss';

const MiracleCondition = ({ colourItem, shotItem, product }) => {
	useEffect(() => {
		isBrowser && sendViewItemEvent(product);
	}, []);

	return (
		<div className='miracle-condition'>
			<div className='container'>
				<div className='miracle-condition__info miracle-step__info'>
					<h3
						className={`miracle-condition__title 
                    miracle-step__title`}
					>
						Your Miracle System is ready
					</h3>
					<p
						className={`miracle-condition__text 
                    miracle-condition__text--desktop miracle-step__text`}
					>
						Josh's unique innovation with Miracle Shot gives you the
						best home hair colour ever, leaving your hair stronger,
						softer and healthier
					</p>
				</div>
				<MiracleConditionList {...{ colourItem, shotItem }} />
				<p
					className={`miracle-condition__text 
                miracle-condition__text--mobile miracle-step__text`}
				>
					Josh's unique innovation with Miracle Shot gives you the
					best home hair colour ever, leaving your hair stronger,
					softer and healthier
				</p>
				<a
					href='https://joshwoodcolour.zendesk.com/hc/en-gb/categories/360002906457-Miracle-System' // eslint-disable-line
					target='_blank'
					className='miracle-condition-faq miracle-step-accordion'
					rel='noreferrer'
				>
					<div
						className={`miracle-condition-faq__header 
                        miracle-step-accordion__header`}
					>
						<h3
							className={`miracle-condition-faq__title 
                        miracle-step-accordion__title`}
						>
							Miracle FAQ
						</h3>
					</div>
				</a>
			</div>
		</div>
	);
};

export default MiracleCondition;
