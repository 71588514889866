import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getMetafield } from '../../../../utils/helpers';
import { useMetaImage } from '../../../../utils/hooks';

const ProductPermHairColour = ({
	product,
	setSelectedVariant,
	setActiveVariant,
	allColours,
}) => {
	const metaImages =
		allColours?.variants?.map((variant) => {
			const productImageMetafield = getMetafield(
				variant,
				'image_variant'
			);

			const metafieldImage = useMetaImage(productImageMetafield) ?? null;
			return metafieldImage;
		}) ?? null;
	return (
		<>
			{product.map((variant) => {
				const productImageMetafield = getMetafield(
					variant,
					'image_variant'
				);
				const name = variant.title.match(/\d+(\.\d+)?/)[0];

				const isAvailable = variant.availableForSale;
				const metafieldImage =
					metaImages.find((image) =>
						image.metaImage?.includes(name)
					) ?? null;
				let imageToShow;

				if (metafieldImage) {
					imageToShow = metafieldImage.metaImage;
				} else {
					imageToShow = getImage(variant.image);
				}

				return (
					<div
						key={variant.id}
						style={{
							opacity: isAvailable ? '1' : '0.5',
							cursor: isAvailable ? 'pointer' : 'not-allowed',
						}}
						onClick={() => {
							if (!isAvailable) return;
							setSelectedVariant(variant);
							setActiveVariant(variant);
						}}
						className='target-variant__product'
						data-id={variant.id}
					>
						{productImageMetafield ? (
							<LazyLoadImage
								src={imageToShow}
								alt='product variant'
								className='select-variant__product'
							/>
						) : (
							<GatsbyImage
								image={imageToShow}
								alt='product variant'
								className='select-variant__product'
							/>
						)}
					</div>
				);
			})}
		</>
	);
};

export default ProductPermHairColour;
