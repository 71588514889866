// External
import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

const TextBlock = ({ text }) => (
	<div className='rich-text' itemProp='text'>
		{render(text)}
	</div>
);

export default TextBlock;
