import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '../CloseIcon';
import CartItem from './CartItem';
import CartFooter from './CartFooter';
import CartProgressBar from './CartProgressBar';
import Loader from '../Loader';
import { StoreContext } from '../../context/store-context';
import CartRecommendedProducts from './CartRecommendedProducts';
import { getGlobalMetafields } from '../../utils/helpers';

const CartBody = ({
	hasItems,
	cart,
	quantity,
	closeCart,
	isOpen,
	cartLimit,
	setModal,
	showModal,
	setSelectedPermanentBase,
	selectedPermanentBase,
	setSelectedPermanentShot,
	selectedPermanentShot,
	productsList,
	selectedValue,
	setSelectedValue,
	cartStories,
}) => {
	const { isCartBlocked, allProducts } = useContext(StoreContext);
	const lineItems = hasItems ? cart.lines.edges : [];
	const isCartLimitExceeded =
		cartLimit &&
		lineItems &&
		lineItems.some((item) => item.node.quantity > cartLimit);

	const [productFree, setProductFree] = useState(null);

	const modalOpen = () => {
		showModal(true);
		setModal(null, 'cart-limit', 'Cannot place order, conditions not met');
	};

	const getInformationProductFree = () => {
		getGlobalMetafields().then((metafields) => {
			if (
				metafields &&
				metafields.length > 0 &&
				Array.isArray(allProducts)
			) {
				const product = metafields?.find(
					(item) => item.key === 'product_free'
				).value;

				const productNameFree = allProducts.find(
					(item) => item.handle === product
				)?.title;

				const valueToGet = metafields?.find(
					(item) => item.key === 'product_free_threshold'
				).value;

				const isActiveProductFree = metafields?.find(
					(item) => item.key === 'isactiveproductfree'
				).value;

				setProductFree({
					name: productNameFree,
					valueToGet,
					isActiveProductFree: JSON.parse(isActiveProductFree),
				});
			}
		});
	};

	useEffect(() => {
		getInformationProductFree();
	}, []);

	useEffect(() => {
		if (isOpen && cartLimit) {
			isCartLimitExceeded && modalOpen();
		}
	}, [lineItems]);

	return (
		<>
			{hasItems && (
				<>
					<div className='cart-header'>
						<span className='cart-header__title'>Your bag </span>
						{!isCartBlocked && (
							<span className='cart-header__count'>
								{quantity} {quantity > 1 ? ' items' : ' item'}
							</span>
						)}
						<CloseIcon
							handleClose={closeCart}
							classname='cart-close'
						/>
					</div>
					<div className='cart-items'>
						<CartProgressBar
							cart={cart}
							productFree={productFree}
						/>
						{isCartBlocked ? (
							<Loader />
						) : (
							<ul>
								{lineItems.map((item) => (
									<CartItem
										key={`cartItem${item.node.id}`}
										item={item}
										cartLimit={cartLimit}
										lineItems={lineItems}
										setSelectedPermanentBase={
											setSelectedPermanentBase
										}
										selectedPermanentBase={
											selectedPermanentBase
										}
										setSelectedPermanentShot={
											setSelectedPermanentShot
										}
										selectedPermanentShot={
											selectedPermanentShot
										}
										productsList={productsList}
										selectedValue={selectedValue}
										setSelectedValue={setSelectedValue}
									/>
								))}
							</ul>
						)}
						<CartRecommendedProducts />
					</div>
				</>
			)}
			<CartFooter
				hasItems={hasItems}
				isOpen={isOpen}
				closeCart={closeCart}
				cart={cart}
				productsList={productsList}
				isCartLimitExceeded={isCartLimitExceeded}
				isCartBlocked={isCartBlocked}
				modalOpen={modalOpen}
				cartStories={cartStories}
			/>
		</>
	);
};

export default CartBody;
