import React, { useState, useEffect } from 'react';

import { getMetafield, isBrowser } from '../../../../utils/helpers';
import ProductInfoV2 from './ProductInfoV2';

const ProductInfoContainerV2 = ({
	product,
	cart,
	collections,
	windowWidth,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	withShadeShot,
	shots,
	activeVariant,
	setActiveVariant,
	rating,
	scrollToReviews,
	sellingPlanGroup,
	miracleSystem,
}) => {
	const [recommendedProduct, setRecommended] = useState(null);
	const [isRecommendedSelected, setRecommendedSelected] = useState(true);

	const shopLookProductsHandles = getMetafield(product, 'shop_the_look');
	const recommendedProductHandle = getMetafield(product, 'recommended');
	const recommendedTag = getMetafield(product, 'recommended_tag');
	const recommendedText = getMetafield(product, 'recommended_text');
	const recommendedTextMobile = getMetafield(
		product,
		'recommended_text_mobile'
	);

	const handleToReview = (e) => {
		if (e.target.classList.contains('yotpo-icon')) {
			const widget = document.querySelector('.yotpo-main-widget');
			isBrowser && widget && window.scrollTo(0, widget.offsetTop - 80);
		}
	};

	useEffect(() => {
		document.body.addEventListener('click', handleToReview);
		return () => {
			document.body.removeEventListener('click', handleToReview);
		};
	}, []);

	return (
		<ProductInfoV2
			{...{
				product,
				cart,
				collections,
				shopLookProductsHandles,
				setRecommended,
				recommendedProduct,
				recommendedProductHandle,
				recommendedTag,
				recommendedText,
				recommendedTextMobile,
				isRecommendedSelected,
				setRecommendedSelected,
				windowWidth,
				allBaseColours,
				productsList,
				shadeShotsProducts,
				withShadeShot,
				shots,
				activeVariant,
				setActiveVariant,
				rating,
				scrollToReviews,
				sellingPlanGroup,
				miracleSystem,
			}}
		/>
	);
};

export default ProductInfoContainerV2;
