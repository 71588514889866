import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getMetafield, getVideoId } from '../../../utils/helpers';
import { useWindowWidth } from '../../../utils/hooks';
import Video from '../../../components/Video';

import './ProductTalks.scss';

const ProductTalksV2 = ({ product, activeVariant, section = 'Josh Talks' }) => {
	const windowWidth = useWindowWidth();

	const talksTitle = getMetafield(product, 'talks_title');
	const talksDescription = getMetafield(product, 'talks_description');
	const talksAuthor = getMetafield(product, 'talks_author');
	const talksImg = getMetafield(product, 'talks_image');
	const talksVideoId = getVideoId(getMetafield(product, 'talks_video'));
	const variantTalksTitle =
		activeVariant && getMetafield(activeVariant, 'talks_title');
	const variantTalksDescription =
		activeVariant && getMetafield(activeVariant, 'talks_description');
	const variantTalksImg =
		activeVariant && getMetafield(activeVariant, 'talks_image');
	const variantVideoId =
		activeVariant && getVideoId(getMetafield(activeVariant, 'talks_video'));

	const applyTitle = getMetafield(product, 'title apply');
	const applySubtitle = getMetafield(product, 'subtitle_apply');
	const applyDescription = getMetafield(product, 'description_apply');
	const applyTalks = getMetafield(product, 'talks_about_apply');
	const applyAuthor = getMetafield(product, 'author_apply');
	const applyVideo = getVideoId(getMetafield(product, 'video_url'));
	const applyImage = getMetafield(product, 'image_apply');

	const isJoshTalkSection = section === 'Josh Talks';
	const videoId = isJoshTalkSection
		? talksVideoId || variantVideoId
		: applyVideo;
	const titleSection = isJoshTalkSection
		? talksTitle || variantTalksTitle
		: applyTitle;
	const imageSection = isJoshTalkSection
		? talksImg || variantTalksImg
		: applyImage;

	const isMobile = windowWidth < 800;
	return (
		<div className='pdp-talks-v2'>
			<div className='container flex'>
				<div className='pdp-talks-v2__content'>
					<div className='pdp-talks-v2__content__text'>
						{isJoshTalkSection && (
							<>
								<h2 className='pdp-talks-v2__content__title bold'>
									{talksTitle}
								</h2>

								{isMobile && (
									<div className='pdp-talks-v2__content__video'>
										{videoId ? (
											<Video
												videoId={videoId}
												videoTitle={titleSection}
												initialLoad
											/>
										) : null}
										{!videoId && imageSection && (
											<LazyLoadImage
												src={imageSection}
												alt={titleSection}
												effect='blur'
												wrapperClassName='lazyLoad-wrapper-img'
											/>
										)}
									</div>
								)}

								<p className='pdp-talks-v2__content__desc'>
									{talksDescription}
								</p>
								{talksAuthor && (
									<p className='pdp-talks-v2__content__author'>
										{talksAuthor}
									</p>
								)}
							</>
						)}

						{!isJoshTalkSection && (
							<>
								<h2 className='pdp-talks-v2__content__title bold'>
									{applyTitle}
								</h2>
								<p className='pdp-talks-v2__content__desc bold'>
									{applySubtitle}
								</p>
								<p className='pdp-talks-v2__content__desc'>
									{applyDescription}
								</p>

								{isMobile && (
									<div className='pdp-talks-v2__content__video'>
										{videoId ? (
											<Video
												videoId={videoId}
												videoTitle={titleSection}
												initialLoad
											/>
										) : null}
										{!videoId && imageSection && (
											<LazyLoadImage
												src={imageSection}
												alt={titleSection}
												effect='blur'
												wrapperClassName='lazyLoad-wrapper-img'
											/>
										)}
									</div>
								)}

								{isMobile && (
									<p style={{ marginTop: '10px' }}>★★★★★</p>
								)}

								<p className='pdp-talks-v2__content__desc'>
									{applyTalks}
								</p>

								{applyAuthor && (
									<>
										<p className='pdp-talks-v2__content__author'>
											<b>{applyAuthor}</b>{' '}
											<span>Verified Buyer</span>
										</p>
										{!isMobile && <p>★★★★★</p>}
									</>
								)}
							</>
						)}
					</div>
					{!isMobile && (
						<div className='pdp-talks-v2__content__video'>
							{videoId ? (
								<Video
									videoId={videoId}
									videoTitle={titleSection}
									initialLoad
								/>
							) : null}
							{!videoId && imageSection && (
								<LazyLoadImage
									src={imageSection}
									alt={titleSection}
									effect='blur'
									wrapperClassName='lazyLoad-wrapper-img'
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductTalksV2;
