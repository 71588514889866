import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { StoreContext } from '../../../context/store-context';

const MobileMenuSecondary = ({ menu, setDropdownOpen }) => {
	const { setModal, showModal } = useContext(StoreContext);
	const showContactModal = () => {
		setModal(null, 'contact us', null);
		showModal(true);
	};

	return (
		<ul className='mobile-pages-nav'>
			{(menu?.content?.items || []).map(({ _uid, title, url }) => {
				const isContact = (title || '')
					.toLowerCase()
					.includes('contact');
				return (
					<li className='mobile-pages-nav__item' key={_uid}>
						{!isContact ? (
							<>
								{!url.url.includes('http') ? (
									<Link
										onClick={() => setDropdownOpen(false)}
										className='mobile-pages-nav__link'
										to={url.url}
									>
										<span className='mobile-pages-nav__text'>
											{title}
										</span>
									</Link>
								) : (
									<a
										href={url.url}
										onClick={() => setDropdownOpen(false)}
										className='mobile-pages-nav__link'
									>
										<span className='mobile-pages-nav__text'>
											{title}
										</span>
									</a>
								)}
							</>
						) : (
							<button
								className='mobile-pages-nav__link'
								onClick={showContactModal}
							>
								<span className='mobile-pages-nav__text'>
									{title}
								</span>
							</button>
						)}
					</li>
				);
			})}
			<Link
				to='/pages/book-a-video-hair-consultation'
				onClick={() => setDropdownOpen(false)}
				className='mobile-pages-nav__video-consultation'
			>
				Book a video consultation
			</Link>
		</ul>
	);
};

export default MobileMenuSecondary;
