// File to add all helpers for checkout

const redirectToCheckoutPage = () => {
	const loginCheckoutDetails = window?.location?.search;
	if (!loginCheckoutDetails?.includes('checkout')) return;
	const searchParams = new URLSearchParams(loginCheckoutDetails);
	const checkoutValues = searchParams?.get('checkout_url');
	const checkoutURL = checkoutValues?.includes('?')
		? checkoutValues.split('?')
		: checkoutValues;
	if (Array.isArray(checkoutURL) && checkoutURL.length > 0)
		return `https://checkout.joshwoodcolour.com${checkoutURL[0]}`;
	return `https://checkout.joshwoodcolour.com${checkoutURL}`;
};

export default redirectToCheckoutPage;
