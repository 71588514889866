// External
import axios from 'axios';

// Internal
import { submitErrorToSlack } from './slackMessage';

export default async (query, variables = {}) => {
	const url = `https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`;
	const header = {
		'Content-Type': 'application/json',
		'X-Shopify-Storefront-Access-Token':
			process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
	};

	const requestBody = { query, variables };

	const requestParams = {
		headers: header,
		redirect: 'follow',
	};

	return axios
		.post(url, requestBody, requestParams)
		.then((res) => {
			if (!res?.data?.data) {
				throw new Error(
					`No data returned from request with params ${query} ${variables}`
				);
			}
			return res.data.data;
		})
		.catch((error) => {
			console.error('Error in Request function:', error);
			if (error?.response?.status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
		});
};
