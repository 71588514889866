import React from 'react';
import cn from 'classnames';

const ProductSelectionLevelFilters = ({
	filtersList,
	activeType,
	setActiveType,
}) => (
	<div
		className='pdp-selection__filters
		pdp-selection-filters'
	>
		{filtersList
			.filter((item) => item !== '')
			.map((item) => (
				<div
					onClick={() => setActiveType(item)}
					key={item}
					role='button'
					className={cn('pdp-selection-filters__item', {
						'pdp-selection-filters__item--active':
							item === activeType,
					})}
				>
					{item}
				</div>
			))}
	</div>
);

export default ProductSelectionLevelFilters;
