import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SalonGallerySecondSection = () => (
	<div className='salon-gallery__experts salon-gallery-experts'>
		<div className='salon-gallery-experts__info'>
			<h4 className='salon-gallery-experts__title'>Meet the Team</h4>
			<p className='salon-gallery-experts__text'>
				Josh has hand selected the best colourists and stylists the
				world has to offer. Their creative talent and technical
				expertise, honed while working on fashion shows, editorial and
				red carpet alongside working in the Atelier, draws clients from
				all over the world.
			</p>
			<Link
				className='salon-gallery-experts__button'
				to='/pages/meet-the-experts'
			>
				<span>Meet Our Team of Experts</span>
			</Link>
		</div>
		<div className='salon-gallery-experts__img'>
			<LazyLoadImage
				src='/images/experts/salon-team.jpg'
				alt='salon gallery expert'
				effect='blur'
				wrapperClassName='lazyLoad-wrapper-img'
			/>
		</div>
	</div>
);
export default SalonGallerySecondSection;
