import React from 'react';
import IFrame from 'react-iframe';

import './BookChat.scss';

const BookChat = ({ headerText }) => {
	const widgetName = `widget_${Math.random()}`;

	return (
		<div className='book-chat-page'>
			<div className='book-chat-page__content'>
				<div className='container book-chat-page__container'>
					<div className='book-chat-page__text'>
						<h1 className='book-chat-page__title'>{headerText}</h1>
						<p>
							Book your free 1 to 1 video consultation to achieve
							your perfect Josh Wood Colour.
						</p>
						<p>
							Featuring professionals from our world famous
							Atelier.
						</p>
					</div>
					<div id='widget' className='book-chat-widget'>
						<IFrame
							id={widgetName}
							name={widgetName}
							url='https://joshwood.simplybook.it'
							/* eslint-disable-next-line */
							src='https://joshwood.simplybook.it/v2/?widget-type=iframe&theme=minimal&theme=minimal&timeline=modern&datepicker=top_calendar#book/service/1/count/1/provider/any/'
							className='book-chat-widget__body'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookChat;

