import React from 'react';
import { Link } from 'gatsby';

import './SalonGrid.scss';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const SalonGridItem = ({ salon }) => {
	const salonImage = getShopifyImage({
		image: { originalSrc: salon.image },
		width: 800,
		height: 600,
	});
	return (
		<li className='salon-item'>
			<Link to={`${salon.handle}`}>
				<div className='salon-item__img'>
					{salon.image ? (
						<GatsbyImage image={salonImage} alt={salon.name} />
					) : (
						<StaticImage
							src='../../../static/images/empty-expert.png'
							alt='empty'
						/>
					)}
				</div>
				<h3 className='salon-item__name'>{salon.name}</h3>
				<span className='salon-item__address'>{salon.address}</span>
			</Link>
		</li>
	);
};

export default SalonGridItem;
