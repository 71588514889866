import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CurrentGalleryItem = ({ img }) => (
	<div id='miracleGalleryItem' className='permanent-gallery__item'>
		<div className='swiper-zoom-container'>
			<LazyLoadImage
				src={`/images/${img}`}
				alt='image'
				effect='blur'
			/>
		</div>
	</div>
);

export default CurrentGalleryItem;
