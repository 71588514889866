import React, { useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useProductByHandle from '../../../../utils/useProductByHandle';

const ProductRecommendedItem = ({
	recommendedProductHandle,
	setRecommended,
	isRecommendedSelected,
}) => {
	const product = useProductByHandle(recommendedProductHandle);

	useEffect(() => {
		if (product && product.variants.length) {
			setRecommended(product.variants[0]);
		}
	}, [product]);

	return (
		<>
			{product && (
				<div
					className={`recommended-product__item recommended-product-item ${
						isRecommendedSelected &&
						'recommended-product-item--active'
					}`}
				>
					{product.title && product.images.length && (
						<div className='recommended-product-item__image'>
							{product.images[0].gatsbyImageData ? (
								<GatsbyImage
									alt={product.title}
									image={getImage(
										product.images[0].gatsbyImageData
									)}
								/>
							) : (
								<GatsbyImage
									src={getImage(
										product.images[0].originalSrc
									)}
									alt={product.title}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</>
	);
};
export default ProductRecommendedItem;
