import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import cn from 'classnames';
import MiracleStepHeader from './MiracleStepHeader';
import MiracleStepBack from './MiracleStepBack';
import { getMetafield } from '../../../utils/helpers';

const MiracleReadyStep = ({
	nextStep,
	activeFirstLevel,
	activeSecondLevel,
}) => {
	const classnamesCondition = cn(
		'pdp-miracle-condition__img pdp-miracle-row__img',
		{ 'pdp-miracle-row__img--disabled': activeFirstLevel },
		{ 'pdp-miracle-row__img--active': activeSecondLevel }
	);

	const activeSecondLevelAlt = activeSecondLevel?.title?.split('+')[1];

	return (
		<>
			<MiracleStepHeader
				title='3. Your Miracle System is ready'
				text='Josh’s unique innovation with Miracle Shot gives you the best home hair colour ever, leaving your hair stronger, softer and healthier.'
			/>
			<div
				className={`pdp-miracle-condition__list
                pdp-miracle-condition__list--ready`}
			>
				<div>
					{activeFirstLevel ? (
						<>
							<div className={classnamesCondition}>
								<LazyLoadImage
									src={getMetafield(
										activeFirstLevel,
										'selection_product_image'
									)}
									alt={activeFirstLevel}
									effect='blur'
								/>
								<span>
									{activeFirstLevel.title.match(
										/[+-]?\d+(\.\d+)?/g
									)}
								</span>
							</div>
						</>
					) : null}
				</div>
				<div>
					{activeSecondLevel && (
						<div className={classnamesCondition}>
							<LazyLoadImage
								src={getMetafield(
									activeSecondLevel,
									'variant_image'
								)}
								alt={activeSecondLevelAlt}
								effect='blur'
							/>
							<span>{activeSecondLevelAlt}</span>
						</div>
					)}
				</div>
				<div>
					{activeSecondLevel && (
						<div className={classnamesCondition}>
							<LazyLoadImage
								src='/images/miracle/steps/tones/pdp/miracle-condition.png'
								alt={activeSecondLevelAlt}
								effect='blur'
							/>
							<span>Miracle Shot</span>
						</div>
					)}
				</div>
			</div>
			<div
				role='button'
				className='pdp-miracle-condition-button pdp-miracle-condition-button--pdp'
				onClick={nextStep}
			>
				<span>Delivery Options</span>
			</div>
		</>
	);
};

export default MiracleReadyStep;
