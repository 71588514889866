import React from 'react';
import './Yotpo.scss';
import Loader from '../Loader';

const StarRating = React.lazy(() => import('react-svg-star-rating'));

const Yotpo = ({
	rating,
	scrollToReviews = null,
	showCount = false,
	size = 10,
}) => (
	<button
		className='product-rating__scroll-trigger'
		onClick={scrollToReviews}
	>
		<React.Suspense fallback={<Loader />}>
			<StarRating
				starClassName='product-rating__star'
				containerClassName='product-rating'
				initialRating={rating.product_score}
				roundedCorner='false'
				activeColor='#000'
				emptyColor='#fff'
				isReadOnly='true'
				unit='float'
				size={size}
			/>
		</React.Suspense>
		{showCount && (
			<span className='product-rating__count'>
				({rating.total_reviews} reviews)
			</span>
		)}
	</button>
);

export default Yotpo;
