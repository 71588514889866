// External
import React, { useEffect } from 'react';

// Internal
import { useMetaObject, useMetaImage } from '../../../../utils/hooks';

const ProductWow = ({ productMetafield }) => {
	const { metaObject } = useMetaObject(productMetafield?.variant_is_wow);

	const handleMetaProperties = (isMetaImage = true) => {
		if (!metaObject || metaObject.type !== 'why_is_wow_variant') return;
		for (const field of metaObject.fields) {
			if (isMetaImage && field.key === 'wow_image') {
				return field.value;
			}
			if (!isMetaImage && field.key === 'is_wow') {
				return field.value;
			}
		}
	};

	const { metaImage, setUpdate } = useMetaImage(handleMetaProperties());
	const wowTextSection = handleMetaProperties(false);

	const isValidSection = metaImage && wowTextSection;

	useEffect(() => {
		setUpdate((prev) => !prev);
	}, [productMetafield, metaObject]);

	return (
		<section className='variant--benefits-section'>
			{isValidSection && (
				<>
                    <img src={metaImage} alt='benefits of using this product' />
					<div>
						<div
							dangerouslySetInnerHTML={{ __html: wowTextSection }}
						/>
					</div>
				</>
			)}
		</section>
	);
};

export default ProductWow;
