export const isStoryblokEditionView = () => {
	if (typeof window === 'undefined') return false;
	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});
	const isTimestampValid = Boolean(
		params['_storyblok'] &&
			params['_storyblok_tk[token]'] &&
			params['_storyblok_tk[timestamp]'] >
				Math.floor(Date.now() / 1000) - 7200
	);
	return isTimestampValid;
};

export default isStoryblokEditionView;
