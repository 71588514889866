import React, { useEffect, useState } from 'react';
import ProductInfo from './ProductInfo';
import { getMetafield, isBrowser } from '../../../../utils/helpers';

const ProductInfoContainer = ({
	product,
	cart,
	collections,
	windowWidth,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	withShadeShot,
	shots,
	activeVariant,
	setActiveVariant,
	rating,
	scrollToReviews,
	sellingPlanGroup,
	miracleSystem,
}) => {
	const [recommendedProduct, setRecommended] = useState(null),
		[isRecommendedSelected, setRecommendedSelected] = useState(true),
		shopLookProductsHandles = getMetafield(product, 'shop_the_look'),
		recommendedProductHandle = getMetafield(product, 'recommended'),
		recommendedTag = getMetafield(product, 'recommended_tag'),
		recommendedText = getMetafield(product, 'recommended_text'),
		recommendedTextMobile = getMetafield(
			product,
			'recommended_text_mobile'
		),
		handleToReview = (e) => {
			if (e.target.classList.contains('yotpo-icon')) {
				const widget = document.querySelector('.yotpo-main-widget');
				isBrowser &&
					widget &&
					window.scrollTo(0, widget.offsetTop - 80);
			}
		};

	useEffect(() => {
		document.body.addEventListener('click', handleToReview);
		return () => {
			document.body.removeEventListener('click', handleToReview);
		};
	}, []);

	return (
		<ProductInfo
			{...{
				product,
				cart,
				collections,
				shopLookProductsHandles,
				setRecommended,
				recommendedProduct,
				recommendedProductHandle,
				recommendedTag,
				recommendedText,
				recommendedTextMobile,
				isRecommendedSelected,
				setRecommendedSelected,
				windowWidth,
				allBaseColours,
				productsList,
				shadeShotsProducts,
				withShadeShot,
				shots,
				activeVariant,
				setActiveVariant,
				rating,
				scrollToReviews,
				sellingPlanGroup,
				miracleSystem,
			}}
		/>
	);
};

export default ProductInfoContainer;
