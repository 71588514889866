import React from 'react';
import cn from 'classnames';

import PermanentSelection from '../../../products/PermanentSelection/PermanentSelection';

const ProductInfoTitleV2 = ({
	product,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	withShadeShot,
	miracleSystem,
	shots,
	sellingPlanGroup,
	id = '',
}) => (
	<div
		id={id}
		className={cn('pdp-v2-info__title-row', {
			'pdp-v2-info__title-row--flex':
				allBaseColours.length && allBaseColours.length,
		})}
	>
		<h1 className='pdp-v2-info__title bold'>{product?.title}</h1>
		{product.handle.includes('permanent-colour') && (
			<PermanentSelection
				allBaseColours={allBaseColours}
				productsList={productsList}
				shadeShotsProducts={shadeShotsProducts}
				withShadeShot={withShadeShot}
				shots={shots}
				product={product}
				sellingPlanGroup={sellingPlanGroup}
				miracleSystem={miracleSystem}
			/>
		)}
	</div>
);

export default ProductInfoTitleV2;
