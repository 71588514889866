import React, { useContext, useEffect, useState } from 'react';

import MiracleSlider from '../MiracleSlider';

import { useWindowWidth } from '../../../utils/hooks';
import { StoreContext } from '../../../context/store-context';

import './MiracleColour.scss';

const MiracleColour = ({
	baseColourQuery,
	colourItem,
	setColourItem,
	setActiveItem,
	setShotItem,
	handleSetStep,
	setBaseColourQuery,
	allBaseColours,
	headerText,
}) => {
	const { setMiracleGalleryImages, setIsShowGallery } =
			useContext(StoreContext),
		width = useWindowWidth(),
		[slidesView, setSlidesView] = useState(width > 992 ? 9 : 3.2),
		[goSlide, setGoSlide] = useState(null),
		colourPreview = '/images/miracle/colour-preview.jpg',
		currentItem = allBaseColours.find(({ title }) => title === colourItem),
		initSlide = colourItem
			? allBaseColours.indexOf(currentItem)
			: Math.ceil(allBaseColours.length / 2 - 1),
		/* eslint-disable */
		sortedColours =
			Array.isArray(allBaseColours) &&
			allBaseColours.sort((a, b) =>
				parseFloat(
					a.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
				).toFixed(1) <
				parseFloat(
					b.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
				).toFixed(1)
					? 1
					: -1
			),
		settings = {
			slidesPerView: slidesView,
			centeredSlides: true,
			initialSlide: initSlide,
			slideToClickedSlide: true,
			centeredSlidesBounds: width < 991,
			observer: true,
			observeParents: true,
		},
		handleClick = (title) => {
			if (colourItem !== title) {
				setMiracleGalleryImages(title, 'colours');
			}
			setIsShowGallery(colourItem !== title);
			setColourItem(colourItem !== title ? title : null);
			setActiveItem(colourItem !== title);
			setShotItem(null);
		};

	useEffect(() => {
		if (colourItem) {
			setMiracleGalleryImages(colourItem, 'colours');
			setIsShowGallery(true);
			setActiveItem(true);
		}
		if (width > 992) {
			setSlidesView(colourItem ? 11 : 9);
		}
	}, [colourItem]);

	useEffect(() => {
		if (baseColourQuery) {
			const currentItem = allBaseColours.find((i) =>
					i.title.toLowerCase().includes(baseColourQuery)
				),
				idx = allBaseColours.indexOf(currentItem);
			if (currentItem) {
				handleClick(currentItem.title);
				setGoSlide(idx);
				handleSetStep();
				setBaseColourQuery(null);
			}
		}
	}, [baseColourQuery]);

	return (
		<div className='miracle-colour'>
			<div className='container'>
				<div
					className={`miracle-colour__info 
                miracle-step__info miracle-step-info`}
				>
					<h1 className='miracle-colour__title miracle-step__title'>
						{headerText}
					</h1>
					<p className='miracle-colour__text miracle-step__text'>
						Select the colour that closely matches the roots and
						mid-lengths of your hair to see more images
					</p>
					{width > 991 && (
						<img
							className='miracle-step-info__image'
							src={colourPreview}
							alt='colour'
							loading='lazy'
						/>
					)}
				</div>
				<MiracleSlider
					goSlide={goSlide}
					items={sortedColours}
					type='colour'
					activeItem={colourItem}
					settings={settings}
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};
export default MiracleColour;
