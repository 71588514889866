import React, { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPlayer from 'react-player/file';
import onScreen from './onScreen';

const LocalVideo = React.memo(({ source, name, className }) => {
	const componentRef = useRef(null);
	const isOnScreen = onScreen(componentRef);

	const [isPlaying, setIsPlaying] = useState(true);

	useEffect(() => {
		setIsPlaying(isOnScreen);
	}, [isOnScreen]);

	const onClick = () => {
		setIsPlaying(!isPlaying);
	};

	return (
		<div className={`${className}__video`} ref={componentRef}>
			<div onClick={onClick} className={`${className}__video--button`}>
				<LazyLoadImage
					src={
						isPlaying
							? '/images/pause-button.svg'
							: '/images/play-button.svg'
					}
					alt={isPlaying ? 'pause' : 'play'}
					effect='blur'
				/>
			</div>
			<ReactPlayer
				playsinline
				url={source}
				playing={isPlaying}
				muted
				loop
				controls={false}
				width='100%'
				height='100%'
			/>
		</div>
	);
});

export default LocalVideo;
