import isAWholeNumber from './isAWholeNumber';

const getStoryblokProductPrice = (product) => {
	if (!product || typeof product !== 'object') return;
	if (Array.isArray(product.variants) && product.variants.length > 0) {
		return isAWholeNumber(Number(product.variants[0].price.amount))
			? Number(product.variants[0].price.amount)
			: Number(product.variants[0].price.amount).toFixed(2);
	}
	if (product?.priceRange?.minVariantPrice)
		return isAWholeNumber(Number(product.priceRange.minVariantPrice.amount))
			? Number(product.priceRange.minVariantPrice.amount)
			: Number(product.priceRange.minVariantPrice.amount).toFixed(2);
	if (product?.priceRange?.maxVariantPrice)
		return isAWholeNumber(Number(product.priceRange.maxVariantPrice.amount))
			? Number(product.priceRange.maxVariantPrice.amount)
			: Number(product.priceRange.maxVariantPrice.amount).toFixed(2);
};

export default getStoryblokProductPrice;
