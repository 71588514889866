import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import upsellSellingGroups from '../../../utils/sellingPlanGroups/upsellSellingGroups';

const UpsellAddonPreview = ({
	upsellAddonProductHandle,
	upsellAddonProductDescription,
	upsellAddonProductPrice,
	upsellAddonProductExtraInfo,
	allProducts,
	closeCart,
	addVariantToCart,
	frequencyText,
}) => {
	const upsellAddonProduct = allProducts.find(
		(item) => item.handle === upsellAddonProductHandle
	);
	const discount = Math.floor(
		(1 -
			Number(upsellAddonProductPrice) /
				Number(upsellAddonProduct.variants[0].price)) *
			100
	);
	const addUpsell = async () => {
		const allSellingPlanGroups = await upsellSellingGroups(
			upsellAddonProduct.storefrontId
		);
		const sellingPlanGroups =
			allSellingPlanGroups.product.sellingPlanGroups.edges[0].node
				.sellingPlans.edges;
		const correctSellingPlan = sellingPlanGroups.filter(
			(item) => item.node.name === frequencyText
		).length
			? sellingPlanGroups.filter(
					(item) => item.node.name === frequencyText
			  )
			: sellingPlanGroups;
		addVariantToCart(
			upsellAddonProduct.variants[0].storefrontId,
			1,
			[],
			false,
			upsellAddonProduct,
			correctSellingPlan[0].node.id
		);
	};

	return (
		<span className='cart-item cart-item-bundle'>
			{upsellAddonProduct.images[0] && (
				<Link
					to={`/products/${upsellAddonProductHandle}`}
					className='cart-item__img'
					onClick={closeCart}
				>
					<LazyLoadImage
						src={upsellAddonProduct.images[0].originalSrc}
						alt={upsellAddonProduct.title}
						effect='blur'
					/>
				</Link>
			)}
			<div className='cart-item__content'>
				<div className='cart-item-bundle__title addon'>
					Add {upsellAddonProduct.title} for{' '}
					<span>£{upsellAddonProductPrice}</span>{' '}
					{discount > 0 && (
						<span className='cart-item-bundle__title__discount'>
							SAVE {discount}%
						</span>
					)}
				</div>
				<p className='cart-item-bundle__description addon'>
					{upsellAddonProductDescription}
				</p>
				<p className='cart-item-bundle__description__extra'>
					{upsellAddonProductExtraInfo}
				</p>
				<button
					onClick={addUpsell}
					className='cart-item-bundle__button button button--purple'
				>
					add to my subscription
				</button>
			</div>
		</span>
	);
};

export default UpsellAddonPreview;
