import cartData from './fragments';
import request from '../request';

export default async () => {
	const query = `mutation cartCreate {
		cartCreate {
			cart {
				${cartData}
			}
		}
	}`;
	return request(query);
};
