import React from 'react';
import BlogRelatedProducts from '../../BlogRelatedProducts/Index';
import '../BlogStyles.scss';

const ShopifyProducts = ({ blok }) => {
	const { products, isProductInsideBlockContent } = blok;

	return (
		<section className='blog-container'>
			<BlogRelatedProducts
				relatedProducts={products}
				isProductInsideBlockContent={isProductInsideBlockContent}
			/>
		</section>
	);
};

export default ShopifyProducts;
