// External
import React from 'react';
import { Link } from 'gatsby';

// Internal
import useBreadcrumb from '../../helpers/useBreadcrumb';

import {
	BLOG_SECTION_BREADCRUMB,
	EXPERTS_SECTION_BREADCRUMB,
	PRODUCT_SECTION_BREADCUMB,
} from '../../constants/breadcrumbs';

import './Breadcrumb.scss';

const Breadcrumb = ({ section, article, product }) => {
	const { breadcrumb, breadcrumbLink, currentSection } = useBreadcrumb(
		section,
		article,
		product
	);

	const handleCurrentSectionLink = () => {
		if (section === BLOG_SECTION_BREADCRUMB) return '/blogs/community';
		if (section === PRODUCT_SECTION_BREADCUMB) return '/collections/all-products';
		if (section === EXPERTS_SECTION_BREADCRUMB) return '/pages/josh-wood-colour-hair-salon-london/';
	};

	return (
		<div className='bread-crumb--container'>
			<ol>
				<li>
					<Link to='/'>
						<h4>Home</h4>
					</Link>
				</li>
				{(currentSection || section) && (
					<li>
						<img
							src='/images/icons/breadcrum-separator.png'
							alt='breadcrumb separator list'
							loading='lazy'
						/>
						<Link to={handleCurrentSectionLink()}>
							<h4>{currentSection || section}</h4>
						</Link>
					</li>
				)}
				{breadcrumb && (
					<li>
						<img
							src='/images/icons/breadcrum-separator.png'
							alt='breadcrumb separator list'
							loading='lazy'
						/>
						{breadcrumbLink ? (
							<Link to={breadcrumbLink}>
								<h4>{breadcrumb}</h4>
							</Link>
						) : (
							<h4>{breadcrumb}</h4>
						)}
					</li>
				)}
			</ol>
		</div>
	);
};

export default Breadcrumb;
