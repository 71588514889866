import React from 'react';

import AccountOrdersItem from './AccountOrdersItem';

const AccountOrders = ({ orders }) => (
	<div className='account-orders'>
		<h3>Order History</h3>
		<table className='responsive-table'>
			<thead>
				<tr>
					<th scope='col'>Order</th>
					<th scope='col'>Date</th>
					<th scope='col'>Payment Status</th>
					<th scope='col'>Fulfillment Status</th>
					<th scope='col'>Total</th>
				</tr>
			</thead>
			<tbody>
				{orders.map((item) => (
					<AccountOrdersItem order={item.node} key={item.node.id} />
				))}
			</tbody>
		</table>
	</div>
);

export default AccountOrders;
