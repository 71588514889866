import React, { useState } from 'react';
import { Link } from 'gatsby';

import FooterSubmenu from './FooterSubmenu';
import LocalImage from '../../LocalImage';

const FooterDropdown = ({ title, list, collectionsPreviews }) => {
	const [isDropdownOpened, setDropdownOpen] = useState(false);

	return (
		<div
			className={`footer-dropdown ${
				isDropdownOpened && 'footer-dropdown--active'
			}`}
		>
			<button
				className='footer-dropdown__title'
				onClick={() => setDropdownOpen(!isDropdownOpened)}
			>
				{title}
			</button>
			<div className='footer-dropdown__content'>
				<div className='footer__collections footer-collections'>
					{collectionsPreviews &&
						collectionsPreviews.map((item) => (
							<div
								key={item.index}
								className='footer-collections__col'
							>
								<Link
									className='footer-collections__item'
									to={`/collections/${item.handle}`}
								>
									{item.image && (
										<div className='footer-collections__image'>
											<LocalImage
												alt={item.title}
												image={item.image}
											/>
										</div>
									)}
									<p className='footer-collections__text'>
										{item.title}
									</p>
								</Link>
							</div>
						))}
				</div>
				<FooterSubmenu title={title} list={list} />
			</div>
		</div>
	);
};

export default FooterDropdown;
