import React from 'react';
import isAWholeNumber from '../../../helpers/isAWholeNumber';

const MiracleDeliveryRow = ({ activeRadio, prices }) => {
	const currentPrice = isAWholeNumber(prices.currentPrice)
		? parseInt(prices.currentPrice)
		: prices.currentPrice;
	return (
		<>
			{activeRadio !== 'auto-delivery' ? (
				<div
					className={`miracle-delivery__price 
                miracle-delivery__price-single`}
				>
					<div className='miracle-delivery-price__row'>
						<strong>
							<p className='miracle-delivery-price__current'>
								<span className='price'>
									{prices.currentPrice
										? `£ ${currentPrice}`
										: '£ 19'}
								</span>
							</p>
						</strong>
						<strong className='miracle-delivery-price__treatment'>
							<span>/treatment</span>
						</strong>
					</div>
				</div>
			) : (
				<div
					className={`miracle-delivery__price 
            miracle-delivery__price-auto`}
				>
					<div
						className='miracle-delivery-price__row border'
						style={
							activeRadio === 'auto-delivery'
								? { paddingRight: '32px' }
								: {}
						}
					>
						<p className='miracle-delivery-price__current'>
							<span className='price'>
								{prices.discountPrice
									? `£ ${prices.discountPrice}`
									: '£ 19'}
							</span>
							<span className='price--strikeout'>
								{prices.currentPrice ? `${currentPrice}` : '29'}
							</span>
						</p>
						<strong
							className='miracle-delivery-price__treatment
                    miracle-delivery-price__treatment--auto'
						>
							<span>/treatment</span>
						</strong>
					</div>
					<div className='miracle-delivery-price--text'>
						<strong>15% off</strong> every order{' '}
						<strong>+ free gifts</strong> with Subscribe & Save
					</div>
				</div>
			)}
		</>
	);
};

export default MiracleDeliveryRow;
