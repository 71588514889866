import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ColourNavigation = ({ slideTo }) => (
	<div className='permanent-colour-navigation permanent-colour-navigation--hide'>
		<div
			role='button'
			className='permanent-colour-navigation__item permanent-colour-navigation__item--left'
			onClick={() => slideTo('lighter')}
		>
			<div className='permanent-colour-navigation__icon permanent-colour-navigation__icon--left'>
				<LazyLoadImage
					alt='permanent-left'
					src='/images/miracle/steps/miracle-left.svg'
					effect='blur'
				/>
			</div>
			<span className='permanent-colour-navigation__text'>lighter</span>
		</div>
		<div
			className='permanent-colour-navigation__item permanent-colour-navigation__item--right'
			onClick={() => slideTo('darker')}
		>
			<span className='permanent-colour-navigation__text'>darker</span>
			<div className='permanent-colour-navigation__icon permanent-colour-navigation__icon--right'>
				<LazyLoadImage
					alt='miracle-right'
					src='/images/miracle/steps/miracle-right.svg'
					effect='blur'
				/>
			</div>
		</div>
	</div>
);

export default ColourNavigation;
