import React from 'react';

const MiracleStepBack = ({
	setActiveStep,
	setActiveSteps,
	activeSteps,
	activeStep,
}) => {
	const handleClick = () => {
		setActiveStep(activeStep - 1);
		setActiveSteps(activeSteps.filter((step) => step !== activeStep));
	};

	return (
		<div role='button' className='pdp-miracle-back' onClick={handleClick}>
			<span>back</span>
		</div>
	);
};

export default MiracleStepBack;
