import React from 'react';

import './CookiePolicy.scss';
import SEO from '../../../components/SEO';
import CookiePolicy from './CookiePolicy';

const headerText = 'Cookie policy';


const CookiePolicyPage = ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<CookiePolicy headerText={headerText} />
	</>
);

export default CookiePolicyPage;

