import React, { useContext, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import getVariantPrice from '../../../helpers/productsPricing';
import ProductInfoTitleV2 from '../ProductFirstScreenV2/ProductInfoV2/ProductInfoTitleV2';
import YotpoV2 from '../../../components/Yotpo/YoptoV2';
import { StoreContext } from '../../../context/store-context';
import { getMetafield } from '../../../utils/helpers';
import ProductOneTestimonial from '../ProductFirstScreenV2/ProductOneTestimonial';

import './ProductDetail.scss';

const aboutMiracle = [
	{
		title: 'Salon-quality colour at home:',
		description:
			'Achieve rich, professional-looking colour in the comfort and convenience of your home.',
	},
	{
		title: 'Hair-loving ingredients:',
		description:
			'Packed with Vitamins B3, B5, and Omega 3 & 6, our gentle, ammonia-free formula is clinically proven to protect as it colours.',
	},
	{
		title: 'Confidence from root to tip:',
		description:
			'Get 100% grey coverage and stronger, healthier hair every time you colour.',
	},
];

const ProductDetail = ({
	product,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	rating,
	scrollToReviews,
	sellingPlanGroup,
}) => {
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);

	const isSubscribeOnPDP = getMetafield(product, 'subscription_on_pdp');
	const usesTestimonial = getMetafield(product, 'uses_testimonial');
	const [groupFound, setGroupFound] = useState(
		Object.keys(sellingPlanGroup).length > 0
	);
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);

	const imagePdp = product?.media[0].preview.image.gatsbyImageData;
	return (
		<div className='container'>
			<div className='pdp-detail'>
				<div className='pdp-detail__content'>
					<div className='pdp-detail__content__image'>
						<GatsbyImage
							loading='lazy'
							alt={product.title}
							image={imagePdp}
						/>
					</div>
					<div className='pdp-detail__content__text'>
						{rating && (
							<YotpoV2
								rating={rating}
								scrollToReviews={scrollToReviews}
								showCount
							/>
						)}

						<ProductInfoTitleV2
							product={product}
							productsList={productsList}
							allBaseColours={allBaseColours}
							withShadeShot={[]}
							shots={[]}
							shadeShotsProducts={shadeShotsProducts}
							sellingPlanGroup={sellingPlanGroup}
							miracleSystem={{}}
						/>

						{canSubscribe && (
							<div className='pdp-v2-info__price-pdp'>
								<p>
									From{' '}
									<span>
										{getVariantPrice(product, true, 15)}
									</span>{' '}
									(Free shipping + free gifts)*
								</p>
							</div>
						)}
						{aboutMiracle.map((item) => (
							<div
								key={item.title}
								className='pdp-detail__content__text__item'
							>
								<div className='pdp-detail__content__text__list' />
								<p>
									<b>{item.title}</b> {item.description}
								</p>
							</div>
						))}
						<a
							href='#select-shade'
							className='pdp-detail__content__text__link'
						>
							Choose my shade now
						</a>

						{usesTestimonial && <ProductOneTestimonial />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductDetail;
