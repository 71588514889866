import React, { createRef, useRef, useState } from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import LocalImage from '../../../LocalImage';
import reviews from '../../../../constants/reviews';
import { useWindowWidth } from '../../../../utils/hooks';
import './style.scss';

SwiperCore.use([Pagination, Autoplay]);

const MiracleReview = () => {
	const windowWidth = useWindowWidth();
	const [showContents, setShowContents] = useState([]);
	const reviewsLength = reviews.length;
	const elRefs = useRef([]);

	if (elRefs.current.length !== reviewsLength) {
		// add or remove refs
		elRefs.current = Array(reviewsLength)
			.fill()
			.map((_, i) => elRefs.current[i] || createRef());
	}

	const handleShowContents = (index) => {
		showContents.includes(index)
			? setShowContents(showContents.filter((item) => item !== index))
			: setShowContents([...showContents, index]);
	};

	return (
		<section className='miracle-review'>
			<div className='miracle-review__container container'>
				<div className='miracle-review__items swiper-container'>
					<Swiper
						slidesPerView={1.7}
						simulateTouch
						centeredSlides
						spaceBetween={40}
						initialSlide={1}
						speed={700}
						loop
						autoplay={{ delay: 5000 }}
						pagination={{
							el: '.miracle-review__pagin',
							clickable: true,
							type: 'bullets',
						}}
						navigation={{
							nextEl: '.miracle-review-next',
							prevEl: '.miracle-review-prev',
						}}
						breakpoints={{
							750: {
								centeredSlides: false,
								slidesPerView: 1,
							},
						}}
					>
						{reviews?.map((slide, index) => {
							const isBigHeight =
								elRefs.current[index].current &&
								elRefs.current[index].current.clientHeight >
									155;
							return (
								<SwiperSlide
									className='miracle-review__item'
									key={`${slide.name}`}
								>
									<div className='miracle-review__avatar'>
										<LocalImage
											image={slide.imgUrl}
											alt={`review-${index}`}
										/>
									</div>
									<div className='miracle-review__info'>
										{windowWidth <= 750 && (
											<span className='miracle-review__author'>
												{slide.name}
											</span>
										)}
										<p
											ref={elRefs.current[index]}
											className={cn(
												'miracle-review__text',
												{
													'miracle-review__text--hidden':
														windowWidth < 991 &&
														isBigHeight &&
														!showContents.includes(
															index
														),
												}
											)}
										>
											"{slide.text}"
										</p>
										{isBigHeight && windowWidth < 991 && (
											<span
												onClick={() =>
													handleShowContents(index)
												}
												className='miracle-review__more'
											>
												{showContents.includes(index)
													? 'show less'
													: 'show more'}
											</span>
										)}
										{windowWidth > 750 && (
											<span className='miracle-review__author'>
												{slide.name}
											</span>
										)}
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className='miracle-review__pagin swiper-pagination' />
				</div>
				<div className='miracle-review-prev swiper-button-prev' />
				<div className='miracle-review-next swiper-button-next' />
			</div>
		</section>
	);
};

export default MiracleReview;
