import React from 'react';

import '../klaviyo-competition/klaviyo-competition.scss';

const KlaviyoCompetition = () => (
	<section className='klaviyo-competition-container'>
		<div className='klaviyo-form-XtHenF' />
	</section>
);

export default KlaviyoCompetition;
