/* eslint-disable */
export const customerQuery = (id) => {
	return {
		query: `query($id: ID!){
			customer(id: $id) {
				id
				email
				orders(first: 250) {
					edges {
						node {
							id
						}
					}
				}
				amountSpent {
					amount
				}
				tags
			}
		}`,
		variables: { id: `${id}` },
	};
};
