import React, { useEffect, useState } from 'react';
import ProductGiftCardOptions from './ProductGiftCardOptions';
import ProductGiftCardTitle from './ProductGiftCardTitle';
import ProductGiftCardPrice from './ProductGiftCardPrice';
import ProductInfoDescription from '../ProductInfo/ProductInfoDescription';
import ProductGiftCardButton from './ProductGiftCardButton';

import './ProductGiftCard.scss';

const ProductGiftCard = ({ product, rating, availableForSale }) => {
	const { price, storefrontId } = product.variants[0],
		[activeItem, setActiveItem] = useState(storefrontId),
		[itemPrice, setItemPrice] = useState(price),
		[options, setOptions] = useState([]),
		[selectedOption, setSelectedOption] = useState('£20.00');

	useEffect(() => {
		setOptions(product.options[0].values);
	}, [product]);

	const productVariantsHasManyValues = product.variants.length > 1;

	useEffect(() => {
		let productOptions;

		if (productVariantsHasManyValues) {
			productOptions = product.variants.filter(
				(item) => item.title === selectedOption
			)[0];
		} else {
			productOptions = product.variants[0];
		}

		setItemPrice(productOptions.price);
		setActiveItem(productOptions.storefrontId);
	}, [selectedOption]);

	return (
		<>
			<ProductGiftCardTitle product={product} />
			<ProductGiftCardPrice {...{ itemPrice, product, rating }} />
			<ProductInfoDescription product={product} />
			{productVariantsHasManyValues && (
				<div className='pdp-gift-card'>
					<div className='pdp-gift-card-options'>
						<h2>{product.options[0].name}</h2>
						<ProductGiftCardOptions
							{...{
								options,
								selectedOption,
								setSelectedOption,
							}}
						/>
					</div>
				</div>
			)}
			{availableForSale ? (
				<ProductGiftCardButton
					{...{
						product,
						activeItem,
					}}
				/>
			) : (
				<div className='pdp-info__out-of-stock'>Out of stock</div>
			)}
		</>
	);
};

export default ProductGiftCard;
