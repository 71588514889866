/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import MiracleSlider from '../Slider';

import { StoreContext } from '../../../../context/store-context';
import { useWindowWidth } from '../../../../utils/hooks';

import './Tone.scss';

const Tone = ({ selectedBase, selectedTone, setSelectedTone, shadeShots }) => {
	const settings = {
			slidesPerView: 3,
			spaceBetween: 3,
			centeredSlides: true,
			centeredSlidesBounds: true,
			initialSlide: shadeShots.findIndex(
				(item) =>
					item.title
						.toLowerCase()
						.split(' - ')[0]
						.split(' ')
						.join('-') === selectedTone
			),
			slideToClickedSlide: true,
			observer: true,
			observeParents: true,
			navigation: {
				prevEl: '.pdp-swiper-prev',
				nextEl: '.pdp-swiper-next',
			},
		},
		handleClick = useCallback((title) => {
			const selectedTone = title
				.toLowerCase()
				.split(' - ')[0]
				.replace(' ', '-');
			setSelectedTone(selectedTone);
		}, []);

	return (
		<div className='permanent-colour'>
			<div className='container'>
				<MiracleSlider
					query={selectedTone}
					selectedBase={selectedBase}
					selectedTone={selectedTone}
					items={shadeShots}
					type='tone'
					settings={settings}
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};

export default Tone;
