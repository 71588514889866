import React, { useContext, useEffect, useState } from 'react';

import ProductPhotosSlider from './ProductPhotosSlider';
import ProductInfoContainer from './ProductInfo';
import ProductInfoCollection from './ProductInfo/ProductInfoCollection';

import { useWindowWidth } from '../../../utils/hooks';

import './ProductFirstScreen.scss';
import { StoreContext } from '../../../context/store-context';

const ProductFirstScreen = ({
	product: currentItem,
	cart,
	collections,
	addToCart,
	customPreviewImage,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	activeVariant,
	setActiveVariant,
	rating,
	scrollToReviews,
	sellingPlanGroup,
}) => {
	const { currentPermanent } = useContext(StoreContext),
		windowWidth = useWindowWidth(),
		[withShadeShot, setWithShadeShot] = useState([]),
		[shots, setShots] = useState([]),
		[product, setProduct] = useState(currentPermanent || currentItem),
		[miracleSystem, setMiracleSystem] = useState({});

	useEffect(() => {
		if (currentPermanent) {
			setProduct(currentPermanent);
		}
	}, [currentPermanent]);

	useEffect(() => {
		let shadeShots = [];
		const { title } = product,
			colourNumber = title.split('')[2],
			necessaryProductPlusShadeShot =
				productsList &&
				productsList
					.filter(
						(item) =>
							item.title.includes('Shot') &&
							item.handle.includes('permanent')
					)
					.filter(
						(item) =>
							typeof +item.handle.split('-')[2] === 'number' &&
							item.handle.split('-')[2]
					)
					.filter((item) => item.title.includes(colourNumber));

		if (
			isPermanentColour &&
			necessaryProductPlusShadeShot &&
			necessaryProductPlusShadeShot.length
		) {
			shadeShots = necessaryProductPlusShadeShot[0].variants
				.filter((item) => !item.title.includes('Shot'))
				.map(({ title }) => {
					const variantId = shadeShotsProducts.find((item) =>
						item.title.includes(title)
					);
					return { title, variantId };
				});
			setShots(shadeShots);
		}
		setWithShadeShot(necessaryProductPlusShadeShot);
		const miracleSystem =
			productsList &&
			productsList.find((item) => item.handle === 'miracle-system');
		setMiracleSystem(miracleSystem);
	}, []);

	return (
		<div className='pdp-main'>
			<div className='container flex--desktop between'>
				<ProductPhotosSlider
					{...{
						allBaseColours,
						shadeShotsProducts,
						withShadeShot,
						miracleSystem,
						product,
						customPreviewImage,
						productsList,
						shots,
					}}
				/>
				{!isPermanentColour ? (
					<ProductInfoContainer
						{...{
							product,
							collections,
							cart,
							addToCart,
							windowWidth,
							allBaseColours,
							productsList,
							shadeShotsProducts,
							withShadeShot,
							shots,
							activeVariant,
							setActiveVariant,
							rating,
							scrollToReviews,
							sellingPlanGroup,
							miracleSystem,
						}}
					/>
				) : (
					<ProductInfoCollection
						{...{
							product,
							collections,
							cart,
							addToCart,
							windowWidth,
							productsList,
							allBaseColours,
							shadeShotsProducts,
							shots,
							activeVariant,
							setActiveVariant,
							rating,
							sellingPlanGroup,
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default ProductFirstScreen;
