import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/SEO';

import { StoreContext } from '../../context/store-context';
import ProductPageV2 from './ProductPageV2';

export default function ProductV2({
	location,
	pageContext: {
		page,
		allBaseColours,
		shadeShotsProducts,
		isPermanentColour,
		rating,
		sellingPlanGroup,
		collectionPages
	},
	data: { product = {}, collections = [] },
}) {
	const { allProducts} = useContext(StoreContext);
	collections = collections.nodes.map((node) => ({
		title: node.title.toLowerCase(),
		handle: node.handle,
		products: node.products,
	}));
	const productsList = allProducts;
	const { cart } = useContext(StoreContext);

	return (
		<>
			<SEO
				page={page}
				location={location}
				product={product}
				title={product.title}
				pageType='PDP'
			/>
			<ProductPageV2
				productsList={productsList}
				{...{
					product,
					collections,
					cart,
					allBaseColours,
					shadeShotsProducts,
					isPermanentColour,
					rating,
					sellingPlanGroup,
					otherCollections: collectionPages.data.allShopifyCollection.nodes,
				}}
			/>
		</>
	);
}

export const query = graphql`
	query ($handle: String!) {
		product: shopifyProduct(handle: { eq: $handle }) {
			...ProductCard
		}
		collections: allShopifyCollection(
			filter: { products: { elemMatch: { handle: { eq: $handle } } } }
		) {
			nodes {
				title
				handle
				products {
					handle
					id
					title
				}
			}
		}
	}
`;
