// External
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import SalonInfoContacts from './SalonInfoContacts/SalonInfoContacts';

const SalonMap = ({ allHours }) => (
	<div className='salon-map' id='map'>
		<div className='container'>
			<div className='salon-map__content'>
				<SalonInfoContacts allHours={allHours} isButtons classNames='salon-map__contacts' />
				<div className='salon-map__img'>
					<a href='https://goo.gl/maps/J4QCCS14NFCaN1mV6'>
						<LazyLoadImage
							src='/images/salon/map.jpg'
							alt='salon map'
							effect='blur'
						/>
					</a>
				</div>
			</div>
		</div>
	</div>
);
export default SalonMap;
