import React from 'react';

const SalonPriceListBottomSectionItem = ({ title, price, info }) => (
	<div
		className={`salon-price-list-bottom-section__item 
        salon-price-list-bottom-section-item`}
	>
		<div className='salon-price-list-bottom-section-item__row'>
			<div className='salon-price-list-bottom-section-item__title'>
				{title}
			</div>
			<div className='salon-price-list-bottom-section-item__price'>
				{price}
			</div>
		</div>
		{info ? (
			<div className='salon-price-list-bottom-section-item__info'>
				{info}
			</div>
		) : null}
	</div>
);
export default SalonPriceListBottomSectionItem;
