import React from 'react';

import './ModalTooltip.scss';

const ModalTooltip = ({
	modal: {
		product: { text },
	},
}) => (
	<div className='modal-tooltip'>
		<div
			className='modal-tooltip__text'
			dangerouslySetInnerHTML={{ __html: text }}
		/>
	</div>
);

export default ModalTooltip;
