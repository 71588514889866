import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const FrequentlyProductImage = (props) => (
	<div className='frequently-product__image'>
		{!props.isMain && props.selectedVariant ? (
			<button
				className={`frequently-product__checkbox 
          ${
				props.selectedProductsIds.some(
					(item) =>
						item.variantId === props.selectedVariant.storefrontId
				) && 'frequently-product__checkbox--active'
			}`}
				onClick={props.handleCheck}
			/>
		) : null}
		{props.product && (
			<>
				{props?.product?.images?.length && (
					<>
						{props.product.images[0] &&
						props.product.images[0].gatsbyImageData ? (
							<GatsbyImage
								alt={props.product.title}
								image={getImage(
									props.product.images[0].gatsbyImageData
								)}
							/>
						) : (
							<GatsbyImage
								src={getImage(
									props.product.images[0].originalSrc
								)}
								alt={props.product.title}
							/>
						)}
					</>
				)}
				<button
					className='frequently-product__link'
					onClick={props.openProductModal}
				/>
			</>
		)}
	</div>
);

export default FrequentlyProductImage;
