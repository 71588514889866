import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StoreContext } from '../../../context/store-context';

import ProductPairWith from './ProductPairWith';

import {
	createArray,
	decodeBase64,
	getMetafield,
} from '../../../utils/helpers';

const ProductPairWithContainer = ({ product, cart }) => {
	const pairProducts = createArray(getMetafield(product, 'pair_with'));
	const { yotpoRatings, allProducts } = useContext(StoreContext);
	const products = allProducts?.filter((e) => pairProducts.includes(e.handle));

	products?.map((item, index) => {
		yotpoRatings?.find((rating) => {
			if (rating.domain_key === decodeBase64(item.storefrontId)) {
				products[index].rating = rating;
			}
		});
	});

	return products?.length ? (
		<ProductPairWith products={products} cart={cart} />
	) : null;
};

export default ProductPairWithContainer;
