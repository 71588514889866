// External
import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import { CURRENT_SORT_OPTIONS } from '../../constants/productSortFilter';

import './ProductSortModal.scss';

const ProductSortModal = ({
	setOpenCloseSortFilter,
	currentSortFilter,
	setCurrentSortFilter,
}) => {
	const handleChangeCurrentSortFilter = (sortFilter) => {
		if (!sortFilter || typeof sortFilter !== 'string') return;
		setCurrentSortFilter(sortFilter);
	};

	const getSortOptionDefault = (sortOption) => {
		if (sortOption === CURRENT_SORT_OPTIONS[0]) {
			return `${sortOption} (Default)`;
		}
		return sortOption;
	};

	useEffect(() => {
		const handleClickOutside = () => {
			setOpenCloseSortFilter(false);
		};
		document.body.addEventListener('click', handleClickOutside);

		return () => {
			document.body.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div
			className='product-sort__body'
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<div className='blody--close'>
				<button
					onClick={() => {
						setOpenCloseSortFilter(false);
					}}
				>
					<LazyLoadImage
						src='/images/close-icon-white.svg'
						alt='close'
						effect='blur'
					/>
				</button>
			</div>
			<ol>
				{Array.isArray(CURRENT_SORT_OPTIONS) &&
					CURRENT_SORT_OPTIONS.map((sortOption) => (
						<li key={sortOption}>
							<button
								onClick={() => {
									handleChangeCurrentSortFilter(sortOption);
								}}
							>
								{currentSortFilter === sortOption ? (
									<b>{getSortOptionDefault(sortOption)}</b>
								) : (
									getSortOptionDefault(sortOption)
								)}
							</button>
						</li>
					))}
			</ol>
		</div>
	);
};

export default ProductSortModal;
