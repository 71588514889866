import React from 'react';

import DangerouslySetHtmlContent from '../DangerouslySetHtmlContent';

import './TimelyButton.scss';

const TimelyButton = ({ html, classNames }) => (
	<div className={`timely-button ${classNames && classNames}`}>
		<DangerouslySetHtmlContent html={html} />
	</div>
);
export default TimelyButton;
