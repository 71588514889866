// External
import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import { useWindowWidth } from '../../../../utils/hooks';
import './style.scss';

const Subscribe = () => {
	const windowWidth = useWindowWidth();

	return (
		<section id='salon-quality-colour-block'>
			<div className='salon-block'>
				<div className='salon-block__img'>
					<LazyLoadImage
						src='images/home-page/image3.webp'
						alt='Salon Quality colour'
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				</div>
				<div className='salon-block__container container'>
					<div className='salon-block__content'>
						<div className='salon-block__header'>
							<h3 className='salon-block__title'>
								Subscribe to
								{windowWidth < 750 ? <br /> : ' '}
								The Miracle System
							</h3>
						</div>
						<ul className='salon-block__list'>
							<li className='salon-block__item'>
								<strong>FREE </strong> Starter Kit (worth £15)
							</li>
							<li className='salon-block__item'>
								<strong>15% off </strong>every treatment
								(normally £ 34)
							</li>
							<li className='salon-block__item'>
								<strong>FREE </strong>Shipping, every time
							</li>
							<li className='salon-block__item'>
								Flexibility to change, pause or cancel any time
							</li>
							<li className='salon-block__item'>
								Exclusive discounts and gifts
							</li>
							<li className='salon-block__item salon-block__item-fl'>
								Access to
								<Link to='/pages/book-a-video-chat'>
									<strong>Josh’s Colour Clinic</strong>
								</Link>
							</li>
						</ul>
						<div className='salon-block__buttons'>
							<Link
								to='/miracle-steps'
								className='salon-block__btn'
							>
								Find My Shade
							</Link>
							<Link
								to='pages/miracle-about'
								target='_blank'
								className='salon-block__btn-find'
							>
								Find Out More
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Subscribe;
