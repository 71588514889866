export const HAIR_CONCERNS_FILTER = 'concerns';
export const HAIR_TYPE_FILTER = 'type';
export const HAIR_COLOUR_FILTER = 'colour';
export const PRICE_FILTER = 'price';
export const RATING_FILTER = 'rating';

export const productsFilters = [
	{
		label: '1. Hair Concerns',
		name: HAIR_CONCERNS_FILTER,
		options: [
			{
				label: 'Grey hair',
				value: 'hairConcern_greyHair',
			},
			{
				label: 'Blonde brassiness',
				value: 'hairConcern_blondeBrassiness',
			},
			{
				label: 'Balayage',
				value: 'hairConcern_balayage',
			},
			{
				label: 'Ammonia free',
				value: 'hairConcern_ammoniaFree',
			},
			{
				label: 'Refresh hair colour',
				value: 'hairConcern_refreshHair',
			},
			{
				label: 'Sensitive scalp',
				value: 'hairConcern_sensitiveScalp',
			},
			{
				label: 'Stop hair breakage',
				value: 'hairConcern_stopBreakage',
			},
		],
	},
	{
		label: '2. Hair Type',
		name: HAIR_TYPE_FILTER,
		options: [
			{
				label: 'Fine',
				value: 'hairType_fine',
			},
			{
				label: 'Fragile',
				value: 'hairType_fragile',
			},
			{
				label: 'Dry',
				value: 'hairType_dry',
			},
			{
				label: 'Damaged',
				value: 'hairType_damaged',
			},
		],
	},
	{
		label: '3. Hair Colour',
		name: HAIR_COLOUR_FILTER,
		options: [
			{
				label: '2.0',
				value: 'hairColour_2.0',
			},
			{
				label: '3.0',
				value: 'hairColour_3.0',
			},
			{
				label: '4.0',
				value: 'hairColour_4.0',
			},
			{
				label: '5.0',
				value: 'hairColour_5.0',
			},
			{
				label: '5.5',
				value: 'hairColour_5.5',
			},
			{
				label: '6.0',
				value: 'hairColour_6.0',
			},
			{
				label: '6.5',
				value: 'hairColour_6.5',
			},
			{
				label: '7.0',
				value: 'hairColour_7.0',
			},
			{
				label: '7.5',
				value: 'hairColour_7.5',
			},
			{
				label: '8.0',
				value: 'hairColour_8.0',
			},
			{
				label: '9.0',
				value: 'hairColour_9.0',
			},
		],
	},
	{
		label: '4. Price range',
		name: PRICE_FILTER,
		options: [
			{
				label: 'Under £10',
				value: '[0, 10]',
			},
			{
				label: '£10 to £20',
				value: '[10, 20]',
			},
			{
				label: '£20 to £50',
				value: '[20, 50]',
			},
			{
				label: '£20 to £100',
				value: '[20, 100]',
			},
		],
	},
	{
		label: '5. Rating',
		name: RATING_FILTER,
		options: [
			{
				label: '4 & up',
				value: '[4, 5]',
			},
			{
				label: '3 & up',
				value: '[3, 5]',
			},
		],
	},
];
