import React from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import ButtonLink from '../ButtonLink';
import './style.scss';

const CtaBanner = ({ blok }) => {
	const { title, text, cTA, isLarge, bgColourOuter, bgColourInner } = blok;
	const wrapperCn = `cta-banner__styling-wrapper${
		isLarge ? ' cta-banner__styling-wrapper--large' : ''
	}`;
	const bgColourOuterSanitised = bgColourOuter.trim();
	const bgColourInnerSanitised = bgColourInner.trim();

	return (
		<section
			{...storyblokEditable(blok)}
			key={blok._uid}
			className='cta-banner'
			style={{ backgroundColor: bgColourOuterSanitised }}
		>
			<div
				className={wrapperCn}
				style={{ backgroundColor: bgColourInnerSanitised }}
			>
				<div className='cta-banner__layout-wrapper'>
					<h4 className='cta-banner__title'>{title}</h4>
					{text && text !== '' && (
						<p className='cta-banner__text'>{text}</p>
					)}
					{cTA[0] && <ButtonLink blok={cTA[0]} />}
				</div>
			</div>
		</section>
	);
};

export default CtaBanner;
