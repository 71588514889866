import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './BlogPageFeature.scss';
import TextBlock from '../../../components/Storyblok/blocks/TextBlock';
import LocalImageOptimized from '../../../components/LocalImage/LocalImageOptimized';

const BlogPageFeature = ({ featureArticle }) => {
	const articleDescription = featureArticle.contentHtml
		? featureArticle.contentHtml.replace(/<[^>]+>/g, '').trim()
		: null;
	const description = articleDescription
		? articleDescription.length > 163
			? `${articleDescription.substring(0, 163)}...`
			: articleDescription
		: null;

	const blogLink = featureArticle?.storyblok_blog
		? `/${featureArticle.full_slug}`
		: `/blogs/community/${featureArticle.handle}`;

	return (
		<div className='blog-feature'>
			<div className='blog-feature__info'>
				<h2 className='blog-feature__title'>{featureArticle.title}</h2>
				<div
					className={`blog-feature__description ${
						featureArticle?.storyblok_blog &&
						'blog-feature__description-rich-text'
					}`}
				>
					{featureArticle.contentHtml ? (
						<p dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<TextBlock text={featureArticle.content} />
					)}
				</div>

				<Link to={blogLink} className='blog-feature__link'>
					read feature
				</Link>
			</div>
			<div className='blog-feature__img'>
				{featureArticle?.storyblok_blog ? (
					<LocalImageOptimized
						imageUrl={featureArticle.image}
						alt={featureArticle.imageAlt}
						loadingType='eager'
					/>
				) : (
					<LazyLoadImage
						src={featureArticle.image?.originalSrc}
						alt={featureArticle.title}
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				)}
			</div>
		</div>
	);
};

export default BlogPageFeature;
