import React from 'react';

const ProductGiftCardOption = ({ option, isSelected, setSelectedOption }) => (
	<div
		className={`pdp-gift-card__option ${
			isSelected && 'pdp-gift-card__option--selected'
		}`}
		onClick={() => {
			setSelectedOption(option);
		}}
	>
		<span>{option}</span>
	</div>
);

export default ProductGiftCardOption;
