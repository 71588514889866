// External
import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import './UpsellContent.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LocalImage from '../../../LocalImage';
import { StoreContext } from '../../../../context/store-context';

const UpsellContent = ({
	permanentColour,
	currentShadeShot,
	setShowUpsell,
	setStep,
	isCheckShade,
	isCheckFree,
	setCheckFree,
	setCheckShade,
	handleClose,
	productWithShots,
	options,
	selectedValue,
	currentMiracleSystem,
	setCurrentMiracleSystem,
	miracleSystem,
	setSelectedValue,
}) => {
	const { allProducts } = useContext(StoreContext);
	const allMiracleSystems =
		allProducts &&
		allProducts.find((item) => item.handle === 'miracle-system');
	const { title, priceRangeV2, media } = permanentColour,
		[subtotal, setSubtotal] = useState(null);

	useEffect(() => {
		let total = +permanentColour.priceRangeV2.minVariantPrice.amount;

		if (isCheckShade) {
			total = +productWithShots.priceRangeV2.minVariantPrice.amount;
		}
		setSubtotal(total);
	}, [isCheckShade, permanentColour, currentShadeShot, productWithShots]);

	const goStep = (number) => {
			if (number === 1) {
				setCheckShade(false);
			}
			setStep(number);
			setShowUpsell(false);
		},
		removeShot = (event) => {
			event.stopPropagation();
			setCheckShade(false);
			if (isCheckFree) {
				setCheckFree(false);
			}
		},
		handleCheckFree = () => {
			setCheckFree(!isCheckFree);
		},
		addToBag = () => {
			const rightSystem =
				permanentColour &&
				currentShadeShot &&
				allMiracleSystems.variants.find(
					(item) =>
						item.title.includes(
							parseFloat(
								permanentColour.title.match(
									/[-+]?([0-9]*\.[0-9]+|[0-9]+)/
								)
							).toFixed(1)
						) &&
						item.title
							.toLowerCase()
							.includes(
								currentShadeShot.title
									.split('-')[0]
									.toLowerCase()
									.trim()
							)
				);

			rightSystem &&
				rightSystem.storefrontId &&
				setCurrentMiracleSystem(rightSystem);
		};

	const shadeShotItemImg =
		currentShadeShot &&
		currentShadeShot.media[0].preview.image.gatsbyImageData;

	return (
		<div className='upsell-content animate'>
			<div className='upsell-content__close upsell-content-close'>
				<div
					role='button'
					className='upsell-content-close__button'
					onClick={handleClose}
				>
					<LazyLoadImage
						src='/images/miracle/steps/nav/miracle-close.svg'
						alt='close'
						effect='blur'
					/>
				</div>
			</div>
			<h2 className='upsell-content__title upsell-content-title'>
				Add to bag
			</h2>
			{!currentMiracleSystem && (
				<div className='item__container'>
					<div className='permanent-item__body'>
						<div className='permanent-item__img'>
							{media[0].preview.image.gatsbyImageData ? (
								<GatsbyImage
									alt={title}
									image={
										media[0].preview.image.gatsbyImageData
									}
								/>
							) : (
								<img
									src={media[0].preview.image.originalSrc}
									alt={title}
								/>
							)}
						</div>
						<div className='permanent-item__content'>
							<div className='permanent-item__title-bloc'>
								<h3 className='permanent-item__title'>
									{title}
								</h3>
							</div>
							<div className='permanent-item__price-bloc'>
								<div className='permanent-item__price'>
									£{' '}
									{parseInt(
										priceRangeV2.minVariantPrice.amount
									)}
								</div>
								<div className='permanent-item__rating' />
							</div>
						</div>
					</div>
				</div>
			)}
			{!currentMiracleSystem && productWithShots && (
				<div className='item__container'>
					<div
						className={`shot__shade-shot-item shade-shot-item shade-shot-item${
							isCheckShade ? '--active' : '--disabled'
						}`}
					>
						<div className='shade-shot-item__body'>
							<div
								className={`shade-shot-item__img ${
									!isCheckShade &&
									'shade-shot-item__img--default'
								}`}
							>
								{!isCheckShade ? (
									<LocalImage
										src='/images/pdp/yout-shade-shot.png'
										alt='Yout shade shot'
										loading='lazy'
									/>
								) : (
									<GatsbyImage
										image={shadeShotItemImg}
										alt={currentShadeShot.title}
									/>
								)}
							</div>
							<div className='shade-shot-item__content'>
								{!isCheckShade && (
									<div
										className='shade-shot-item__subtitle'
										onClick={() => goStep(2)}
									>
										Personalise your colour with
									</div>
								)}
								<div
									className={`shade-shot-item__title-bloc ${
										isCheckShade &&
										'shade-shot-item__title-bloc--after'
									}`}
								>
									<h3 className='shade-shot-item__title'>
										{isCheckShade
											? currentShadeShot.title
											: 'Shade shot'}
									</h3>
								</div>
								<div className='shade-shot-item__price-bloc'>
									<div className='shade-shot-item__price'>
										£ 5
									</div>
									<div className='shade-shot-item__rating' />
								</div>
								{!isCheckShade ? (
									<p className='shade-shot-item__text'>
										Customise your hair colour finish and
										add a rich mix of personalised tones
									</p>
								) : (
									<div
										className='shade-shot-item__button'
										style={{ padding: '0px', width: '80%' }}
									>
										<button
											className='shot-button'
											onClick={() => goStep(2)}
										>
											<div className='shot-button__icon'>
												<img
													src='/images/pdp/shade-btn-image.png'
													alt='Icon'
													loading='lazy'
												/>
											</div>
											change tones
										</button>
									</div>
								)}
							</div>
						</div>
						{!isCheckShade && (
							<div
								className='shade-shot-item__button'
								onClick={() => goStep(2)}
							>
								<button className='shot-button'>
									<div className='shot-button__icon'>
										<img
											src='/images/pdp/shade-btn-image.png'
											alt='Icon'
											loading='lazy'
										/>
									</div>
									Choose tones
								</button>
							</div>
						)}
					</div>
					{isCheckShade && (
						<div
							className={`shot__free-shot free-shot ${
								isCheckFree
									? 'free-shot--active'
									: 'free-shot--disabled'
							}`}
						>
							<div
								className='free-shot__body'
								onClick={handleCheckFree}
							>
								<div className='free-shot__img'>
									<img
										src='/images/pdp/miracle-condition.png'
										alt='Miracle condition'
										loading='lazy'
									/>
								</div>
								<div className='free-shot__desc'>
									<div className='free-shot__subtitle'>
										Josh recommends to boost hair health
									</div>
									<h3 className='free-shot__title'>
										Add a Miracle Shot £ 15
									</h3>
									<p className='free-shot__text'>
										Miracle Shot uses the colour to lock in
										its{' '}
										<strong>unique mix of vitamins </strong>
										and <strong>conditions</strong> from
										within, for longer lasting{' '}
										<strong>shine </strong>
										and <strong>strength</strong>
									</p>
								</div>
							</div>
							<button
								onClick={() => {
									addToBag();
									setSubtotal(subtotal + 15);
								}}
								className='free-shot__button button
                            button--dark'
							>
								add to selection
							</button>
						</div>
					)}
				</div>
			)}
			{currentMiracleSystem && (
				<div className='item__container'>
					<div className='permanent-item__body'>
						<div className='permanent-item__img'>
							<GatsbyImage
								image={miracleSystem.media[0].preview.image.gatsbyImageData}
								alt={`${currentMiracleSystem.product.title} - ${currentMiracleSystem.title}`}
								loading='lazy'
							/>
						</div>
						<div className='permanent-item__content'>
							<div className='permanent-item__title-bloc'>
								<h3 className='permanent-item__title'>
									{`${currentMiracleSystem.product.title} - ${currentMiracleSystem.title}`}
								</h3>
							</div>
							<div className='permanent-item__price-bloc'>
								<div className='permanent-item__price'>
									£ {parseInt(currentMiracleSystem.price)}
								</div>
								<div className='permanent-item__rating' />
							</div>
						</div>
					</div>
				</div>
			)}
			<div className='upsell-content__subtotal upsell-content-subtotal'>
				<span className='upsell-content-subtotal__title'>Subtotal</span>
				<span className='upsell-content-subtotal__value'>
					£ {subtotal}
				</span>
			</div>
		</div>
	);
};

export default UpsellContent;
