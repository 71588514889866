/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { storeSorting } from '../../../helpers/storeSorting';

const PlaceAutocomplete = ({
	onPlaceSelect,
	currentLocation,
	setCurrentLocation,
	setDirections,
	storesToShow,
	setIsAutocompleteVisible,
}) => {
	const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
	const inputRef = useRef(null);
	const places = useMapsLibrary('places');
	const geo = useMapsLibrary('geocoding');

	useEffect(() => {
		if (!places || !inputRef.current) return;

		const options = {
			fields: ['geometry', 'name', 'formatted_address'],
		};
		setPlaceAutocomplete(
			new places.Autocomplete(inputRef.current, options)
		);

		inputRef?.current?.addEventListener('focus', () => {
			setIsAutocompleteVisible(true);
		});

		inputRef?.current?.addEventListener('blur', () => {
			setIsAutocompleteVisible(false);
		});
	}, [places]);
	useEffect(() => {
		if (!placeAutocomplete) return;
		placeAutocomplete.addListener('place_changed', () => {
			const placeLocation = placeAutocomplete.getPlace();

			onPlaceSelect(placeLocation);

			placeLocation?.geometry?.location?.lat() !== undefined &&
				placeLocation?.geometry?.location?.lng() !== undefined &&
				setCurrentLocation({
					lat: placeLocation.geometry.location.lat(),
					lng: placeLocation.geometry.location.lng(),
				});
		});
	}, [onPlaceSelect, placeAutocomplete]);

	useEffect(() => {
		setDirections({
			coordinates: {
				latitude: storesToShow[0].coordinates.latitude,
				longitude: storesToShow[0].coordinates.longitude,
			},
		});
	}, [onPlaceSelect]);

	useEffect(() => {
		if (!geo) return;

		if (currentLocation) {
			const geocoder = new geo.Geocoder();
			geocoder.geocode(
				{
					location: currentLocation,
				},
				(results, status) => {
					if (status === 'OK') {
						if (results[0]) {
							onPlaceSelect(results[0]);
							setDirections({
								coordinates: {
									latitude:
										storesToShow[0].coordinates.latitude,
									longitude:
										storesToShow[0].coordinates.longitude,
								},
							});
							if (currentLocation.initial) {
								inputRef.current.value =
									results[0].formatted_address;
							}
						}
					}
				}
			);
		}
	}, [geo, currentLocation]);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			const autocompleteService = new places.AutocompleteService();
			const query = inputRef.current.value;

			if (!query || !autocompleteService) return;

			autocompleteService.getPlacePredictions(
				{
					input: query,
				},
				(results, status) => {
					if (status === 'OK' && results) {
						if (results.length === 0) return;

						const placeService = new places.PlacesService(
							document.createElement('div')
						);
						placeService.getDetails(
							{ placeId: results[0].place_id },
							(place, status) => {
								if (status === 'OK') {
									onPlaceSelect(place);
									setCurrentLocation({
										lat: place.geometry.location.lat(),
										lng: place.geometry.location.lng(),
									});
									setDirections({
										coordinates: {
											latitude:
												storesToShow[0].coordinates
													.latitude,
											longitude:
												storesToShow[0].coordinates
													.longitude,
										},
									});
									inputRef.current.value =
										place.formatted_address;
								}
							}
						);
					}
				}
			);
		}
	};
	return (
		<div className='autocomplete-container'>
			<input
				ref={inputRef}
				placeholder=''
				className='input-form-store'
				onKeyDown={handleKeyDown}
			/>
		</div>
	);
};

export default PlaceAutocomplete;
