// External
import React from 'react';
import Select from 'react-select';

// Internal
import './MiracleSelect.scss';

const MiracleSelect = ({
	optionsObjects,
	handleSelect,
	selectedValue,
	setSelectedValue,
	disabled = false,
	type,
	origin,
}) => {
	const prefix =
		type === 'pdp' ? 'product-miracle-select' : 'miracle-delivery-row';

	const handleChange = (e) => {
		setSelectedValue(e.value);
	};

	return (
		<Select
			key={`${prefix}${origin}`}
			value={optionsObjects.find((obj) => obj.value === selectedValue)}
			options={optionsObjects}
			classNamePrefix={prefix}
			isSearchable={false}
			components={{
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
			}}
			isDisabled={disabled}
			onMenuOpen={handleSelect}
			onMenuClose={handleSelect}
			onChange={handleChange}
			menuPortalTarget={document.body}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
		/>
	);
};

export default MiracleSelect;
