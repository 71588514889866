import React from 'react';

import ModalNotColourItem from './ModalNotColourItem';

const ModalNotColour = ({ modal: { product: products } }) => {
	const baseColours =
		products &&
		products.filter(
			(item) =>
				!item.handle.includes('shade-shot') &&
				!item.handle.includes('miracle-system') &&
				!item.handle.includes('miracle-system') &&
				item.handle.includes('permanent-colour') &&
				!item.handle.includes('8-5')
		);

	return (
		<div className='modal-not-colour'>
			<div className='modal-not-colour__title'>
				<span>
					<strong>What’s your base colour?</strong>
				</span>
				<span>colour closest to your root natural colour</span>
			</div>
			<div className='modal-not-colour__list'>
				{(baseColours || []).map((p) => {
					const t = p.title.split(' ')[2];
					return (
						<ModalNotColourItem
							handle={p.handle}
							title={t}
							key={p.id}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default ModalNotColour;
