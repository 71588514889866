import React from 'react';
import cn from 'classnames';

import ProductSelectionLevelHeader from './ProductSelectionLevelHeader';
import LevelListItem from './LevelListItem';
import PreviewLevels from './PreviewSecondLevel';

const previewFiveLevel = [
	'dark-blonde-blending',
	'dark-blending',
	'light-blending',
];

const ProductSelectionBlending = ({
	isFilterActive,
	items,
	errorSelectLevel,
	setActiveFiveLevel,
	activeFourLevel,
	activeFiveLevel,
	isMobile = false,
	activeStep = false,
	nextStep = false,
}) => {
	const title = activeFiveLevel && activeFiveLevel.title.split('/')[2];

	const element = items?.map((item) => (
		<LevelListItem
			isFilterActive={isFilterActive}
			activeStep={activeStep}
			nextStep={nextStep}
			isFiveLevel
			key={item.storefrontId}
			item={item}
			setActiveLevel={setActiveFiveLevel}
			activeLevel={activeFiveLevel}
		/>
	));

	const levelErrorName = () => {
		if (title) return false;
		if (
			activeFourLevel &&
			!activeFiveLevel &&
			errorSelectLevel?.errorLevelFive
		)
			return errorSelectLevel.errorLevelFive;
	};

	return (
		<div
			className={cn('pdp-selection-level', {
				'pdp-selection-level--disabled': !activeFourLevel,
			})}
		>
			{!isMobile && (
				<ProductSelectionLevelHeader
					title='Select blending brush'
					name={title}
					errorLevel={levelErrorName}
					showTitle
				/>
			)}
			<div className='pdp-selection-level__row pdp-selection-level-row'>
				{!activeFourLevel ? (
					<PreviewLevels
						items={previewFiveLevel}
						path='miracle/steps/blending/pdp/'
					/>
				) : (
					element
				)}
			</div>
		</div>
	);
};

export default ProductSelectionBlending;
