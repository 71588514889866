import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const HeaderTop = ({ news, windowWidth }) => {
	const parsedNews = news ? JSON.parse(news) : { mobile: [], desktop: [] };
	const newsToUse =
		windowWidth < 750 ? parsedNews.mobile || [] : parsedNews.desktop || [];

	const handleSliderClickToShowPopUp = (event) => {
		const anchorElement = event.target.closest('a');

		if (anchorElement && anchorElement.href.includes('#popup')) {
			window._klOnsite = window._klOnsite || [];
			window._klOnsite.push(['openForm', 'XQUZKg']);
		}
	};

	const isInDate = (item) => {
		if (!item.days) return true;

		const currentDate = new Date();
		const day = currentDate.getDay();
		const hour = currentDate.getHours();

		const isFirstDayAndBeforeStartTime =
			day === item.days[0] && hour <= item.startTime;
		const isLastDayAndAfterEndTime =
			day === item.days[item.days.length - 1] && hour >= item.endTime;

		if (isFirstDayAndBeforeStartTime || isLastDayAndAfterEndTime)
			return false;

		return item.days.includes(day);
	};

	return (
		<>
			<div className='announcement-bar'>
				{windowWidth > 992 && (
					<div className='announcement-bar__item'>
						<p>
							<strong>FREE</strong>
							&nbsp;UK Delivery on orders over £35
						</p>
					</div>
				)}
				<div className='announcement-bar__slider-container'>
					<Swiper
						slidesPerView={1}
						initialSlide={1}
						allowTouchMove={false}
						centeredSlides
						loop={newsToUse.length > 1}
						preventClicks={false}
						preventClicksPropagation={false}
						autoplay={{
							delay: 10000,
						}}
					>
						{newsToUse
							.filter((item) => item.text && isInDate(item))
							.map((item, index) => (
								<SwiperSlide key={item.text}>
									<div
										className='announcement-bar__slide'
										id={`slider${index}`}
										onClick={handleSliderClickToShowPopUp}
										dangerouslySetInnerHTML={{
											__html: item.text,
										}}
									/>
								</SwiperSlide>
							))}
					</Swiper>
				</div>
				{windowWidth > 992 && (
					<div className='announcement-bar__item' />
				)}
			</div>
			{/* <div className="shipscout-placeholder"/> */}
		</>
	);
};

export default HeaderTop;
