import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SalonGalleryFirstSection = () => (
	<div className='salon-gallery__info'>
		<div className='salon-gallery__img salon-gallery__img--first'>
			<LazyLoadImage
				src='/images/salon/gallery-1.jpg'
				alt='main salon gallery'
				effect='blur'
			/>
		</div>
	</div>
);
export default SalonGalleryFirstSection;
