export default [
	{
		name: 'Sharon',
		text: 'No more greys. My hair feels so shiny and healthy, it’s amazing!',
		imgUrl: 'miracle/author2.webp',
	},
	{
		name: 'Mei',
		text: 'I love how easy The Miracle System is to use. I find my colour doesn’t fade as much, and it looks really healthy.',
		imgUrl: 'miracle/author4.webp',
	},
	{
		name: 'Rebecca',
		text: 'I’m so pleased; The Miracle System gave me great coverage, and my hair’s condition actually felt better than before colouring.',
		imgUrl: 'miracle/author5.webp',
	},
	{
		name: 'Natalie',
		text: 'This is a great colour, that covers greys well and leaves my hair super shiny. A fuss-free, easy to use products that smells divine and gives great lasting results - thank you Josh!',
		imgUrl: 'miracle/author6.webp',
	},
	{
		name: 'Kelly',
		text: 'My colour looks so natural and brings out extra shine and smoothness to my hair. The kit comes with everything you need, and I think the Miracle Shot makes a lot of difference to the end result.',
		imgUrl: 'miracle/author7.webp',
	},
	{
		name: 'Mira',
		text: 'Not a little grey hair in sight! What’s great is that you can customise The Miracle System to be the perfect colour for you.',
		imgUrl: 'miracle/author8.webp',
	},
	{
		name: 'Abbey',
		text: 'I just tried the Josh Wood Miracle System for the first time and I can’t believe how soft my hair feels! I had a video consultation with one of their colourists who recommended shade 5.0 with the Chestnut Shade Shot, and it’s a perfect match. I won’t look back.',
		imgUrl: 'miracle/author9.webp',
	},
];
