import { codesToPreAdd } from '../constants/home-page';
import { getDiscount } from '../utils/helpers';

export const applyPreAddDiscount = async (cartId, code, addVariantToCart) => {
	sessionStorage.setItem('code', code);

	const discountCode = codesToPreAdd.find((item) => item.code === code);
	const discount = await getDiscount(btoa(discountCode.id));

	const { itemPrerequisites, itemEntitlements } = discount.data.priceRule;
	const variantId =
		itemEntitlements.products.edges[0].node.variants.edges[0].node.id;

	const listOfNecessaryIds = [];
	const { products, productVariants } = itemPrerequisites;

	if (products.edges.length) {
		products.edges.forEach((product) =>
			product.node.variants.edges.forEach((variant) =>
				listOfNecessaryIds.push(variant.node.id)
			)
		);
	}

	if (productVariants.length) {
		productVariants.edges.forEach((variant) =>
			listOfNecessaryIds.push(variant.node.id)
		);
	}

	if (listOfNecessaryIds.length) {
		sessionStorage.setItem(
			'itemsShouldBeAdded',
			JSON.stringify(listOfNecessaryIds)
		);
	}

	const lineItemsToUpdate = [
		{
			variantId,
			quantity: 1,
		},
	];

	await addVariantToCart(cartId, lineItemsToUpdate);
};

export default applyPreAddDiscount;
