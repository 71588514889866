/* eslint-disable */
import React from 'react';

import SEO from '../../components/SEO';
import SalonPriceList from '../../templates/SalonPriceList/SalonPriceList';

const headerText = 'Atelier services & price list';

const SalonPriceListPage = ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<SalonPriceList headerText={headerText} />
	</>
);

export default SalonPriceListPage;
