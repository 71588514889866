// External
import React, { useEffect, useState } from 'react';

// Internal
import { getMetafield } from '../../../../utils/helpers';
import chevron from '../../../../../static/images/chevron.png';

const VariantInformation = ({ product }) => {
	const [variantIngredients, setVariantIngredients] = useState([]);
	const aboutVariant = getMetafield(product, 'about_variant');
	const ingredientsVariant = getMetafield(product, 'ingredients_variant');
	const howToUseVariant = getMetafield(product, 'how_to_use_variant');
	useEffect(() => {
		product.variants?.map((variant) => {
			const variantIngredientMetafield =
				getMetafield(variant, 'variant_ingredients') ??
				'There are not available ingredients for this product';

			setVariantIngredients((prevIngredients) => [
				...prevIngredients,
				{
					variantName: variant.title,
					variantIngredientMetafield,
				},
			]);
		});
	}, [product]);

	return (
		<section className='about--variant-section'>
			{aboutVariant && (
				<details open>
					<summary>
						<h3>About the product</h3>
						<span className='icon'>
							<img
								src={chevron}
								alt='open and close the dropdown section'
							/>
						</span>
					</summary>
					<div dangerouslySetInnerHTML={{ __html: aboutVariant }} />
				</details>
			)}
			{(ingredientsVariant || variantIngredients?.length > 0) && (
				<details>
					<summary>
						<h3>Ingredients</h3>
						<span className='icon'>
							<img
								src={chevron}
								alt='open and close the dropdown section'
							/>
						</span>
					</summary>
					{variantIngredients?.length > 0 ? (
						<div className='variants-ingredients'>
							{variantIngredients.map((variant) => (
								<details className='variant-details'>
									<summary>
										<h4>{variant.variantName}</h4>
										<span className='variant-icon'>
											<img
												src={chevron}
												alt='open and close the dropdown section'
											/>
										</span>
									</summary>
									<p
										dangerouslySetInnerHTML={{
											__html: variant.variantIngredientMetafield,
										}}
									/>
								</details>
							))}
						</div>
					) : (
						<p
							dangerouslySetInnerHTML={{
								__html: ingredientsVariant,
							}}
						/>
					)}
				</details>
			)}
			{howToUseVariant && (
				<details>
					<summary>
						<h3>How to Use?</h3>
						<span className='icon'>
							<img
								src={chevron}
								alt='open and close the dropdown section'
							/>
						</span>
					</summary>
					<p dangerouslySetInnerHTML={{ __html: howToUseVariant }} />
				</details>
			)}
		</section>
	);
};

export default VariantInformation;
