import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import { alertErrorToSlack } from '../../utils/slackMessage';
import { StoreContext } from '../../context/store-context';

const ErrorPage = ({ resetErrorBoundary, error }) => {
	const { setCart } = useContext(StoreContext);

	useEffect(() => {
		const handleError = () => {
			setTimeout(() => {
				resetErrorBoundary();
			}, 1);
			if (error) {
				alertErrorToSlack(error);
			}
			localStorage.clear();
			sessionStorage.clear();
			navigate('/');
		};
		handleError();
	}, []);

	return null;
};

export default ErrorPage;
