import React, { useEffect, useState } from 'react';

const InstaFeed = ({ location }) => {
	const [isShown, setIsShown] = useState();

	useEffect(() => {
		setIsShown(!location?.pathname?.includes('consultation'));
	}, [location]);

	return (
		<div className={`${isShown ? 'display' : 'hide'}`} id='insta-feed' />
	);
};

export default InstaFeed;
