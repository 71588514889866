import littledata from '@littledata/headless-shopify-sdk';

const fetchLittledataClientIds = (cartData) => {
	const ga4MeasurementId = 'G-5TT6JG7CTF';

	return littledata
		.fetchClientIds({
			ga4MeasurementId,
		})
		.then((shopifyNoteAttributes) =>
			littledata.sendCartToLittledata(cartData.id)
		);
};

export default fetchLittledataClientIds;
