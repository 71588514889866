import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ProductTooltipItem = ({
	item,
	name,
	openModal,
	width,
	isActiveClass,
}) => (
	<div
		role='button'
		className={`pdp-tooltip__item ${
			isActiveClass === name &&
			width <= 768 &&
			'pdp-tooltip__item--active'
		}`}
		onClick={() => openModal(item.type, item.text, name)}
	>
		<div className='pdp-tooltip__header'>
			<LazyLoadImage
				src={`/images/${item.icon}.svg`}
				alt={name}
				className='pdp-tooltip__icon'
				effect='blur'
			/>
			<span className='pdp-tooltip__body'>{name}</span>
		</div>
		<div className='pdp-tooltip__content'>
			<div
				className='pdp-tooltip__text'
				dangerouslySetInnerHTML={{ __html: item.text }}
			/>
		</div>
	</div>
);

export default ProductTooltipItem;
