// External
import React, { useEffect, useState } from 'react';

// Internal
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductBlogGrid from './ProductBlogGrid';
import getProductInformation from '../../utils/fetchProducts';

import './blogRelatedProducts.scss';
import { useWindowWidth } from '../../utils/hooks';

const BlogRelatedProducts = ({
	relatedProducts,
	isProductInsideBlockContent,
}) => {
	const [allFeaturedProducts, setAllFeaturedProducts] = useState([]);
	const width = useWindowWidth();
	const products = relatedProducts?.items;
	const areValidFeaturedProducts =
		Array.isArray(products) &&
		Array.isArray(allFeaturedProducts) &&
		products.length === allFeaturedProducts.length;

	useEffect(() => {
		if (!Array.isArray(products)) return;
		const ids = products.map((product) => product.id);
		getProductInformation(ids)
			.then((data) => {
				setAllFeaturedProducts(data);
			})
			.catch((error) => {
				console.error(error);
				setAllFeaturedProducts(null);
			});
	}, []);

	return (
		<>
			<section
				className={` featured-products__section ${
					isProductInsideBlockContent &&
					'article article-products'
				}`}
			>
				{areValidFeaturedProducts && (
					<>
						{!isProductInsideBlockContent && (
							<h3 className='featured-products__title'>
								Featured products
							</h3>
						)}

						<Swiper
							simulateTouch
							initialSlide={0}
							spaceBetween={10}
							slidesPerView='auto'
							pagination={{
								el: '.related-products__section--pagin',
								clickable: true,
								type: 'bullets',
							}}
						>
							{allFeaturedProducts.map((product) => (
								<SwiperSlide className='product-swiper'>
									<ProductBlogGrid
										featuredProduct={product}
										key={product?.title}
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</>
				)}
				{width < 767 && (
					<div className='related-products__section--pagin' />
				)}
			</section>
		</>
	);
};

export default BlogRelatedProducts;
