import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import cn from 'classnames';

import { useWindowWidth } from '../../utils/hooks';

import './Video.scss';

const Video = ({ classname, title, videoId, initialLoad = false }) => {
	const windowWidth = useWindowWidth();
	const [loadedVideo, setLoadedVideo] = useState(initialLoad);

	return (
		<div
			onMouseEnter={
				windowWidth > 991 ? () => setLoadedVideo(true) : () => false
			}
			onClick={
				windowWidth < 992 ? () => setLoadedVideo(true) : () => false
			}
			className={`video ${classname && classname}`}
		>
			{title ? <h3 className='video-title'>{title}</h3> : null}

			{loadedVideo && (
				/* eslint-disable-next-line */

				<ReactPlayer
					className={cn('video__iframe', {
						'video__iframe--loaded': loadedVideo,
					})}
					url={`https://www.youtube.com/watch?v=${videoId}`}
					width='100%'
					height='100%'
					controls
					config={{
						youtube: {
							playerVars: {
								autoplay: 0,
								modestbranding: 1,
								showinfo: 1,
							},
						},
					}}
				/>
			)}
			<div
				className='_2hdkJ'
				style={{
					backgroundImage: `url(https://img.youtube.com/vi/
                    ${videoId}/sddefault.jpg)`,
				}}
			>
				<div className='_16Q0f' />
			</div>
		</div>
	);
};

export default Video;
