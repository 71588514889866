import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../context/store-context';
import ArticleRelatedArticles from '../../ArticleRelatedArticles/ArticleRelatedArticles';
import '../BlogStyles.scss';

const BlogRelatedArticles = ({ blok }) => {
	const { relatedArticles } = blok;
	const { articles, setArticles } = useContext(StoreContext);

	const fetchBlogs = async () => {
		const response = await fetch(
			`https://api.storyblok.com/v2/cdn/stories?starts_with=blogs/&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}`
		);

		const blogs = await response.json();

		setArticles(blogs);
	};

	useEffect(() => {
		if (articles.length === 0) {
			fetchBlogs();
		}
	}, []);

	const blogsRelated = articles?.stories
		?.filter((blog) => relatedArticles.includes(blog.uuid))
		.map((article) => ({
			id: article.id,
			full_slug: article.full_slug,
			image: article.content.body[0].featuredImage.filename,
			imageAlt: article.content.body[0].featuredImage.alt,
			title: article.content.body[0].title,
			storyblok_blog: true,
			publishedAt: article.created_at,
			tags: article.tag_list,
			content: article.content.body[0].content,
		}));

	return (
		<>
			{blogsRelated?.length > 0 && (
				<section className='blog-container'>
					<ArticleRelatedArticles relatedArticles={blogsRelated} />
				</section>
			)}
		</>
	);
};

export default BlogRelatedArticles;
