// External
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

const ProductPhotoGallery = ({
	product,
	thumbsSwiper,
	setThumbsSwiper,
	customPreviewImage,
}) => {
	const countThumb = (product?.media || []).length,
		sliderGallerySettings = {
			loop: false,
			slidesPerView: 5,
			spaceBetween: 10,
			onSwiper: setThumbsSwiper,
			breakpoints: {
				1200: {
					spaceBetween: 12,
				},
			},
			preloadImages: false,
			lazy: true,
		},
		goNext = () => {
			if (thumbsSwiper !== null) {
				thumbsSwiper.slideNext();
			}
		},
		goPrev = () => {
			if (thumbsSwiper !== null) {
				thumbsSwiper.slidePrev();
			}
		};
	return (
		<div className='pdp-photo-gallery'>
			{countThumb > 5 && (
				<button className='pdp-prev-arrow' onClick={goPrev} />
			)}
			<Swiper {...sliderGallerySettings}>
				{customPreviewImage && (
					<SwiperSlide
						className='pdp-photo-gallery__slide'
						key={customPreviewImage}
					>
						<LazyLoadImage
							src={customPreviewImage}
							alt={product?.title}
							className='pdp-photo-gallery__img swiper-lazy'
							effect='blur'
						/>
					</SwiperSlide>
				)}
				{Array.isArray(product?.media) &&
					product.media.map((image) => (
						<SwiperSlide
							className='pdp-photo-gallery__slide'
							key={image.shopifyId}
						>
							{image && (
								<div className='pdp-photo-gallery__item'>
									{image?.preview?.image?.gatsbyImageData &&
									!image?.preview.image.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											alt={image.shopifyId}
											image={
												image.preview.image
													.gatsbyImageData
											}
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={image.shopifyId}
										/>
									)}
								</div>
							)}
						</SwiperSlide>
					))}
			</Swiper>
			{countThumb > 5 && (
				<button className='pdp-next-arrow' onClick={goNext} />
			)}
		</div>
	);
};

export default ProductPhotoGallery;
