import request from '../request';

export default async (upsellId) => {
	const variables = {
		upsellId,
	};
	const query = ` query GetProductSellingPlanGroup($upsellId: ID!) {
        product (id: $upsellId) {
            sellingPlanGroups(first: 1) {
                edges {
                    node {
                        sellingPlans(first:10) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;
	return request(query, variables);
};
