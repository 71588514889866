/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

const MapHandler = ({ place, current }) => {
	const map = useMap();

	useEffect(() => {
		if (!map || !place) return;

		if (place.geometry?.viewport) {
			map.fitBounds(place.geometry?.viewport);
			map.setZoom(12);
		}

		if (place.coordinates?.latitude && place.coordinates?.longitude) {
			map.setCenter({
				lat: place.coordinates.latitude,
				lng: place.coordinates.longitude,
			});
			map.setZoom(16);
		}
	}, [map, place]);

	useEffect(() => {
		if (!map || !current) return;

		map.setCenter({
			lat: current.lat,
			lng: current.lng,
		});
		map.setZoom(15);
	}, [map, current]);
	return null;
};

export default MapHandler;
