import React from 'react';
import { getMetafield } from '../../../../utils/helpers';

const ProductInfoRating = ({ product }) => {
	const ratingText = getMetafield(product, 'text_rating_info'),
		ratingColor = getMetafield(product, 'rating_color'),
		style = {
			backgroundColor: ratingColor || 'rgba(236, 186, 86, 0.5)',
		};

	return (
		<span style={style} className='pdp-info__rating'>
			{ratingText}
		</span>
	);
};

export default ProductInfoRating;
