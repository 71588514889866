/* eslint-disable react/function-component-definition */
import React from 'react';
import { Link } from 'gatsby';
import { ReactSVG } from 'react-svg';
import './style.scss';

function NotFoundPage() {
	return (
		<div className='page-404'>
			<h1 className='page-404__title'>
				404 We couldn’t find that page, but why?
			</h1>
			<h6 className='page-404__subtitle'>
				We asked some leading hair colourists:
			</h6>
			<div className='page-404__text'>
				<dl>
					<dt>
						<Link
							className='page-404__link'
							to='/pages/miracle-about'
						>
							Miracle System
						</Link>{' '}
						is being used on the page.
					</dt>
					<dd>
						It will return 2.7 times stronger and 62% less likely to
						break.
					</dd>

					<dt>
						<Link
							className='page-404__link'
							to='/collections/permanent-hair-colour'
						>
							Permanent Colour
						</Link>{' '}
						change is underway.
					</dt>
					<dd>No more grey when the page returns.</dd>

					<dt>
						<Link
							className='page-404__link'
							to='/collections/miracle-mask'
						>
							Miracle Mask
						</Link>{' '}
						came to the rescue.
					</dt>
					<dd>
						The page was weak and damaged but will return revived in
						a deeply better{' '}
						<Link
							className='page-404__link'
							to='/collections/conditioners'
						>
							condition
						</Link>
						.
					</dd>

					<dt>
						Our{' '}
						<Link
							className='page-404__link'
							to='/pages/josh-wood-colour-hair-salon-london'
						>
							salon
						</Link>{' '}
						had an opportunity available for a make-over.
					</dt>
					<dd>
						Creative masterpieces designed by our{' '}
						<Link
							className='page-404__link'
							to='/pages/meet-the-experts'
						>
							experts
						</Link>{' '}
						will be with us soon.
					</dd>

					<dt>
						The page went for a{' '}
						<Link
							className='page-404__link'
							to='/collections/hair-gloss-and-glaze'
						>
							Gloss
						</Link>{' '}
						up.
					</dt>
					<dd>It will return with vibrant colour that pops.</dd>

					<dt>
						The photos are being{' '}
						<Link
							className='page-404__link'
							to='/collections/root-spray'
						>
							Airbrushed
						</Link>
						.
					</dt>
					<dd>
						Colours are being{' '}
						<Link
							className='page-404__link'
							to='/collections/root-touch-up-brushes'
						>
							blended with brushes
						</Link>{' '}
						so no more stray{' '}
						<Link
							className='page-404__link'
							to='/collections/root-touch-up'
						>
							roots
						</Link>
						.
					</dd>
				</dl>
				<p>In the mean time, maybe you would like to:</p>
				<ul>
					<li>
						Read our{' '}
						<Link className='page-404__link' to='/blogs/community'>
							community articles
						</Link>{' '}
						in our waiting area whilst treatment is happening.
					</li>
					<li>
						Get a free{' '}
						<Link
							className='page-404__link'
							to='/pages/consultation'
						>
							consultation
						</Link>{' '}
						to perfect your look.
					</li>
					<li>
						Browse{' '}
						<Link
							className='page-404__link'
							to='/collections/all-products'
						>
							all the products
						</Link>{' '}
						in our gift shop.
					</li>
				</ul>
				<p>Thank you for your patience.</p>
			</div>
			<Link className='page-404__continue continue-button' to='/'>
				<span className='continue-button__text'>continue shopping</span>
				<ReactSVG
					src='/images/left-arrow.svg'
					wrapper='span'
					className='icon icon--wide icon-arrow-right'
				/>
			</Link>
		</div>
	);
}

export default NotFoundPage;
