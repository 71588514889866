/* eslint-disable */
import React from 'react';

import SEO from '../../components/SEO';
import Salon from '../../templates/SalonPage/SalonPage';

const headerText = 'Josh Wood: King Of Colour';

const SalonPage = ({ location, pageContext: { page, allHours } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<Salon headerText={headerText} allHours={allHours} />
	</>
);

export default SalonPage;
