import React from 'react';

import ExpertArticlePage from './ExpertArticlePage';
import SEO from '../../components/SEO';

const ExpertArticlePageContainer = ({
	location,
	pageContext: { expert, page },
}) => (
	<>
		<SEO page={page} location={location} title={expert.name} />
		<ExpertArticlePage {...{ expert }} />
	</>
);

export default ExpertArticlePageContainer;
