import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import TrustPilot from '../../TrustPilot';
import ButtonGradient from '../../ButtonGradient';

import './CustomerReviews.scss';

const CustomerReviews = () => (
	<section className='container' id='customer-reviews'>
		<div className='customer-reviews'>
			<div className='customer-reviews__image'>
				<StaticImage
					src='../../../../static/images/home-page/reviews-comments.png'
					alt='Customer reviews'
				/>
			</div>
			<div className='customer-reviews__content'>
				<TrustPilot id='5419b6ffb0d04a076446a9af' height='40px' />
				<h2>
					Our customers have plenty to say about their Josh Wood
					Colour experience.
				</h2>

				<p>
					From easy grey coverage to that perfect shade match, here’s
					what people are loving about our products.
				</p>

				<ButtonGradient
					isLink
					isPinkOutline
					linkUrl='/pages/all-reviews'
					buttonText='View all reviews'
				/>
			</div>
		</div>
	</section>
);

export default CustomerReviews;
