import React from 'react';
import ProductGiftCardOption from './ProductGiftCardOption';

const ProductGiftCardOptions = ({
	options,
	selectedOption,
	setSelectedOption,
}) => (
	<div className='pdp-gift-card__options'>
		{options.map((option, index) => (
			<ProductGiftCardOption
				/* eslint-disable-next-line */
				key={index}
				option={option}
				isSelected={selectedOption === option}
				setSelectedOption={setSelectedOption}
			/>
		))}
	</div>
);

export default ProductGiftCardOptions;
