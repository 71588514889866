import React from 'react';

import ModalRedList from './ModalRedList';

const ModalRedRight = ({ title, list }) => {
	const types = ['text', 'before', 'after'];
	return (
		<div className='modal-red__right'>
			<div className='modal-red__header'>
				<h3 className='modal-red__title'>{title} Results Scale</h3>
				<p className='modal-red__text'>
					Match your shade below to see how it could transform
				</p>
			</div>
			<div className='modal-red__container'>
				{types.map((type) => (
					<ModalRedList
						key={type}
						list={list}
						title={title}
						type={type}
					/>
				))}
			</div>
			<p className='modal-red__subtext'>
				See how your shade could look. Colour varies according to your
				base shade. Light hair reveals a more vibrant result.
			</p>
		</div>
	);
};

export default ModalRedRight;
