import React from 'react';

import ProductAwardItem from './ProductAwardItem';

import './ProductAwards.scss';

const ProductAwards = ({ awards, product }) => (
	<div className='pdp-awards'>
		<div className='container'>
			<ul className='pdp-awards__items flex--desktop center'>
				{(awards || []).map((award, i) => (
					<ProductAwardItem
						/* eslint-disable-next-line */
						key={i}
						award={award}
						idx={i}
						product={product}
					/>
				))}
			</ul>
		</div>
	</div>
);

export default ProductAwards;
