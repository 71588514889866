import React from 'react';
import { Link } from 'gatsby';

import FooterLogo from './Logo';
import SocialItem from '../../SocialItem';
import TrustPilot from '../../TrustPilot';

const FooterLeftSide = ({ socials, location }) => {
	const isHomePage = location?.pathname === '/';
	return (
		<div className='footer__left-side'>
			<div className='footer-site__logo'>
				<Link to='/'>
					<FooterLogo />
				</Link>
			</div>
			<div className='footer__rating'>
				<ul className='footer__socials'>
					{(socials || []).map((item) => (
						<SocialItem item={item} key={item.id} />
					))}
				</ul>
				{!isHomePage && (
					<TrustPilot id='53aa8807dec7e10d38f59f32' height='90px' />
				)}
			</div>
		</div>
	);
};

export default FooterLeftSide;
