/* eslint-disable */
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { v4 as uuid } from 'uuid';
import { StoreProvider } from './src/context/store-context';
import redirects from './static/redirects.json';

import './src/styles/global.scss';

export const onRouteUpdate = ({ prevLocation }) => {
	if (window.dataLayer) {
		window.dataLayer.push({ event: 'dl_route_change', event_id: uuid() });
	} else {
		window.dataLayer = [{ event: 'dl_route_change', event_id: uuid() }];
	}

	window.prevPath = prevLocation?.pathname;
	redirects.forEach(({ fromPath, toPath }) => {
		if (location.pathname === fromPath) {
			window.location.replace(toPath);
		}
	});
};

export const wrapRootElement = ({ element }) => (
	<StoreProvider>{element}</StoreProvider>
);

export const replaceHydrateFunction = () => (element, container) =>
	ReactDOM.createRoot(container).render(element);
