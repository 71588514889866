import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import MiracleColourNavigation from '../MiracleColour/MiracleColourNavigation';
import MiracleSliderItem from './MiracleSliderItem';
import MiracleToneTooltip from '../MiracleTone/MiracleToneTooltip';

import { isBrowser } from '../../../utils/helpers';
import { StoreContext } from '../../../context/store-context';
import { useWindowWidth } from '../../../utils/hooks';

import './MiracleSlider.scss';

const MiracleSlider = ({
	items,
	type,
	settings,
	onClick,
	activeItem,
	goSlide,
}) => {
	const [swiper, setSwiper] = useState(null);
	const [tooltip, setTooltip] = useState('');
	const width = useWindowWidth();
	const { showModal, setModal, isShowGallery } = useContext(StoreContext);
	const [itemSelected, setItemSelected] = useState("");
	const slideTo = (text) =>
		swiper[text !== 'darker' ? 'slidePrev' : 'slideNext']();

	let pressTimer;
	const onTouchStart = (img, title) => {
			isBrowser &&
				width < 768 &&
				(pressTimer = window.setTimeout(() => {
					setModal({ img, title }, 'long-tap'); // eslint-disable-line
					showModal(true); // eslint-disable-line
				}, 500));
		},
		onTouchEnd = () => {
			if (width < 768) {
				clearTimeout(pressTimer);
				showModal(false);
			}
		};

	useEffect(() => {
		if (goSlide !== null && swiper) {
			swiper.slideTo(goSlide);
		}
	}, [goSlide, swiper]);

	useEffect(() => {
		if (swiper) {
			swiper.slideTo(settings.initialSlide);
		}
	}, [settings.initialSlide]);

	return (
		<>
			<div
				className={`miracle-slider miracle-${type}-list ${
					isShowGallery && 'miracle-slider--animate'
				}`}
			>
				{type === 'tone' && activeItem && (
					<MiracleToneTooltip {...{ tooltip }} />
				)}
				<Swiper onSwiper={setSwiper} {...settings}>
					{items?.map((item, i) => (
						<SwiperSlide
							/* eslint-disable-next-line */
							key={i}
							className={
								type === 'colour' &&
								isShowGallery &&
								'active-slide'
							}
						>
							<MiracleSliderItem
								{...{
									setTooltip,
									onTouchStart,
									onClick,
									type,
									onTouchEnd,
									item,
									activeItem,
									setItemSelected
								}}
							/>
						</SwiperSlide>
					))}
				</Swiper>
				
			</div>

			
			{!isShowGallery ? (
				<MiracleColourNavigation
					slideTo={slideTo}
					isShowGallery={isShowGallery}
				/>
			) :<p className='miracle-item-selected'>{itemSelected}</p>}
			
		</>
	);
};
export default MiracleSlider;
