import React, { useState } from 'react';

import InfoVideoBlock from '../../../components/InfoVideoBlock';

import { getMetafield, getVideoId } from '../../../utils/helpers';

const ProductTalks = ({ product, activeVariant }) => {
	const talksTitle = getMetafield(product, 'talks_title'),
		talksDescription = getMetafield(product, 'talks_description'),
		talksImg = getMetafield(product, 'talks_image'),
		talksVideoId = getVideoId(getMetafield(product, 'talks_video')),
		variantTalksTitle =
			activeVariant && getMetafield(activeVariant, 'talks_title'),
		variantTalksDescription =
			activeVariant && getMetafield(activeVariant, 'talks_description'),
		variantTalksImg =
			activeVariant && getMetafield(activeVariant, 'talks_image'),
		variantVideoId =
			activeVariant &&
			getVideoId(getMetafield(activeVariant, 'talks_video'));

	return (
		<div className='pdp-talks light-bg-container'>
			<div className='container flex'>
				<InfoVideoBlock
					title={variantTalksTitle || talksTitle}
					text={variantTalksDescription || talksDescription}
					videoId={variantVideoId || talksVideoId}
					img={variantTalksImg || talksImg}
					videoTitle={variantTalksTitle || talksTitle}
				/>
			</div>
		</div>
	);
};

export default ProductTalks;
