import React from 'react';

import Yotpo from '../../../../components/Yotpo';

const ProductGiftCardPrice = ({ itemPrice, rating }) => (
	<div className='pdp-info__price'>
		<div className='pdp-info__flex'>
			<>{`£ ${parseInt(itemPrice)}`}</>
		</div>
		{rating && <Yotpo rating={rating} />}
	</div>
);

export default ProductGiftCardPrice;
