// External
import React from 'react';
import { Link } from 'gatsby';

// Internal
import './ExpertsGridItem.scss';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const ExpertsGridItem = ({ expert }) => {
	const expertImage = getShopifyImage({
		image: { originalSrc: `https:${expert.img_url}` },
		width: 230,
		height: 310,
	});
	return (
		<li className='expert-item'>
			<Link to={`/pages/meet-the-experts/${expert.handle}`}>
				<div className='expert-item__img'>
					{expert.img_url ? (
						<GatsbyImage image={expertImage} alt={expert.name} />
					) : (
						<StaticImage
							src='../../../../../static/images/empty-expert.png'
							alt='empty'
						/>
					)}
				</div>
				<h3 className='expert-item__name'>{expert.name}</h3>
				<span className='expert-item__profession'>
					{expert.tags[0]}
				</span>
			</Link>
		</li>
	);
};
export default ExpertsGridItem;
