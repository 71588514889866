import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useClickOutside } from '../../../utils/hooks';

const MiracleGalleryActions = ({
	showInfo,
	setShowInfo,
	handleClose,
	windowWidth,
}) => {
	const helpBloc = useRef(null);

	useClickOutside(helpBloc, () => setShowInfo(false));

	return (
		<div className='miracle-gallery-actions'>
			<div
				role='button'
				className={`miracle-gallery-actions__item 
                miracle-gallery__close`}
				onClick={handleClose}
			>
				<LazyLoadImage
					src='/images/miracle/steps/nav/miracle-close.svg'
					alt='close'
					effect='blur'
					wrapperClassName='lazyLoad-wrapper-img'
				/>
			</div>
			<div ref={helpBloc} className='miracle-gallery-actions__help'>
				<div
					className={`miracle-gallery-actions__item 
                    miracle-gallery-actions__item_bubble 
                    miracle-gallery-actions__item--grey`}
					onClick={() => setShowInfo(!showInfo)}
				>
					<span>{windowWidth > 750 ? 'need help?' : '?'}</span>
				</div>
				<div
					className={`miracle-gallery-actions__item 
                    miracle-gallery-actions__item--purple ${
						!showInfo && 'miracle-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/consultation-v2'>Take a quiz</Link>
				</div>
				<div
					className={`miracle-gallery-actions__item 
                    miracle-gallery-actions__item--pink ${
						!showInfo && 'miracle-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/book-a-video-chat'>
						Book a video consultation
					</Link>
				</div>
			</div>
		</div>
	);
};

export default MiracleGalleryActions;
