import React from 'react';
import './style.scss';

const Homepage = ({ components }) => (
	<div className='miracle'>{components}</div>
);

export default Homepage;


