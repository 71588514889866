import React from 'react';

const FrequentlyInfo = ({ addToCart, variantsTotalSum }) => (
	<div className='frequently-info'>
		<div className='frequently-info__title'>Frequently Bought Together</div>
		{variantsTotalSum && (
			<div className='frequently-info__price'>
				<span>
					Total price: <strong>£{variantsTotalSum}</strong>
				</span>
			</div>
		)}
		<button
			onClick={addToCart}
			type='button'
			className='button button--dark frequently-info__button'
		>
			<span className='button__text'>Add Selection to Bag</span>
		</button>
	</div>
);

export default FrequentlyInfo;
