import React from 'react';
import { Link } from 'gatsby';

import LocalVideo from '../../components/LocalVideo';
import video from '../../../static/miracle-videos/how-to-use.mp4';
import Video from '../../components/Video';

const HowToUseMiracle = ({ windowWidth }) => (
	<div className='how-to-use'>
		<div id='how-to-use__video' className='how-to-use__top'>
			<LocalVideo
				source={video}
				name='how-to-use-video'
				className='how-to-use__top'
			/>
		</div>
		<div className='how-to-use__body'>
			<div className='how-to-use__body__text'>
				<h2 className='how-to-use__body__text__title'>
					How to use The Miracle System
				</h2>
				<p className='how-to-use__body__text__desc'>
					The three elements are activated by mixing together to
					become <strong>The Miracle System</strong>, which improves
					the quality of both hair colour and health over multiple
					applications.
				</p>
				<p className='how-to-use__body__text__desc'>
					See how Bryony Blake applies the Miracle System to cover her
					greys to achieve a salon-quality finish at home.
				</p>
				{windowWidth >= 750 && (
					<div className='how-to-use__button'>
						<Link to='/miracle-steps'>
							<span>Find My Shade</span>
						</Link>
					</div>
				)}
			</div>
			<div className='how-to-use__body__img'>
				<Video videoId='xTnp6Vsz0qU' videoTitle='How it Works' />
			</div>
			{windowWidth <= 750 && (
				<div className='how-to-use__button'>
					<Link to='/miracle-steps'>
						<span>Find My Shade</span>
					</Link>
				</div>
			)}
		</div>
	</div>
);

export default HowToUseMiracle;
