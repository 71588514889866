import React, { useContext } from 'react';

import InfoVideoBlock from '../../../components/InfoVideoBlock';

import { getMetafield, getVideoId } from '../../../utils/helpers';
import { StoreContext } from '../../../context/store-context';

import './ProductHowUse.scss';

const ProductHowUse = ({ product, activeVariant }) => {
	const { setModal, showModal } = useContext(StoreContext),
		/* eslint-disable-next-line */
		placeholderText =
			'Recommended for use in a shade close to your natural colour as seen in your root regrowth.<br> Not designed for dramatic colour transformations. Always do a patch test first 48 hours before colouring.',
		worthNoting = getMetafield(product, 'worth_noting')
			? getMetafield(product, 'worth_noting')
			: placeholderText,
		modalText = `<h3>Worth noting</h3><p>${worthNoting}</p>`,
		openModal = () => {
			setModal(product, 'link', modalText);
			showModal(true);
		},
		text = getMetafield(product, 'description_how_to_use'),
		videoId = getVideoId(getMetafield(product, 'video_how_to_use')),
		img = getMetafield(product, 'how_to_use_image'),
		variantHowDescription =
			activeVariant && getMetafield(activeVariant, 'how_use_description'),
		variantHowImg =
			activeVariant && getMetafield(activeVariant, 'how_use_image'),
		variantHowVideoId =
			activeVariant &&
			getVideoId(getMetafield(activeVariant, 'how_use_video'));

	return (
		<div className='pdp-how-use light-bg-container'>
			<div className='container'>
				<InfoVideoBlock
					reverse
					title='How to use'
					text={variantHowDescription || text}
					videoId={variantHowVideoId || videoId}
					videoTitle='How to use'
					openModal={openModal}
					img={variantHowImg || img}
				/>
			</div>
		</div>
	);
};

export default ProductHowUse;
