import React, { useEffect, useState } from 'react';
import upsellSellingGroups from '../../../utils/sellingPlanGroups/upsellSellingGroups';

const UpsellSubscriptionPreview = ({
	allProducts,
	closeCart,
	replaceCartItem,
	item,
	cart,
	currentProduct,
	currentVariant,
}) => {
	const [sellingPlans, setSellingPlans] = useState(),
		[selectedSellingPlan, setSelectedSellingPlan] = useState(),
		handleChange = (e) => {
			setSelectedSellingPlan(e.target.value);
		},
		addUpsell = () => {
			replaceCartItem(
				cart.id,
				item,
				item.node.merchandise.id,
				item.node.id,
				currentVariant.storefrontId,
				[],
				item.quantity,
				selectedSellingPlan
			);
		};

	const updateSellingPlans = async () => {
		const data = await upsellSellingGroups(currentProduct.storefrontId);
		setSellingPlans(
			data.product.sellingPlanGroups.edges[0]?.node.sellingPlans
		);
		setSelectedSellingPlan(
			data.product.sellingPlanGroups.edges[0]?.node.sellingPlans.edges[0]
				.node.id
		);
	};

	useEffect(() => {
		updateSellingPlans();
	}, []);

	return (
		<span className='cart-item cart-item-bundle cart-item-subscription'>
			<div className='cart-item__content'>
				<div className='cart-item-bundle__title'>
					Subscribe today to receive free delivery on every order
				</div>
				<div className='cart-item-bundle__description'>
					<ul>
						<li>Get a free Bowl & Brush worth £15 when you purchase today.</li>
						<li>15% off every treatment</li>
						<li>Pause, change or cancel at anytime.</li>
					</ul>
				</div>
				<div className='cart-item-subscription__submit'>
					<select
						className='cart-item-subscription__select'
						onChange={handleChange}
					>
						{sellingPlans &&
							sellingPlans.edges.map((element) => (
								<option
									key={`upsellSubPreview${element.node.id}`}
									value={element.node.id}
								>
									{element.node.name}
								</option>
							))}
					</select>
					<button
						onClick={addUpsell}
						className='cart-item-subscription__button button button--dark'
					>
						Update
					</button>
				</div>
			</div>
		</span>
	);
};

export default UpsellSubscriptionPreview;
