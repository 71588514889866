import React from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

const ButtonAction = (props) => (
	<button
		type={props.type}
		className={cn(
			`button${props.classname ? ` ${props.classname}` : ''}`,
			props.buttonClasses
		)}
		data-test-id='button-main__action'
		onClick={props.handleClick}
	>
		{props.icon && (
			<ReactSVG
				src={`/images/${props.icon}`}
				style={{ marginRight: '10px' }}
			/>
		)}
		<span data-test-id='button-link__action' className='button__text'>
			{props.buttonText}
		</span>
	</button>
);

export default ButtonAction;
