import React from 'react';

import LocalImage from '../../../components/LocalImage';

import './MiracleInfo.scss';
import { getMetafieldParse } from '../../../utils/helpers';

const MiracleInfo = ({ product }) => {
	const miracleInfo = getMetafieldParse(product, 'miracle_info');

	return (
		<div className='pdp-miracle-info container'>
			<div className='pdp-miracle-info__items'>
				{miracleInfo?.data.map((item) => (
					<div key={item.title} className='pdp-miracle-info__item'>
						<div className='pdp-miracle-info__item__image'>
							<LocalImage
								image={`pdp/${item.image}`}
								alt={item.title}
								classname='pdp-miracle-info__item__image-local'
							/>
						</div>
						<div className='pdp-miracle-info__item__text'>
							<h4>{item.title}</h4>
							<p>
								<b>{item.subtitle}</b>
							</p>
							{item.desc.map((desc) => (
								<p key={desc}>{desc}</p>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default MiracleInfo;
