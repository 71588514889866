// External
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import cn from 'classnames';
import { getMetafield } from '../../../../../utils/helpers';

const LevelListItem = ({
	isFilterActive,
	nextStep,
	activeStep,
	isSecondLevel = false,
	isThirdLevel = false,
	isFourthLevel = false,
	isFiveLevel = false,
	item,
	setActiveLevel,
	activeLevel,
	activeType,
	filtersList,
	isOneLevel,
}) => {
	getMetafield(item, 'selection_product_image');

	const filterTypes =
		getMetafield(item, 'filter_type') &&
		getMetafield(item, 'filter_type').split('|');

	const title = isOneLevel
		? item.title
		: isSecondLevel
		? item.title && item.title.split('-')[0]
		: item.title;

	const firstLevelImage = !isSecondLevel
		? getMetafield(item, 'selection_product_image')
		: getMetafield(item, 'variant_image');
	const image = !isOneLevel
		? firstLevelImage
		: getMetafield(item, 'variant_image');
	const variantDescription = getMetafield(item, 'description') || '';

	const imageThirdLevel = !isThirdLevel ? image : item.media[0].preview.image.originalSrc;

	const getFourthMetaImage = getMetafield(item, 'variant_image_smudge');

	const imageFourthLevel = !isFourthLevel
		? imageThirdLevel
		: getFourthMetaImage;

	const getFiveMetaImage = getMetafield(item, 'variant_image_blending');

	const imageFiveLevel = !isFiveLevel ? imageFourthLevel : getFiveMetaImage;

	const classes = cn(
		'pdp-selection-level-row__img',
		{
			'pdp-selection-level-row__img--hidden':
				isFilterActive &&
				filterTypes &&
				!filterTypes.includes(activeType) &&
				filtersList &&
				filtersList.length > 1,
		},
		{
			'pdp-selection-level-row__img--active':
				activeLevel && activeLevel.title === item.title,
		}
	);

	const handleClick = (item, description) => {
		setActiveLevel(item, description);
		nextStep && nextStep();
	};

	return (
		<div
			className={classes}
			onClick={() => handleClick(item, variantDescription)}
		>
			{imageFiveLevel ? (
				<LazyLoadImage
					src={imageFiveLevel}
					alt={item.title}
					effect='blur'
					delayTime={0}
				/>
			) : (
				<span>{title}</span>
			)}
		</div>
	);
};

export default LevelListItem;
