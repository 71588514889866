import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

const ButtonLink = ({ blok }) => {
	const { label, url, options } = blok;

	return (
		<Link
			key={blok._uid}
			className={cn(`button button--${options}`)}
			to={url.url}
		>
			<span className='button__text'>{label}</span>
		</Link>
	);
};

export default ButtonLink;
