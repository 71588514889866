import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'gatsby';
import CartItemPrice from './CartItemPrice';

const CartItemContent = ({
	isUpsellCart,
	item,
	sellingPlanId,
	frequencyText,
	cartLimit,
	handleUpdate,
	closeCart,
	upsellBaseColour,
	handleRemove,
	recurringPrice,
	discountPrice,
	withoutDiscountPrice,
	compareAtPrice,
	cart,
}) => (
	<div className='cart-item'>
		<>
			{item.node.merchandise?.image.src && (
				<Link
					to={`/products/${item.node.merchandise.product.handle}`}
					className='cart-item__img'
					onClick={closeCart}
				>
					<LazyLoadImage
						alt={item.node.merchandise.title}
						effect='blur'
						src={item.node.merchandise.image.src}
					/>
				</Link>
			)}
		</>

		<div className='cart-item__content'>
			<div className='cart-item__header'>
				<p className='cart-item__title'>
					{item.node.merchandise.product.title}
					{item.node.merchandise?.title !== 'Default Title' &&
						!item.node.merchandise?.title.includes('£') &&
						` - ${item.node.merchandise?.title.replaceAll(
							'/',
							' + '
						)}`}
				</p>
				{sellingPlanId && (
					<p className='cart-item__subscription'>{frequencyText}</p>
				)}
			</div>
			<div className='cart-item__info'>
				<div className='cart-item__qty'>
					<span
						className={`${item.node.quantity === 1 && 'disabled'}`}
						role='button'
						tabIndex='0'
						onClick={() =>
							handleUpdate(item.node.quantity - 1, true)
						}
						onKeyPress={() =>
							handleUpdate(item.node.quantity - 1, true)
						}
					>
						-
					</span>
					<p>{item.node.quantity}</p>
					<span
						className={`${
							cartLimit &&
							item.quantity >= cartLimit &&
							'disabled'
						}`}
						role='button'
						tabIndex='0'
						onClick={() => handleUpdate(item.node.quantity + 1)}
						onKeyPress={() => handleUpdate(item.node.quantity + 1)}
					>
						+
					</span>
				</div>
				<CartItemPrice
					{...{
						recurringPrice,
						discountPrice,
						withoutDiscountPrice,
						compareAtPrice,
						item,
						cart,
					}}
				/>
			</div>
			<button onClick={handleRemove} className='cart-item__remove'>
				Remove
			</button>
		</div>
	</div>
);

export default CartItemContent;
