import React, { useContext } from 'react';

import { StoreContext } from '../../../../context/store-context';

const ProductGiftCardButton = ({ activeItem }) => {
	const { addVariantToCart } = useContext(StoreContext);

	return (
		<div className='pdp-wrapper-button pdp-info__button'>
			<button
				disabled={!activeItem}
				className='button button--dark'
				onClick={() => {
					addVariantToCart(activeItem, 1, []);
				}}
			>
				<span>add to bag</span>
			</button>
		</div>
	);
};

export default ProductGiftCardButton;
