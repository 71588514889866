// External
import React, { useState } from 'react';
import cn from 'classnames';

// Internal
import { getMetafield } from '../../utils/helpers';

const ShotModalSelect = ({ products, setVariantId, setDisabled }) => {
	const [selectedOption, setSelectedOption] = useState(null),
		[openedSelect, setOpenedSelect] = useState(false),
		options = [];

	products &&
		products
			.filter(
				(product) => !product.title.toLowerCase().includes('miracle')
			)
			.forEach((product) => {
				const option = {
					value: product.storefrontId,
					label: product.title,
					image: getMetafield(product, 'variant_image')
						? getMetafield(product, 'variant_image')
						: product.image.originalSrc,
				};
				options.push(option);
			});

	const handleClick = (option) => {
		setSelectedOption(option);
		setVariantId(option.value);
		setOpenedSelect(false);
		setDisabled(false);
	};

	return (
		<div className='shot-modal__select'>
			<div
				className={cn('custom-colour-select', {
					'custom-colour-select--opened': openedSelect,
				})}
			>
				<div
					onClick={() => setOpenedSelect(!openedSelect)}
					className='custom-colour-select__value custom-colour-select-value'
				>
					{selectedOption && selectedOption.image && (
						<img
							className='custom-colour-select-value__image'
							src={selectedOption.image}
							alt={selectedOption.label}
							loading='lazy'
						/>
					)}
					<div className='custom-colour-select-value__label'>
						{selectedOption
							? selectedOption.label
							: 'Select Shade Shot'}
					</div>
				</div>
				<ul className='custom-colour-select__list'>
					{options.map((item) => (
						<div
							key={`modal-select-${item.value}`}
							onClick={() => handleClick(item)}
							className={cn(
								'custom-colour-select__option custom-colour-select-option',
								{
									'custom-colour-select-option--active':
										selectedOption &&
										selectedOption.value === item.value,
								}
							)}
						>
							{item.image && (
								<img
									className='custom-colour-select-option__image'
									src={item.image}
									alt={item.label}
									loading='lazy'
								/>
							)}
							<div
								key={item.value}
								className='custom-colour-select-option__label'
							>
								{item.label}
							</div>
						</div>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ShotModalSelect;
