// External
import React from 'react';
import LazyLoad from 'react-lazyload';

// Internal
import KlaviyoForm from './KlaviyoForm';

const Klaviyo = ({
	id,
	inHeaderVisited,
	inHeaderNoVisited,
	inFooter,
	inHome,
}) => (
	<LazyLoad once offset={200}>
		<KlaviyoForm
			id={id}
			inHeaderVisited={inHeaderVisited}
			inHeaderNoVisited={inHeaderNoVisited}
			inFooter={inFooter}
			inHome={inHome}
		/>
	</LazyLoad>
);

export default Klaviyo;
