import React, { useEffect } from 'react';

const CookiePolicy = ({ headerText }) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.id = 'CookieDeclaration';
		script.src =
			'https://consent.cookiebot.com/41478290-ddf4-4be2-b394-590fc4ba57a9/cd.js';
		script.async = true;

		const divContainer = document.getElementById('cookiebot-policy');
		divContainer.appendChild(script);

		return () => {
			divContainer.removeChild(script);
		};
	}, []);

	return (
		<section id='cookiebot-policy'>
			<h1>{headerText}</h1>
		</section>
	);
};

export default CookiePolicy;
