import React from 'react';

import './ModalCartLimit.scss';

const ModalCartLimit = ({ handleClose }) => (
	<div className='modal-cart-limit'>
		<div className='modal-cart-limit__content'>
			<p>
				Can not place order, conditions not met: product limit exceeded
			</p>
			<div
				className='modal-cart-limit__button button button--dark'
				onClick={handleClose}
			>
				<span className='button__text'>Ok</span>
			</div>
		</div>
	</div>
);

export default ModalCartLimit;
