const sorts = {
	collections: {
		gifts: {
			names: ['£ LOW to HIGH', '£ HIGH to LOW'],
			keys: ['price-asc', 'price-desc'],
		},
	},
};

export default sorts;
