import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import AccountCustomMessage from '../AccountCustomMessage';
import { submitErrorToSlack } from '../../../utils/slackMessage';

const AccountActivate = ({ location }) => {
	const [customerId, setCustomerId] = useState('');
	const [activationToken, setActivationToken] = useState('');
	const [errors, setErrors] = useState({});
	const [passwords, setPasswords] = useState({
		password: '',
		password_confirmation: '',
	});
	const [isEmptyFields, setIsEmptyFields] = useState(false);

	useEffect(() => {
		const params = location.pathname.split('/'),
			customerId = btoa(`gid://shopify/Customer/${params[3]}`);
		setCustomerId(customerId);
		const activationToken = params[4];
		setActivationToken(activationToken);
	}, []);

	const activateUser = async (body) => {
		const url =
			`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);
		const header = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		try {
			const response = await axios.post(url, body, {
				headers: header,
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			const data = response.data;
			if (data.errors) {
				setErrors(data.errors);
				console.error(errors);
			} else {
				navigate('/account/login');
			}
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};
	const submit = (e) => {
		e.preventDefault();
		const { password, password_confirmation } = passwords;
		if (
			password_confirmation &&
			password &&
			password === password_confirmation
		) {
			setIsEmptyFields(false);
			const graphql = JSON.stringify({
				query: `mutation customerActivate(
                    	$id: ID!, 
						$input: CustomerActivateInput!
					) {
					  customerActivate(id: $id, input: $input) {
						customer {
						  id
						}
						customerAccessToken {
						  accessToken
						  expiresAt
						}
						customerUserErrors {
						  code
						  field
						  message
						}
					  }
					}`,
				variables: {
					id: customerId,
					input: { activationToken, password },
				},
			});
			activateUser(graphql);
		} else {
			setIsEmptyFields(true);
		}
	};

	return (
		<form onSubmit={submit} className='account__reset-form reset-form'>
			<h1 className='reset-form__title'>Activate Account</h1>
			<p style={{ textAlign: 'center', fontSize: '16px' }}>
				Create your password to activate your account.
			</p>
			{isEmptyFields ? (
				<div
					className={`account__message 
                form-message form-message--error`}
				>
					<h2 className='h3 form-message__title ' data-form-status=''>
						Please adjust the following:
					</h2>
					<div className='errors'>
						<ul>
							<li>
								Fields must be filled and the values of
								passwords should be the same
							</li>
						</ul>
					</div>
				</div>
			) : null}
			<label className='signIn-form__field'>
				<span>Password</span>
				<input
					type='password'
					className='search-page-form__input'
					onChange={(e) =>
						setPasswords({
							...passwords,
							password: e.target.value,
						})
					}
					value={passwords.password || ''}
				/>
			</label>
			<label className='signIn-form__field'>
				<span>Confirm password</span>
				<input
					type='password'
					className='search-page-form__input'
					onChange={(e) =>
						setPasswords({
							...passwords,
							password_confirmation: e.target.value,
						})
					}
					value={passwords.password_confirmation || ''}
				/>
			</label>
			<button
				type='submit'
				className={`signIn-form__button 
                signIn-form-button button button--dark`}
			>
				<span className='reset-form-button__text'>
					Activate account
				</span>
			</button>

			<div className='reset-form__back'>
				<Link to='/'>Decline</Link>
			</div>
			{errors ? (
				<AccountCustomMessage
					isPassword
					messages={errors}
					isError={!!errors}
				/>
			) : null}
		</form>
	);
};

export default AccountActivate;
