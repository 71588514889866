import React, { useCallback, useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { StoreContext } from '../../../../context/store-context';

import ProductTooltipItem from './ProductTooltipItem';

import { tooltips } from '../../../../constants/tooltips';
import { useWindowWidth } from '../../../../utils/hooks';
import { getMetafield } from '../../../../utils/helpers';

import './ProductTooltip.scss';

const ProductTooltip = ({ product, isMiracle }) => {
	const { setModal, showModal } = useContext(StoreContext),
		[isActiveClass, setIsActiveClass] = useState(''),
		width = useWindowWidth(),
		deliveryTitle = getMetafield(product, 'delivery_title'),
		openModal = useCallback(
			(type, text, title) => {
				if (width > 768) {
					setModal({ text }, 'tooltip', null);
					showModal(true);
				} else {
					setIsActiveClass(isActiveClass === title ? '' : title);
				}
			},
			[width, isActiveClass]
		);
	return (
		<div className='pdp-tooltip'>
			<div className='pdp-tooltip__items'>
				{!isMiracle ? (
					Array.isArray(tooltips) &&
					tooltips.map((item, i) => (
						<ProductTooltipItem
							item={item}
							name={
								i === 0 && deliveryTitle
									? deliveryTitle
									: item.name
							}
							key={item.name}
							openModal={openModal}
							isActiveClass={isActiveClass}
							width={width}
						/>
					))
				) : (
					<>
						<div className='pdp-tooltip__item'>
							<div className='pdp-tooltip__icon'>
								<LazyLoadImage
									src='/images/Free-Returns.svg'
									alt='Free-Return'
									effect='blur'
								/>
							</div>
							<span>Free Shipping with Subscribe & Save</span>
						</div>
						<a
							href='https://joshwoodcolour.com/faq#subscribe-and-save'
							className={`pdp-tooltip__item
                                	pdp-tooltip__item--link`}
						>
							<span>Subscribe & Save FAQ</span>
						</a>
					</>
				)}
			</div>
		</div>
	);
};

export default ProductTooltip;
