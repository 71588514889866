/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, {
	Autoplay,
	EffectFade,
	Lazy,
	Navigation,
	Pagination,
	Virtual,
	Zoom,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CurrentGalleryItem from './CurrentGalleryItem';

import './Gallery.scss';
import { StoreContext } from '../../../../context/store-context';
import ItemGalleryActions from './ItemGalleryActions';

SwiperCore.use([
	Zoom,
	Navigation,
	Autoplay,
	Pagination,
	Virtual,
	Lazy,
	EffectFade,
]);

const ItemGallery = ({ step, selectedBase, selectedTone, handleClose }) => {
	const [itemImages, setItemImages] = useState([]),
		[showInfo, setShowInfo] = useState(false),
		{
			setMiracleGallerySwiper,
			miracleGalleryImages,
			miracleGallerySwiper,
		} = useContext(StoreContext);

	useEffect(() => {
		const result = [],
			mainPath = `pdp/${step === 2 ? 'tones' : 'colours'}/`,
			colourIndex = selectedBase.replace('-', '.');
		if (step === 1) {
			for (let i = 1; i <= 3; i++) {
				result.push(`${mainPath + colourIndex}-${i}.webp`);
			}
		}
		if (step === 2) {
			const tone =
				selectedTone === 'default-title' ? 'black-onyx' : selectedTone;
			for (let i = 1; i <= 3; i++) {
				result.push(`${mainPath + tone}-${colourIndex}-${i}.webp`);
			}
		}
		setItemImages(result);
	}, [selectedBase, step, selectedTone]);

	useEffect(() => {
		if (miracleGallerySwiper) {
			miracleGallerySwiper.update();
			if (miracleGallerySwiper.navigation) {
				miracleGallerySwiper.navigation.update();
			}
		}
	}, [miracleGalleryImages, miracleGallerySwiper]);

	return (
		<div className='permanent-gallery animate permanent-gallery--active'>
			<Swiper
				onSwiper={setMiracleGallerySwiper}
				slidesPerView={1}
				simulateTouch
				updateOnWindowResize
				observer='true'
				fadeEffect={{
					crossFade: true,
				}}
				loop
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				watchOverflow
				watchSlidesVisibility
				preloadImages='false'
				lazy='true'
				virtual
				scale={3}
				toggle='false'
				initialSlide={1}
				breakpoints={{
					360: {
						effect: 'fade',
					},
					750: {
						effect: 'coverflow',
					},
				}}
			>
				{(itemImages || []).map((img, _) => (
					<SwiperSlide key={_} virtualIndex={_}>
						<CurrentGalleryItem img={img} />
					</SwiperSlide>
				))}
			</Swiper>

			<ItemGalleryActions
				{...{ showInfo, setShowInfo, handleClose }}
				swiper={miracleGallerySwiper}
			/>
		</div>
	);
};

export default ItemGallery;
