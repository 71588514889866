import React from 'react';
import cn from 'classnames';
import PermanentSelection from '../../PermanentSelection/PermanentSelection';

const ProductInfoTitle = ({
	product,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	withShadeShot,
	miracleSystem,
	shots,
	sellingPlanGroup,
}) => (
	<>
		<div
			className={cn('pdp-info__title-row', {
				'pdp-info__title-row--flex':
					allBaseColours.length && allBaseColours.length,
			})}
		>
			<h1 className='pdp-info__title bold'>{product?.title}</h1>
			{product.handle.includes('permanent-colour') && (
				<PermanentSelection
					allBaseColours={allBaseColours}
					productsList={productsList}
					shadeShotsProducts={shadeShotsProducts}
					withShadeShot={withShadeShot}
					shots={shots}
					product={product}
					sellingPlanGroup={sellingPlanGroup}
					miracleSystem={miracleSystem}
				/>
			)}
		</div>
	</>
);

export default ProductInfoTitle;
