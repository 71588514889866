/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SelectListItem = ({
	isFirstStep,
	item,
	image,
	title,
	description,
	setSelectedBaseProduct,
	selectedBaseColour,
	setSelectedBaseColour,
	selectedShot,
	setSelectedShot,
}) => {
	const handleClick = () => {
		if (isFirstStep) {
			setSelectedBaseProduct(item);
			setSelectedBaseColour({
				id: item.variants[0].storefrontId,
				title,
				description,
				image,
				handle: item.handle,
			});
		} else {
			setSelectedShot({
				id: item.storefrontId,
				title,
			});
		}
	};

	return (
		<div
			onClick={handleClick}
			className={cn(
				'modal-permanent-list__item modal-permanent-list-item',
				{
					'modal-permanent-list-item--selected':
						(isFirstStep &&
							item.title.includes(selectedBaseColour.title)) ||
						(!isFirstStep && selectedShot.id === item.storefrontId),
				}
			)}
		>
			<div className='modal-permanent-list-item__image'>
				{image && (
					<LazyLoadImage src={`/images/${image}`} alt={item.title} />
				)}
			</div>
			<div className='modal-permanent-list-item__info'>
				<div className='modal-permanent-list-item__title'>{title}</div>
				{description && (
					<div className='modal-permanent-list-item__name'>
						{description}
					</div>
				)}
			</div>
		</div>
	);
};

export default SelectListItem;
