import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SliderItem = ({
	item,
	image,
	title,
	description,
	type,
	selectedBase,
	query,
}) => {
	const isActive =
		(type === 'colour' &&
			item.title.includes(selectedBase.replace('-', '.'))) ||
		(type === 'tone' &&
			item.title.toLowerCase().replace(' ', '-').includes(query));
	return (
		<div
			role='button'
			className={`permanent-slider-item permanent-colour-list__item permanent-colour-item 
			${isActive && 'permanent-slider-item--active'}`}
		>
			<div className='permanent-slider-item__img permanent-colour-item__img'>
				<LazyLoadImage
					src={`/images/${image}`}
					alt='alt'
					effect='blur'
				/>
			</div>
			<p className='permanent-slider-item__title permanent-colour-item__title'>
				{title} {description}
			</p>
		</div>
	);
};

export default SliderItem;
