import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import LocalVideo from '../../LocalVideo';

import videoDesktop from '../../../../static/images/home-page/hero-banner/hero-video-desktop.mp4';
import videoMobile from '../../../../static/images/home-page/hero-banner/hero-video-mobile.mp4';
import TrustPilot from '../../TrustPilot';

import ButtonGradient from '../../ButtonGradient';
import { useWindowWidth } from '../../../utils/hooks';

import './Hero.scss';
import LocalImage from '../../LocalImage';

const HeroHomepageV2 = () => {
	const width = useWindowWidth();

	const isMobile = width < 768;

	return (
		<>
			{isMobile && (
				<section className='miracle-hero-banner-bg-top'>
					<TrustPilot id='5419b6ffb0d04a076446a9af' height='25px' />
					<h2>
						<span className='miracle-hero-banner__heading--mobile'>
							The Miracle System
						</span>
					</h2>

					<h3>
						<span className='miracle-hero-banner__heading--mobile homepage-hero'>
							Salon quality hair colour that makes you feel like
							you again
						</span>
					</h3>
				</section>
			)}
			<section className='miracle-hero-banner homepage-hero'>
				<div className='miracle-hero-banner__video'>
					<div className='miracle-hero-banner__video--small'>
						<LocalVideo
							source={videoMobile}
							className='miracle-hero-banner'
							name='hero-video-small'
						/>
					</div>
					<div className='miracle-hero-banner__video--large'>
						<LocalVideo
							source={videoDesktop}
							className='miracle-hero-banner'
							name='hero-video-large'
						/>
					</div>
				</div>

				{!isMobile && (
					<div className='miracle-hero-banner__content'>
						<TrustPilot
							id='5419b6ffb0d04a076446a9af'
							height='25px'
						/>
						<h3>
							<span className='miracle-hero-banner__heading--desktop homepage-hero-title'>
								The Miracle System
							</span>
						</h3>

						<h2>
							<span className='miracle-hero-banner__heading--desktop homepage-hero'>
								Salon quality hair colour that makes you feel
								like you again
							</span>
						</h2>

						<div className='miracle-hero-banner__content-list'>
							<p className='miracle-hero-banner__content-list-item'>
								<strong>Reduces hair breakage</strong> 62%
							</p>
							<p className='miracle-hero-banner__content-list-item'>
								<strong>Ammonia-free</strong> formula with great
								smell
							</p>

							<p className='miracle-hero-banner__content-list-item'>
								<strong>Multi-tonal permanent colour</strong>{' '}
								with 100% grey coverage
							</p>
						</div>

						<div className='miracle-hero-banner__content-buttons'>
							<ButtonGradient
								isLink
								isPink
								linkUrl='/pages/hair-colour-care-quiz'
								buttonText='Get Your Perfect Match'
							/>
							<LocalImage
								image='/home-page/guarantee.png'
								alt='Money back guarantee'
							/>
						</div>
					</div>
				)}
			</section>
			{isMobile && (
				<section className='miracle-hero-banner-bg-bottom'>
					<div className='miracle-hero-banner__content-list mobile-home'>
						<p className='miracle-hero-banner__content-list-item'>
							<strong>Reduces hair breakage</strong> 62%
						</p>
						<p className='miracle-hero-banner__content-list-item'>
							<strong>Ammonia-free</strong> formula with great
							smell
						</p>

						<p className='miracle-hero-banner__content-list-item'>
							<strong>Multi-tonal permanent colour</strong> with
							100% grey coverage
						</p>
					</div>

					<div className='miracle-hero-banner__content-buttons mobile-home'>
						<ButtonGradient
							isLink
							isPink
							linkUrl='/pages/hair-colour-care-quiz'
							buttonText='Get Your Perfect Match'
						/>

						<LocalImage
							image='/home-page/guarantee.png'
							alt='Money back guarantee'
						/>
					</div>
				</section>
			)}
		</>
	);
};

export default HeroHomepageV2;
