import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import ButtonGradient from '../../ButtonGradient';

import './DifferenceChart.scss';

const data = [
	{
		title: 'Josh Wood Colour',
		logo: '/images/logo-josh.svg',
		fields: Array(6).fill('checkmark'),
	},
	{
		title: 'Regular Home Kits',
		fields: ['checkmark', 'cross', 'cross', 'cross', 'cross', 'cross'],
	},
	{
		title: 'Salon Treatments',
		fields: ['cross', 'checkmark', 'cross', 'cross', 'cross', 'cross'],
	},
];

const fieldLabels = [
	'Exceptional colour & toning',
	'100% grey coverage',
	'Doesn’t break the bank',
	'Easy at-home application',
	'Strengthens hair over time',
	'Time saving',
];

const DifferenceChart = () => (
	<div className='difference-chart'>
		<div className='difference-chart__content container'>
			<div className='difference-chart__content__title'>
				<h2>The Josh Wood Colour difference</h2>
			</div>

			<div className='difference-chart__content__table'>
				<div>
					<div className='difference-chart__content__table-title' />
					{fieldLabels.map((label) => (
						<p
							key={label}
							className='difference-chart__content__table-field'
						>
							{label}
						</p>
					))}
				</div>

				{data.map((item, index) => (
					<div
						key={item.title}
						className={`${
							index === 0 &&
							'difference-chart__content__table-border-gradient'
						}`}
					>
						<div className='difference-chart__content__table-title'>
							{item.logo ? (
								<StaticImage
									src='../../../../static/images/logo-josh.svg'
									alt='Customer reviews'
								/>
							) : (
								item.title
							)}
						</div>
						{item.fields.map((field, fieldIndex) => (
							<p
								key={`${fieldIndex + 1}`}
								className={`difference-chart__content__table-field ${field}`}
							/>
						))}
					</div>
				))}
			</div>
		</div>

		<div className='difference-chart__button'>
			<ButtonGradient
				isLink
				isPink
				linkUrl='/collections/best-selling-products'
				buttonText='Shop all best sellers'
			/>
		</div>
	</div>
);

export default DifferenceChart;
