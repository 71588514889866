import React from 'react';

import './BlogPageTags.scss';

const BlogPageTags = ({ tags }) => (
	<ul className='blog-tags'>
		{(tags || []).map((tag) => (
			<li key={tag} className='blog-tags__item'>
				{tag}
			</li>
		))}
	</ul>
);

export default BlogPageTags;
