import React from 'react';

import LocalImage from '../../../LocalImage';

const ModalRedListImg = ({ title, item, type }) => {
	const imgName = `${title.toLowerCase()}-${item
			.toLowerCase()
			.replace(' ', '-')}-${type}`,
		image = `red/${imgName}.png`;
	return <LocalImage image={image} alt={item} />;
};

export default ModalRedListImg;
