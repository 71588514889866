import getProductRating from './getProductRating';

const filterProducts = (allProducts, activeFilters, ratings) => {
	if (!Array.isArray(allProducts) || !activeFilters) {
		return [];
	}

	const { price, rating, concerns, type, colour } = activeFilters;
	const priceRange = price && JSON.parse(price);
	const ratingRange = rating && JSON.parse(rating);

	const productsToShow = allProducts.filter((product) => {
		const productPrice = Number(product.variants?.[0]?.price);
		const productRating = getProductRating(product, ratings)?.product_score;
		const productHairTags =
			(Array.isArray(product?.tags) && product.tags) || [];

		const isPriceCoincidence = priceRange
			? productPrice >= Math.min(...priceRange) &&
			  productPrice <= Math.max(...priceRange)
			: true;

		const isRatingCoincidence = ratingRange
			? productRating >= Math.min(...ratingRange) &&
			  productRating <= Math.max(...ratingRange)
			: true;

		const isHairConcernsCoincidence = concerns
			? productHairTags.includes(concerns)
			: true;

		const isHairTypeCoincidence = type
			? productHairTags.includes(type)
			: true;

		const isHairColourCoincidence = colour
			? productHairTags.includes(colour)
			: true;

		return (
			isPriceCoincidence &&
			isRatingCoincidence &&
			isHairConcernsCoincidence &&
			isHairTypeCoincidence &&
			isHairColourCoincidence
		);
	});

	return productsToShow;
};

export default filterProducts;
