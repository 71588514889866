import React from 'react';
import cn from 'classnames';

const PDFDownloadButton = ({ blok }) => {
	const { Asset, ButtonDownloadText, options } = blok;

	return (
		<a
			href={Asset.filename}
			download
			className={cn(`button button--${options}`)}
			target='_blank'
			rel='noopener noreferrer'
		>
			<span className='button__text'>{ButtonDownloadText}</span>
		</a>
	);
};

export default PDFDownloadButton;
