// External
import React, { useState, useEffect, useRef } from 'react';

// Internal
import ProductSortModal from './ProductSortModal';
import useCloseWhenClickOutside from '../../hooks/useCloseWhenClickOutside';

import {
	CURRENT_SORT_OPTIONS,
	SORT_RELEVANCE,
	SORT_TITLE_ASCENDENT,
	SORT_TITLE_DESCENDENT,
	SORT_LOW_HIGH,
	SORT_HIGH_LOW,
} from '../../constants/productSortFilter';
import {
	sortProductByTitleAZ,
	sortProductByTitleZA,
	sortProductByLowerPrice,
	sortProductByHigherPrice,
} from '../../helpers/productSorting';

import './SortProducts.scss';

const SortProducts = ({ allProducts, setFilteredProducts }) => {
	const [openCloseSort, setOpenCloseSort] = useCloseWhenClickOutside(false);
	const [currentSortFilter, setCurrentSortFilter] = useState(SORT_RELEVANCE);

	const defaultSortedItems = useRef(allProducts);

	const getButtonRotation = (openCloseSortFilter) => {
		if (openCloseSortFilter) return '-90';
		return '90';
	};

	const handleShowSortFilterModal = () => {
		setOpenCloseSort((currentValue) => !currentValue);
	};

	useEffect(() => {
		const changeAllProductsToSort = () => {
			if (
				!Array.isArray(allProducts) ||
				!Array.isArray(defaultSortedItems.current)
			)
				return;
			if (allProducts.length !== defaultSortedItems.current.length) {
				defaultSortedItems.current = allProducts;
				setCurrentSortFilter(SORT_RELEVANCE);
			}
		};
		changeAllProductsToSort();
	}, [allProducts]);

	useEffect(() => {
		const handleSortFilterProducts = () => {
			if (!CURRENT_SORT_OPTIONS.includes(currentSortFilter)) return;
			if (currentSortFilter === SORT_RELEVANCE) {
				setFilteredProducts(defaultSortedItems.current);
			}
			if (currentSortFilter === SORT_TITLE_ASCENDENT) {
				setFilteredProducts(sortProductByTitleAZ(allProducts));
			}
			if (currentSortFilter === SORT_TITLE_DESCENDENT) {
				setFilteredProducts(sortProductByTitleZA(allProducts));
			}
			if (currentSortFilter === SORT_LOW_HIGH) {
				setFilteredProducts(sortProductByLowerPrice(allProducts));
			}
			if (currentSortFilter === SORT_HIGH_LOW) {
				setFilteredProducts(sortProductByHigherPrice(allProducts));
			}
		};
		handleSortFilterProducts();
	}, [currentSortFilter]);

	return (
		<section
			className='sort-products__container'
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<header onClick={handleShowSortFilterModal}>
				<div>
					<p>Sort</p>
				</div>
				<div>
					<p>
						{currentSortFilter}{' '}
						<img
							style={{
								transform: `rotate(${getButtonRotation(
									openCloseSort
								)}deg)`,
							}}
							src='/images/icons/breadcrum-separator.png'
							alt='sort filter indicator'
						/>
					</p>
				</div>
			</header>
			<article>
				{openCloseSort && (
					<ProductSortModal
						setOpenCloseSortFilter={setOpenCloseSort}
						currentSortFilter={currentSortFilter}
						setCurrentSortFilter={setCurrentSortFilter}
					/>
				)}
			</article>
		</section>
	);
};

export default SortProducts;
