import React from 'react';

import SalonPriceListItem from './SalonPriceListItem/SalonPriceListItem';

const PriceListSection = ({ title, data }) => (
	<section className='price-list-section'>
		<div className='container'>
			<h3 className='salon-price-page__title'>{title}</h3>
			<div className='price-list-section__row'>
				{(data || []).map((item, i) => (
					<SalonPriceListItem
						/* eslint-disable-next-line */
						key={i}
						title={item.title}
						info={item.info}
						prices={item.prices}
					/>
				))}
			</div>
		</div>
	</section>
);
export default PriceListSection;
