const getProductRating = (product, ratings) => {
	if (!product || !Array.isArray(ratings)) {
        return null
	}

	const productRating = ratings.find(
		(rating) =>
			rating.domain_key ===
			product.storefrontId?.replace('gid://shopify/Product/', '')
	);

    return productRating
};

export default getProductRating;