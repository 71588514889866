import React from 'react';
import { ReactSVG } from 'react-svg';

import LocalImage from '../../../components/LocalImage';

import { gemMiracleTone } from '../../../utils/helpers';

const MiracleConditionList = ({ shotItem }) => {
	const { toneName, toneImg, colourName } = gemMiracleTone(shotItem.title);
	const colourFluid = `miracle/steps/condition/colour/${colourName}.png`;
	const toneFluid = `miracle/steps/condition/tone/${toneImg}.png`
		.replace(/\s+/g, '')
		.replace(/gloss/g, '');
	const conditionFluid = 'miracle/steps/miracle-condition.png';

	return (
		<div className='miracle-condition-list'>
			<div
				className={`miracle-condition-list__item 
            miracle-condition-item`}
			>
				<div className='miracle-condition-item__type'>
					<span>Colour</span>
					<ReactSVG src='/images/miracle/steps/tick-miracle.svg' />
				</div>

				<div className='miracle-condition-item__img'>
					<LocalImage image={colourFluid} alt={colourName} />
				</div>

				<span className='miracle-condition-item__title'>
					{colourName}
				</span>
			</div>
			+
			<div
				className={`miracle-condition-list__item
				miracle-condition-item`}
			>
				<div className='miracle-condition-item__type'>
					<span>Tone</span>
					<ReactSVG src='/images/miracle/steps/tick-miracle.svg' />
				</div>
				<div className='miracle-condition-item__img'>
					<LocalImage image={toneFluid} alt={toneName} />
				</div>
				<span className='miracle-condition-item__title'>
					{toneName}
				</span>
			</div>
			+
			<div
				className={`miracle-condition-list__item
             miracle-condition-item miracle-condition-item--last`}
			>
				<div className='miracle-condition-item__type'>
					<span>Condition</span>
					<ReactSVG src='/images/miracle/steps/tick-miracle.svg' />
				</div>
				<div className='miracle-condition-item__img'>
					<LocalImage image={conditionFluid} alt='Condition' />
				</div>
				<span className='miracle-condition-item__title'>
					Miracle Shot
				</span>
			</div>
		</div>
	);
};

export default MiracleConditionList;
