import React from 'react';

import LocalImage from '../../../components/LocalImage';

import { getMetafield } from '../../../utils/helpers';

const MiracleSliderItem = ({
	onTouchStart,
	onClick,
	type,
	onTouchEnd,
	item,
	activeItem,
	setTooltip,
	setItemSelected,
}) => {
	const colour = item.title.replace(/[^.0-9]/gim, '');
	const tone = item.title.split(' + ')[1];
	const name = type !== 'tone' ? colour : tone;
	const allPathName = `miracle/steps/${type}s`;
	const toneHandle = (name || '').replace(' ', '-').toLowerCase();
	const tooltipText = getMetafield(item, 'tooltip_text');
	const img =
		type !== 'tone'
			? `${name}`
			: `${toneHandle}-${colour}`
					.replace(/\s+/g, '')
					.replace(/gloss/g, '');
	const attributes = type !== 'tone' ? [item.title] : [tone, item];
	const handleClick = () => {
		onClick(...attributes);
		if (type === 'tone') {
			setTooltip(tooltipText);
		}

		const titleTextOnlyColourAndTone = item.title.replace(/^.*?(\d+\.\d+) - (.*)$/, '$2 $1')
		setItemSelected(titleTextOnlyColourAndTone)
	};
	const fluidImg = `${allPathName}/${img}.webp`;
	return (
		<div
			role='button'
			className={`miracle-slider-item 
            miracle-${type}-list__item miracle-${type}-item ${
				activeItem === item.title && 'miracle-slider-item--active'
			}`}
			onClick={handleClick}
			onTouchStart={() => onTouchStart(fluidImg, name)}
			onTouchEnd={onTouchEnd}
		>
			<div
				className={`miracle-slider-item__img miracle-${type}-item__img`}
			>
				<LocalImage image={fluidImg} alt={name} />
			</div>
			<p
				className={`miracle-slider-item__title
                 miracle-${type}-item__title`}
			>
				{name}
			</p>
		</div>
	);
};

export default MiracleSliderItem;
