import React from 'react';
/* eslint-disable */
const Email = (props) => (
		<label className='signIn-form__field'>
			<span>Email</span>
			{props.setValue && typeof props.value === 'string' ? (
				<input
					onChange={(e) => props.setValue(e.target.value)}
					type='text'
					name='Email'
					value={props.value}
					className='search-page-form__input'
				/>
			) : (
				<input
					type='text'
					name='Email'
					className='search-page-form__input'
				/>
			)}
		</label>
	),
	/* eslint-enable */
	Password = (props) => (
		<label className='signIn-form__field'>
			<span>{props.title ? props.title : 'Password'}</span>
			{props.setValue && typeof props.value === 'string' ? (
				<input
					onChange={(e) => props.setValue(e.target.value)}
					type='Password'
					className='search-page-form__input'
				/>
			) : (
				<input type='Password' className='search-page-form__input' />
			)}
		</label>
	),
	Custom = (props) => {
		const title = props.title ? props.title : 'Custom title',
			type = props.type ? props.type : 'text',
			name = props.name ? props.name : title;

		return (
			<label className='signIn-form__field'>
				<span>{title}</span>
				{props.element ? (
					props.element
				) : (
					<>
						{props.setValue && typeof props.value === 'string' ? (
							<input
								name={name}
								type={type}
								className='search-page-form__input'
								onChange={(e) => props.setValue(e.target.value)}
							/>
						) : (
							<input
								name={name}
								type={type}
								className='search-page-form__input'
							/>
						)}
					</>
				)}
			</label>
		);
	},
	Fields = {
		Email,
		Password,
		Custom,
	};
export default Fields;
