import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMetafieldsObject, isAvailable } from '../../utils/helpers';
import { getArticleFeaturedItems } from '../../helpers/getArticleFeaturedItems';

import './articleFeaturedProducts.scss';
import ProductBlogGrid from '../BlogRelatedProducts/ProductBlogGrid';
import { useWindowWidth } from '../../utils/hooks';

const ArticleFeaturedProducts = ({ article, products }) => {
	const articleMetafields = getMetafieldsObject(article?.metafields);
	const width = useWindowWidth();

	const featuredProductsHandles =
		articleMetafields?.featured_products
			?.split('|')
			?.map((product) => product.split(':')?.[0]) ?? [];

	const featuredProducts = products?.filter((product) =>
		featuredProductsHandles?.includes(product.handle)
	);

	const productsToDisplay = getArticleFeaturedItems(
		article,
		featuredProducts,
		products
	);

	const productsWithStoryblokStructure = productsToDisplay.map((product) => ({
		...product,
		availableForSale: isAvailable(product),
		image: product.media[0].preview.image.originalSrc,
		price: product.variants[0].price,
	}));

	return (
		<>
			<div className='featured-products__section'>
				<h3 className='featured-products__title'>Featured products</h3>
				<Swiper
					simulateTouch
					initialSlide={0}
					spaceBetween={10}
					slidesPerView='auto'
					pagination={{
						el: '.related-products__section--pagin',
						clickable: true,
						type: 'bullets',
					}}
				>
					{productsWithStoryblokStructure.map((product) => (
						<SwiperSlide className='product-swiper'>
							<ProductBlogGrid
								featuredProduct={product}
								key={product?.title}
								productPrice={product.price}
							/>
						</SwiperSlide>
					))}
				</Swiper>
				{width < 767 && (
					<div className='related-products__section--pagin' />
				)}
			</div>
		</>
	);
};

export default ArticleFeaturedProducts;
