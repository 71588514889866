import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '../../CloseIcon';
import MiracleModalSteps from './MiracleModalSteps';
import MiracleReadyStep from './MiracleReadyStep';
import MiracleDeliveryStep from './MiracleDeliveryStep';
import ProductSelectionLevelOne from '../../../templates/products/ProductFirstScreen/ProductBundlesSection/ProductSelectionLevels/ProductSelectionLevelOne';
import MiracleStepHeader from './MiracleStepHeader';

import miracle from '../../../constants/miracle';

import './MiracleModal.scss';
import { StoreContext } from '../../../context/store-context';
import { decodeBase64, getMetafield } from '../../../utils/helpers';
import ProductSelectionLevelTwo from '../../../templates/products/ProductFirstScreen/ProductBundlesSection/ProductSelectionLevels/ProductSelectionLevelTwo';
import MiracleStepBack from './MiracleStepBack';

const MiracleModal = ({ modal }) => {
	const [activeStep, setActiveStep] = useState(1),
		[activeSteps, setActiveSteps] = useState([1]),
		[activeFirstLevel, setActiveFirstLevel] = useState(null),
		[activeSecondLevel, setActiveSecondLevel] = useState(null),
		[secondLevelData, setSecondLevelData] = useState([]),
		{ showModal } = useContext(StoreContext),
		{
			isOneLevel,
			firstLevelData,
			product,
			filtersList,
			canSubscribe,
			sellingPlanGroup,
			options,
			activeRadio,
			setActiveRadio,
			isFilterActive,
		} = modal.info,
		handleClose = () => {
			showModal(false);
			setActiveStep(1);
			setActiveSteps([1]);
		},
		nextStep = () => {
			setActiveStep(activeStep + 1);
			setActiveSteps([...activeSteps, activeStep + 1]);
		};

	useEffect(() => {
		const currentVariants =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list') &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);
		currentVariants &&
			setSecondLevelData(
				product.variants.filter(
					(item) =>
						item.storefrontId &&
						currentVariants.includes(
							item.storefrontId
								.replace('gid://shopify/Product/', '')
								.replace('gid://shopify/ProductVariant/', '')
						)
				)
			);
	}, [activeFirstLevel]);

	return (
		<>
			<CloseIcon handleClose={handleClose} classname='modal-close' />
			<div className='miracle-modal'>
				<h3 className='miracle-modal__title'>
					Create Your Personal Miracle System
				</h3>
				<MiracleModalSteps
					steps={miracle.steps}
					activeStep={activeStep}
					activeSteps={activeSteps}
				/>
				{activeStep === 1 ? (
					<>
						<MiracleStepHeader
							title='1. Choose your base colour'
							text='Select the colour that closely matches the roots and mid-lengths of your hair.'
						/>
						<ProductSelectionLevelOne
							isFilterActive={isFilterActive}
							activeStep={activeStep}
							nextStep={nextStep}
							isOneLevel={isOneLevel}
							items={firstLevelData}
							filtersList={filtersList}
							activeFirstLevel={activeFirstLevel}
							setActiveFirstLevel={setActiveFirstLevel}
							isMobile
						/>
					</>
				) : activeStep === 2 ? (
					<>
						<MiracleStepHeader
							title='2. Choose your tone'
							text='Customise your hair colour finish and add a rich mix of personalised tones.'
						/>
						<ProductSelectionLevelTwo
							isFilterActive={isFilterActive}
							activeStep={activeStep}
							nextStep={nextStep}
							items={secondLevelData}
							activeFirstLevel={activeFirstLevel}
							setActiveSecondLevel={setActiveSecondLevel}
							activeSecondLevel={activeSecondLevel}
							isOneLevel={isOneLevel}
							isMobile
						/>
					</>
				) : activeStep === 3 ? (
					<MiracleReadyStep
						nextStep={nextStep}
						setActiveStep={setActiveStep}
						setActiveSteps={setActiveSteps}
						activeSteps={activeSteps}
						activeStep={activeStep}
						activeFirstLevel={activeFirstLevel}
						activeSecondLevel={activeSecondLevel}
						setActiveSecondLevel={setActiveSecondLevel}
					/>
				) : activeStep === 4 ? (
					<MiracleDeliveryStep
						nextStep={nextStep}
						product={product}
						activeFirstLevel={activeFirstLevel}
						activeSecondLevel={activeSecondLevel}
						setActiveModal={handleClose}
						canSubscribe={canSubscribe}
						sellingPlanGroup={sellingPlanGroup}
						options={options}
						activeRadio={activeRadio}
						setActiveRadio={setActiveRadio}
					/>
				) : null}
				{activeStep !== 1 && (
					<div className='miracle-modal__footer'>
						<MiracleStepBack
							setActiveStep={setActiveStep}
							setActiveSteps={setActiveSteps}
							activeSteps={activeSteps}
							activeStep={activeStep}
						/>
						<a
							href='https://joshwoodcolour.zendesk.com/hc/en-gb/articles/360015357538-What-are-the-ingredients-in-the-Miracle-Shot-' // eslint-disable-line
							className='miracle-modal__footer-link'
						>
							Miracle System FAQ
						</a>
					</div>
				)}
			</div>
		</>
	);
};

export default MiracleModal;
