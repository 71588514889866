// External
import React from 'react';
import { getShopifyImage } from 'gatsby-source-shopify';
import parse from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

const HTMLParser = ({
	contentHtml,
	defaultImageAlt = '',
	containerClassName = '',
}) => {
	const replace = ({ attribs, name }) => {
		if (!attribs) {
			return undefined;
		}
		if (name === 'img' && attribs?.src) {
			const image = {
				src: attribs?.src,
				originalSrc: attribs?.src,
				url: attribs?.src,
				width: attribs.width || 'auto',
				height: attribs.height || 'auto',
				altText: attribs.alt || defaultImageAlt,
				objectPosition: 'center',
			};

			const imageData = getShopifyImage({
				image,
				options: {
					formats: ['auto', 'webp'],
					quality: 50,
					aspectRatio: 1,
					backgroundColor: `transparent`,
				},
			});

			return (
				<GatsbyImage
					image={imageData}
					src={attribs?.src}
					alt={attribs.alt || defaultImageAlt}
					objectFit='contain'
					as='span'
					loading='lazy'
				/>
			);
		}
	};

	return (
		<div className={containerClassName}>
			{parse(contentHtml, { replace })}
		</div>
	);
};

export default HTMLParser;
