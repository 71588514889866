// External
import React, { useEffect, useRef, useState } from 'react';

// Internal
import TextBlock from '../blocks/TextBlock';
import './style.scss';

const FAQQuestion = ({ item, isQuestionOpen, onToggle }) => {
	const transitionRef = useRef(null);

	const [isAnimating, setAnimating] = useState(false);
	const [isOpen, setOpen] = useState(false);

	function toggle() {
		onToggle();
		if (isOpen) {
			setAnimating(true);
			setOpen(false);
			setTimeout(() => {
				setAnimating(false);
			}, 0);
		} else {
			setAnimating(true);
			setTimeout(() => {
				setAnimating(false);
				setOpen(true);
			}, 320);
		}
	}

	useEffect(() => {
		const handleOpenFAQ = () => {
			const pathHash = window.location.hash;
			const [, buttonId] = pathHash.split('#');
			if (item?.url_id !== buttonId) return;
			setTimeout(() => {
				const button = document.getElementById(buttonId);
				const topButton = button?.offsetTop;
				const headerHeight = 144;
				window.scrollTo({
					top: topButton - headerHeight,
				});
				button?.click();
			}, 200);
		};
		handleOpenFAQ();
	}, []);

	return (
		<li
			className={`faq__list-item${isQuestionOpen ? ' is-open' : ''}${
				isAnimating ? ' is-animating' : ''
			}`}
			itemScope
			itemProp='mainEntity'
			itemType='https://schema.org/Question'
		>
			<button
				className='faq__list-item-toggle'
				id={item?.url_id}
				onClick={toggle}
			>
				<h3 className='faq__list-item-title' itemProp='name'>
					{item.question}
				</h3>
			</button>
			<div
				ref={transitionRef}
				className='faq__answer'
				itemScope
				itemProp='acceptedAnswer'
				itemType='https://schema.org/Answer'
			>
				<TextBlock text={item.answer} />
			</div>
		</li>
	);
};

export default FAQQuestion;
