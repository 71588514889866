import React, { useState } from 'react';

import './ModalHint.scss';
import { ReactSVG } from 'react-svg';

const ModalHint = ({ modal, showModal }) => {
	const { product } = modal;
	const [email, setEmail] = useState('');
	const handleSendHint = () => {
		const subject = encodeURIComponent(
			'In case you’re wondering what to get me'
		);
		const body = encodeURIComponent(
			`Hi! Here's a gift suggestion I'd love to receive: https://joshwoodcolour.com/products/${product.handle}`
		);
		window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
		setEmail('');
		showModal(false);
	};

	return (
		<div className='hint-form'>
			<h2>
				Send a hint
				<ReactSVG src='/images/gift-black.svg' />
			</h2>
			<p>
				Add your loved one's email address below to let them know this
				is a gift you really want to receive.
			</p>
			<div className='container-form'>
				<div className='label-form'>Email</div>
				<input
					type='email'
					className='input-form'
					placeholder=''
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<button
				type='button'
				className='hint-button'
				onClick={handleSendHint}
			>
				Send
			</button>
		</div>
	);
};

export default ModalHint;
