import React from 'react';
import TextBlock from '../blocks/TextBlock';
import '../BlogStyles.scss';

const BlogContent = ({ blok }) => {
	const { content } = blok;
	return (
		<section className='blog-container'>
			<div className='article'>
				<div>
					<TextBlock text={content} />
				</div>
			</div>
		</section>
	);
};

export default BlogContent;
