import React from 'react';

const FilterCheck = () => (
	<span className='filter-item__check'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
		>
			<path
				d='M18 2L7 13L2 8'
				stroke='#414141'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	</span>
);

export default FilterCheck;
