// External
import React, { useEffect, useState } from 'react';

// Internal
import { getMetafield, removeArrayItem } from '../../../../utils/helpers';
import ProductInfoRating from './ProductInfoRating';

const ProductInfoCollection = ({ product, collections }) => {
	const showRating = getMetafield(product, 'is_rating_info');
	const ratingText = getMetafield(product, 'text_rating_info');
	const collectionName = getMetafield(product, 'collection_name');

	const [collection, setCollection] = useState('');

	const titleCollections =
		Array.isArray(collections) &&
		collections.map((collection) => collection.title);

	useEffect(() => {
		removeArrayItem(titleCollections, 'all products');
		removeArrayItem(titleCollections, 'gloss hair treatment');
		removeArrayItem(titleCollections, 'colour bundles');
		removeArrayItem(
			titleCollections,
			'why not try the colour of the season...?'
		);
		removeArrayItem(titleCollections, 'multi buy perm');

		const handleShowTags = () => {
			if (titleCollections.includes('accessories & kits'))
				return 'accessories & kits';
			if (titleCollections.includes('hair colour')) return 'hair colour';
			if (titleCollections.includes('red hair colour'))
				return 'red hair colour';
			return titleCollections[0];
		};
		const currentProductTag = handleShowTags();
		setCollection(currentProductTag);
	}, []);

	return (
		<div className='pdp-info__collection pdp-info-collection'>
			<span>{collectionName || collection}</span>
			{showRating && ratingText && (
				<ProductInfoRating product={product} />
			)}
		</div>
	);
};

export default ProductInfoCollection;
