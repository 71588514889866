import React, { useState } from 'react';

import ProductZoomPhotoSlideContainer from './ProductZoomPhotoSlider';
import ProductPhotos from './ProductPhotos';

import './ModalZoomImage.scss';

const ModalZoomImages = ({ modal }) => {
	const { product, index, customPreviewImage } = modal.info;

	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [sliderNoAnimation, setSliderNoAnimation] = useState(false);

	return (
		<div className='modal-zoom-mobile'>
			<div className='modal-zoom-mobile-current-choice'>
				<span>{product.title}</span>
			</div>

			<div
				className={`pdp-zoom-photo-slider ${
					sliderNoAnimation && 'pdp--zoom-photo-slider--no-animation'
				}`}
			>
				<ProductZoomPhotoSlideContainer
					setSliderNoAnimation={setSliderNoAnimation}
					product={product}
					thumbsSwiper={thumbsSwiper}
					slideToIndex={index}
				/>
			</div>
			<ProductPhotos
				product={product}
				customPreviewImage={customPreviewImage}
				setThumbsSwiper={setThumbsSwiper}
				thumbsSwiper={thumbsSwiper}
			/>
		</div>
	);
};

export default ModalZoomImages;
