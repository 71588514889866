import React from 'react';
import cn from 'classnames';

const AccountCustomMessage = ({ messages, isError, isPassword }) => (
	<div
		className={cn('account-custom-message', {
			'account-custom-message--error': isError,
		})}
	>
		{!isPassword ? (
			<ul className='account-custom-message__list'>
				{messages.map((item, index) => (
					<li
						/* eslint-disable-next-line */
						key={index}
						className='account-custom-message__item'
					>
						{item.message}
					</li>
				))}
			</ul>
		) : (
			<>
				{messages.password && (
					<ul className='account-custom-message__list s'>
						{messages.password.map((item, index) => (
							<li
								/* eslint-disable-next-line */
								key={index}
								className='account-custom-message__item'
							>
								{item}
							</li>
						))}
					</ul>
				)}
				{messages.password_confirmation && (
					<ul className='account-custom-message__list'>
						{messages.password_confirmation.map((item, index) => (
							<li
								/* eslint-disable-next-line */
								key={index}
								className='account-custom-message__item'
							>
								{item}
							</li>
						))}
					</ul>
				)}
			</>
		)}
	</div>
);

export default AccountCustomMessage;
