import React, { useState } from 'react';
import cn from 'classnames';
import axios from 'axios';

import Fields from '../../components/Fields';
import { submitErrorToSlack } from '../../utils/slackMessage';

const AccountForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [infoText, setInfoText] = useState([]);
	const [error, setError] = useState(false);
	const showReject = (errors) => {
		setError(true);
		setInfoText(errors);
	};
	const success = () => {
		setEmail('');
		setError(false);
		setInfoText([
			{
				message: `We've sent you an email with a 
				link to update your password.`,
			},
		]);
	};
	const sendForm = async (e) => {
		e.preventDefault();

		const url =
			`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);

		const graphql = JSON.stringify({
			query: `
				mutation customerRecover($email: String!) {
				  customerRecover(email: $email) {
					customerUserErrors {
					  code
					  field
					  message
					}
				  }
				}
			  `,
			variables: {
				email,
			},
		});

		const myHeaders = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		try {
			const response = await axios.post(url, graphql, {
				headers: myHeaders,
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			const customer = response.data;

			if (customer.errors) {
				const newErrors =
					typeof customer.errors === 'string'
						? [{ message: customer.errors }]
						: customer.errors;
				showReject(newErrors);
			} else {
				const data = customer.data.customerRecover;
				if (data.customerUserErrors.length > 0) {
					showReject(data.customerUserErrors);
				} else {
					success();
				}
			}
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	return (
		<form onSubmit={sendForm} className='account__login-form signIn-form'>
			<h2 className='signIn-form__title'>Reset your password</h2>
			{infoText.length > 0 ? (
				<div
					className={cn(
						'account__message',
						{ 'form-message--error': error },
						{ 'form-message--success': !error }
					)}
				>
					{error && (
						<h2
							className='h3 form-message__title '
							data-form-status=''
						>
							Please adjust the following:
						</h2>
					)}

					<div className='errors'>
						<ul>
							{Array.isArray(infoText) &&
								infoText.map(({ message }) => (
									<li key={`${message}`}>{message}</li>
								))}
						</ul>
					</div>
				</div>
			) : (
				<p className='signIn-form__description'>
					We will send you an email to reset your password.
				</p>
			)}

			<Fields.Email value={email} setValue={setEmail} />
			<button
				type='submit'
				className={`signIn-form__button 
                signIn-form-button button button--dark`}
			>
				<span className='signIn-form-button__text'>submit</span>
			</button>
		</form>
	);
};

export default AccountForgotPassword;
