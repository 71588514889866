// External
import React, { useRef } from 'react';
import { Link } from 'gatsby';

// Internal
import { useClickOutside } from '../../../../../utils/hooks';

const ItemGalleryActions = ({ showInfo, setShowInfo }) => {
	const helpBloc = useRef(null);

	useClickOutside(helpBloc, () => setShowInfo(false));

	return (
		<div className='miracle-gallery-actions'>
			<div className='miracle-gallery-actions__help'>
				<div
					ref={helpBloc}
					className='miracle-gallery-actions__item miracle-gallery-actions__item--grey'
					onClick={() => setShowInfo(!showInfo)}
				>
					<span>?</span>
				</div>
				<div
					className={`miracle-gallery-actions__item miracle-gallery-actions__item--purple ${
						!showInfo && 'miracle-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/hair-colour-care-quiz/'>Take a quiz</Link>
				</div>
				<div
					className={`miracle-gallery-actions__item miracle-gallery-actions__item--pink ${
						!showInfo && 'miracle-gallery-actions__item--hide'
					}`}
				>
					<Link to='/pages/book-a-video-hair-consultation/'>
						Book a video consultation
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ItemGalleryActions;
