import React from 'react';

import ModalRedLeft from './ModalRedLeft';
import ModalRedRight from './ModalRedRight';

import { createArray, getMetafield, isBrowser } from '../../../utils/helpers';

import './ModalRed.scss';

const ModalRed = ({ modal }) => {
	const title = modal.product.title.split(' ')[0],
		list = createArray(getMetafield(modal.product, 'tone_list'));
	return (
		<div className='modal-red'>
			{isBrowser && window.outerWidth > 768 && (
				<ModalRedLeft title={title} />
			)}
			<ModalRedRight title={title} list={list} />
		</div>
	);
};

export default ModalRed;
