/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../../context/store-context';

const ConfirmButton = ({
	setCurrentStep,
	currentStep,
	selectedBaseColour,
	selectedShot,
	handleClose,
	productsList,
	product,
}) => {
	const {
			addVariantToCart,
			setSelectedPermanentBase,
			replaceCartItem,
			setSelectedPermanentShot,
			cart,
			addShadeShotFromCart,
			setAddShadeShotFromCart,
		} = useContext(StoreContext),
		isShotSelected = JSON.stringify(selectedShot) !== '{}';
	const handleCLick = () => {
		setAddShadeShotFromCart(false);
		if (currentStep === 1) {
			setCurrentStep(2);
		} else {
			handleClose();
			JSON.stringify(selectedBaseColour) !== '{}' &&
				setSelectedPermanentBase(selectedBaseColour);
			isShotSelected && setSelectedPermanentShot(selectedShot);

			const shot =
				isShotSelected &&
				productsList.length &&
				selectedShot &&
				product &&
				productsList.find(
					(item) =>
						item.title
							.toLowerCase()
							.includes(
								selectedShot.title.split(' ')[0].toLowerCase()
							) &&
						item.handle.toLowerCase().includes('shade-shot') &&
						!item.handle.toLowerCase().includes('miracle') &&
						!item.handle.toLowerCase().includes('permanent')
				);

			const lineItems = cart.lines.edges,
				upsellItem =
					lineItems.length &&
					lineItems.find(
						(item) =>
							Object.keys(item.node.attributes).length > 0 &&
							item.node.attributes.some(
								(attr) => attr['key'] === 'isUpsellCart'
							)
					),
				customAttrs = [
					{
						key: 'isUpsellCart',
						value: 'true',
					},
					{
						key: 'baseColourId',
						value:
							JSON.stringify(selectedBaseColour) !== '{}'
								? selectedBaseColour.id
								: 'null',
					},
					{
						key: 'variantId',
						value:
							isShotSelected && selectedShot && product
								? selectedShot.id
								: 'null',
					},
					{
						key: 'shotId',
						value: shot ? shot.variants[0].storefrontId : 'null',
					},
				];

			if (JSON.stringify(selectedBaseColour) !== '{}') {
				const idForCart =
					!selectedShot && !product
						? selectedBaseColour.id
						: selectedShot.id;
				upsellItem && idForCart
					? replaceCartItem(
							cart.id,
							upsellItem,
							upsellItem.node.merchandise.id,
							upsellItem.node.id,
							idForCart,
							customAttrs
					  )
					: addVariantToCart(idForCart, 1, customAttrs);
			}
		}
	};

	const handleClickWithoutShot = () => {
		handleClose();
		JSON.stringify(selectedBaseColour) !== '{}' &&
			setSelectedPermanentBase(selectedBaseColour);

		const lineItems = cart.lines.edges,
			upsellItem =
				lineItems.length &&
				lineItems.find(
					(item) =>
						Object.keys(item.node.attributes).length > 0 &&
						item.node.attributes.some(
							(attr) => attr['key'] === 'isUpsellCart'
						)
				),
			customAttrs = [
				{
					key: 'isUpsellCart',
					value: 'true',
				},
				{
					key: 'baseColourId',
					value:
						JSON.stringify(selectedBaseColour) !== '{}'
							? selectedBaseColour.id
							: 'null',
				},
				{
					key: 'variantId',
					value: 'null',
				},
				{
					key: 'shotId',
					value: 'null',
				},
			];

		if (JSON.stringify(selectedBaseColour) !== '{}') {
			const idForCart = selectedBaseColour.id;
			upsellItem && idForCart
				? replaceCartItem(
						cart.id,
						upsellItem,
						upsellItem.node.merchandise.id,
						upsellItem.node.id,
						idForCart,
						customAttrs
				  )
				: addVariantToCart(idForCart, 1, customAttrs);
		}
	};
	return (
		<>
			{selectedBaseColour && currentStep === 1 && (
				<button
					onClick={product ? handleCLick : handleClickWithoutShot}
					className='modal-permanent__confirm-button modal-permanent-confirm-button'
				>
					<div className='modal-permanent-confirm-button__text'>
						Confirm Base Colour
					</div>
				</button>
			)}
			{selectedShot && currentStep === 2 && (
				<button
					onClick={handleCLick}
					className='modal-permanent__confirm-button modal-permanent-confirm-button'
				>
					<div className='modal-permanent-confirm-button__text'>
						Confirm Shade Shot
					</div>
				</button>
			)}
		</>
	);
};

export default ConfirmButton;
