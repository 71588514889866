export const seo = require('../../static/seo.json');

export const codesToPreAdd = [
	{
		code: 'MIRACLE50',
		id: 'gid://shopify/PriceRule/988100362403',
	},
	{
		code: 'MIRACLE45',
		id: 'gid://shopify/PriceRule/988035514531',
	},
	{
		code: 'MIRACLE-SHOT',
		id: 'gid://shopify/PriceRule/988099805347',
	},
];

export const homePage = {
	whySection: [
		'Expertly Created',
		'Ammonia Free',
		'Personalised',
		'Natural Looking',
	],
	mediaSection: {
		chocolate: {
			title: 'Chocolate',
			text: 'Introducing our new semi-permanent hair colour to enhance brown hair with a rich chocolate brunette tone and a high-shine finish.',
			image: 'home-page/chocolate.webp',
			url: '/products/chocolate-gloss',
			isMovingImage: true,
			isReverse: false,
		},
		root: {
			title: 'Root touch up',
			text: 'Quick fix root touch ups. Disguise stray hairs instantly.',
			image: 'home-page/rootHome.jpg',
			url: '/collections/root-touch-up',
			isReverse: true,
		},
		refresh: {
			title: 'Colour Refresh and Tone',
			text: 'Use Gloss to refresh hair tone and condition, or a pastel Glaze for a vibrant pop of colour, both with high shine salon results.',
			image: 'home-page/refreshHome-v2.jpg',
			url: '/collections/gloss-and-glaze',
			isReverse: false,
		},
	},
	featuredCollections: ['colour', 'root-touch-up', 'gloss', 'care'],
	textInfoSection: [
		{
			imageUrl: 'membership',
			title: 'Membership',
			text: 'Never run out of your favourite products. Be first to learn about our membership program.',
			buttonLink: '#membership-2',
			buttonText: 'Sign-Up',
		},
		{
			imageUrl: 'meet-josh',
			title: 'Meet Josh',
			text: "The world leading colourist reveals why he's bottled his expertise and wants to help you achieve the feel good factor of great hair colour",
			buttonLink: '/pages/about-josh',
			buttonText: 'Read More',
		},
	],
	featuredProducts: {
		trending: ['caramel-gloss', 'everything-mask'],
		recommended: ['permanent-colour-6-0-palest-brown'],
	},
	clientsSlider: [
		{
			img: 'slider-image1',
			text: 'My only happy at-home experience has been using Josh Wood Colour.',
		},
		{
			img: 'slider-image2',
			text: 'I tested out the service – It was life enhancing. Not least because an actual expert tells me exactly what colour I need, and where precisely I should apply it.',
		},
		{
			img: 'slider-image1',
			text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
		},
	],
	about: {
		text: `	<p class="text-hom--from"><span class="text-home__span">Introducing Josh Wood Colour, the only home hair colour range of its kind, professionally formulated by the world's leading hair colour expert.</span> Supported by a team of London's top professional hairdressers, expert guidance is only a click away. Whether you want to grow out your grey, cover your roots or maintain your colour, book in for a complimentary 1:1 video consultation for professional advice, personalised hair colour recommendations and premium home hair colouring results.</p>
				<p class="text-home text-block"><span class="text-home__span">Discover award-winning permanent hair dye that delivers truly personalised results.</span> Ammonia free and full of natural ingredients for a super shiny, well conditioned finish with 100% grey coverage. Combine with Josh's Shade Shot innovation to achieve multi-dimensional tones that look more natural and are unique to you. Elevate your home hair colour experience and discover 3 simple steps to reduce damage and minimise breakage in hair with Josh's latest launch, the Miracle System. Clinically proven to deliver stronger, shinier, healthier hair. Rich in Vitamins B3 and B5 plus Omega 3 & 6, never compromise on condition again, with permanent hair dye that cares. </p>
			   <p class="text-home"><span class="text-home__span">In need of a semi-permanent hair colour solution?</span> We've got you covered. For salon-fresh hair colour and condition in a weekly treatment mask try our award-winning Gloss hair treatment. Full of Shea Butter and Perilla Oil, Gloss refereshes hair colour, stops fade, nourishes and conditions hair for the ultimate, super shine finish. Ideal for revitalizing highlights or pro-longing salon colour. </p>
			   <p class="text-home"><span class="text-home__span">Want a temporary, quick fix root touch up to disguise stray greys or strips of regrowth between colouring?</span> Professionally formulated, our best-selling brush-on root touch-up is designed to effortlessly paint away grey regrowth. The simplest of root concealers, simply click the colour onto the root touch-up brush, paint over grey hair and watch it disappear. </p>
			   <p class="text-hom--from"> <span class="text-home__span">Complete your hair care regime with our award-winning care range.</span> Featuring sulphate-free professsionally formulated shade and texture specific shampoo and conditioner for natural blonde and brunette or coloured hair, and our best-selling hair mask, the Everything Mask. A deep-conditioning, weekly treatment to lock in hair colour and hydrate hair, Everything Mask prevents fade, and maintains condition and shine for all shades and textures. Feel confident in maintaing your hair's brilliance with the full collection of blonde, brown, red and grey protective hair products </p>`,
		title: 'Discover Josh Wood Colour, the only home hair colour range of its kind',
	},
};
