import React, { useContext, useEffect, useState } from 'react';
import { useStoryblokState } from 'gatsby-source-storyblok';
import cn from 'classnames';

import MiracleStepsItems from './MiracleStepsItems';
import MiracleStepsNav from './MiracleStepsNav';
import MiracleColour from './MiracleColour';
import MiracleStepsButton from './MiracleStepsButton';
import MiracleTone from './MiracleTone';
import MiracleDelivery from './MiracleDelivery';
import MiracleCondition from './MiracleCondition';
import MiracleGallery from './MiracleGallery';

import { StoreContext } from '../../context/store-context';

import miracle from '../../constants/miracle';
import { getUrlQueries, isBrowser, partIsNumber } from '../../utils/helpers';
import { useSeoData, useWindowWidth } from '../../utils/hooks';
import SEO from '../../components/SEO';
import './MiracleSteps.scss';

const { getSellingPlanGroup } = require('../../utils/node-helpers');

const MiracleSteps = ({ location, allSellingPlanGroups, storyblokEntry }) => {
	const { allProducts, setIsSteps, setIsShowGallery } =
			useContext(StoreContext),
		{ subscriptionsEnabled } = useContext(StoreContext),
		page = useSeoData('/miracle-steps'),
		story = useStoryblokState(storyblokEntry),
		sellingPlanGroup = subscriptionsEnabled
			? getSellingPlanGroup(
					'gid://shopify/Product/6568869068880',
					allSellingPlanGroups?.nodes
			  )
			: {},
		windowWidth = useWindowWidth(),
		allSteps = miracle.steps.length,
		miracleButtonText = miracle.buttonText || {},
		[baseColourQuery, setBaseColourQuery] = useState(null),
		[baseToneQuery, setBaseToneQuery] = useState(null),
		[step, setStep] = useState(1),
		[activeSteps, setActiveSteps] = useState(['colour']),
		[activeItem, setActiveItem] = useState(null),
		[buttonText, setButtonText] = useState('Confirm base colour'),
		[colourItem, setColourItem] = useState(null),
		[shotItem, setShotItem] = useState(null),
		[variants, setVariants] = useState([]),
		findInAllProducts = () => {
			if (allProducts) {
				return allProducts.find(
					(item) => item.handle === 'miracle-system'
				);
			}
			return [];
		},
		getAllBaseColours = (products) =>
			products
				.filter(
					(item) =>
						!item.handle.includes('shot') &&
						item.handle.includes('permanent')
				)
				.filter((item) => partIsNumber(item.handle, 2))
				.filter((item) => !item.title.includes('8.5')),
		[product, setProduct] = useState(findInAllProducts()),
		[allBaseColours, setAllBaseColours] = useState([]),
		setCurrentButtonText = (currentStep = step) => {
			for (const k in miracleButtonText) {
				if (parseInt(k) === currentStep) {
					setButtonText(miracleButtonText[k]);
				}
			}
		},
		handleClose = () => {
			setIsShowGallery(null);
			setActiveItem(null);
			setShotItem(null);
			if (step === 2) {
				setActiveSteps(['colour', 'tone']);
				setBaseToneQuery(null);
			}
			if (step === 1) {
				setActiveSteps(['colour']);
				setColourItem(null);
				setBaseColourQuery(null);
				setBaseToneQuery(null);
			}
		};

	useEffect(() => {
		setProduct(findInAllProducts());
	}, [allProducts]);

	useEffect(() => {
		setAllBaseColours(getAllBaseColours(allProducts ?? []));
	}, [product]);

	useEffect(() => {
		if (isBrowser) {
			const helpButton = document.getElementById('launcher');
			if (helpButton) {
				windowWidth > 750
					? (helpButton.style.display = 'block')
					: (helpButton.style.display = 'none');
			}
		}
	}, [windowWidth]);

	useEffect(() => {
		if (isBrowser) {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	}, []);

	useEffect(() => {
		if (colourItem) {
			const colourNumber = (colourItem || '').replace(/[^.0-9]/gim, '');
			setVariants([]);
			setVariants(
				product.variants.filter((i) => i.title.includes(colourNumber))
			);
		} else {
			setVariants([]);
		}
	}, [colourItem]);

	useEffect(() => {
		if (isBrowser) {
			const colourQuery = getUrlQueries('base_colour'),
				toneQuery = getUrlQueries('shade_shot');
			if (colourQuery) {
				setBaseColourQuery(colourQuery.toLowerCase());
			}
			if (colourQuery && toneQuery) {
				setBaseToneQuery(toneQuery.toLowerCase());
			}
		}
	}, []);

	useEffect(() => {
		setIsSteps(true);
		return () => {
			setIsSteps(false);
			setIsShowGallery(null);
		};
	}, []);
	const handlePrevStep = (stepId) => {
			setCurrentButtonText(stepId);
			const activeStepLower = miracle.steps.map((el) => el.toLowerCase());
			setActiveSteps(activeStepLower.slice(0, stepId));
			setIsShowGallery(null);
			setStep(stepId);
		},
		handleSetStep = () => {
			if (!activeItem && !baseColourQuery && !baseToneQuery) {
				return false;
			}
			setActiveSteps((prevState) => {
				const currentItem = miracle.steps[step].toLowerCase();
				if (!prevState.includes(currentItem)) {
					return [...prevState, currentItem];
				}
				return [...prevState];
			});
			setIsShowGallery(null);
			setStep((prevState) => {
				const newStep = prevState + 1;
				setCurrentButtonText(newStep);
				if (newStep !== 3 && newStep !== 4) {
					setActiveItem(null);
				}
				return newStep;
			});
		},
		headerText = 'Select base colour';

	return (
		<>
			<SEO page={page} location={location} title={headerText} />
			<div
				className={cn('miracle-steps', {
					'step-4': step === 4,
				})}
			>
				<MiracleStepsItems {...{ activeSteps, handlePrevStep }} />
				<MiracleStepsNav
					{...{
						setCurrentButtonText,
						setColourItem,
						setShotItem,
						allSteps,
						setIsSteps,
						activeSteps,
						setActiveSteps,
						step,
						setStep,
						setActiveItem,
						handlePrevStep,
					}}
				/>
				<MiracleGallery step={step} handleClose={handleClose} />

				{step === 1 && activeSteps.includes('colour') && (
					<MiracleColour
						{...{
							setBaseColourQuery,
							handleSetStep,
							baseColourQuery,
							setShotItem,
							setColourItem,
							colourItem,
							setActiveItem,
							allBaseColours,
							headerText,
						}}
					/>
				)}
				{step === 2 && activeSteps.includes('tone') && (
					<MiracleTone
						{...{
							baseToneQuery,
							handleSetStep,
							setBaseToneQuery,
							toneList: variants,
							activeItem,
							setActiveItem,
							shotItem,
							setShotItem,
						}}
					/>
				)}
				{step === 3 && activeSteps.includes('condition') && (
					<MiracleCondition {...{ colourItem, shotItem, product }} />
				)}
				{step === 4 && activeSteps.includes('delivery') && (
					<MiracleDelivery
						{...{
							shotItem,
							product,
							allProducts,
							sellingPlanGroup,
							story,
						}}
					/>
				)}
				{step !== 4 && (
					<MiracleStepsButton
						{...{
							handleSetStep,
							buttonText,
							activeItem,
						}}
					/>
				)}
			</div>
		</>
	);
};

export default MiracleSteps;
