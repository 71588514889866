import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { StoreContext } from '../../../context/store-context';
import Fields from '../../../components/Fields';
import AccountCustomMessage from '../../../templates/account/AccountCustomMessage';
import doDataLayerUserEvent from '../../../utils/dataLayer/dataLayerUserEvent';
import { submitErrorToSlack } from '../../../utils/slackMessage';

import {
	setHrefLangTag,
	removeHrefLangTag,
} from '../../../helpers/setHrefLang';

const AccountRegister = () => {
	const [emailUp, setEmailUp] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [errors, setErrors] = useState(null);
	const [success, setSuccess] = useState([]);
	const [forgotPass, setForgotPass] = useState(false);
	const { cart } = useContext(StoreContext);
	const sendForm = async (e) => {
		e.preventDefault();

		const url =
			`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);

		const graphql = JSON.stringify({
			query: `mutation customerCreate($input: CustomerCreateInput!) {
				customerCreate(input: $input) {
				  customerUserErrors {
					code
					field
					message
				  }
				  customer {
					id
				  }
				}
			  }`,
			variables: { input: { email: emailUp, password } },
		});

		const myHeaders = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		try {
			const response = await axios.post(url, graphql, {
				headers: myHeaders,
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			if (
				response.data.data.customerCreate &&
				response.data.data.customerCreate.customerUserErrors
			) {
				setErrors(response.data.data.customerCreate.customerUserErrors);
				setSuccess([]);
				console.error(errors);
			} else if (response.data.data.errors) {
				setErrors(response.data.data.errors);
				setSuccess([]);
				console.error(errors);
			}

			if (response.data.data.customerCreate.customer) {
				setErrors(null);
				setSuccess([...success, { message: 'account was created!' }]);
				doDataLayerUserEvent(
					response.data.data.customerCreate.customer.id,
					cart,
					'dl_sign_up'
				);
			}
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	useEffect(() => {
		setHrefLangTag('register-hreflang');

		return () => {
			removeHrefLangTag('register-hreflang');
		};
	}, []);

	return forgotPass ? (
		<div className='signIn-form__resetPass'>
			<button onClick={() => setForgotPass(false)} type='button'>
				Cancel
			</button>
		</div>
	) : (
		<form className='account__login-form signIn-form' onSubmit={sendForm}>
			<h1 className='signIn-form__title'>Create Account</h1>
			<Fields.Custom setValue={setName} value={name} title='First Name' />
			<Fields.Custom
				setValue={setLastName}
				value={lastName}
				title='Last Name'
			/>
			<Fields.Email setValue={setEmailUp} value={emailUp} />
			<Fields.Password setValue={setPassword} value={password} />
			<button
				type='submit'
				className='signIn-form__button signIn-form-button button button--dark'
			>
				<span className='signIn-form-button__text'>Create</span>
			</button>

			<div className='signIn-form__create'>
				<Link to='/account/login'>Log In</Link>
			</div>
			{(errors || success) && (
				<AccountCustomMessage
					messages={errors || success}
					isError={!!errors}
				/>
			)}
		</form>
	);
};

export default AccountRegister;
