import React from 'react';
import ReactPlayer from 'react-player';

import './Video.scss';

const Vimeo = ({ classname, title, videoId, initialLoad = false }) => (
	<div className={`video ${classname && classname}`}>
		{title ? <h3 className='video-title'>{title}</h3> : null}

		<ReactPlayer
			url={`https://player.vimeo.com/video/${videoId}`}
			controls
			config={{
				vimeo: {
					playerOptions: {
						autoplay: 0,
						playIcon: null,
					},
					title,
				},
			}}
			width='100%'
			height='100%'
		/>
	</div>
);

export default Vimeo;
