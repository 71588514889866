// External
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import ButtonLink from '../../ButtonLink';
import LocalVideo from '../../../LocalVideo';
import { matchImgExtension } from '../../../../utils/helpers';

import { useWindowWidth } from '../../../../utils/hooks';
import LocalImageOptimized from '../../../LocalImage/LocalImageOptimized';
import { StoreContext } from '../../../../context/store-context';
import { isStoryblokEditionView } from '../../../../helpers/storyblok';
import './style.scss';

function extractImageName(url) {
	if (!url) return null;
	const matches = url.match(/\/([^/]+\.[^.]+)$/);
	return matches ? matches[1] : null;
}

const MiracleHero = ({ blok }) => {
	const { storyBlokImages } = useContext(StoreContext);

	const {
		link,
		heading,
		subtitle,
		headingMobile,
		subtitleMobile,
		headingIcon,
		cTA,
		mediaSmall,
		mediaLarge,
		cornerFlashSmall,
		cornerFlashLarge,
	} = blok;

	const imageTypes = ['webp', 'jpg', 'jpeg', 'png'];

	const width = useWindowWidth();

	const isImage = (media) =>
		imageTypes.some((ext) => matchImgExtension(ext, media.filename));

	const mediaSmallCornerFlash =
		cornerFlashSmall?.alt || 'JoshWoodColour promotion cornerFlash';

	const mediaSmallVideo = storyBlokImages.find(
		({ node }) => node.base === extractImageName(mediaSmall.filename)
	)?.node?.publicURL;
	const mediaLargeVideo = storyBlokImages.find(
		({ node }) => node.base === extractImageName(mediaLarge.filename)
	)?.node?.publicURL;

	const isStoryblokEdition = isStoryblokEditionView();

	const imageUrl =
		width < 850 ? cornerFlashSmall.filename : cornerFlashLarge.filename;

	return (
		<section
			{...storyblokEditable(blok)}
			key={blok._uid}
			className='miracle-hero-banner'
		>
			<div className='miracle-hero-banner__video'>
				<div className='miracle-hero-banner__video--small'>
					{isImage(mediaSmall) ? (
						<LocalImageOptimized
							imageUrl={mediaSmall.filename}
							alt={mediaSmall.alt}
							customClass='miracle-hero-banner__mobile--image'
						/>
					) : (
						<LocalVideo
							source={mediaSmallVideo}
							className='miracle-hero-banner'
							name='hero-video-small'
						/>
					)}
				</div>
				<div className='miracle-hero-banner__video--large'>
					{isImage(mediaLarge) ? (
						<LocalImageOptimized
							imageUrl={mediaLarge.filename}
							alt={mediaLarge.alt}
							customClass='miracle-hero-banner__desktop--image'
							loadingType='eager'
						/>
					) : (
						<LocalVideo
							source={mediaLargeVideo}
							className='miracle-hero-banner'
							name='hero-video-large'
						/>
					)}
				</div>
			</div>
			{cornerFlashLarge.id && cornerFlashSmall.id && (
				<Link to={link.url}>
					{isStoryblokEdition ? (
						<LazyLoadImage
							src={imageUrl}
							className='miracle-hero-banner__promo'
							alt={mediaSmallCornerFlash}
						/>
					) : (
						<LocalImageOptimized
							imageUrl={imageUrl}
							alt={mediaSmallCornerFlash}
							customClass='miracle-hero-banner__promo'
							loadingType='eager'
						/>
					)}
				</Link>
			)}
			<div className='miracle-hero-banner__content'>
				{heading && headingMobile && (
					<h2>
						<span className='miracle-hero-banner__heading--mobile'>
							{headingIcon.filename && (
								<LazyLoadImage
									src={headingIcon.filename}
									alt='icon'
									width='20px'
									height='20px'
									loading='lazy'
								/>
							)}
							{headingMobile}
						</span>
						<span className='miracle-hero-banner__heading--desktop'>
							{heading}
						</span>
					</h2>
				)}
				{subtitle && subtitleMobile && (
					<h3>
						<span className='miracle-hero-banner__heading--mobile'>
							{subtitleMobile}
						</span>
						<span className='miracle-hero-banner__heading--desktop'>
							{subtitle}
						</span>
					</h3>
				)}
				<div className='miracle-hero-banner__row'>
					<ButtonLink blok={cTA[0]} />
				</div>
			</div>
		</section>
	);
};

export default MiracleHero;
