import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { StoreContext } from '../context/store-context';
import { submitErrorToSlack } from './slackMessage';

export default () => {
	const { token } = useContext(StoreContext),
		[customerData, setCustomerData] = useState(null),
		[isUpdate, setIsUpdate] = useState(false),
		refetch = () => setIsUpdate(true),
		customerQuery = {
			query: `query($token: String!){
            customer(customerAccessToken: $token) {
                id
                email
                addresses(first: 20) {
                    edges {
                        node {
                            id
                            firstName
                            lastName
                            address1
                            address2
                            zip
                            city
                            country
                        }
                    }
                }
                defaultAddress {
                    id
                    firstName
                    lastName
                    address1
                    address2
                    zip
                    city
                    country
                }
                orders(first: 250, reverse: true) {
                    edges {
                        node {
                            id
                            name
                            processedAt
                            totalPrice {
                                amount
                            }
                            financialStatus
                            fulfillmentStatus
                            statusUrl
                            lineItems(first: 50) {
                                edges {
                                    node {
                                        title
                                        quantity
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`,
			variables: { token: `${token}` },
		};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					'/api/getCustomer',
					customerQuery,
					{
						headers: {
							'Content-Type': 'application/json',
						},
					}
				);
                if (!response.data) {
                    throw new Error('API request failed');
                }
				const data = response.data.data;
				if (data) {
					setCustomerData(data);
					setIsUpdate(false);
				}
			} catch (error) {
				const status = error?.response?.status;
				if (status >= 500) {
					submitErrorToSlack('/api/getCustomer', error, 'POST');
				}
				console.error(error);
				return error;
			}
		};

		fetchData();
	}, [isUpdate, token]);

	return { customerData, refetch };
};
