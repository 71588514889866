import cartData from './fragments';
import request from '../request';

export default async (cartId, lineItemIds) => {
	const variables = {
		cartId,
		lineIds: lineItemIds,
	};
	const query = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				${cartData}
			}
		  }
		}
	`;
	return request(query, variables);
};
