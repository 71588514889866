import React from 'react';

const NoSubscriptionsPlaceholder = () => (
	<div
		className='miracle-delivery-radio__content'
		style={{ marginTop: '23px' }}
	>
		Subscriptions temporarily unavailable
	</div>
);

export default NoSubscriptionsPlaceholder;
