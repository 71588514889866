const cartData = `
	id
	checkoutUrl
	createdAt
	updatedAt
	lines(first: 100) {
	  edges {
		node {
		  id
		  quantity
		  sellingPlanAllocation {
		  	sellingPlan {
		  		id
		  		name
		  	}
			priceAdjustments {
				price {
				  amount
				}
				compareAtPrice {
				  amount
				}
				perDeliveryPrice {
				  amount
				}
			}
		  }
		  discountAllocations {
			discountedAmount {
				amount
			}
		  }
		  merchandise {
			... on ProductVariant {
			  id
			  sku
			  title
			  product {
			  	id
			  	handle
			  	title
			  }
			  priceV2 {
				amount
				currencyCode
			  }
			  image {
				src
			  }
			}
		  }
		  attributes {
			key
			value
		  }
		}
	  }
	}
	cost {
	  totalAmount {
		amount
		currencyCode
	  }
	  subtotalAmount {
		amount
		currencyCode
	  }
	  totalTaxAmount {
		amount
		currencyCode
	  }
	  totalDutyAmount {
		amount
		currencyCode
	  }
	}
	discountCodes {
		code
		applicable
	}
	buyerIdentity {
      email
      phone
      customer {
        id
      }
      countryCode
    }
    attributes {
    	key
    	value
	}
`;

export default cartData;
