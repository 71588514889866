import React, { useCallback } from 'react';

import MiracleSlider from '../Slider';

import './Colour.scss';

const Colour = ({ selectedBase, setBaseColour, baseColours }) => {
	const settings = {
		slidesPerView: 3,
		centeredSlides: true,
		initialSlide: baseColours.findIndex(
			(item) =>
				item.title.split(' ')[2].replace('.', '-') === selectedBase
		),
		slideToClickedSlide: true,
		centeredSlidesBounds: true,
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: '.pdp-swiper-prev',
			nextEl: '.pdp-swiper-next',
		},
	},
		handleClick = useCallback((title) => {
			const numberBase = title.match(/\d+(\.\d+)?/);
			setBaseColour(numberBase[0].replace(".", "-"));
		}, []);

	return (
		<div className='permanent-colour'>
			<div className='container'>
				<MiracleSlider
					selectedBase={selectedBase}
					query={selectedBase}
					items={baseColours}
					type='colour'
					settings={settings}
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};
export default Colour;