import fetch from 'node-fetch';
import HttpError from './httpError';
import { submitErrorToSlack } from './slackMessage';

const fetchMetaImage = async (globalId, variablesFetch = {}) => {
	const areValidParameters = !globalId && typeof globalId !== 'string';

	if (areValidParameters) return;
    
	const queryToFetch = `
	{
		node(id: "${globalId}") {
		  ... on MediaImage {
			id
			image {
			    url
			}
		  }
		}
	  }
    `;

	const url = `https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`;
	const header = {
		'Content-Type': 'application/json',
		'X-Shopify-Storefront-Access-Token':
			process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
	};

	try {
		const body = JSON.stringify({
			query: queryToFetch,
			variables: variablesFetch,
		});
		const queryResponse = await fetch(url, {
			method: 'POST',
			headers: header,
			body,
			redirect: 'follow',
		});
		if (!queryResponse?.ok) {
			throw new HttpError(
				queryResponse?.status,
				'Node fetch data failed'
			);
		}
		const data = await queryResponse.json();

		if (data?.data?.node?.image?.url) {
			return data.data.node.image.url;
		}
		if (data?.errors) {
			throw new Error(
				'There was a problem with your request, error fetching meta image'
			);
		}
	} catch (error) {
		if (error?.statusCode >= 500) {
			submitErrorToSlack(url, error, 'GET');
		}
		console.error(error);
		return error;
	}
};

export default fetchMetaImage;
