import React, { useEffect } from 'react';
import { isBrowser } from '../../utils/helpers';
import './YotpoCarousel.scss';

const YotpoCarousel = ({ productId }) => {
	useEffect(() => {
		if (isBrowser && typeof window.yotpo !== 'undefined') {
			window.yotpo.initWidgets();
		}
	}, []);

	return (
		<div
			className='yotpo yotpo-reviews-carousel'
			data-background-color='transparent'
			data-mode='top_rated'
			data-type='per_product'
			data-count='9'
			data-show-bottomline='1'
			data-autoplay-enabled='1'
			data-autoplay-speed='3000'
			data-show-navigation='1'
			data-product-id={productId}
		>
			&nbsp;
		</div>
	);
};

export default YotpoCarousel;
