import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CurrentGalleryItem = ({ img }) => {
	const settings = {
		img: `/images/${img}`,
		scale: 1,
	};

	return (
		<div className='miracle-gallery__container'>
			{settings.img && (
				<div className='miracle-gallery__item'>
					<LazyLoadImage
						className='miracle-gallery__default-image'
						src={settings.img}
						alt='Permanent colour'
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				</div>
			)}
		</div>
	);
};

export default CurrentGalleryItem;
