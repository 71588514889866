/* eslint-disable */
import React from 'react';

import SEO from '../../components/SEO';

const PrivacyPolicyPage = ({ location, pageContext: { body, page } }) => (
	<>
		<SEO page={page} location={location} />
		<div className='text-page'>
			<div className='container'>
				{body ? (
					<div
						className='text-page__content'
						dangerouslySetInnerHTML={{ __html: body }}
					/>
				) : null}
			</div>
		</div>
	</>
);

export default PrivacyPolicyPage;
