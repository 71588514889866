/* eslint-disable */
import React from 'react';

import ConfirmButton from './ConfirmButton';
import Index from './ItemGallery';
import CurrentChoice from './CurrentChoice';

import '../ModalPermanentDesktop.scss';

const RightSide = ({
	selectedBaseColour,
	selectedShot,
	setCurrentStep,
	currentStep,
	handleClose,
	productsList,
	product
}) => (
	<div className='modal-permanent__right-side modal-permanent-right-side'>
		{Object.entries(selectedBaseColour).length !== 0 && (
			<CurrentChoice
				selectedBaseColour={selectedBaseColour}
				selectedShot={selectedShot}
			/>
		)}
		<Index
			step={currentStep}
			selectedBase={selectedBaseColour.title}
			selectedShot={selectedShot}
		/>
		{(currentStep === 1 || (currentStep === 2 && selectedShot)) && (
			<ConfirmButton
				setCurrentStep={setCurrentStep}
				currentStep={currentStep}
				selectedBaseColour={selectedBaseColour}
				selectedShot={selectedShot}
				productsList={productsList}
				handleClose={handleClose}
				product={product}
			/>
		)}
	</div>
);

export default RightSide;
