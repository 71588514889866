import { useState, useEffect } from 'react';

const useCloseWhenClickOutside = (initialValue) => {
	const [closeContainer, setCloseContainer] = useState(initialValue);

	useEffect(() => {
		const handleCloseModal = () => {
			setCloseContainer(false);
		};
		document.body.addEventListener('click', handleCloseModal);
		return () => {
			document.body.removeEventListener('click', handleCloseModal);
		};
	}, []);

	return [closeContainer, setCloseContainer];
};

export default useCloseWhenClickOutside;
