import React, { useContext } from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';

import { StoreContext } from '../../../context/store-context';
import usePreviewableStoryblokState from '../../../utils/usePreviewableStoryblokState';

import './ProductFaq.scss';

const ProductFaq = ({ product }) => {
	const { faqEntry, sbClient } = useContext(StoreContext);
	const { story } = usePreviewableStoryblokState(sbClient, faqEntry);

	const schema =
		story?.full_slug === 'faq'
			? { itemScope: 'itemscope', itemType: 'https://schema.org/FAQPage' }
			: {};

	return (
		<div className='pdp-faq container'>
			<div className='pdp-faq__text'>
				<h2>Frequently Asked Questions</h2>
				<p>
					Need to know more about The Miracle System? Look no further.
				</p>
			</div>
			<div
				className='landing'
				{...schema}
				{...storyblokEditable(story?.content)}
			>
				<StoryblokComponent
					blok={story?.content?.body[3]}
					key={story?.content?.body[3]._uid}
				/>
			</div>
		</div>
	);
};

export default ProductFaq;
