import React from 'react';

import ButtonGradient from '../../ButtonGradient';
import LocalImage from '../../LocalImage';

const MoreOptions = () => (
	<section className='start-options without-bg'>
		<div className='options'>
			<div className='option'>
				<LocalImage
					image='/home-page/image3.webp'
					alt='At Home - Get everything you need to colour with confidence and be kind to your hair'
				/>
				<div className='overlay-img more-options'>
					<p className='title'>At Home</p>
					<div className='desc'>
						<p>
							Get everything you need to colour with confidence
							and be kind to your hair.
						</p>
					</div>

					<div className='overlay-img__btn'>
						<ButtonGradient
							isLink
							isOutlineWhite
							linkUrl='/collections/all-products'
							buttonText='Shop Now'
						/>
					</div>
				</div>
			</div>
			<div className='option'>
				<LocalImage
					image='/home-page/meet-salon.webp'
					alt='In Salon - Visit our London Atelier'
				/>
				<div className='overlay-img more-options'>
					<p className='title'>In Salon</p>
					<div className='desc'>
						<p>
							Book an appointment with our A-List approved
							Experts.
						</p>
					</div>

					<div className='overlay-img__btn'>
						<ButtonGradient
							isLink
							isOutlineWhite
							linkUrl='/pages/josh-wood-colour-hair-salon-london'
							buttonText='Visit our London Atelier'
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default MoreOptions;
