import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useWindowWidth } from '../../utils/hooks';
import { getMetafield } from '../../utils/helpers';

const ShotModalProduct = ({
	product,
	setActive,
	setDisabled,
	active,
	setVariantId,
}) => {
	const windowWidth = useWindowWidth(),
		[available, setAvailable] = useState(false),
		img = getMetafield(product, 'variant_image');

	useEffect(() => {
		setAvailable(product.availableForSale);
	}, []);

	const handleClick = () => {
		active === product.title ? setActive(null) : setActive(product.title);
		setVariantId(product.storefrontId);
		active === product.title ? setDisabled(true) : setDisabled(false);
	};
	return (
		<div
			className={`shot-modal-product shot-modal__product ${
				active === product.title && 'shot-modal-product--active'
			}`}
		>
			<div className='shot-modal-product__img'>
				<LazyLoadImage
					src={img || product.image.originalSrc}
					alt={product.title}
					effect='blur'
					wrapperClassName='lazyLoad-wrapper-img'
				/>
			</div>
			<h3 className='shot-modal-product__title'>{product.title}</h3>
			{available ? (
				windowWidth > 991 && (
					<button
						className='shot-modal-product__select'
						onClick={handleClick}
					>
						<span>select</span>
					</button>
				)
			) : (
				<span>out of stock</span>
			)}
		</div>
	);
};

export default ShotModalProduct;
