import React, { useState } from 'react';
import SwiperCore, { Thumbs } from 'swiper';

import { useWindowWidth } from '../../../../utils/hooks';
import ProductGallerySlider from './ProductGallerySlider';
import { getMetafield } from '../../../../utils/helpers';
import PermanentSelection from '../../../products/PermanentSelection/PermanentSelection';
import ProductRedInfo from '../../../products/ProductFirstScreen/ProductRedInfo';
import ProductPhotos from './ProductPhotos';

import './ProductImages.scss';

SwiperCore.use([Thumbs]);

const ProductImages = ({
	allBaseColours,
	shadeShotsProducts,
	withShadeShot,
	miracleSystem,
	product,
	customPreviewImage,
	productsList,
	sellingPlanGroup,
	shots,
}) => {
	const windowWidth = useWindowWidth();
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [sliderNoAnimation, setSliderNoAnimation] = useState(false);

	const glossModalLinkText = getMetafield(product, 'gloss_modal_link_text');
	const showPermanentSection = windowWidth < 992 && shots.length > 0;

	return (
		<div className='pdp-images-v2'>
			{showPermanentSection && (
				<PermanentSelection
					{...{
						allBaseColours,
						shadeShotsProducts,
						withShadeShot,
						product,
						productsList,
						miracleSystem,
						sellingPlanGroup,
					}}
				/>
			)}
			{glossModalLinkText && (
				<ProductRedInfo
					product={product}
					glossModalLinkText={glossModalLinkText}
					setSliderNoAnimation={setSliderNoAnimation}
				/>
			)}
			<div className='pdp-images-v2__slider'>
				<ProductGallerySlider
					product={product}
					customPreviewImage={customPreviewImage}
					setThumbsSwiper={setThumbsSwiper}
					thumbsSwiper={thumbsSwiper}
				/>
				{windowWidth > 750 && (
					<>
						<div className='pdp-images-v2__slider-bg-top' />
						<div className='pdp-images-v2__slider-bg-bottom' />
					</>
				)}
			</div>
			<div
				className={`pdp-images-v2__gallery ${
					sliderNoAnimation && 'pdp-images-v2--no-animation'
				}`}
			>
				<ProductPhotos
					thumbsSwiper={thumbsSwiper}
					product={product}
					customPreviewImage={customPreviewImage}
					setSliderNoAnimation={setSliderNoAnimation}
				/>
			</div>
		</div>
	);
};

export default ProductImages;
