import cartData from './fragments';
import request from '../request';

export default async (cartId, lineItemId, merchandiseId, quantity) => {
	const variables = {
		cartId,
		lines: {
			id: lineItemId,
			merchandiseId,
			quantity,
		},
	};
	const query = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
	  cartLinesUpdate(cartId: $cartId, lines: $lines) {
		cart {
			${cartData}
		}
	  }
	}
	`;
	return request(query, variables);
};
