import React from 'react';
import SEO from '../../SEO';

const StoryblokSEO = ({ blok, location }) => {
	const { title, description, SEO: storyblokSEO } = blok;
	const page = { title, description };

	return <SEO location={location} page={page} noindex={storyblokSEO} />;
};

export default StoryblokSEO;
