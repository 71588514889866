import React, { useContext, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import { StoreContext } from '../../../context/store-context';

import { decodeBase64, getMetafield } from '../../../utils/helpers';
import ProductListItemContainer from '../../../components/ProductListItem/ProductListItemContainer';
import { useWindowWidth } from '../../../utils/hooks';

import './ProductPerfectBundle.scss';

const ProductPerfectBundle = ({ product, cart }) => {
	const perfectBundleProducts = getMetafield(product, 'perfect_bundle');
	const { yotpoRatings, allProducts } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
 
	const cleanProductsString = perfectBundleProducts.replace(/"/g, '');
	const productsStringToArray = cleanProductsString.split(',');
	const products = allProducts?.filter((e) =>
		productsStringToArray?.includes(e.storefrontId)
	);

	useEffect(() => {
		products?.map((item, index) => {
			yotpoRatings?.find((rating) => {
				if (rating.domain_key === decodeBase64(item.storefrontId)) {
					products[index].rating = rating;
				}
			});
		});
	}, []);
	const sliderSettings = {
		slidesPerView: 2,
		spaceBetween: 10,
		onInit: (slider) => {
			if (slider.slides.length <= 2) {
				slider.el.style.paddingRight = '0px';
				slider.update();
			}
		},
	};
	return (
		<div
			className={`pdp-perfect-bundle${
				products?.length < 2 ? ' pdp-perfect-bundle--small' : ''
			}`}
		>
			<div className='container'>
				<h2 className='pdp-perfect-bundle__title bold'>
					The Perfect Bundle
				</h2>
				{windowWidth > 768 ? (
					<ul className='pdp-perfect-bundle__grid'>
						{products?.map((product) => (
							<ProductListItemContainer
								key={product.id}
								classname='pdp-perfect-bundle__item'
								product={product}
								cart={cart}
							/>
						))}
					</ul>
				) : (
					<Swiper
						{...sliderSettings}
						className='pdp-perfect-bundle__slider'
					>
						{products?.map((product) => (
							<SwiperSlide key={product.id}>
								<ProductListItemContainer
									classname='pdp-perfect-bundle__item'
									product={product}
									cart={cart}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</div>
		</div>
	);
};

export default ProductPerfectBundle;
