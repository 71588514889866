// External
import React, { useContext } from 'react';

// Internal
import ShotModal from './ShotModal';
import { getMetafield, getVideoId } from '../../utils/helpers';
import { StoreContext } from '../../context/store-context';

const ShotModalContainer = () => {
	const { bundleProducts, shadeModal, closeShotModal } =
		useContext(StoreContext);
	const products = bundleProducts ? bundleProducts.variants : [];
	const videoId =
		getVideoId(getMetafield(bundleProducts || {}, 'youtube_video_link')) ||
		'bdXVq9yy-tg';

	return shadeModal ? (
		<ShotModal
			products={products}
			closeShotModal={closeShotModal}
			videoId={videoId}
		/>
	) : null;
};

export default ShotModalContainer;
