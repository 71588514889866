export const tooltips = [
	{
		name: 'Delivery & Returns',
		icon: 'Free-Returns',
		type: 'link',
		text: `
<h3>Delivery & Returns</h3>
<p><strong>Standard Delivery</strong> - Orders typically arrive within 4 - 5 days</p>
    <p><i>Fulfilled by Royal Mail (not tracked)</i></p>
<p>-&nbsp;FREE for orders of £35 and over</p>
<p>- £4 for orders under £35</p>
<p><br> <strong>Express Delivery - </strong>Orders typically arrive within 1 - 2 days</p>
<p><i>Fulfilled by DPD (tracked)</i></p>
<p>- £7 for all orders</p>
<p></p>
<p><strong>Express Click & Collect Delivery to a location within the UK</strong> - Orders typically arrive within 1 - 2 days</p>
<p><i>Fufilled by DPD (Tracked)</i></p>
<p>-£5 on all orders
<p></p>
<p><strong>Unfortunately for now we are unable to ship outside of the UK, but we are hoping the situation will change very soon. We will update our service options for Europe/ROI as soon as they are available.</strong></p>
`,
	},
	{
		name: '100% Money Back Guarantee',
		icon: 'Satisfaction-Guarantee',
		type: 'link',
		text: `<h3>100% Money Back Guarantee</h3>
<p><strong>We are all about feel good hair.</strong>&nbsp;If you don’t feel great about one of our products—one of our expert team will consult with you to find a solution you do love. And if you’re still not happy? We’ll refund your money on your first box, 100%.</p>`,
	},
];
