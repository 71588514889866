// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import MiracleStepsAccordion from '../MiracleStepsAccordion';
import MiracleSlider from '../MiracleSlider';
import { StoreContext } from '../../../context/store-context';
import { useWindowWidth } from '../../../utils/hooks';

import './MiracleTone.scss';

const MiracleTone = ({
	toneList,
	setActiveItem,
	setShotItem,
	shotItem,
	baseToneQuery,
	handleSetStep,
	setBaseToneQuery,
}) => {
	const { setMiracleGalleryImages, setIsShowGallery } =
			useContext(StoreContext),
		countTones = toneList?.length,
		[slidesView, setSlidesView] = useState(2.2),
		activeIdx = shotItem ? toneList.indexOf(shotItem) : null,
		width = useWindowWidth(),
		tonePreview = '/images/miracle/tone-preview.jpg',
		settings = {
			slidesPerView: countTones < 3 ? countTones : 3,
			spaceBetween: 3,
			centeredSlides: true,
			centeredSlidesBounds: true,
			initialSlide: shotItem
				? activeIdx
				: toneList.length > 2
				? Math.ceil((toneList.length - 1) / 2)
				: 0,
			slideToClickedSlide: true,
			observer: true,
			observeParents: true,
			breakpoints: {
				768: {
					slidesPerView: countTones < 5 ? countTones : 5,
					width: countTones < 5 ? countTones * 150 : null,
					centeredSlides: true,
					spaceBetween: 0,
					observer: true,
					observeParents: true,
					centeredSlidesBounds: true,
					slideToClickedSlide: true,
				},
				991: {
					centeredSlides: true,
					slidesPerView: countTones || 5,
					width: countTones < 5 ? countTones * 224 : null,
					spaceBetween: 0,
					shortSwipes: false,
					initialSlide: 2,
					centeredSlidesBounds: true,
					slideToClickedSlide: true,
				},
			},
		},
		handleClick = (tone, item) => {
			const activeTone = shotItem ? shotItem.title.split(' + ')[1] : '';
			if (activeTone !== tone) {
				setMiracleGalleryImages(item.title, 'tones');
			}
			setIsShowGallery(activeTone !== tone);
			setShotItem(activeTone !== tone ? item : null);
			setActiveItem(activeTone !== tone);
		};

	useEffect(() => {
		if (shotItem) {
			setMiracleGalleryImages(shotItem.title, 'tones');
			setIsShowGallery(true);
			setActiveItem(true);
		}
		if (width < 768) {
			setSlidesView(shotItem ? 3 : 2.2);
		}
	}, [shotItem]);

	useEffect(() => {
		if (baseToneQuery && toneList.length) {
			const currentItem = toneList.find((i) =>
				i.title.toLowerCase().includes(baseToneQuery)
			);
			if (currentItem) {
				const tone = currentItem.title.split(' + ')[1];
				handleClick(tone, currentItem);
				handleSetStep();
				setBaseToneQuery(null);
			}
		}
	}, [baseToneQuery, toneList]);

	return (
		<div className='miracle-tone'>
			<div className='container'>
				<div
					className={`miracle - tone__info 
                miracle-step__info miracle-step-info`}
				>
					<h3 className='miracle-tone__title miracle-step__title'>
						Choose Your Tone
					</h3>
					<p className='miracle-tone__text miracle-step__text'>
						Customise your hair colour finish and add a rich mix of
						personalised tones
					</p>
					{width > 991 && (
						<img
							className='miracle-step-info__image'
							src={tonePreview}
							alt='tone'
							loading='lazy'
						/>
					)}
				</div>
				<MiracleSlider
					baseToneQuery={baseToneQuery}
					items={toneList}
					type='tone'
					activeItem={shotItem?.title}
					settings={settings}
					onClick={handleClick}
				/>
				<MiracleStepsAccordion
					type='tone'
					classname='miracle-tone-shot'
				/>
			</div>
		</div>
	);
};

export default MiracleTone;
