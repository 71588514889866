import React from 'react';

const ProductGiftCardTitle = ({ product }) => (
	<>
		<div className='pdp-info__title-row'>
			<h1 className='pdp-info__title bold'>{product?.title}</h1>
		</div>
	</>
);

export default ProductGiftCardTitle;
