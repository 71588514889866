import React from 'react';
import LocalImage from '../../../../../components/LocalImage';

const PreviewLevels = ({ items, path = '', format = 'png'}) => (
	<>
		{Array.isArray(items) &&
			items.map((item) => (
				<div key={item} className='pdp-selection-level-row__img'>
					<LocalImage image={`${path}${item}.${format}`} alt={item} />
				</div>
			))}
	</>
);

export default PreviewLevels;
