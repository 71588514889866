import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './ProductDifference.scss';
import { getMetafieldParse } from '../../../utils/helpers';

const ProductDifference = ({ product }) => {
	const differentSections = getMetafieldParse(product, 'difference_sections');
	const differentTexts = getMetafieldParse(product, 'difference_texts');
	const sectionsMerged = [
		{
			title: 'Josh Wood Colour',
			logo: '/images/logo-josh.svg',
			fields: Array(differentTexts.fieldLabels.length).fill('checkmark'),
		},
		...differentSections.data,
	];

	return (
		<div className='pdp-difference'>
			<div className='pdp-difference__content container'>
				<div className='pdp-difference__content__title'>
					<h2>The Josh Wood Colour difference</h2>
				</div>

				<div className='pdp-difference__content__table'>
					<div>
						<div className='pdp-difference__content__table-title' />
						{differentTexts?.fieldLabels.map((label) => (
							<p
								key={label}
								className='pdp-difference__content__table-field'
							>
								{label}
							</p>
						))}
					</div>

					{sectionsMerged?.map((item, index) => (
						<div
							key={item.title}
							className={`${
								index === 0 &&
								'pdp-difference__content__table-border-gradient'
							}`}
						>
							<div className='pdp-difference__content__table-title'>
								{item.logo ? (
									<LazyLoadImage
										src={item.logo}
										alt={item.title}
										effect='blur'
									/>
								) : (
									item.title
								)}
							</div>
							{item.fields.map((field, fieldIndex) => (
								<p
									key={`${fieldIndex + 1}`}
									className={`pdp-difference__content__table-field ${field}`}
								/>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProductDifference;
