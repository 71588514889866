import React from 'react';
import LocalImage from '../../../components/LocalImage';

import './ProductHairBenefits.scss';

const benefits = [
	{
		percentage: '62%',
		title: 'less breakage',
		description: 'than untreated hair',
		image: 'miracle-about/less-breakage.png',
	},
	{
		percentage: '38%',
		title: 'less fade',
		description: 'Over 12 washes vs permanent colour alone',
		image: 'miracle-about/less-fade.png',
	},
	{
		percentage: '2.7x',
		title: 'stronger',
		description: 'for greater tensile strength',
		image: 'miracle-about/times-stronger.png',
	},
	{
		percentage: '97%',
		title: 'of people',
		description: 'felt their hair had a professional look',
		image: 'miracle-about/of-people-1.png',
	},
	{
		percentage: '96%',
		title: 'of people',
		description: 'felt their hair felt shinier',
		image: 'miracle-about/of-people-2.png',
	},
];

const ProductHairBenefits = ({ product }) => (
	<div className='pdp-hair-benefits'>
		<div className='container'>
			<div className='pdp-hair-benefits__content'>
				{benefits.map((benefit) => (
					<div
						key={benefit.percentage}
						className='pdp-hair-benefits__content__benefit'
					>
						<LocalImage image={benefit.image} alt={benefit.title} />
						<div className='pdp-hair-benefits__content__benefit__text'>
							<h3>{benefit.percentage}</h3>
							<h4>{benefit.title}</h4>
							<p>{benefit.description}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
);

export default ProductHairBenefits;
