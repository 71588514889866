import { lazy } from 'react';

import StoryblokSEO from '../components/Storyblok/SEO';
import ButtonLink from '../components/Storyblok/ButtonLink';
import CtaBanner from '../components/Storyblok/CtaBanner';
import SalonBlock from '../components/Storyblok/SalonBlock';
import MiracleHero from '../components/Storyblok/Homepage/MiracleHero';
import PDFDownloadButton from '../components/Storyblok/PDFDownloadButton';

import CombineWithCollections from '../components/Storyblok/Homepage/CombineWithCollections';
import LandingHero from '../components/Storyblok/Promotion/LandingHero';
import LandingProducts from '../components/Storyblok/Promotion/LandingProducts';
import LandingReviews from '../components/Storyblok/Promotion/LandingReviews';
import FAQSection from '../components/Storyblok/FAQs/faqSection';
import FAQQuestion from '../components/Storyblok/FAQs/faqQuestion';
import FAQTitle from '../components/Storyblok/FAQs/faqTitle';

import StoryblokBlog from '../components/Storyblok/Blog';
import StoryblokVideo from '../components/Storyblok/Video';

import BlogGeneralInfo from '../components/Storyblok/BlogGeneralInfo';
import BlogContent from '../components/Storyblok/BlogContent';
import KlaviyoEmail from '../components/Storyblok/KlaviyoEmail';
import ShopifyProducts from '../components/Storyblok/ShopifyProducts';
import BlogRelatedArticles from '../components/Storyblok/BlogRelatedArticles';
import FiltersProducts from '../components/Storyblok/FiltersProducts';

const TrustPilot = lazy(() => import('../components/Storyblok/TrustPilot'));
const MiracleReview = lazy(() =>
	import('../components/Storyblok/Homepage/MiracleReview')
);
const SalonQualityColour = lazy(() =>
	import('../components/Storyblok/Homepage/SalonQualityColour')
);
const TheMiracleSystem = lazy(() =>
	import('../components/Storyblok/Homepage/TheMiracleSystem')
);
const AsSeenIn = lazy(() =>
	import('../components/Storyblok/Homepage/AsSeenIn')
);
const Subscribe = lazy(() =>
	import('../components/Storyblok/Homepage/Subscribe')
);
const Guarantee = lazy(() =>
	import('../components/Storyblok/Homepage/Guarantee')
);
const SectionVideo = lazy(() =>
	import('../components/Storyblok/Homepage/SectionVideo')
);
const SectionAbout = lazy(() =>
	import('../components/Storyblok/Homepage/SectionAbout')
);

const storyblokComponents = {
	miracleHero: MiracleHero,
	combineWithCollections: CombineWithCollections,
	miracleReview: MiracleReview,
	trustPilot: TrustPilot,

	sEO: StoryblokSEO,
	cTA: ButtonLink,
	ctaBanner: CtaBanner,
	salonBlock: SalonBlock,
	PDFDownloadButton,
	ProductFilter: FiltersProducts,

	salonQualityColour: SalonQualityColour,
	theMiracleSystem: TheMiracleSystem,
	guarantee: Guarantee,
	subscribe: Subscribe,
	asSeenIn: AsSeenIn,
	sectionVideo: SectionVideo,
	sectionAbout: SectionAbout,

	landingHero: LandingHero,
	landingProducts: LandingProducts,
	landingReviews: LandingReviews,

	faqTitle: FAQTitle,
	faqSection: FAQSection,
	faqQuestion: FAQQuestion,

	Blog: StoryblokBlog,
	Video: StoryblokVideo,

	BlogGeneralInfo,
	BlogContent,
	KlaviyoEmail,
	ShopifyProducts,
	BlogRelatedArticles,
};

export default storyblokComponents;
