const getProductStorefrontId = (product) => {
	const storefrontId = product?.storefrontId;
	if (!storefrontId || typeof storefrontId !== 'string')
		return `https://joshwoodcolour.com/products/${product?.handle}`;
	const id = storefrontId.split('/');
	return `https://admin.shopify.com/store/${process.env.SHOP_NAME}/products/${
		id[id.length - 1]
	}`;
};

export default getProductStorefrontId;
