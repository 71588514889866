import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../../context/store-context';
import ProductListItemContainer from '../../../ProductListItem/ProductListItemContainer';
import { getSortedKeys, sendPlpOrSearchEvent } from '../../../../utils/helpers';
import './style.scss';

const LandingProducts = ({ pageCollections, allSellingPlanGroups, blok }) => {
	const { cart, sortKeys } = useContext(StoreContext);
	const handle = blok.collectionHandle.toLowerCase().trim();
	const products = pageCollections?.products || [];

	useEffect(() => {
		if (products && products.length) {
			sendPlpOrSearchEvent(cart, products, 'dl_view_item_list');
			getSortedKeys(sortKeys, products);
		}
	}, []);

	return (
		<div className='products-list-grid-container'>
			{blok.title !== '' && (
				<h2 className='product-list__title'>{blok.title}</h2>
			)}
			{products && products.length ? (
				<ul className='products-list-grid'>
					{products.map((item, index) => (
						<ProductListItemContainer
							index={index + 1}
							key={item.id}
							product={item}
							cart={cart}
							allSellingPlanGroups={allSellingPlanGroups}
							hideRating
						/>
					))}
				</ul>
			) : (
				<div className='products-list__empty'>
					Please check back soon
				</div>
			)}
		</div>
	);
};

export default LandingProducts;
