import { useEffect } from 'react';

export default function useCheckStorageVersion(version) {
	useEffect(() => {
		const userIsNew = !localStorage.getItem('shopify_cart_id');
		const appVersion = localStorage.getItem('APP_VERSION');

		if (userIsNew) {
			localStorage.setItem('APP_VERSION', version);
		} else if (!appVersion || appVersion !== version) {
			localStorage.clear();
			window.location.reload();
			localStorage.setItem('APP_VERSION', version);
		}
	}, [version]);
}
