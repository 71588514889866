import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'swiper/swiper-bundle.min.css';

import './AsSeenIn.scss';

SwiperCore.use([Autoplay]);

const AsSeenInV2 = ({ row = false, isHomepage = false }) => (
	<section
		className={`as-seen-in-v2 ${row && 'as-seen-in-v2-row'} ${
			isHomepage && 'homepage-seen-in'
		}`}
	>
		<h2 className='as-seen-in-v2__title'>As seen in...</h2>
		<Swiper
			loop
			autoplay={{
				delay: 0,
				disableOnInteraction: false,
			}}
			spaceBetween={20}
			allowTouchMove={false}
			className='swiper-continuous-scroll'
			breakpoints={{
				320: {
					slidesPerView: 1,
					speed: 3500,
					spaceBetween: 10,
				},
				500: {
					slidesPerView: 1.5,
					speed: 4000,
				},
				1000: {
					slidesPerView: 3,
					speed: 6000,
				},
				1600: {
					slidesPerView: 4,
					speed: 7000,
				},
			}}
		>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-1-new.png' />
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage
					src='/images/pdp/partner-2-new.svg'
					className='partner-2'
				/>
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-3-new.svg' />
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-4-new.png' />
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage
					src='/images/pdp/partner-5-new.png'
					className='partner-5'
				/>
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-6-new.svg' />
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-7-new.png' />
			</SwiperSlide>
			<SwiperSlide>
				<LazyLoadImage src='/images/pdp/partner-8-new.png' />
			</SwiperSlide>
			<SwiperSlide className='slide-custom'>
				<LazyLoadImage
					src='/images/pdp/partner-9-new.svg'
					className='partner-9'
				/>
			</SwiperSlide>
			<SwiperSlide className='slide-custom'>
				<LazyLoadImage
					src='/images/pdp/partner-10-new.svg'
					className='partner-9'
				/>
			</SwiperSlide>
		</Swiper>
	</section>
);

export default AsSeenInV2;
