// External
import React, { useState, useContext, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';

// Internal
import { StoreContext } from '../../../context/store-context';

import AccountCustomMessage from '../AccountCustomMessage';
import { submitErrorToSlack } from '../../../utils/slackMessage';

const AccountReset = ({ location }) => {

	const [customerId, setCustomerId] = useState('');
	const [editToken, setEditToken] = useState('');
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({ id: customerId });
	const [isEmptyFields, setIsEmptyFields] = useState(false);
	const { setAuthorized } = useContext(StoreContext);

	useEffect(() => {
		const params = location.pathname.split('/'),
			customerId = btoa(`gid://shopify/Customer/${params[3]}`);
		setCustomerId(customerId);
		const token = params[4];
		setEditToken(token);
	}, []);

	const success = (response) => {
		setAuthorized(response.data.customerReset.customerAccessToken);
		navigate('/account');
	};
	const updatePassword = async (body) => {
		const url =
			`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);
	
		const header = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		try {
			const response = await axios.post(url, body, {
				headers: header,
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			const data = response.data;
			if (data.errors) {
				setErrors(data.errors);
				console.error(errors);
			} else {
				success(data);
				navigate('/account/login');
			}
		} catch (error) {
			submitErrorToSlack(url, error, 'POST');
			console.error(error);
			return error;
		}
	};
	const submit = (e) => {
		e.preventDefault();

		if (
			formData.password_confirmation &&
			formData.password &&
			formData.password_confirmation === formData.password
		) {
			setIsEmptyFields(false);
			const graphql = JSON.stringify({
				query: `mutation customerReset(
					$id: ID!, 
					$input: CustomerResetInput!
				) {
					customerReset(id: $id, input: $input) {
					  customer {
						id
					  }
					  customerAccessToken {
						accessToken
						expiresAt
					  }
					  customerUserErrors {
						code
						field
						message
					  }
					}
				  }
				`,
				variables: {
					input: {
						resetToken: editToken,
						password: formData.password,
					},
					id: customerId,
				},
			});
			updatePassword(graphql);
		} else {
			setIsEmptyFields(true);
		}
	};

	return (
		<form onSubmit={submit} className='account__reset-form reset-form'>
			<h1 className='reset-form__title'>Reset account password</h1>
			{isEmptyFields && (
				<div
					className={`account__message 
                form-message form-message--error`}
				>
					<h2 className='h3 form-message__title ' data-form-status=''>
						Please adjust the following:
					</h2>
					<div className='errors'>
						<ul>
							<li>Fields must be filled</li>
						</ul>
					</div>
				</div>
			)}
			<label className='signIn-form__field'>
				<span>Password</span>
				<input
					type='password'
					className='search-page-form__input'
					onChange={(e) =>
						setFormData({ ...formData, password: e.target.value })
					}
					value={formData.password || ''}
				/>
			</label>
			<label className='signIn-form__field'>
				<span>Confirm password</span>
				<input
					type='password'
					className='search-page-form__input'
					onChange={(e) =>
						setFormData({
							...formData,
							password_confirmation: e.target.value,
						})
					}
					value={formData.password_confirmation || ''}
				/>
			</label>
			<button
				type='submit'
				className='signIn-form__button signIn-form-button button button--dark'
			>
				<span className='reset-form-button__text'>Reset password</span>
			</button>

			<div className='reset-form__back'>
				<Link to='/account/login'>Log In</Link>
			</div>
			{errors && (
				<AccountCustomMessage
					isPassword
					messages={errors}
					isError={!!errors}
				/>
			)}
		</form>
	);
};

export default AccountReset;
