import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'gatsby';

import { useWindowWidth } from '../../utils/hooks';

const HowItWorksSection = ({ title, imgUrl, text, linkText, linkUrl }) => {
	const windowWidth = useWindowWidth();

	return (
		<div className='how-it-works-section'>
			<div className='how-it-works-section__info'>
				{title && windowWidth > 991 && (
					<h3 className='how-it-works-section__title'>{title}</h3>
				)}
				{text && (
					<div className='how-it-works-section__text'>{text}</div>
				)}
				{linkText && linkUrl && (
					<Link
						className='how-it-works-section__link button'
						to={linkUrl}
					>
						{linkText}
					</Link>
				)}
			</div>
			<div className='how-it-works-section__right'>
				{title && windowWidth < 992 && (
					<h3 className='how-it-works-section__title'>{title}</h3>
				)}
				{imgUrl && title && (
					<LazyLoadImage
						className='how-it-works-section__image'
						src={imgUrl}
						alt={title}
						effect='blur'
					/>
				)}
			</div>
		</div>
	);
};

export default HowItWorksSection;
