import React from 'react';

import SalonGalleryFirstSection from './SalonGalleryFirstSection';
import SalonGallerySecondSection from './SalonGallerySecondSection';
import SalonGalleryThirdSection from './SalonGalleryThirdSection';

const SalonGallery = () => (
	<div className='salon-gallery'>
		<div className='container'>
			<SalonGalleryFirstSection />
			<SalonGallerySecondSection />
			<SalonGalleryThirdSection />
		</div>
	</div>
);
export default SalonGallery;
