import React, { cloneElement, useContext, useEffect, useState } from 'react';

// Internal
import { StoreContext } from '../../../context/store-context';
import ProductListItemContainer from '../../../components/ProductListItem/ProductListItemContainer';
import FilterAndSort from '../../../components/FilterAndSort';
import SortProducts from '../../../components/ProductSort/Index';
import {
	getFilteredTags,
	getSortedKeys,
	sendPlpOrSearchEvent,
} from '../../../utils/helpers';
import './ProductsListGrid.scss';

const ProductsListGrid = ({
	productsList,
	cart,
	filter,
	collection,
	allSellingPlanGroups,
	showFilters = true,
	isLoading,
	isSearchView = false,
}) => {
	const { filterTags, sortKeys } = useContext(StoreContext);
	useEffect(() => {
		sendPlpOrSearchEvent(cart, productsList, 'dl_view_item_list');
	}, []);

	const [allProductsList, setAllProductsList] = useState(productsList);

	let filteredList = getFilteredTags(filterTags, allProductsList);

	const newTagsArray = (filter || '').replace('-', ' ').split();
	filteredList = filter
		? getFilteredTags(newTagsArray, filteredList)
		: filteredList;

	getSortedKeys(sortKeys, filteredList);


	useEffect(() => {
		if (isSearchView) {
			const newProducts = productsList.filter(
				(product) =>
					!allProductsList.some(
						(product2) => product2.id === product.id
					)
			);

			setAllProductsList([...allProductsList, ...newProducts]);
		}
	}, [productsList]);

	return (
		<>
			{showFilters && (
				<div className='products-filters-container'>
					<FilterAndSort
						allProducts={productsList}
						setFilteredProducts={setAllProductsList}
					/>
					<SortProducts
						allProducts={allProductsList}
						setFilteredProducts={setAllProductsList}
					/>
				</div>
			)}
			<div
				className={`products-list-grid-container ${
					isLoading ? 'loading' : ''
				}`}
			>
				<ul className='products-list-grid'>
					{Array.isArray(filteredList) && filteredList.length > 0 ? (
						<>
							{filteredList.map((item, index) => (
								<ProductListItemContainer
									index={index + 1}
									key={item.id}
									product={item}
									cart={cart}
									collection={collection}
									allSellingPlanGroups={allSellingPlanGroups}
									isHoverButton
								/>
							))}
						</>
					) : (
						<div className='collection-products-empty'>
							<p>No Products found!</p>
						</div>
					)}
				</ul>
			</div>
		</>
	);
};

export default ProductsListGrid;