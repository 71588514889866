import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useScrollBlock } from '../../utils/hooks';

import './ModalProduct.scss';

const ModalProduct = ({ productData }) => {
	const [isActive, setActive] = useState(false),
		[blockScroll, allowScroll] = useScrollBlock();

	useEffect(() => {
		if (productData) {
			setActive(true);
			blockScroll();
		}
	}, [productData]);

	const closeModal = () => {
		setActive(false);
		allowScroll();
	};

	return (
		<>
			<div
				className={`modal-product ${
					isActive && 'modal-product--active'
				} `}
			>
				<button className='modal-product__close' onClick={closeModal}>
					×
				</button>
				<div className='modal-product__container'>
					<div className='modal-product__image'>
						{productData.image && (
							<GatsbyImage
								image={getImage(productData.image)}
								alt={productData.title}
							/>
						)}
					</div>
					<div className='modal-product__info'>
						{productData.productType && (
							<div className='modal-product__type'>
								{productData.productType}
							</div>
						)}
						{productData.title && (
							<div className='modal-product__title'>
								{productData.title}
							</div>
						)}
						<div className='modal-product__row'>
							{productData.price && (
								<div className='modal-product__price'>
									£{productData.price}
								</div>
							)}
							<div className='frequently-product__rate'>
								<div
									className='yotpo bottomLine yotpo--hide'
									data-appkey={process.env.YOTPO_ID}
									data-domain={`${process.env.SHOPIFY_STORE_URL}`}
									data-product-id={productData.id}
									data-product-models={productData.id}
									data-name={productData.title}
									data-url={`${
										process.env.SHOP_NAME ===
										'jwc-production'
											? 'joshwoodcolour.com'
											: `${process.env.SHOP_NAME}.myshopify.com`
									}/products/${productData.handle}`}
									data-image-url={productData.image}
									data-description={productData.description}
								/>
								<div
									className='yotpo yotpo--hide'
									data-name={productData.title}
									data-url={`${
										process.env.SHOP_NAME ===
										'jwc-production'
											? 'joshwoodcolour.com'
											: `${process.env.SHOP_NAME}.myshopify.com`
									}/products/${productData.handle}`}
									data-image-url={productData.image}
									data-description={productData.description}
								/>
							</div>
						</div>
						{productData.description && (
							<div
								className='modal-product__description'
								dangerouslySetInnerHTML={{
									__html: productData.description,
								}}
							/>
						)}
						{productData.advantages && (
							<div
								className={`modal-product__advantages 
                                modal-product-advantages`}
							>
								{productData.advantages.map((item) => (
									<div
										key={item}
										className='modal-product-advantages__item'
									>
										<span>{item}</span>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className={`modal-product-overlay ${
					isActive && 'modal-product-overlay--active'
				} `}
				onClick={closeModal}
			/>
		</>
	);
};

export default ModalProduct;
