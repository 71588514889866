import React, { useContext } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StoreContext } from '../../context/store-context';

const LocalImageOptimized = ({
	imageUrl,
	customClass = '',
	alt = '',
	loadingType = 'lazy',
}) => {
	const { storyBlokImages } = useContext(StoreContext);

	function extractImageName(url) {
		if (!url) return null;
		const matches = url.match(/\/([^/]+\.[^.]+)$/);
		return matches ? matches[1] : null;
	}
	const imageName = extractImageName(imageUrl);
	if (!imageName) {
		console.error(`Image with name "${imageName}" not found.`);
		return null;
	}
	const imageNode = storyBlokImages.find(
		({ node }) => node.base === imageName
	)?.node;
	if (!imageNode?.childImageSharp?.gatsbyImageData) {
		console.error(`Image with name "${imageName}" not found.`);
		return null;
	}

	const gatsbyImage = getImage(imageNode.childImageSharp?.gatsbyImageData);
	return (
		<GatsbyImage
			image={gatsbyImage}
			className={customClass}
			alt={alt}
			loading={loadingType}
		/>
	);
};

export default LocalImageOptimized;
