/* eslint-disable */
import React from 'react';

const ConfirmButton = ({ buttonText, handleSubmit, showUpsell }) => (
	<button
		onClick={handleSubmit}
		className={`modal-permanent-mobile__confirm-button animate modal-permanent-mobile-confirm-button`}
	>
		<div className='modal-permanent-confirm-button__text'>{buttonText}</div>
	</button>
);

export default ConfirmButton;
