/* eslint-disable */
import React from 'react';

import SEO from '../../components/SEO';
import BlogPage from './BlogPage';

const headerText = 'We’re here for you and your hair';

const BlogPageContainer = ({ location, pageContext: { articles, page } }) => {

	return (
		<>
			<SEO page={page} location={location} title={headerText} />
			<BlogPage articles={articles} headerText={headerText} />
		</>
	);
};

export default BlogPageContainer;
