import React from 'react';

import Video from '../../Video';

const ModalRecommendedVideo = ({ link }) => (
	<div className='modal-recommended-video'>
		<Video
			classname='modal-recommended-video__video'
			videoTitle='Personalise your choice'
			videoId={link.split('v=')[1]}
		/>
	</div>
);

export default ModalRecommendedVideo;
