import React from 'react';

const SalonPriceListItemPrices = ({ prices }) => (
	<div className='price-list--item__prices price-list--item-prices'>
		{(prices || []).map((item, i) => (
			/* eslint-disable-next-line */
			<div key={i} className='price-list--item-prices__item'>
				<div className='price-list--item-prices__title'>
					{item.title}
				</div>
				<div className='price-list--item-prices__price'>
					{item.price}
				</div>
			</div>
		))}
	</div>
);
export default SalonPriceListItemPrices;
