import React from 'react';
import LocalImage from '../../../components/LocalImage';

const MiracleGalleryItem = ({ img }) => {
	const fluidImg = `${img.directory}/${img.name}.webp`.replace(/\s+/g, '').replace(/gloss/g, '');

	return (
		<div id='miracleGalleryItem' className='miracle-gallery__item'>
			<div className='swiper-zoom-container'>
				<LocalImage image={fluidImg} alt='miracle system' />
			</div>
		</div>
	);
};

export default MiracleGalleryItem;
