import React from 'react';

import LocalImage from '../../components/LocalImage';

import { useWindowWidth } from '../../utils/hooks';

import '../../pages/pages/consultation/ConsultationPage.scss';

const ConsultationHeroBanner = ({ headerText }) => {
	const windowWidth = useWindowWidth();

	return (
		<section className='consultation-page-banner'>
			<div className='consultation-page-banner__container container'>
				<div className='consultation-page-banner__info'>
					<h1 className='consultation-page-banner__title'>
						{headerText}
					</h1>
				</div>
			</div>
			{windowWidth > 991 && <LocalImage alt='consultation' image='' />}
		</section>
	);
};

export default ConsultationHeroBanner;
