// External
import React from 'react';

// Internal
import ProductMiracleButton from './ProductMiracleButton';
import { useWindowWidth } from '../../../../../../utils/hooks';
import { getMetafield } from '../../../../../../utils/helpers';

const ProductMiracleActions = ({
	product,
	activeRadio,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
	usesShadeShotSelection
}) => {
	const width = useWindowWidth();
	const isHintActive = JSON.parse(
		getMetafield(product, 'is_active_send_hint')
	);
	const isMobile = width < 550;

	return (
		<div className='product-miracle-actions-v2 flex'>
			<ProductMiracleButton
				{...{
					product,
					activeSecondLevel,
					recommendedProduct,
					isRecommendedSelected,
					setActiveModal,
					canSubscribe,
					activeRadio,
					selectedValue,
					sellingPlanGroup,
					levelValidator,
					setErrorSelectLevel,
					isHintActive,
					usesShadeShotSelection
				}}
				oneTime={activeRadio !== 'auto-delivery'}
				isMobile={isMobile}
			/>

			{isMobile && (
				<ProductMiracleButton
					{...{
						product,
						activeSecondLevel,
						recommendedProduct,
						isRecommendedSelected,
						setActiveModal,
						canSubscribe,
						activeRadio,
						selectedValue,
						sellingPlanGroup,
						levelValidator,
						setErrorSelectLevel,
						isHintActive,
						usesShadeShotSelection
					}}
					oneTime={activeRadio !== 'auto-delivery'}
					className='mobile'
				/>
			)}
		</div>
	);
};

export default ProductMiracleActions;
