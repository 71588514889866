// External
import React, { useContext } from 'react';

// Internal
import { StoreContext } from '../../context/store-context';

import getProductRating from '../../helpers/getProductRating';
import { checkOption } from '../../utils/helpers';
import getStoryblokProductPrice from '../../helpers/getProductFromStoryblokPrice';

import Button from '../Button';
import RatingStars from '../Rating/RatingStars';

const ProductBlogGrid = ({ featuredProduct, allFeaturedImages, productPrice = undefined }) => {
	const { yotpoRatings } = useContext(StoreContext);

	const handle = featuredProduct?.handle;
	const image = featuredProduct?.image;
	const name = featuredProduct?.title;
	const isAvailable = featuredProduct?.availableForSale;

	const price = productPrice ?? getStoryblokProductPrice(featuredProduct);

	const hasVariants = () => {
		if (
			featuredProduct &&
			featuredProduct?.variants &&
			Array.isArray(featuredProduct?.variants)
		) {
			return featuredProduct.variants.length > 1;
		}
		return false;
	};

	const isCustomized = hasVariants();
	const isBundle = checkOption(featuredProduct, 'bundle');

	const productRating = getProductRating(featuredProduct, yotpoRatings);
	const reviewsAverage = productRating?.product_score;
	const reviewsCount = productRating?.total_reviews;

	return (
		<div className='featured-products__container'>
			<a
				href={`https://joshwoodcolour.com/products/${handle}`}
				className='featured-products__container-img'
			>
				<img
					data-test-id='storyblok-product__image'
					src={allFeaturedImages || image}
					alt='Featured product from Storyblok'
				/>
			</a>
			<div>
				<h3 data-test-id='storyblok-product__name' className='product-title'>
					<a href={`https://joshwoodcolour.com/products/${handle}`}>
						<b>{name}</b>
					</a>
				</h3>
			</div>
			<div className='featured-products__container--reviews' data-test-id='storyblok-product__rating'>
				{reviewsAverage && reviewsCount ? (
					<RatingStars rating={reviewsAverage} count={reviewsCount} showNumberReviews />
				) : (
					<p>No reviews yet</p>
				)}
			</div>
			<div className=''>
				<p data-test-id='storyblok-product__price'>{`£ ${price}`}</p>
			</div>
			<div className='featured-products__container--add-to-bag' data-test-id='storyblok-product__button'>
				<Button
					buttonText={isCustomized ? 'customise' : 'add to bag'}
					product={featuredProduct}
					className='product-list-item__button'
					isLink={isCustomized}
					linkUrl={isCustomized && `/products/${handle}`}
					isLight
					isBundle={isBundle}
					availableForSale={isAvailable}
				/>
			</div>
		</div>
	);
};

export default ProductBlogGrid;

