import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import { getQuery } from '../../utils/helpers';
import SEO from '../../components/SEO';
import ProductsListPageV2 from './ProductListPageV2';
import filters from '../../constants/filters';
import sorts from '../../constants/sorts';

const CollectionV2 = ({
	location,
	pageContext: { page, allSellingPlanGroups, collectionFAQ },
	data: { collection, products },
}) => {
	const { cart } = useContext(StoreContext),
		[collectionFilters, setCollectionFilters] = useState([]),
		[collectionSorts, setCollectionSorts] = useState([]),
		queryTag = getQuery('filter');

	useEffect(() => {
		for (const k in filters.collections) {
			if (k === collection.handle) {
				setCollectionFilters(filters.collections[k]);
			}
		}
		for (const k in sorts.collections) {
			if (k === collection.handle) {
				setCollectionSorts(sorts.collections[k]);
			}
		}
	}, []);

	return (
		<>
			<SEO
				page={page}
				location={location}
				title={collection.title}
				pageType='PLP'
			/>
			<ProductsListPageV2
				products={products}
				productsList={collection.products}
				location={location}
				collection={{ ...collection }}
				filter={queryTag}
				collectionFilters={collectionFilters}
				collectionSorts={collectionSorts}
				cart={cart}
				allSellingPlanGroups={allSellingPlanGroups}
				collectionFAQ={collectionFAQ}
			/>
		</>
	);
};

export default CollectionV2;

export const query = graphql`
	query ($handle: String!) {
		collection: shopifyCollection(handle: { eq: $handle }) {
			handle
			description
			descriptionHtml
			title
			image {
							altText
							originalSrc
							gatsbyImageData
						}
			storefrontId
			metafields {
				key
				value
			}
			products {
				...ProductCardPLP
			}
		}
	}
`;
