import React from 'react';
import Breadcrumb from '../../BreadCrumb/Index';
import { BLOG_SECTION_BREADCRUMB } from '../../../constants/breadcrumbs';
import '../BlogStyles.scss';

const BlogGeneralInfo = ({ blok }) => {
	const { title, creationDate, author } = blok;
	const formattedDate = new Date(creationDate).toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
	return (
		<section className='blog-container'>
			<div className='article'>
				<Breadcrumb section={BLOG_SECTION_BREADCRUMB} />
				<h2 className='article__title'> {title} </h2>
				<h3 className='article__created'>
					{author && `By ${author}. `}
					Created on {formattedDate}
				</h3>
			</div>
		</section>
	);
};

export default BlogGeneralInfo;
