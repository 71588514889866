import React from 'react';

import LocalImage from '../../LocalImage';

import './ModalLongTap.scss';

const ModalLongTap = ({ modal: { product: data } }) => (
	<div className='miracle-tap-modal'>
		<div className='miracle-tap-modal__container'>
			<p className='miracle-tap-modal__text'>{data.title}</p>
			<div className='miracle-tap-modal__img'>
				<LocalImage image={data.img} alt={data.title} />
			</div>
		</div>
	</div>
);

export default ModalLongTap;
