import React from 'react';
import { ReactSVG } from 'react-svg';
import { getPrice } from '../../utils/helpers';

const principalTitle = (props) => (
	<h3>
		{props.title}
		<strong>{props.strongText}</strong>
	</h3>
);

const CartProgressBar = ({ cart, productFree = null }) => {
	const delivery = cart?.cost?.totalAmount.amount
		? 35 - cart.cost.totalAmount.amount
		: 35;
	const deliveryPrice = getPrice(delivery, cart, 2);
	const isFreeDelivery =
		delivery <= 0 ||
		cart?.lines?.edges.filter((item) => item.node.sellingPlanAllocation)
			.length;
	const fillDelivery = Number(((35 - delivery) / 35) * 100);

	const amountToGetIt = productFree?.valueToGet || 0;
	const cartAmount = cart?.cost?.totalAmount.amount;

	const percentageFreeProduct =
		(parseFloat(cartAmount) / parseFloat(amountToGetIt)) * 100;
	const progressBarFreeProduct =
		percentageFreeProduct > 100 ? 100 : percentageFreeProduct;

	const remainingAmount =
		parseFloat(amountToGetIt) - parseFloat(cartAmount) > 0
			? (parseFloat(amountToGetIt) - parseFloat(cartAmount)).toFixed(2)
			: '0.00';

	const progressBarsCart = {
		'FREE-PRODUCT':
			productFree && productFree?.isActiveProductFree
				? {
						title: `Spend £${remainingAmount} more to get your `,
						strongText: ` ${productFree?.name}`,
						priceToComplete: Number(amountToGetIt).toFixed(2),
						fill:
							progressBarFreeProduct < 100
								? progressBarFreeProduct
								: 100,
						fillText: `You've got a ${productFree?.name}`,
				  }
				: null,
		DELIVERY:
			{
				title: `Spend another ${deliveryPrice} to get `,
				strongText: ' FREE delivery',
				priceToComplete: `35.00`,
				fill: isFreeDelivery ? 100 : fillDelivery,
				fillText: "Great news! You've got FREE delivery",
			} || null,
	};

	const filteredProgressBarsCart = Object.fromEntries(
		Object.entries(progressBarsCart).filter(
			([key, value]) => value !== null
		)
	);

	return (
		<div className='progress-promotion' style={{display: "none"}}>
			{Object.keys(filteredProgressBarsCart).map((key) => {
				const { priceToComplete, fill, fillText } =
					progressBarsCart[key];
				return (
					<div className='progress-promotion__content' key={key}>
						<div className='progress-promotion__title'>
							<ReactSVG
								src={`/images/${
									key === 'FREE-PRODUCT'
										? 'gift-bag'
										: 'delivery'
								}.svg`}
							/>
							{key === 'DELIVERY' && (
								<>
									{!isFreeDelivery ? (
										principalTitle(progressBarsCart[key])
									) : (
										<h3>{fillText}</h3>
									)}
								</>
							)}

							{key === 'FREE-PRODUCT' && (
								<>
									{remainingAmount !== '0.00' ? (
										principalTitle(progressBarsCart[key])
									) : (
										<h3>{fillText}</h3>
									)}
								</>
							)}
						</div>

						<div className='progress-promotion__progress'>
							{fill !== 100 && (
								<>
									<div className='progress-promotion__progress-bar'>
										<div
											className='progress-promotion__progress-bar-fill'
											style={{ width: `${fill}%` }}
										/>
									</div>
									<div className='progress-promotion__price'>
										<span>£{priceToComplete}</span>
									</div>
								</>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default CartProgressBar;
