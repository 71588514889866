import React, { useState } from 'react';

import ProductsItem from './ProductItem';
import ModalProduct from '../../../../components/ModalProduct';

const ProductsList = ({
	frequentlyMainProductHandle,
	frequentlyProductsHandles,
	setSelectedProductsIds,
	selectedProductsIds,
}) => {
	const [productData, setProductData] = useState(null);

	return (
		<>
			<div className='frequently-section__list frequently-section-list'>
				{frequentlyMainProductHandle ? (
					<ProductsItem
						isMain
						handle={frequentlyMainProductHandle}
						setSelectedProductsIds={setSelectedProductsIds}
						selectedProductsIds={selectedProductsIds}
						setProductData={setProductData}
					/>
				) : null}
				<div className='frequently-section__plus'>+</div>
				{frequentlyProductsHandles
					? frequentlyProductsHandles.map((handle, i) => (
							<ProductsItem
								/* eslint-disable-next-line */
								key={i}
								handle={handle}
								setSelectedProductsIds={setSelectedProductsIds}
								selectedProductsIds={selectedProductsIds}
								setProductData={setProductData}
							/>
					  ))
					: null}
			</div>
			{productData && <ModalProduct productData={productData} />}
		</>
	);
};

export default ProductsList;
