// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import MiracleStepHeader from './MiracleStepHeader';
import DeliveryRadio from '../../DeliveryRadio';
import ProductTooltip from '../../../templates/products/ProductFirstScreen/ProductTooltip';
import ProductMiracleActions from '../../../templates/products/ProductFirstScreen/ProductBundlesSection/ProductSelectionLevels/ProductMiracleActions';

import {
	getPrice,
	getSellingPlanGroupDefaultValue,
} from '../../../utils/helpers';
import { StoreContext } from '../../../context/store-context';

const MiracleDeliveryStep = ({
	product,
	canSubscribe,
	activeSecondLevel,
	setActiveModal,
	sellingPlanGroup,
	options,
}) => {
	const { cart } = useContext(StoreContext);
	const [prices, setPrices] = useState({ currentPrice: 0, discountPrice: 0 });
	const [activeRadio, setActiveRadio] = useState(
		canSubscribe ? 'auto-delivery' : 'One-Time Purchase'
	);
	const [selectedValue, setSelectedValue] = useState(
		canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
	);
    
	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[0],
				discount = parseInt(
					plan.node.pricingPolicies[0].adjustmentValue.percentage
				),
				price = parseInt(product.variants[0].price);
			setPrices({
				currentPrice: price,
				discountPrice: Math.round(price - price * (discount / 100)),
			});
		}
	}, []);

	const isDiscount =
		activeRadio === 'auto-delivery' &&
		prices?.currentPrice !== prices?.discountPrice;
	const priceText = isDiscount
		? `now 
            	${getPrice(prices?.discountPrice, cart)}, then 
            	${getPrice(prices?.currentPrice, cart)}/treatment ongoing`
		: `${getPrice(prices?.currentPrice, cart)}/treatment`;

	return (
		<div className='miracle-modal-step miracle-modal-step--four'>
			<MiracleStepHeader
				title='4. Confirm your delivery'
				text='The more you use the Miracle System the better the colour and condition.' // eslint-disable-line
			/>
			<div className='miracle-modal-step__recommended'>
				<p>
					<strong>Josh recommends colouring every 4-6 weeks</strong>
				</p>
				<p>save 15% off every order when you choose auto-delivery</p>
			</div>
			<DeliveryRadio
				activeRadio={activeRadio}
				setActiveRadio={setActiveRadio}
				classname='pdp-miracle-radios'
				product={product}
			/>
			<ProductMiracleActions
				{...{
					product,
					setActiveModal,
					activeRadio,
					activeSecondLevel,
					canSubscribe,
					selectedValue,
					setSelectedValue,
					sellingPlanGroup,
					optionsObjects,
				}}
				origin='mobile'
			/>
			<ProductTooltip product={product} />
		</div>
	);
};

export default MiracleDeliveryStep;
