/* eslint-disable */
import React from 'react';

import SEO from '../../../components/SEO';
import HowItWorksPageBanner from '../../../templates/how-it-works/HowItWorksPageBanner';
import HowItWorksSection from '../../../templates/how-it-works/HowItWorksSection';

import './HowItWorksPage.scss';

const data = [
		{
			title: 'Cover grey',
			text: `From temporary root touch ups to achieving 
            permanent 100% grey coverage, 
            find personalised hair colour that suits you best.`,
			imgUrl: '/images/how_it_works/image-1.jpg',
			linkText: 'shop cover grey',
			linkUrl: '/collections/grey-coverage',
		},
		{
			title: 'Personalise your colour',
			text: `Customise your permanent hair colour and add rich, 
            natural tones unique to you with Shade Shot.`,
			imgUrl: '/images/how_it_works/image-2.jpg',
			linkText: 'shop personalise colour',
			linkUrl: '/collections/shade-shot',
		},
		{
			title: 'Colour refresh',
			/* eslint-disable-next-line */
			text: 'Refresh colour, neutralise brassiness, nourish hair and enhance shine with our Gloss, a semi permanent hair colour treatment mask.',
			imgUrl: '/images/how_it_works/image-3.jpg',
			linkText: 'shop colour refresh',
			linkUrl: '/collections/gloss',
		},
		{
			title: 'Boost condition',
			/* eslint-disable-next-line */
			text: 'Cleanse, condition and care for your colour with our expert range of shampoos, conditioners and treatment masks for blonde and brunette hair.',
			imgUrl: '/images/how_it_works/image-4.jpg',
			linkText: 'shop boost condition',
			linkUrl: '/collections/care',
		},
	],
	headerText =
		'Hair colour, gloss and treatments that cover grey, boost condition and amplify shine',
	HowItWorksPage = ({ location, pageContext: { page } }) => (
		<>
			<SEO page={page} location={location} title={headerText} />
			<div className='how-it-works-page'>
				<HowItWorksPageBanner headerText={headerText} />
				<div className='container'>
					{(data || []).map((item, index) => (
						<HowItWorksSection
							/* eslint-disable-next-line */
							key={index}
							title={item.title}
							imgUrl={item.imgUrl}
							text={item.text}
							linkText={item.linkText}
							linkUrl={item.linkUrl}
						/>
					))}
				</div>
			</div>
		</>
	);

export default HowItWorksPage;
