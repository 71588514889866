import React, { useContext } from 'react';

import Footer from './Footer';

import { StoreContext } from '../../context/store-context';

const FooterContainer = ({ location }) => {
	const { menu, isSteps } = useContext(StoreContext);

	return !isSteps && <Footer location={location} menu={menu} />;
};

export default FooterContainer;
