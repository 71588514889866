import React from 'react';
import { ReactSVG } from 'react-svg';

const CartEmpty = ({ closeCart }) => (
	<div className='cart-empty'>
		<h2 className='cart-empty__title'>Your cart</h2>
		<p className='cart-empty__text'>Your cart is currently empty.</p>
		<button
			className='cart-empty__button cart-empty-button continue-button'
			onClick={closeCart}
		>
			<span className='continue-button__text'>continue shopping</span>
			<ReactSVG
				src='/images/left-arrow.svg'
				wrapper='span'
				className='icon icon--wide icon-arrow-right'
			/>
		</button>
	</div>
);

export default CartEmpty;
