import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../context/store-context';
import { getPrice } from '../../utils/helpers';
import applyDiscount from '../../utils/cart/applyDiscount';

const CartDiscount = ({ setPrice }) => {
	const [message, setMessage] = useState(null),
		{ cart, addVariantToCart, updateCart } = useContext(StoreContext),
		flattenLineItems = () => cart.lines.edges.map((item) => item.node),
		[lineItems, setLineItems] = useState(flattenLineItems()),
		[discountCode, setDiscountCode] = useState(''),
		[showCodeForm, setShowCodeForm] = useState(false),
		totalPrice = getPrice(cart.cost.totalAmount.amount, cart, 2).slice(1),
		checkApplicable = (data) => {
			const applicable = data.discountCodes[0].applicable;
			if (!applicable) {
				setMessage(
					`Code "${data.discountCodes[0].code}" not applicable to current cart`
				);
			}
			return applicable;
		},
		handleSubmitDiscount = async (e) => {
			e.preventDefault();

			applyDiscount(cart.id, discountCode).then((data) => {
				updateCart(data.cartDiscountCodesUpdate.cart);
				if (!checkApplicable(data.cartDiscountCodesUpdate.cart))
					return false;

				let successMessage = 'Code successful!';
				const totalSum = parseFloat(
					totalPrice -
						data.cartDiscountCodesUpdate.cart.cost.totalAmount
							.amount
				).toFixed(2);
				if (Number(totalSum) !== 0) {
					successMessage = `<strong>
							${getPrice(totalSum, cart, 2)}
							</strong>
							<br><em>subtotal calculated at checkout</em>`;
				}

				setPrice(getPrice(cart.cost.totalAmount.amount, cart, 2));
				setMessage(successMessage);
				setTimeout(() => {
					setMessage(null);
				}, 2500);
			});
		};

	useEffect(() => {
		setLineItems(flattenLineItems());
	}, [cart]);

	useEffect(() => {
		if (cart.discountCodes.length) {
			const code = cart.discountCodes[0].code;
			setDiscountCode(code);
		}
	}, [cart]);

	return (
		<div className='cart-footer__discount cart-footer-discount'>
			<button
				onClick={() => setShowCodeForm(!showCodeForm)}
				className={`cart-footer-discount__show ${
					showCodeForm && 'cart-footer-discount__show--opened'
				}`}
			>
				<span>Enter gift or promo code?</span>
			</button>
			<form
				onSubmit={handleSubmitDiscount}
				className={`cart-footer-discount__form ${
					!showCodeForm && 'cart-footer-discount__form--hidden'
				}`}
			>
				<input
					type='text'
					placeholder='Enter Coupon'
					name='discount'
					className='cart-footer-discount__input'
					value={discountCode}
					onChange={(e) => setDiscountCode(e.target.value)}
				/>
				<button
					type='submit'
					className='button cart-footer-discount__button'
				>
					<span>apply</span>
				</button>
			</form>
			{message && (
				<div className='cart-footer-discount__tooltip'>
					<p dangerouslySetInnerHTML={{ __html: message }} />
				</div>
			)}
		</div>
	);
};

export default CartDiscount;
