// External
import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

// Internal
import { StoreContext } from '../../../context/store-context';
import { isBrowser } from '../../../utils/helpers';
import useCustomer from '../../../utils/useCustomer';
import AccountBanner from '../../../templates/account/AccountPage/AccountBanner';
import {
	setHrefLangTag,
	removeHrefLangTag,
} from '../../../helpers/setHrefLang';

const AccountSubscriptions = () => {
	const { removeAuthorized } = useContext(StoreContext);
	const logout = () => {
		removeAuthorized();
		navigate('/');
	};

	useEffect(() => {
		setHrefLangTag('account-subscription-hreflang');

		return () => {
			removeHrefLangTag('account-subscription-hreflang');
		};
	}, []);

	return (
		<>
			{isBrowser ? (
				<>
					{window.sessionStorage.getItem('accessToken') ? (
						<section className='account-page'>
							<div className='container'>
								<h1>My Account</h1>
								<button
									className='account-page__logout'
									onClick={logout}
								>
									Log out
								</button>
								<AccountBanner />
							</div>
						</section>
					) : (
						navigate('/account/login')
					)}
				</>
			) : null}
		</>
	);
};

export default AccountSubscriptions;
