import React from 'react';

import { Link } from 'gatsby';
import { useWindowWidth } from '../../utils/hooks';
import LocalImageOptimized from '../../components/LocalImage/LocalImageOptimized';

import './subscription-link-expired.scss';

const ManageSubscriptionLinkExpired = () => {
	const windowWidth = useWindowWidth();
	return (
		<section className='subscription-link-expired-section'>
			{windowWidth > 767 && (
				<div className='link-expired-image-container'>
					<LocalImageOptimized imageUrl='../../../static/images/miracle-about/banner.webp' />
				</div>
			)}

			<div className='link-expired-text'>
				<h2>Something went wrong!</h2>
				<span>
					The link to manage your subscription link has expired.
				</span>
				<br />
				<p>
					If you would like to make any changes to your subscription,
					please log into your account <Link to='/account'>here</Link>
					, or contact the Client Delight Team, <a href='mailto:expert@joshwoodcolour.com'> expert@joshwoodcolour.com</a>
				</p>
			</div>
		</section>
	);
};

export default ManageSubscriptionLinkExpired;
