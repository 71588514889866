import { Link } from 'gatsby';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getMetafield } from '../../utils/helpers';

const AnotherBigListItem = ({ product }) => {
	const url = getMetafield(product, 'url');
	const image = getMetafield(product, 'not_product_image');

	return (
		<li className='products-list-grid__gift products-list-grid-gift'>
			<div className='products-list-grid-gift__img'>
				<Link to={url}>
					{product.images.length > 0 || image ? (
						product?.images[0] &&
						product?.images[0].gatsbyImageData ? (
							<GatsbyImage
								image={getImage(
									product.images[0].gatsbyImageData
								)}
								alt={product.title}
							/>
						) : (
							<LazyLoadImage
								src={image}
								alt={product.title}
								effect='blur'
							/>
						)
					) : (
						<LazyLoadImage
							src='/images/no-image.jpg'
							alt="There's no element to show"
							effect='blur'
						/>
					)}
				</Link>
			</div>
		</li>
	);
};

export default AnotherBigListItem;
