import React, { useContext } from 'react';
import { StoreContext } from '../../../../context/store-context';

import './ProductRedInfo.scss';

const ProductRedInfo = ({ product, glossModalLinkText }) => {
	const { setModal, showModal } = useContext(StoreContext),
		openRedModal = () => {
			setModal(product, 'red');
			showModal(true);
		};
	return (
		<button className='pdp-red-link' onClick={openRedModal}>
			<span className='pdp-red-link__icon' />
			<span className='pdp-red-link__text'>
				{glossModalLinkText || 'View Before / After'}
			</span>
		</button>
	);
};

export default ProductRedInfo;
