import React from 'react';
import { extractInnerPortionText } from '../../utils/helpers';

import './KeyTakeAways.scss';

const KeyTakeAways = ({ text }) => (
	<section className='key-take-away__section'>
		<header>
			<h2>Key Take aways</h2>
		</header>
		<article>
			<p
				dangerouslySetInnerHTML={{
					__html: extractInnerPortionText(text, 2),
				}}
			/>
		</article>
	</section>
);

export default KeyTakeAways;
