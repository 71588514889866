// External
import React, { useEffect, useState } from 'react';

// Internal
import { useWindowWidth } from '../../utils/hooks';
import getVariantPrice from '../../helpers/productsPricing';

import chevron from '../../../static/images/chevron.png';
import Loader from '../Loader';

import './DeliveryRadio.scss';

const MiracleSelect = React.lazy(() => import('../MiracleSelect'));

const DeliveryRadioV2 = ({
	activeRadio,
	setActiveRadio,
	radios,
	center,
	classname,
	discountPercentageWithSub = 15,
	product,
	levelValidator,
	setErrorSelectLevel,
	optionsObjects,
	selectedValue,
	setSelectedValue,
	usesShadeShotSelection,
}) => {
	const radioDeliveryOptions = [
		{
			title: `Subscribe & Save ${discountPercentageWithSub}%`,
			subtitle: 'Free shipping & regular free gifts',
			price: getVariantPrice(product, true, discountPercentageWithSub),
			activeSelect: true,
			footer: 'Free gifts included with subscription purchase',
			val: 'auto-delivery',
		},
		{
			title: 'One-time purchase',
			price: getVariantPrice(product),
			val: 'One-Time Purchase',
		},
	];
	const radiosList = radios || radioDeliveryOptions;

	const [isOpen, setIsOpen] = useState(false);
	const [rotateChevron, setRotateChevron] = useState(false);

	const handleSelect = () => {
		setIsOpen(!isOpen);
	};
	const usesTwoLevelsValidation = usesShadeShotSelection
		? typeof levelValidator === 'object' &&
		  levelValidator?.isValidOne &&
		  levelValidator?.isValidTwo
		: typeof levelValidator === 'object' && levelValidator?.isValidOne;
	const isValid = usesTwoLevelsValidation;

	const getActive = (value) => {
		if (typeof levelValidator !== 'object') return value;
		if (isValid) return value;
	};

	const isAutoDelivery = activeRadio === 'auto-delivery';

	const width = useWindowWidth();

	const validationErrorRadioDelivery = (val) => {
		if (typeof levelValidator === 'object') {
			if (!levelValidator?.isValidOne) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne: 'Please choose colour before',
						errorLevelTwo: '',
					});
			} else if (!levelValidator?.isValidTwo) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne: '',
						errorLevelTwo: 'Please choose tone before',
					});
			}
		}

		if (typeof levelValidator !== 'object') {
			setActiveRadio(val);
		} else if (isValid) {
			setActiveRadio(val);
		}
	};

	useEffect(() => {
		const setStylePositions = () => {
			const radioPosition = isAutoDelivery ? '3' : '2';
			const lastRadio = document.querySelector(
				`.delivery-radios-v2__item:nth-child(${radioPosition})`
			);
			const unsubscribe = document.querySelector(
				'.delivery-radios-v2__unsubscribe'
			);
			if (!lastRadio || !levelValidator) return;
			if (width > 550) {
				if (unsubscribe) unsubscribe.style.bottom = '-25px';
				lastRadio.style.marginTop = '0px';
				return;
			}
			if (
				levelValidator.isValidOne &&
				levelValidator.isValidTwo &&
				isAutoDelivery
			) {
				lastRadio.style.marginTop = '100px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% + 32px)';
			} else {
				lastRadio.style.marginTop = '25px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% - 10px)';
			}
		};
		setStylePositions();
	}, [levelValidator, activeRadio]);

	return (
		<div
			className={`delivery-radios-v2 ${
				center && 'delivery-radios-v2--center'
			} ${classname && classname}`}
		>
			{isAutoDelivery && (
				<span className='delivery-radios-v2__deliver'>
					<b>Subscribe & Save</b>
				</span>
			)}

			<div className='delivery-radios-v2__container'>
				{radiosList.map((radio) => {
					const val = radio.val.toLowerCase().replace(' ', '-');
					return (
						<div
							key={radio.val}
							className='delivery-radios-v2__container-box'
							style={{
								border:
									activeRadio === getActive(val)
										? `1px solid #D56E8F`
										: '1px solid #CDCDCD',
							}}
							onClick={() => validationErrorRadioDelivery(val)}
						>
							<div className='delivery-radios-v2__container-box-first'>
								<label>
									<input
										type='radio'
										name='purchase_type'
										value='auto-delivery'
										checked={activeRadio === getActive(val)}
									/>
									<span
										className={`
									${
										activeRadio === getActive(val)
											? 'delivery-radios-v2__container-box-selected'
											: 'delivery-radios-v2__container-box-no-selected'
									}
								`}
									/>
								</label>
								<div className='delivery-radios-v2__container-box-first-box-text'>
									<div className='delivery-radios-v2__container-box-first-text'>
										<p className='bold'>{radio.title}</p>
										{radio.subtitle && (
											<p>{radio.subtitle}</p>
										)}
									</div>
									{radio.price && (
										<p className='bold'>{radio.price}</p>
									)}
								</div>
							</div>
							{radio.activeSelect && (
								<div
									className={`product-miracle-select ${
										activeRadio === getActive(val)
											? 'product-miracle-select__selected'
											: ''
									}`}
									onClick={() => {
										setRotateChevron(
											(currenValue) => !currenValue
										);
									}}
									onBlur={() => {
										setRotateChevron(false);
									}}
									style={{
										pointerEvents:
											activeRadio === getActive(val)
												? 'auto'
												: 'none',
									}}
								>
									<React.Suspense fallback={<Loader />}>
										<MiracleSelect
											type='other'
											{...{
												optionsObjects,
												selectedValue,
												setSelectedValue,
												handleSelect,
												origin,
												disabled: !isValid,
											}}
										/>
									</React.Suspense>
									<img
										className={`select-chevron ${
											rotateChevron
												? 'rotate-chevron'
												: 'rotate-chevron-reverse'
										}`}
										src={chevron}
										alt='chevron for product select'
									/>
								</div>
							)}
							{radio.footer && <p>{radio.footer}</p>}
						</div>
					);
				})}
			</div>
			<div className='delivery-radios-v2__stock'>
				<svg
					width='14'
					height='11'
					viewBox='0 0 14 11'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='m13.09 5.267-1.312-3.063a.44.44 0 0 0-.403-.267h-1.312v-.874a.44.44 0 0 0-.438-.438H1.313a.44.44 0 0 0-.438.438V8.5a.44.44 0 0 0 .438.438h.936a1.75 1.75 0 0 0 3.377 0h2.748a1.75 1.75 0 0 0 3.377 0h.937a.44.44 0 0 0 .437-.438V5.438a.4.4 0 0 0-.035-.171m-3.027-2.455h1.023L12.023 5h-1.96zM3.936 9.375a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75m4.437-1.312H5.626a1.75 1.75 0 0 0-3.377 0H1.75V1.5h7.438v5.495a1.75 1.75 0 0 0-.814 1.068m1.688 1.312a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75m2.188-1.312h-.499a1.75 1.75 0 0 0-1.688-1.313v-.875h2.187z'
						fill='#000'
					/>
				</svg>
				<span>In stock and available for delivery</span>
			</div>
		</div>
	);
};

export default DeliveryRadioV2;
