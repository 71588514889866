export const colourData = [
	{
		title: 'Balayage (Half Head)',
		info: 'Natural-looking low maintenance blended highlight service, that is placed around the face, the parting, and the crown. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£230',
			},
			{
				title: 'Advocate',
				price: '£285',
			},
			{
				title: 'Principal',
				price: '£320',
			},
			{
				title: 'Master',
				price: '£355',
			},
			{
				title: 'Creative Master',
				price: '£405',
			},
		],
	},
	{
		title: 'Balayage (Full Head)',
		info: 'Natural-looking low maintenance blended highlight service, placed throughout the hair. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£295',
			},
			{
				title: 'Advocate',
				price: '£350',
			},
			{
				title: 'Principal',
				price: '£410',
			},
			{
				title: 'Master',
				price: '£460',
			},
			{
				title: 'Creative Master',
				price: '£525',
			},
		],
	},

	{
		title: 'Balayage & Tint (Half Head)',
		info: 'Natural-looking low maintenance blended highlight service, that is placed around the face, the parting, and the crown. This colour service includes a permanent colour service on the roots and a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£270',
			},
			{
				title: 'Advocate',
				price: '£325',
			},
			{
				title: 'Principal',
				price: '£360',
			},
			{
				title: 'Master',
				price: '£395',
			},
			{
				title: 'Creative Master',
				price: '£445',
			},
		],
	},
	{
		title: 'Balayage & Tint (Full Head)',
		info: 'Natural-looking low maintenance blended highlight service, that is placed around the face, the parting, and the crown. This colour service includes a permanent colour service on the roots and a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£345',
			},
			{
				title: 'Advocate',
				price: '£400',
			},
			{
				title: 'Principal',
				price: '£460',
			},
			{
				title: 'Master',
				price: '£510',
			},
			{
				title: 'Creative Master',
				price: '£575',
			},
		],
	},

	{
		title: 'Partial highlights',
		info: 'A shorter highlight service working on the hairline, parting, and crown only. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£140',
			},
			{
				title: 'Advocate',
				price: '£180',
			},
			{
				title: 'Principal',
				price: '£205',
			},
			{
				title: 'Master',
				price: '£265',
			},
			{
				title: 'Creative Master',
				price: '£315',
			},
		],
	},
	{
		title: 'Half head highlights',
		info: 'A half head of highlights, that are placed around the face, the parting, the sides of the head and the crown. This colour service includes a toner',
		prices: [
			{
				title: 'Varderer',
				price: '£200',
			},
			{
				title: 'Advocate',
				price: '£245',
			},
			{
				title: 'Principal',
				price: '£285',
			},
			{
				title: 'Master',
				price: '£340',
			},
			{
				title: 'Creative Master',
				price: '£380',
			},
		],
	},
	{
		title: 'Full head highlights',
		info: 'A full head of highlights, that are placed through all areas of the hair. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£255',
			},
			{
				title: 'Advocate',
				price: '£310',
			},
			{
				title: 'Principal',
				price: '£370',
			},
			{
				title: 'Master',
				price: '£425',
			},
			{
				title: 'Creative Master',
				price: '£490',
			},
		],
	},
	{
		title: 'Partial lights + tint',
		info: 'Highlights around the face and parting with a base colour, can be used to cover grey in between. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£185',
			},
			{
				title: 'Advocate',
				price: '£240',
			},
			{
				title: 'Principal',
				price: '£285',
			},
			{
				title: 'Master',
				price: '£320',
			},
			{
				title: 'Creative Master',
				price: '£360',
			},
		],
	},
	{
		title: 'Half head + tint',
		info: 'A half head of highlights with a base colour applied between foils, can be used to cover grey in between. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£230',
			},
			{
				title: 'Advocate',
				price: '£285',
			},
			{
				title: 'Principal',
				price: '£355',
			},
			{
				title: 'Master',
				price: '£380',
			},
			{
				title: 'Creative Master',
				price: '£440',
			},
		],
	},
	{
		title: 'Full head + tint',
		info: 'A full head of highlights with a base colour applied between foils, can be used to cover grey in between. This colour service includes a toner.',
		prices: [
			{
				title: 'Varderer',
				price: '£275',
			},
			{
				title: 'Advocate',
				price: '£355',
			},
			{
				title: 'Principal',
				price: '£440',
			},
			{
				title: 'Master',
				price: '£455',
			},
			{
				title: 'Creative Master',
				price: '£540',
			},
		],
	},
	{
		title: 'Root bleach + toner',
		info: 'Lighten your roots, finishing with a toner to blend, roots to tips.',
		prices: [
			{
				title: 'Varderer',
				price: '£155',
			},
			{
				title: 'Advocate',
				price: '£195',
			},
			{
				title: 'Principal',
				price: '£270',
			},
			{
				title: 'Master',
				price: '£355',
			},
			{
				title: 'Creative Master',
				price: '£375',
			},
		],
	},
	{
		title: 'Gloss',
		info: 'A quick semi-permanent colour service, to refresh your colour and boost shine.',
		prices: [
			{
				title: 'Varderer',
				price: '£55',
			},
			{
				title: 'Advocate',
				price: '£65',
			},
			{
				title: 'Principal',
				price: '£75',
			},
			{
				title: 'Master',
				price: '£85',
			},
			{
				title: 'Creative Master',
				price: '£95',
			},
		],
	},
	{
		title: 'Tint',
		info: 'A full permanent colour service, perfect for covering grey and refreshing the hair.',
		prices: [
			{
				title: 'Varderer',
				price: '£95',
			},
			{
				title: 'Advocate',
				price: '£110',
			},
			{
				title: 'Principal',
				price: '£140',
			},
			{
				title: 'Master',
				price: '£175',
			},
			{
				title: 'Creative Master',
				price: '£195',
			},
		],
	},
	{
		title: 'Tint + toner',
		info: 'A full permanent colour service, perfect for covering grey and refreshing the hair.',
		prices: [
			{
				title: 'Varderer',
				price: '£115',
			},
			{
				title: 'Advocate',
				price: '£145',
			},
			{
				title: 'Principal',
				price: '£175',
			},
			{
				title: 'Master',
				price: '£210',
			},
			{
				title: 'Creative Master',
				price: '£230',
			},
		],
	},
	{
		title: 'Virgin Bleach',
		info: 'All over colour for hair that has never been coloured, finishing with a toner to blend. By consultation only.',
		prices: [
			{
				title: 'Varderer',
				price: 'By consultation only',
			},
			{
				title: 'Advocate',
				price: 'By consultation only',
			},
			{
				title: 'Principal',
				price: 'By consultation only',
			},
			{
				title: 'Master',
				price: 'By consultation only',
			},
			{
				title: 'Creative Master',
				price: 'By consultation only',
			},
		],
	},
	{
		title: 'Colour Change',
		info: 'By consultation only.',
		prices: [
			{
				title: 'Varderer',
				price: 'By consultation only',
			},
			{
				title: 'Advocate',
				price: 'By consultation only',
			},
			{
				title: 'Principal',
				price: 'By consultation only',
			},
			{
				title: 'Master',
				price: 'By consultation only',
			},
			{
				title: 'Creative Master',
				price: 'By consultation only',
			},
		],
	},
	{
		title: 'Flying colours (Gents)',
		info: 'Subtle colour for men, usually done after a cut.',
		prices: [
			{
				title: 'Varderer',
				price: '£75',
			},
			{
				title: 'Advocate',
				price: '£80',
			},
			{
				title: 'Principal',
				price: '£100',
			},
			{
				title: 'Master',
				price: '£115',
			},
			{
				title: 'Creative Master',
				price: '£135',
			},
		],
	},
];

export const stylingData = [
	{
		title: 'Blow dry',
		prices: [
			{
				title: 'Varderer',
				price: '£45',
			},
			{
				title: 'Advocate',
				price: '£60',
			},
			{
				title: 'Principal',
				price: '£80',
			},
			{
				title: 'Master',
				price: '£115',
			},
			{
				title: 'Creative Master',
				price: '£160',
			},
		],
	},
	{
		title: 'Hair Up',
		prices: [
			{
				title: 'Varderer',
				price: 'from £90',
			},
			{
				title: 'Advocate',
				price: 'from £130',
			},
			{
				title: 'Principal',
				price: 'from £190',
			},
			{
				title: 'Master',
				price: 'from £235',
			},
			{
				title: 'Creative Master',
				price: 'from £295',
			},
		],
	},
	{
		title: 'Cut + blow dry',
		prices: [
			{
				title: 'Varderer',
				price: '£90',
			},
			{
				title: 'Advocate',
				price: '£130',
			},
			{
				title: 'Principal',
				price: '£190',
			},
			{
				title: 'Master',
				price: '£235',
			},
			{
				title: 'Creative Master',
				price: '£295',
			},
		],
	},
	{
		title: 'Gents',
		prices: [
			{
				title: 'Varderer',
				price: '£60',
			},
			{
				title: 'Advocate',
				price: '£80',
			},
			{
				title: 'Principal',
				price: '£125',
			},
			{
				title: 'Master',
				price: '£155',
			},
			{
				title: 'Creative Master',
				price: '£180',
			},
		],
	},
	{
		title: 'Children',
		prices: [
			{
				title: 'Varderer',
				price: '£40',
			},
			{
				title: 'Advocate',
				price: '£60',
			},
			{
				title: 'Principal',
				price: '£90',
			},
			{
				title: 'Master',
				price: '£120',
			},
			{
				title: 'Creative Master',
				price: '£150',
			},
		],
	},
];

export const extensionsData = [
	{
		title: 'Jason',
		prices: [
			{
				title: 'Full Head',
				price: '£1 750',
				info: 'Single strand bonds. Includes hair wash, extensions application, and blow-dry. You must come in and see Jason for a consultation at least 48 hours before for a colour match.',
			},
			{
				title: 'Half Head',
				price: '£980',
				info: 'Single strand bonds. Price from £980 and includes hair wash, extensions application, and blow-dry. You must come in and see Jason for a consultation at least 48 hours before for a colour match.',
			},
			{
				title: 'Fillers',
				price: '£590',
			},
			{
				title: 'Hair Extensions Removal',
				price: '£250',
			},
		],
	},
	{
		title: 'Sophie',
		prices: [
			{
				title: 'Full Head Tape Extensions',
				price: '£900',
				info: 'Tape extensions applied to the sides and crown of the head down to the occipital and nape area. Wash & blowdry pre treatment is included. You must come in and see Sophie for a consultation at least 48 hours before for a colour match.',
			},
			{
				title: 'Three Quarter Head Tape Extensions',
				price: '£730',
				info: 'Tape extensions applied to the sides and crown down to the occipital bone area. You must come in and see Sophie for a consultation at least 48 hours before for a colour match. Wash & Blowdry pre treatment is included.',
			},
			{
				title: 'Half Head Tape Extensions',
				price: '£495',
				info: 'Tape extensions applied to the sides and crown area of the head. You must come in and see Sophie for a consultation at least 48 hours before for a colour match. Wash & Blowdry pre treatment is included.',
			},
			{
				title: 'Sides Tape Extensions',
				price: '£260',
				info: 'Tape extensions applied to the side of the head. Wash & Blowdry pre treatment is included.',
			},
			{
				title: 'Re-tape Booster',
				price: '£175',
			},
			{
				title: 'Tape Removal',
				price: '£125',
				info: 'This service does not include a wash or blow dry.',
			},
		],
	},
];

export const hairTreatmentsData = [
	{
		title: 'Semi-permanent Blowdry',
		prices: [
			{
				title: 'AURA smoothing treatment for short hair',
				price: '£330',
			},
			{
				title: 'AURA smoothing treatment for medium hair',
				price: '£405',
			},
			{
				title: 'AURA smoothing treatment for long hair',
				price: '£455',
			},
		],
	},
	{
		title: 'Treatment Add-on',
		prices: [
			{
				title: 'k18',
				price: 'from £20',
			},
			{
				title: 'Wellaplex',
				price: 'from £30',
			},
			{
				title: 'Miracle Mask',
				price: '£20',
			},
			{
				title: 'Scalp facial',
				price: '£25',
			},
			{
				title: 'more treatments upon request',
			},
		],
	},
];

export const beautyData = [
	{
		title: 'Gel polish removal',
		price: '£25',
	},
	{
		title: 'Polish Change - Hands',
		price: '£30',
	},
	{
		title: 'Polish Change - Feet',
		price: '£35',
	},
	{
		title: 'Manicure',
		price: '£50',
	},
	{
		title: 'Pedicure',
		price: '£70',
	},
	{
		title: 'Nail Art',
		price: 'from £10',
	},
	{
		title: 'Gel polish Manicure',
		price: '£70',
	},
	{
		title: 'Gel polish Pedicure',
		price: '£85',
	},
	{
		title: 'Acrylic Full Set',
		price: '£90',
	},
	{
		title: 'Brow tint',
		price: '£25',
	},
	{
		title: 'Brow shape and wax',
		price: '£45',
	},
	{
		title: 'Facial Area (Chin & Cheek) Wax',
		price: '£45',
	},
	{
		title: 'Upper lip wax',
		price: '£25',
	},
	{
		title: 'Eyelash tint',
		price: '£35',
	},
	{
		title: 'Make-up',
		price: 'from £150',
	},
];

export const eventsData = [
	{
		title: 'Prices available upon request',
		price: 'reservations@joshwoodcolour.com',
	},
];
