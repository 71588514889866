import React, { Fragment } from 'react';
import LocalImage from '../../../components/LocalImage';

import './ProductIncludeWith.scss';
import { getMetafield, getMetafieldParse } from '../../../utils/helpers';

const ProductIncludeWith = ({ product }) => {
	const includeWithDescription = getMetafield(
		product,
		'include_with_section_description'
	);

	const includeWithSteps = getMetafieldParse(
		product,
		'include_with_section_steps'
	);

	return (
		<div className='container'>
			<div className='pdp-include'>
				<h2 className='pdp-include__title'>
					Included with {product?.title}
				</h2>
				{includeWithDescription && (
					<p className='pdp-include__desc'>
						{includeWithDescription}
					</p>
				)}

				<div className={`pdp-include__content ${includeWithSteps?.data.length === 3 ? 'content__grid--3' : 'content__grid--2'}`}>
					{includeWithSteps?.data.map((product, index) => (
						<Fragment key={product.name}>
							<div className='pdp-include__content__text'>
								<LocalImage
									image={product.image}
									alt={product.name}
								/>
								<h3>{product.name}</h3>
								<p>{product.description}</p>
							</div>

							{index < includeWithSteps.data.length - 1 && (
								<div className='pdp-include__content__divider' />
							)}
						</Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProductIncludeWith;
