import littledata from '@littledata/headless-shopify-sdk';
import { submitErrorToSlack } from '../utils/slackMessage';

const getLittledataCartAttributes = async () => {
	const ga4MeasurementId = 'G-5TT6JG7CTF';
	const fetchClientTracker = littledata.fetchClientIds({
		ga4MeasurementId,
	});

	const trackerError = new Error('User is blocking the tracker');

	let result = [];
	try {
		const timeoutPromise = new Promise((_resolve, reject) => {
			setTimeout(() => reject(trackerError), 6000);
		});
		result = await Promise.race([fetchClientTracker, timeoutPromise]);
	} catch (error) {
		const errorMessage = `Error fetching littledata client tracker: ${error}`;
		console.error(errorMessage);
		submitErrorToSlack('Littledata Client', errorMessage, 'POST');
	}

	const noteAttributes = result || [];
	return noteAttributes;
};

export default getLittledataCartAttributes;
