// External
import React from 'react';
import { Link } from 'gatsby';

// Internal
import alertSelect from '../../../../../../static/images/alert-select.png';

const ProductSelectionLevelHeader = ({
	isOneLevel = false,
	title,
	name,
	description,
	errorLevel,
	showTitle = true,
}) => {
	const hasErrorLevel = errorLevel();

	return (
		<div
			className='pdp-selection-level__header
            pdp-selection-level-header'
		>
			<div>
				<span className='pdp-selection-level-header__title-selection'>
					{`${title && title}:`}
				</span>
				<span
					className={`pdp-selection-level-header__name-selected ${
						hasErrorLevel
							? 'pdp-selection-level-header__name-selected--error'
							: ''
					}`}
				>
					{hasErrorLevel ? (
						<>
							<img
								src={alertSelect}
								alt='alert when selection was not done'
							/>
							{hasErrorLevel}
						</>
					) : (
						<>{showTitle && name}</>
					)}
				</span>
			</div>

			{isOneLevel && (
				<Link
					to='/miracle-steps'
					className='pdp-selection-level-header__help'
				>
					Help find my shade
				</Link>
			)}
		</div>
	);
};

export default ProductSelectionLevelHeader;
