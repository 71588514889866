import { useStaticQuery, graphql } from 'gatsby';

const useFetchAllproducts = () => {
	const data = useStaticQuery(graphql`
		query {
			allShopifyProduct {
				nodes {
					id
					handle
					tags
					title
					storefrontId
					productType
					options {
						name
						values
						shopifyId
					}
					priceRangeV2 {
						maxVariantPrice {
							amount
							currencyCode
						}
						minVariantPrice {
							amount
							currencyCode
						}
					}
					metafields {
						key
						value
					}
					media {
						preview {
							image {
								altText
								originalSrc
							}
						}
						shopifyId
					}
					variants {
						availableForSale
						storefrontId
						shopifyId
						image {
							originalSrc
						}
						metafields {
							key
							value
						}
						price
						title
						product {
							title
							handle
							media {
								preview {
									image {
										src
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	return data?.allShopifyProduct?.nodes;
};

export default useFetchAllproducts;