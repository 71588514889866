import React from 'react';
import { navigate } from 'gatsby';

import AccountPage from '../../templates/account/AccountPage';

import { isBrowser } from '../../utils/helpers';

import './Account.scss';

const Account = () => {
	if (!isBrowser) return <>1</>;

	const accessTokenExists = window.sessionStorage.getItem('accessToken');

	if (accessTokenExists) {
		return <AccountPage />;
	}

	return (
		<div className='account-container'>{navigate('/account/login')}</div>
	);
};

export default Account;
