import React from 'react';

import DeliverySubscriptionSelect from '../DeliverySubscriptionSelect';
import NoSubscriptionsPlaceholder from './NoSubscriptionsPlaceholder';
import StepsDeliveryRadioIcon from './StepsDeliveryRadioIcon';
import StepsDeliveryRadioSingleIcon from './StepsDeliveryRadioSingleIcon';
import './StepsDeliveryRadio.scss';

const StepsDeliveryRadio = ({
	activeRadio,
	setActiveRadio,
	options,
	selectedValue,
	setSelectedValue,
	canSubscribe,
	showModal,
	modalShown,
	setModalShown,
}) => (
	<div className='miracle-delivery-radio miracle-delivery__radio'>
		<div className='miracle-delivery-radio__item'>
			{canSubscribe ? (
				<label
					className={`miracle-delivery-radio__item 
                        miracle-delivery-radio__item--auto`}
				>
					<input
						type='radio'
						name='purchase_type'
						value='auto-delivery'
						checked={activeRadio === 'auto-delivery'}
						onChange={(event) => setActiveRadio(event.target.value)}
						onClick={() => {
							if (!modalShown) {
								setModalShown(true);
								setTimeout(() => {
									showModal(true);
								}, [500]);
							}
						}}
						disabled={!canSubscribe}
					/>
					<div className='miracle-delivery-radio__content'>
						<div className='miracle-delivery-radio__header'>
							<StepsDeliveryRadioIcon />
							<div className='miracle-delivery-radio__info'>
								<p>Subscribe & Save 15% off</p>
								<div>
									<span className='miracle-delivery-radio__tick' />
									<span>Free shipping</span>
								</div>
								<div>
									<span className='miracle-delivery-radio__tick' />
									<span>Free gifts</span>
								</div>
								<div>
									<span className='miracle-delivery-radio__tick' />
									<span>Cancel anytime</span>
								</div>
							</div>
							<span className='miracle-delivery-radio__circle' />
						</div>
						<div
							className={`miracle-delivery-radio__select 
                            miracle-delivery-radio-select`}
						>
							<span className='miracle-delivery-radio-select__text'>
								Deliver my Miracle System every
							</span>
							<DeliverySubscriptionSelect
								{...{
									options,
									selectedValue,
									setSelectedValue,
								}}
							/>
						</div>
					</div>
				</label>
			) : (
				<NoSubscriptionsPlaceholder />
			)}
		</div>
		<div className='miracle-delivery-radio__item'>
			<label className='miracle-delivery-radio__item'>
				<input
					type='radio'
					name='purchase_type'
					value='One-Time Purchase'
					checked={activeRadio === 'One-Time Purchase'}
					onChange={(event) => setActiveRadio(event.target.value)}
					onClick={() => {
						if (!modalShown) {
							setModalShown(true);
							setTimeout(() => {
								showModal(true);
							}, [500]);
						}
					}}
				/>
				<div className='miracle-delivery-radio__content'>
					<div className='miracle-delivery-radio__header'>
						<StepsDeliveryRadioSingleIcon />
						<div className='miracle-delivery-radio__info'>
							<p>One-Time Purchase</p>
							<div>
								<span>Shipping fees apply</span>
							</div>
						</div>
						<span className='miracle-delivery-radio__circle' />
					</div>
				</div>
			</label>
		</div>
	</div>
);

export default StepsDeliveryRadio;
