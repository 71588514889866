import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MiracleColourNavigation = ({ slideTo, isShowGallery }) => (
	<div
		className={`miracle-colour-navigation ${
			isShowGallery && 'miracle-colour-navigation--hide'
		}`}
	>
		<div
			role='button'
			className={`miracle-colour-navigation__item 
            miracle-colour-navigation__item--left`}
			onClick={() => slideTo('lighter')}
		>
			<div
				className={`miracle-colour-navigation__icon 
            miracle-colour-navigation__icon--left`}
			>
				<LazyLoadImage
					alt='miracle-left'
					src='/images/miracle/steps/miracle-left.svg'
					effect='blur'
				/>
			</div>
			<span className='miracle-colour-navigation__text'>lighter</span>
		</div>
		<div
			className={`miracle-colour-navigation__item 
            miracle-colour-navigation__item--right`}
			onClick={() => slideTo('darker')}
		>
			<span className='miracle-colour-navigation__text'>darker</span>
			<div
				className={`miracle-colour-navigation__icon 
            miracle-colour-navigation__icon--right`}
			>
				<LazyLoadImage
					alt='miracle-right'
					src='/images/miracle/steps/miracle-right.svg'
					effect='blur'
				/>
			</div>
		</div>
	</div>
);

export default MiracleColourNavigation;
