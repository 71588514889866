import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import './style.scss';

const CombineWithCollections = () => (
	<section className='combine-block'>
		<div className='combine-block__content'>
			<h2 className='combine-block__title'>
				Combine with a Gloss or Root Touch-up for great looking hair
				throughout your colour cycle
			</h2>
			<div className='combine-block__items'>
				<div className='combine-block__item'>
					<div className='combine-block__img'>
						<Link to='/collections/care'>
							<StaticImage
								src='../../../../../static/images/home-page/image4.webp'
								alt='Hair Care Collection'
								objectFit='cover'
								placeholder='blurred'
								loading='lazy'
							/>
						</Link>
					</div>
					<Link
						to='/collections/care'
						className='combine-block__link'
					>
						Hair Care Collection
					</Link>
				</div>

				<div className='combine-block__item'>
					<div className='combine-block__img'>
						<Link to='/collections/gloss-and-glaze'>
							<StaticImage
								src='../../../../../static/images/home-page/image5.webp'
								alt='Gloss and Glaze Collection'
								placeholder='blurred'
								loading='lazy'
								objectFit='cover'
							/>
						</Link>
					</div>
					<Link
						to='/collections/gloss-and-glaze'
						className='combine-block__link'
					>
						Gloss & Glaze Collection
					</Link>
				</div>
				<div className='combine-block__item'>
					<div className='combine-block__img'>
						<Link to='/collections/root-touch-up'>
							<StaticImage
								src='../../../../../static/images/home-page/image6.webp'
								alt='Root touch up Collection'
								placeholder='blurred'
								loading='lazy'
								objectFit='cover'
							/>
						</Link>
					</div>
					<Link
						to='/collections/root-touch-up'
						className='combine-block__link'
					>
						Root touch up Collection
					</Link>
				</div>
			</div>
		</div>
	</section>
);

export default CombineWithCollections;
