import React from 'react';
import Klaviyo from '../../Klaviyo';
import '../BlogStyles.scss';

const KlaviyoEmail = () => (
	<section className='blog-container'>
		<div className='article'>
			<div className='custom-klaviyo blog-klaviyo'>
				<Klaviyo id={process.env.KLAVIYO_FOOTER_FORM_ID} />
			</div>
		</div>
	</section>
);
export default KlaviyoEmail;
