import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LocalImage from '../../../../../components/LocalImage';

const PreviewSecondLevel = ({ items }) => (
	<>
		{Array.isArray(items) &&
			items.map((item) => (
				<div key={item} className='pdp-selection-level-row-v2__img'>
					<LocalImage
						image={`miracle/steps/tones/pdp/${item}.png`}
						alt={item}
					/>
				</div>
			))}
	</>
);

export default PreviewSecondLevel;
