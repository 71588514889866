import React from 'react';

import { getMetafield } from '../../../utils/helpers';

const ModalLinks = ({ modal }) => {
	const text =
		modal.type === 'ingredients'
			? getMetafield(modal.product, 'ingredients')
			: modal.text;
	return (
		<div className='modal-link'>
			{modal.type === 'ingredients' && <h3>Ingredients</h3>}
			<div
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			/>
		</div>
	);
};

export default ModalLinks;
