import React from 'react';

const SalonTooltip = ({ classNames, allHours }) => {
	const data = JSON.parse(allHours ?? null);
	const workTime = data?.work_time;
	const holidayTime = data?.holiday_time;
	const workHeader = data?.work_header;
	const holidayHeader = data?.holiday_header;

	return (
		<div className={`salon-info-tooltip ${classNames && classNames}`}>
			<span className='salon-info-tooltip__name underline'>
				View our opening hours
			</span>
			<div className='salon-info-tooltip__content'>
				<h4>{workHeader}</h4>
				{workTime?.map((item) => (
					<p key={item.day}>
						<span>
							<strong>{item.day}:</strong>
						</span>
						<span> {item.hours}</span>
					</p>
				))}
				<br />
				{holidayHeader && <h4>{holidayHeader}</h4>}
				{holidayTime?.map((item) => (
					<p key={`holiday ${item.day}`}>
						<span>
							<strong>{item.day}:</strong>
						</span>
						<span> {item.hours}</span>
					</p>
				))}
			</div>
		</div>
	);
};

export default SalonTooltip;
