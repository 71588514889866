import React from 'react';
import cn from 'classnames';

import ProductSelectionLevelHeader from './ProductSelectionLevelHeader';
import LevelListItem from './LevelListItem';
import PreviewLevels from './PreviewSecondLevel';

const previewFourLevel = ['dark-smudge', 'brown-smudge', 'blonde-smudge'];

const ProductSelectionSmudge = ({
	isFilterActive,
	items,
	errorSelectLevel,
	setActiveFourLevel,
	activeThirdLevel,
	activeFourLevel,
	isMobile = false,
	activeStep = false,
	nextStep = false,
}) => {
	const title = activeFourLevel && activeFourLevel.title.split('/')[1];

	const element = items?.map((item) => (
		<LevelListItem
			isFilterActive={isFilterActive}
			activeStep={activeStep}
			nextStep={nextStep}
			isFourthLevel
			key={item.storefrontId}
			item={item}
			setActiveLevel={setActiveFourLevel}
			activeLevel={activeFourLevel}
		/>
	));

	const levelErrorName = () => {
		if (title) return false;
		if (
			activeThirdLevel &&
			!activeFourLevel &&
			errorSelectLevel?.errorLevelFour
		)
			return errorSelectLevel.errorLevelFour;
	};

	return (
		<div
			className={cn('pdp-selection-level', {
				'pdp-selection-level--disabled': !activeThirdLevel,
			})}
		>
			{!isMobile && (
				<ProductSelectionLevelHeader
					title='Select colour smudge'
					name={title}
					errorLevel={levelErrorName}
					showTitle
				/>
			)}
			<div className='pdp-selection-level__row pdp-selection-level-row'>
				{!activeThirdLevel ? (
					<PreviewLevels items={previewFourLevel} path="miracle/steps/smudge/pdp/"/>
				) : (
					element
				)}
			</div>
		</div>
	);
};

export default ProductSelectionSmudge;
