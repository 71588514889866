import React from 'react';
import { Link } from 'gatsby';
import LocalImage from '../../../components/LocalImage';

import { useWindowWidth } from '../../../utils/hooks';
import { getMetafield, getMetafieldParse } from '../../../utils/helpers';

import './ProductTestimonial.scss';

const ProductTestimonial = ({ product }) => {
	const windowWidth = useWindowWidth();
	const testimonialTitle = getMetafield(
		product,
		'testimonials_section_title'
	);
	const testimonialDescription = getMetafield(
		product,
		'testimonials_section_description'
	);
	const testimonialImage = getMetafield(
		product,
		'testimonials_section_image'
	);
	const testimonialAboutMiracle = getMetafieldParse(
		product,
		'testimonials_section_about_miracle'
	);
	return (
		<div className='container'>
			<div className='pdp-testimonial'>
				<h2 className='pdp-testimonial__title'>{testimonialTitle}</h2>

				{testimonialDescription && (
					<p className='pdp-testimonial__desc'>
						{testimonialDescription}
					</p>
				)}

				<div className='pdp-testimonial__content'>
					<div className='pdp-testimonial__content__image'>
						<LocalImage
							image={testimonialImage}
							alt='Testimonial about the Miracle System'
						/>
					</div>
					<div className='pdp-testimonial__content__text'>
						{testimonialAboutMiracle?.data.map((item) => (
							<div
								key={item.title}
								className='pdp-testimonial__content__text__item'
							>
								<div className='pdp-testimonial__content__text__list' />
								<p>
									<b>{item.title}</b> {item.description}
								</p>
							</div>
						))}
						<Link
							to='/miracle-steps'
							className='pdp-testimonial__content__text__link'
						>
							Find my shade
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductTestimonial;
