import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../../context/store-context';
import CartUpsell from '../CartUpsell';
import CartItemContent from './CartItemContent';
import UpsellBundlePreview from './UpsellBundlePreview';
import UpsellAddonPreview from './UpsellAddonPreview';
import UpsellSubscriptionPreview from './UpsellSubscriptionPreview';
import { decodeBase64, getMetafield } from '../../../utils/helpers';

const CartItem = ({
	item,
	cartLimit,
	lineItems,
	selectedValue,
	setSelectedValue,
}) => {
	const {
		addVariantToCart,
		removeLineItem,
		updateLineItem,
		cart,
		closeCart,
		setModal,
		showModal,
		replaceCartItem,
		allProducts,
		setAddShadeShotFromCart,
	} = useContext(StoreContext);
	const attributes = item.node.attributes,
		currentProduct = allProducts.find((product) =>
			product.variants.find(
				(variant) =>
					decodeBase64(variant.storefrontId, false) ===
					item.node.merchandise.id
			)
		);
	const currentVariant = currentProduct.variants.find(
		(variant) =>
			decodeBase64(variant.storefrontId, false) ===
			item.node.merchandise.id
	);
	const canSubscribe =
		currentProduct && getMetafield(currentProduct, 'subscription_on_pdp');
	const upsellBundleResult =
		(currentProduct && getMetafield(currentProduct, 'upsell_result')) ||
		(currentVariant && getMetafield(currentVariant, 'upsell_result'));
	const upsellBundleViewProduct =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_bundle_view_product')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_bundle_view_product'));
	const upsellBundleViewDescription =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_bundle_view_description')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_bundle_view_description'));
	const upsellBundleResultVariant =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_result_variant')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_result_variant'));
	const upsellBundleProductPrice =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_bundle_price')) ||
		(currentVariant && getMetafield(currentVariant, 'upsell_bundle_price'));
	const upsellBundleOnlySubscription =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_bundle_only_sub')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_bundle_only_sub'));
	const upsellAddonProductHandle =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_addon_product')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_addon_product'));
	const upsellAddonProductDescription =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_addon_description')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_addon_description'));
	const upsellAddonProductPrice =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_addon_price')) ||
		(currentVariant && getMetafield(currentVariant, 'upsell_addon_price'));
	const upsellAddonProductExtraInfo =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_addon_extra_info')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_addon_extra_info'));
	const isAttr = Object.keys(attributes).length > 0,
		isUpsellCart =
			isAttr && attributes.some((attr) => attr['key'] === 'isUpsellCart');
	const sellingPlanId =
		item.node.sellingPlanAllocation &&
		item.node.sellingPlanAllocation.sellingPlan.id;
	const frequencyText =
		sellingPlanId && item.node.sellingPlanAllocation.sellingPlan.name;
	const hasRecurringPrice =
		sellingPlanId &&
		item.node.sellingPlanAllocation.priceAdjustments.length &&
		item.node.sellingPlanAllocation.priceAdjustments[0].perDeliveryPrice
			.amount;
	const recurringPrice =
		hasRecurringPrice &&
		!Number.isNaN(hasRecurringPrice) &&
		Number(hasRecurringPrice);
	const discountPrice =
		item.node.discountAllocations.length &&
		(+item.node.merchandise.priceV2.amount * item.node.quantity -
			+item.node.discountAllocations[0].discountedAmount.amount) /
			item.node.quantity;		
	const withoutDiscountPrice = +item.node.merchandise.priceV2.amount;
	const compareAtPrice = currentVariant.compareAtPrice;
	const isSubscriptionUpsell = item.node.sellingPlanAllocation !== null;
	const [upsellVariantId, setUpsellVariantId] = useState(null);
	const [upsellBaseColourId, setUpsellBaseColourId] = useState(null);
	const [upsellShotID, setUpsellShotID] = useState(null);
	const [upsellVariant, setUpsellVariant] = useState(null);
	const [upsellBaseColour, setUpsellBaseColour] = useState(null);
	const [upsellShot, setUpsellShot] = useState(null);
	const [options, setOptions] = useState([]);
	const [prices, setPrices] = useState({});
	const miracleSystem =
		allProducts &&
		allProducts.find((item) => item.handle === 'miracle-system');
	const variantsArray = [].concat(
		...(allProducts &&
			allProducts.length &&
			allProducts.map((item) => item.variants))
	);
	const handleRemove = () => {
		removeLineItem(cart.id, item, item.node.id, currentProduct.title);
	};
	const containsUpsell = () =>
		lineItems.filter(
			(item) =>
				item.node.merchandise.product.handle ===
				upsellAddonProductHandle
		).length > 0;
	const handleUpdate = (qty, isRemove = false) => {
		qty > 0 && updateLineItem(cart.id, item, qty, isRemove);
	};

	useEffect(() => {
		if (isUpsellCart) {
			setUpsellBaseColourId(
				isAttr &&
					attributes.find((attr) => attr['key'] === 'baseColourId')[
						'value'
					]
			);
			setUpsellVariantId(
				isAttr &&
					attributes.find((attr) => attr['key'] === 'variantId')[
						'value'
					]
			);
			setUpsellShotID(
				isAttr &&
					attributes.find((attr) => attr['key'] === 'shotId')['value']
			);
		}
	}, [isUpsellCart, attributes]);

	useEffect(() => {
		upsellShotID &&
			allProducts &&
			allProducts.length &&
			setUpsellShot(
				variantsArray.find((item) => item.storefrontId === upsellShotID)
			);
	}, [upsellShotID, variantsArray]);

	useEffect(() => {
		upsellBaseColourId &&
			allProducts &&
			allProducts.length &&
			setUpsellBaseColour(
				variantsArray.find(
					(item) => item.storefrontId === upsellBaseColourId
				)
			);
	}, [upsellBaseColourId, variantsArray]);

	useEffect(() => {
		upsellVariantId &&
			allProducts &&
			allProducts.length &&
			setUpsellVariant(
				variantsArray.find(
					(item) => item.storefrontId === upsellVariantId
				)
			);
	}, [upsellVariantId, variantsArray]);

	const openModal = () => {
		setAddShadeShotFromCart(true);
		const data = JSON.parse(localStorage.getItem('modalPermanentData'));
		data.productsList = allProducts;
		data.upsellBaseColour = upsellBaseColour;
		setModal(null, 'permanent-selection-from-cart', null, data);
		showModal(true);
	};

	const showOnlySubscription =
		!isSubscriptionUpsell && upsellBundleOnlySubscription;

	const isShadeShotProduct = item.node.merchandise.product.title
		.toLowerCase()
		.includes('shade shot');
	return (
		<>
			{!isUpsellCart ? (
				<>
					<CartItemContent
						key={`${item.id}cart-content`}
						{...{
							isUpsellCart,
							sellingPlanId,
							frequencyText,
							cartLimit,
							handleUpdate,
							closeCart,
							upsellBaseColour,
							handleRemove,
							recurringPrice,
							discountPrice,
							withoutDiscountPrice,
							compareAtPrice,
							item,
							cart,
							currentProduct,
						}}
					/>
					{upsellBundleResult &&
						upsellBundleViewProduct &&
						allProducts &&
						!showOnlySubscription && (
							<UpsellBundlePreview
								upsellBundleResultHandle={upsellBundleResult}
								upsellBundleViewProductHandle={
									upsellBundleViewProduct
								}
								upsellBundleResultVariantTitle={
									upsellBundleResultVariant
								}
								upsellBundleViewDescription={
									upsellBundleViewDescription
								}
								upsellBundleProductPrice={
									upsellBundleProductPrice
								}
								frequencyText={frequencyText}
								allProducts={allProducts}
								item={item}
								cart={cart}
								replaceCartItem={replaceCartItem}
								closeCart={closeCart}
							/>
						)}
					{sellingPlanId &&
						!containsUpsell() &&
						upsellAddonProductHandle &&
						allProducts && (
							<UpsellAddonPreview
								upsellAddonProductHandle={
									upsellAddonProductHandle
								}
								upsellAddonProductDescription={
									upsellAddonProductDescription
								}
								upsellAddonProductPrice={
									upsellAddonProductPrice
								}
								upsellAddonProductExtraInfo={
									upsellAddonProductExtraInfo
								}
								frequencyText={frequencyText}
								allProducts={allProducts}
								item={item}
								cart={cart}
								addVariantToCart={addVariantToCart}
								closeCart={closeCart}
							/>
						)}
					{!sellingPlanId && canSubscribe && (
						<UpsellSubscriptionPreview
							currentProduct={currentProduct}
							currentVariant={currentVariant}
							allProducts={allProducts}
							item={item}
							cart={cart}
							replaceCartItem={replaceCartItem}
							closeCart={closeCart}
						/>
					)}
				</>
			) : (
				<div
					className={`cart-upsell-container ${
						selectedValue && 'cart-upsell-container--hide'
					} `}
				>
					<CartItemContent
						{...{
							isUpsellCart,
							sellingPlanId,
							frequencyText,
							cartLimit,
							handleUpdate,
							closeCart,
							upsellBaseColour,
							handleRemove,
							recurringPrice,
							discountPrice,
							withoutDiscountPrice,
							item,
							cart,
						}}
					/>
					{isShadeShotProduct ? (
						<CartUpsell
							item={item}
							cart={cart}
							removeLineItem={removeLineItem}
							addVariantToCart={addVariantToCart}
							setUpsellShotID={setUpsellShotID}
							upsellShotID={upsellShotID}
							upsellShot={upsellShot}
							upsellVariant={upsellVariant}
							upsellBaseColour={upsellBaseColour}
							lineItems={lineItems}
							openModal={openModal}
							options={options}
							prices={prices}
							miracleSystem={miracleSystem}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
							replaceCartItem={replaceCartItem}
							sellingPlanId={sellingPlanId}
							closeCart={closeCart}
						/>
					) : (
						<UpsellBundlePreview
							upsellBundleResultHandle={upsellBundleResult}
							upsellBundleViewProductHandle={
								upsellBundleViewProduct
							}
							upsellBundleResultVariantTitle={
								upsellBundleResultVariant
							}
							upsellBundleViewDescription={
								upsellBundleViewDescription
							}
							upsellBundleProductPrice={upsellBundleProductPrice}
							frequencyText={frequencyText}
							allProducts={allProducts}
							item={item}
							cart={cart}
							replaceCartItem={replaceCartItem}
							closeCart={closeCart}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default CartItem;
