// External
import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import './style.scss';

const SalonQualityColour = () => {
	const items = [
		'Easy to apply at home',
		'100% Grey coverage',
		'Ammonia free and gentle',
		'Personalise your colour with a Shade Shot',
	];

	return (
		<section id='salon-quality-colour-block'>
			<div className='salon-block'>
				<div className='salon-block__img salon-block__contained-img'>
					<LazyLoadImage
						src='images/home-page/image1.webp'
						alt='Salon Quality colour'
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				</div>
				<div className='salon-block__container container'>
					<div className='salon-block__content'>
						<div className='salon-block__header'>
							<h3 className='salon-block__title'>
								Salon Quality Colour and Condition
							</h3>
							<p className='salon-block__subtitle'>
								World-leading colourist Josh Wood’s Miracle
								System brings salon-quality colour and condition
								to your home hair routine, for the first time.
								Our multi award-winning innovation prioritises
								the health of your hair, just as much as the
								premium colour, for the at home colour solution
								that we’ve all been waiting for.
							</p>
						</div>
						<ul className='salon-block__list'>
							{Array.isArray(items) &&
								items.map((item) => (
									<li
										key={item}
										className='salon-block__item salon-block__item_bold'
									>
										{item}
									</li>
								))}
						</ul>
						<Link to='/miracle-steps' className='salon-block__btn'>
							Find My Shade
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SalonQualityColour;
