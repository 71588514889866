import React from 'react';

import LocalImage from '../../LocalImage';

const ModalNotColourItem = ({ handle, title }) => {
	const img = `miracle/steps/colours/pdp/${title}.png`;
	return (
		<a href={`/products/${handle}`} className='modal-not-colour-item'>
			<div className='modal-not-colour-item__img'>
				<LocalImage image={img} alt={title} />
			</div>
			<p className='modal-not-colour-item__name'>{title}</p>
		</a>
	);
};

export default ModalNotColourItem;
