import React, { useContext, useEffect } from 'react';

import { useScrollBlock } from '../../utils/hooks';

import { StoreContext } from '../../context/store-context';

const Overlay = () => {
	const [blockScroll, allowScroll] = useScrollBlock(),
		{
			activeOverlay,
			activeCartOverlay,
			showModal,
			closeShotModal,
			closeCart,
		} = useContext(StoreContext),
		handleClick = () => {
			closeShotModal();
			showModal(false);
		};

	useEffect(() => {
		activeOverlay ? blockScroll() : allowScroll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOverlay]);

	useEffect(() => {
		activeCartOverlay ? blockScroll() : allowScroll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCartOverlay]);
	return (
		<>
			<div
				className={`overlay ${activeOverlay && 'overlay--active'}`}
				role='button'
				aria-label='overlay'
				onClick={() => handleClick()}
				onKeyDown={() => handleClick()}
				tabIndex='0'
			/>
			<div
				className={`overlay ${
					activeCartOverlay && 'overlay--active overlay--cart-active'
				}`}
				role='button'
				aria-label='overlay'
				onClick={closeCart}
				onKeyDown={closeCart}
				tabIndex='0'
			/>
		</>
	);
};

export default Overlay;
