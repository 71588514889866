import React from 'react';

import faqs from '../../../constants/auto-delivery';

import './ModalAutoDelivery.scss';

const ModalAutoDelivery = ({
	modal: {
		product: { type },
	},
}) => (
	<div className='modal-delivery-faq'>
		{type === 'tooltip' ? (
			(faqs || []).map((faq) => (
				<div className='modal-delivery-faq__item' key={faq.title}>
					<h4>
						<strong>{faq.title}</strong>
					</h4>
					<span>{faq.text}</span>
				</div>
			))
		) : (
			<div className='modal-delivery-faq__shot'>
				When added to your Permanent Colour Kit, Shade Shot unlocks the
				natural highs and lows in your own hair colour, giving you a
				completely personalised, tonal result. No 'Helmet Head' results
				in sight!
			</div>
		)}
	</div>
);

export default ModalAutoDelivery;
