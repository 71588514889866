import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import SEO from '../../components/SEO';

import './thankyou.scss';

const headerText = "We can't wait to help";

export default ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<div className='page-email'>
			<div className='container page-width'>
				<div
					className={`page-email__bg page-email__bg--brunette
                     page-email__bg--brunette--mobile`}
				>
					<LazyLoadImage
						src='/images/email-brunette-mobile.jpg'
						alt='mobile email brunette'
						effect='blur'
					/>
				</div>
				<div
					className={`page-email__bg page-email__bg--brunette
                     page-email__bg--brunette--desktop`}
				>
					<LazyLoadImage
						src='/images/email-brunette-desktop.jpg'
						alt='desktop email brunette'
						effect='blur'
					/>
				</div>
				<div className='page-email__content'>
					<h3 className='page-email__title'>{headerText}</h3>
					<p className='page-email__text'>
						Thank you for submitting your preferences. We’ll be in
						touch soon with personalised recommendations and expert
						advice.
					</p>
					<Link
						to='/collections/brown-hair-colour'
						className='page-email__link'
					>
						Explore more
					</Link>
				</div>
			</div>
		</div>
	</>
);
