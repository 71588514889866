import React, { useState } from 'react';

import ModalProduct from '../../../../components/ModalProduct';
import ProductItem from './ProductItem';

import './ShopTheLook.scss';

const ShopTheLook = ({ shopLookProductsHandles }) => {
	const [productData, setProductData] = useState(null);

	return (
		<div className='shop-look'>
			{shopLookProductsHandles &&
				shopLookProductsHandles.map((item, i) => (
					/* eslint-disable-next-line */
					<div className='shop-look__col' key={i}>
						<ProductItem
							setProductData={setProductData}
							handle={item}
						/>
					</div>
				))}
			{productData && <ModalProduct productData={productData} />}
		</div>
	);
};

export default ShopTheLook;
