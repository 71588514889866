// External
import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import './style.scss';

const TheMiracleSystem = () => (
	<section id='salon-quality-colour-block'>
		<div className='salon-block salon-block__reverse'>
			<div className='salon-block__container container'>
				<div className='salon-block__content'>
					<div className='salon-block__header'>
						<h3 className='salon-block__title'>
							The Miracle System
						</h3>
						<p className='salon-block__subtitle'>
							Professional, natural colour that builds stronger,
							shinier, more protected hair with a premium colour
							finish is at your fingertips. Let’s bring out the
							colourist in you.
						</p>
						<p className='salon-block__subtitle'>
							When mixed with your hair colour, the Miracle Shot
							delivers<strong> hair health </strong>from the
							inside out to
							<strong> reduce breakage by 62% </strong>
							and reinforce hair to be
							<strong> 2.7x stronger.</strong>
						</p>
					</div>
					<Link to='/miracle-steps' className='salon-block__btn'>
						Find My Shade
					</Link>
				</div>
			</div>
			<div className='salon-block__img'>
				<LazyLoadImage
					src='images/home-page/image1a.webp'
					alt='Salon Quality colour'
					effect='blur'
					wrapperClassName='lazyLoad-wrapper-img'
				/>
			</div>
		</div>
	</section>
);

export default TheMiracleSystem;
