// BLOG
export const BLOG_SECTION_BREADCRUMB = 'Blog';

// PRODUCTS
export const PRODUCT_SECTION_BREADCUMB = 'Products';
export const HAIR_SECTION_CORCERNS = 'Hair concerns';

export const PRODUCTS_BREADCRUMBS = {
	'/collections/best-selling-products': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Best Selling Products',
	},
	'/collections/colour-correctors': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Colour Correctors',
	},
	'/collections/hair-care': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Care',
	},
	'/collections/permanent-hair-colour-reduces-breakage-miracle-range': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'The Miracle System',
	},
	'/collections/hair-colouring-accessories': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Accessories and Kits',
	},
	'/collections/gift-sets': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Gift sets',
	},
	'/collections/hair-care': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Care',
	},
	'/collections/permanent-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Colour (Permanent)',
	},
	'/collections/hair-gloss-and-glaze': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Colour (Gloss)',
	},
	'/collections/root-touch-up': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Conceal',
	},
	'/collections/cover-grey-hair': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Cover grey',
	},
	'/collections/fix-tone-brassy-hair': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Brassy colour',
	},
	'/collections/keep-balayage-from-fading': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Balayage fade',
	},
	'/collections/ammonia-free-hair-colour': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Ammonia free',
	},
	'/collections/refresh-your-hair-colour': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Refresh colour',
	},
	'/collections/hair-colour-sensitive-scalp': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Sensiteve Scalp',
	},
	'/collections/stop-hair-breakage': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Hair breakage',
	},
};

// Experts
export const EXPERTS_SECTION_BREADCRUMB = 'Salon';

export const EXPERTS_BREADCRUMBS = {
	'/pages/meet-the-experts': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Experts'
	},
	'/pages/josh-wood-colour-hair-salon-london': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Atelier'
	},
	'/josh-wood-colour-hair-salon-claridges': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: "Claridge's"
	},
	'/pages/hair-salon-price-list': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Services'
	},
};