/* eslint-disable */
import React, { useState } from 'react';
import LocalImage from '../../LocalImage';

const CurrentChoice = ({ product }) => {
	const [activeItem, setActiveItem] = useState({
		image:
			`pdp/base/base-${product?.title
				.split(' ')[2]
				.replace('.', '-')}.png` || '',
		title: product?.title || '',
	});

	return (
		<div className='modal-permanent-mobile__current-choice animate modal-permanent-mobile-current-choice'>
			{activeItem.image && (
				<div className='modal-permanent-current-choice__image'>
					<LocalImage
						alt={activeItem.title}
						image={activeItem.image}
					/>
				</div>
			)}
			<div className='modal-permanent-current-choice__text'>
				{activeItem.title && (
					<span>
						{activeItem.title.replace('Permanent Colour ', '')}
					</span>
				)}
			</div>
		</div>
	);
};

export default CurrentChoice;
