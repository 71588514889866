import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '../CloseIcon';
import Video from '../Video';
import ShotModalProduct from './ShotModalProduct';
import Button from '../Button';
import ShotModalSelect from './ShotModalSelect';

import { useWindowWidth } from '../../utils/hooks';
import { StoreContext } from '../../context/store-context';

import './ShotModal.scss';

const ShotModal = ({ products, closeShotModal, videoId }) => {
	const windowWidth = useWindowWidth(),
		{ bundleProducts } = useContext(StoreContext),
		[active, setActive] = useState(null),
		[variantId, setVariantId] = useState(null),
		[disabled, setDisabled] = useState(true),
		[title, setTitle] = useState(null);
	// eslint-disable-next-line  no-unused-vars

	useEffect(() => {
		setTitle(
			bundleProducts
				? bundleProducts.title.includes('+')
					? bundleProducts.title.trim().split('+')[0]
					: bundleProducts.title.trim().split('-')[0]
				: ''
		);
		// setButtonText(buttonPlaceHolder)
		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='shot-modal shot-modal--active'>
			<CloseIcon
				handleClose={closeShotModal}
				classname='shot-modal__close'
			/>
			<div className='shot-modal__container'>
				<div className='shot-modal__row'>
					<div className='shot-modal__info'>
						<h2 className='shot-modal__title'>
							Personalise your choice
						</h2>
						{windowWidth > 749 && (
							<p
								className={`shot-modal__text 
                                shot-modal__text--desktop`}
							>
								Customise your hair colour finish and add a rich
								mix of personalised tones
							</p>
						)}
					</div>
					<Video
						classname='shot-modal__video'
						videoTitle='Personalise your choice'
						videoId={videoId}
					/>
				</div>
				{windowWidth < 750 && (
					<p className='shot-modal__text shot-modal__text--mobile'>
						Customise your hair colour finish and add a rich mix of
						personalised tones
					</p>
				)}
				{windowWidth > 749 && (
					<div className='shot-modal__products'>
						{(products || [])
							.filter(
								(product) =>
									!product.title
										.toLowerCase()
										.includes('miracle')
							)
							.map((product) => (
								<ShotModalProduct
									setActive={setActive}
									active={active}
									key={product.title}
									product={product}
									setDisabled={setDisabled}
									setVariantId={setVariantId}
								/>
							))}
					</div>
				)}
			</div>
			{windowWidth < 750 && (
				<ShotModalSelect
					title={title}
					setDisabled={setDisabled}
					setVariantId={setVariantId}
					products={products}
				/>
			)}
			<Button
				isDark
				disabled={disabled}
				product={bundleProducts}
				buttonText={`add ${title} + Shade Shot`}
				variantId={variantId}
				availableForSale
				classname='shot-modal__button'
			/>
		</div>
	);
};

export default ShotModal;
