import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getMetafield, getMetafieldParse } from '../../../utils/helpers';
import LocalVideo from '../../../components/LocalVideo';

import './ProductSteps.scss';

const ProductSteps = ({ product }) => {
	const generalTitleSteps = getMetafield(product, 'step_general_title');
	const imagesSteps = getMetafieldParse(product, 'step_image');
	const titlesSteps = getMetafieldParse(product, 'step_title');
	const descriptionsSteps = getMetafieldParse(product, 'step_description');
	const buttonsTextSteps = getMetafieldParse(product, 'step_button_text');
	const buttonsLinksSteps = getMetafieldParse(product, 'step_link_button');

	const steps = imagesSteps.map((_, index) => ({
		id: index,
		images: imagesSteps[index],
		title: titlesSteps[index],
		description: descriptionsSteps[index],
		buttonText: buttonsTextSteps[index],
		buttonLink: buttonsLinksSteps[index],
	}));

	return (
		<div className='pdp-steps container'>
			<h3 className='pdp-steps__title'>{generalTitleSteps}</h3>

			<div className='pdp-steps__items'>
				{steps.map((step, index) => (
					<div key={step.id} className='pdp-steps__item'>
						<div
							className={`${
								step.images.length > 1
									? 'pdp-steps__item__images'
									: 'pdp-steps__item__image'
							}`}
						>
							{step.images.map((image, index) => (
								<Fragment key={image.id}>
									{image.media_type === 'image' ? (
										<LazyLoadImage
											src={image.original_src}
											alt={step.title}
											effect='blur'
										/>
									) : (
										<LocalVideo
											source={image.original_src}
											name='how-it-works-video'
											className='how-to-use__top'
										/>
									)}
								</Fragment>
							))}
						</div>
						<div className='pdp-steps__item__text'>
							<h5>STEP {index + 1}</h5>
							<h4>{step.title}</h4>
							<p>
								{step.description &&
									step.description
										.split('\r\n\r\n')
										.map((desc) => (
											<>
												<p>{desc}</p>
												<br />
											</>
										))}
							</p>
							{step.buttonText !== '' && (
								<Link
									to={step.buttonLink}
									className='pdp-steps__item__text__link'
								>
									{step.buttonText}
								</Link>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ProductSteps;
