export const sortProductByTitleAZ = (allProducts) => {
	if (!Array.isArray(allProducts) || allProducts.length <= 1)
		return allProducts;

	const allProductsSorted = allProducts.slice();
	allProductsSorted.sort((firstItem, secondItem) =>
		firstItem.title?.localeCompare(secondItem.title)
	);
	return allProductsSorted;
};

export const sortProductByTitleZA = (allProducts) => {
	if (!Array.isArray(allProducts) || allProducts.length <= 1)
		return allProducts;

	const allProductsSorted = allProducts.slice();
	allProductsSorted.sort((firstItem, secondItem) =>
		secondItem.title?.localeCompare(firstItem.title)
	);
	return allProductsSorted;
};

export const sortProductByLowerPrice = (allProducts) => {
	if (!Array.isArray(allProducts) || allProducts.length <= 1)
		return allProducts;

	const allProductsSorted = allProducts.slice();
	allProductsSorted.sort((firstItem, secondItem) => {
		if (
			firstItem?.priceRangeV2?.minVariantPrice?.amount &&
			secondItem?.priceRangeV2?.minVariantPrice?.amount
		) {
			return firstItem.priceRangeV2.minVariantPrice.amount -
				secondItem.priceRangeV2.minVariantPrice.amount;
		}
	});
	return allProductsSorted;
};

export const sortProductByHigherPrice = (allProducts) => {
	if (!Array.isArray(allProducts) || allProducts.length <= 1)
		return allProducts;

	const allProductsSorted = allProducts.slice();
	allProductsSorted.sort((firstItem, secondItem) => {
		if (
			firstItem?.priceRangeV2?.minVariantPrice?.amount &&
			secondItem?.priceRangeV2?.minVariantPrice?.amount
		) {
			return secondItem.priceRangeV2.minVariantPrice.amount -
				firstItem.priceRangeV2.minVariantPrice.amount;
		}
	});
	return allProductsSorted;
};
