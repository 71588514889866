import React, { useContext, useEffect, useState } from 'react';

import MiracleDeliveryInfo from './MiracleDeliveryInfo';
import MiracleDeliveryRow from './MiracleDeliveryRow';
import StepsDeliveryRadio from '../../../components/StepsDeliveryRadio';

import { StoreContext } from '../../../context/store-context';
import {
	getSellingPlanGroupOptions,
	getSellingPlanGroupDefaultValue,
	getDiscountPrice,
} from '../../../utils/helpers';

import './MiracleDelivery.scss';

const MiracleDelivery = ({ product, shotItem, sellingPlanGroup, story }) => {
	const { setModal, showModal, addVariantToCart, subscriptionsEnabled } =
			useContext(StoreContext),
		[groupFound, setGroupFound] = useState(
			Object.keys(sellingPlanGroup).length
		),
		[canSubscribe, setCanSubscribe] = useState(
			groupFound && subscriptionsEnabled
		),
		[options, setOptions] = useState(
			canSubscribe
				? getSellingPlanGroupOptions(
						sellingPlanGroup.sellingPlans.edges
				  )
				: []
		),
		[prices, setPrices] = useState({}),
		[selectedValue, setSelectedValue] = useState(
			canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
		),
		[activeRadio, setActiveRadio] = useState(
			canSubscribe ? 'auto-delivery' : 'One-Time Purchase'
		),
		[modalShown, setModalShown] = useState(false);

	useEffect(() => {
		if (canSubscribe) {
			const { title, text, subtext, image } = story.content.body[0];
			const info = { title, text, subtext, image };
			setModal(product, 'steps-first', '', info);
			setTimeout(() => {
				setModalShown((modalShown) => {
					if (!modalShown) {
						showModal(true);
						return true;
					}
					return true;
				});
			}, 3000);
		}
	}, []);

	const handleAddToCart = () => {
			const sellingPlanId =
				canSubscribe && activeRadio === 'auto-delivery'
					? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
					: null;
			addVariantToCart(
				shotItem.storefrontId,
				1,
				[],
				true,
				product,
				sellingPlanId
			);
		},
		handleShowModal = () => {
			setModal({ type: 'tooltip' }, 'modal-steps');
			showModal(true);
		};

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[selectedValue],
				discount = parseInt(
					plan.node.pricingPolicies[0].adjustmentValue.percentage
				),
				price = parseInt(product.variants[0].price);
			const discountPrice = getDiscountPrice(price, discount);
			setPrices({
				currentPrice: `${price}.00`,
				discountPrice,
			});
		}
	}, []);

	useEffect(() => {
		if (canSubscribe)
			setSelectedValue(getSellingPlanGroupDefaultValue(options));
	}, [options]);

	return (
		<div className='miracle-delivery'>
			<div className='container miracle-delivery__container'>
				<MiracleDeliveryInfo />
				<MiracleDeliveryRow {...{ activeRadio, prices }} />
				<StepsDeliveryRadio
					{...{
						options,
						setActiveRadio,
						activeRadio,
						center: true,
						selectedValue,
						setSelectedValue,
						canSubscribe,
						showModal,
						modalShown,
						setModalShown,
					}}
				/>

				<div
					className='miracle-delivery-faq miracle-step-accordion'
					onClick={handleShowModal}
				>
					<div
						className={`miracle-delivery-faq__header 
                        miracle-step-accordion__header`}
					>
						<h3
							className={`miracle-delivery-faq__title 
                        miracle-step-accordion__title`}
						>
							Subscribe & Save FAQ
						</h3>
					</div>
				</div>
				<div className='miracle-delivery-buttons'>
					<button
						onClick={() => {
							setModalShown(true);
							handleAddToCart();
						}}
						className='miracle-delivery-button'
						id={`${
							activeRadio === 'auto-delivery'
								? 'miracle-system-auto-delivery-cta'
								: 'miracle-system-one-time-cta'
						}`}
					>
						<span className='miracle-delivery-button__text'>
							Add Miracle System to Bag
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default MiracleDelivery;
