import React from 'react';
import { Link } from 'gatsby';

const ConsultationItem = ({ title, text, linkText, linkUrl, bgColor }) => (
	<div
		className='consultation-item'
		style={bgColor && { backgroundColor: bgColor }}
	>
		{title && <h4 className='consultation-item__title'>{title}</h4>}
		{text && <p className='consultation-item__text'>{text}</p>}
		{linkText && linkUrl && (
			<Link
				to={linkUrl}
				className={`consultation-item__link 
                button button--light button--hover-pink`}
			>
				{linkText}
			</Link>
		)}
	</div>
);

export default ConsultationItem;
