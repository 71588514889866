import React from 'react';

import SalonPriceListItemPrices from './SalonPriceListItemPrices';

const SalonPriceListItem = ({ title, info, prices }) => (
	<div className='price-list-section__column price-list--item'>
		<h4 className='price-list--item__title'>{title}</h4>
		{info ? <p className='price-list--item__info'>{info}</p> : null}
		{prices && <SalonPriceListItemPrices prices={prices} />}
	</div>
);
export default SalonPriceListItem;
