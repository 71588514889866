import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import AlternativeLooksItem from './AlternativeLooksItem';

import './AlternativeLooks.scss';

const AlternativeLooks = ({ alternativeLooks }) => {
	const sliderSettings = {
		slidesPerView: 5,
		freeMode: true,
		simulateTouch: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 5,
			},
		},
	};

	return (
		<div className='pdp-alternative-look'>
			<div className='pdp-alternative-look__container container'>
				<h2 className='pdp-alternative-look__title'>
					Alternative looks
				</h2>
				<div className='pdp-alternative-look__products'>
					<Swiper {...sliderSettings}>
						{alternativeLooks.map((item, i) => (
							/* eslint-disable-next-line */
							<SwiperSlide key={i}>
								<AlternativeLooksItem handle={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default AlternativeLooks;
