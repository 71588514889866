import { useEffect } from 'react';
import { klaviyoLoad } from '../utils/helpers';

const useLoadKlaviyoScript = (isBrowser) => {

	const klaviyoGlobal = isBrowser && window?.klaviyo;

	useEffect(() => {
		if (klaviyoGlobal) {
			window.klaviyo.init({
				account: `${process.env.KLAVIYO_ID}`,
				platform: 'shopify',
			});
		}
	}, [isBrowser, klaviyoGlobal]);

	useEffect(() => {
		klaviyoLoad();
	}, []);

};

export default useLoadKlaviyoScript;
