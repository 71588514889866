import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
	AdvancedMarker,
	AdvancedMarkerAnchorPoint,
	InfoWindow,
	useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

import './styles.scss';

const MarkerWithInfowindow = ({ lat, lng, title, address, store_name }) => {
	const [infowindowOpen, setInfowindowOpen] = useState(false);
	const [markerRef, marker] = useAdvancedMarkerRef();

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				onClick={() => setInfowindowOpen(true)}
				position={{ lat, lng }}
				title={title}
				anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
			>
				<img src='/images/mapPin.png' alt='Pin' />
			</AdvancedMarker>
			{infowindowOpen && (
				<InfoWindow
					anchor={marker}
					maxWidth={300}
					onCloseClick={() => setInfowindowOpen(false)}
					headerContent={store_name}
				>
					<div className='info-window'>
						<p className='info-window__title'>{store_name}</p>
						<p>{address}</p>
						<Link
							className='info-window__button'
							to={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							Directions
						</Link>
					</div>
				</InfoWindow>
			)}
		</>
	);
};

export default MarkerWithInfowindow;
