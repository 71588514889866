function calculateDistance(lat1, lon1, lat2, lon2) {
	const toRad = (value) => (value * Math.PI) / 180;
	const R_EARTH = 6371;

	const deltaLat = toRad(lat2 - lat1);
	const deltaLon = toRad(lon2 - lon1);

	const haversineValue =
		Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
		Math.cos(toRad(lat1)) *
			Math.cos(toRad(lat2)) *
			Math.sin(deltaLon / 2) *
			Math.sin(deltaLon / 2);

	const centralAngle =
		2 *
		Math.atan2(Math.sqrt(haversineValue), Math.sqrt(1 - haversineValue));

	return R_EARTH * centralAngle;
}

// eslint-disable-next-line import/prefer-default-export
export const storeSorting = (stores = [], currentPosition = null) => {
	if (currentPosition === null) return stores;

	const sortedLocations = stores.sort((a, b) => {
		const distanceA = calculateDistance(
			currentPosition.latitude,
			currentPosition.longitude,
			a.coordinates.latitude,
			a.coordinates.longitude
		);
		const distanceB = calculateDistance(
			currentPosition.latitude,
			currentPosition.longitude,
			b.coordinates.latitude,
			b.coordinates.longitude
		);

		return distanceA - distanceB;
	});

	return sortedLocations;
};
