/* eslint-disable */
import React from 'react';

import SEO from '../../../components/SEO';
import ConsultationHeroBanner from '../../../templates/consultation/ConsultationHeroBanner';
import ConsultationItem from '../../../templates/consultation/ConsultationItem';
import Klaviyo from '../../../components/Klaviyo';

import './ConsultationPage.scss';

const headerText = 'Find Your Perfect Hair Colour';

const ConsultationPage = ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} title={headerText} />
		<div className='consultation-page'>
			<ConsultationHeroBanner headerText={headerText} />
			<section className='consultation-page-items'>
				<div className='container'>
					<div className='consultation-page-items__row'>
						<div className='consultation-page-items__column'>
							<ConsultationItem
								title='Instant consultation'
								text={`Let us recommend which
                                 of our hair colour products are best for you`}
								linkText='Start now'
								linkUrl='/pages/consultation-v2'
								bgColor='rgba(232, 222, 233, 1)'
							/>
						</div>
						<div className='consultation-page-items__column'>
							<ConsultationItem
								title='Free personal video appointment'
								text={`Expert advice on our
                                 products + application tips, 
                                 featuring colourists from our 
                                 world famous Atelier`}
								linkText='Book my appointment'
								linkUrl='/pages/book-a-video-chat'
								bgColor='rgba(246, 227, 232, 1)'
							/>
						</div>
					</div>
					<div className='consultation-page__klaviyo'>
						<Klaviyo
							id={process.env.KLAVIYO_CONSULTATION_FORM_ID}
						/>
					</div>
				</div>
			</section>
		</div>
	</>
);

export default ConsultationPage;
