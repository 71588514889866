// External
import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';

// Internal
import ProductFirstScreen from './ProductFirstScreen';
import AlternativeLooks from './AlternativeLooks';
import ProductFrequently from './ProductFrequently';
import ProductTalks from './ProductTalks';
import ProductHighlights from './ProductFirstScreen/ProductInfo/ProductHighlights';
import VariantInformation from './ProductFirstScreen/ProductInfo/VariantInformation';
import ProductWow from './ProductFirstScreen/ProductInfo/ProductWow';
import ProductPairWithContainer from './ProductPairWith';
import YotpoWidget from '../../components/Yotpo/YotpoWidget';
import Klaviyo from '../../components/Klaviyo';
import Breadcrumb from '../../components/BreadCrumb/Index';
import {
	getMetafieldsObject,
	isBrowser,
	getMetafield,
	sendViewItemEvent,
} from '../../utils/helpers';

import { PRODUCT_SECTION_BREADCUMB } from '../../constants/breadcrumbs';
import ProductPerfectBundle from './ProductPerfectBundle';

const ProductAwardsContainer = loadable(() =>
	import('./ProductAwards/ProductAwardsContainer')
);
const ProductClientSliderContainer = loadable(() =>
	import('./ProductClientsSlider/ProductClientSliderContainer')
);
const ProductHowUse = loadable(() => import('./ProductHowUse/ProductHowUse'));

const ProductPage = ({
	product = {},
	collections,
	cart,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	rating,
	sellingPlanGroup,
}) => {
	const frequentlyMainProductHandle = getMetafield(
		product,
		'frequently_main_product'
	);
	const frequentlyProductsHandles = getMetafield(
		product,
		'frequently_products'
	);

	const alternativeLooks = getMetafield(product, 'alternative_looks');
	const hideJoshTalks = getMetafield(product, 'hide_josh_talks_section');
	const hideAccolades = getMetafield(product, 'hid_accolades_sections');
	const hideClientSay = getMetafield(product, 'hide_clients_say_section');
	const hideHowToUse = getMetafield(product, 'hide_how_to_use_section');

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isRootSmudge = JSON.parse(getMetafield(product, 'is_root_smudge'));

	const hidePairWith = getMetafield(product, 'hide_pairs_with_section');
	const productHasPerfectBundle = getMetafield(product, 'perfect_bundle');
	const [activeVariant, setActiveVariant] = useState(null);

	const yotpoRef = useRef(null);

	const productMetafield = getMetafieldsObject(product?.metafields);

	const scrollToReviews = () =>
		window.scrollTo({
			top: yotpoRef.current.getBoundingClientRect().top - 40,
			behavior: 'smooth',
		});

	useEffect(() => {
		isBrowser && sendViewItemEvent(product);
	}, []);

	useEffect(() => {
		const item = {
			ProductName: product.title,
			ProductID: product.id,
			Brand: 'Josh Wood Colour',
			Price: `£${product.variants[0].price}`,
			imageURL: product.media[0].preview.image.originalSrc,
			URL: window.location.href,
		};
		window.klaviyo?.push(['track', 'Viewed Product', item]);
	}, [product]);

	return (
		<div className='pdp'>
			<Breadcrumb section={PRODUCT_SECTION_BREADCUMB} product={product} />
			<ProductFirstScreen
				{...{
					product,
					collections,
					cart,
					allBaseColours,
					productsList,
					shadeShotsProducts,
					isPermanentColour,
					activeVariant,
					setActiveVariant,
					rating,
					scrollToReviews,
					sellingPlanGroup,
				}}
			/>
			{isVariant && isRootSmudge && (
				<>
					<div className='pdp-variant__container'>
						<ProductHighlights
							productMetafield={productMetafield}
						/>
						<VariantInformation product={product} />
					</div>
					<div className='pdp-variant--benefits__container'>
						<ProductWow productMetafield={productMetafield} />
					</div>
				</>
			)}
			{!hidePairWith && (
				<ProductPairWithContainer product={product} cart={cart} />
			)}
			<div className='pdp-klaviyo'>
				<div className='pdp-klaviyo__container container'>
					<Klaviyo id={process.env.KLAVIYO_PDP_FORM_ID} />
				</div>
			</div>
			{alternativeLooks && (
				<AlternativeLooks
					alternativeLooks={alternativeLooks.split('|')}
				/>
			)}
			{frequentlyMainProductHandle && frequentlyProductsHandles ? (
				<ProductFrequently
					frequentlyMainProductHandle={frequentlyMainProductHandle}
					frequentlyProductsHandles={frequentlyProductsHandles.split(
						'|'
					)}
				/>
			) : null}
			{!hideJoshTalks && (
				<ProductTalks product={product} activeVariant={activeVariant} />
			)}
			{!hideAccolades && <ProductAwardsContainer product={product} />}
			{!hideClientSay && (
				<ProductClientSliderContainer product={product} />
			)}
			{!hideHowToUse && (
				<ProductHowUse
					product={product}
					activeVariant={activeVariant}
				/>
			)}
			{productHasPerfectBundle && (
				<ProductPerfectBundle product={product} cart={cart} />
			)}

			<YotpoWidget yotpoRef={yotpoRef} product={product} />
		</div>
	);
};

export default ProductPage;
