import cartData from './fragments';
import request from '../request';

export default async (cartId, discountCodes) => {
	const variables = {
		cartId,
		discountCodes,
	};
	const query = `mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
	  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
		cart {
			${cartData}
		}
		userErrors {
		  code
		  field
		  message
		}
	  }
	}`;
	return request(query, variables);
};
