import React from 'react';

import LocalImage from '../../../components/LocalImage';

import './AboutMiracle.scss';
import { getMetafield, getMetafieldParse } from '../../../utils/helpers';

const AboutMiracle = ({ product }) => {
	const aboutTitle = getMetafield(
		product,
		'unlock_the_best_shade_section_title'
	);
	const aboutSteps = getMetafieldParse(
		product,
		'unlock_the_best_shade_steps'
	);

	const stepsHaveDescription = aboutSteps.data.some((step) =>
		step.hasOwnProperty('desc')
	);
	return (
		<div
			className='about-the-miracle-system-v2'
			style={!stepsHaveDescription ? { display: 'block' } : {}}
		>
			<div
				className='about-the-miracle-system-v2__text'
				style={!stepsHaveDescription ? { margin: '0px' } : {}}
			>
				<h3 className='about-the-miracle-system-v2__title'>
					{aboutTitle}
				</h3>
				<div className='about-the-miracle-system-v2__list'>
					{aboutSteps.data.map((item, index) => (
						<div
							key={item.title}
							className='about-the-miracle-system-v2__list__item'
						>
							<div className='about-the-miracle-system-v2__list__item__number'>
								{index + 1}
							</div>
							<LocalImage image={item.image} />
							<p className='about-the-miracle-system-v2__list__item__desc'>
								<strong>{item.title}</strong>
								<br />
								{item.desc}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AboutMiracle;
