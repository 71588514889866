// External
import React from 'react';
import loadable from '@loadable/component';

// Internal
import LocalImage from '../../components/LocalImage';
import BlogPageGrid from './BlogPageGrid/BlogPageGrid';

import Breadcrumb from '../../components/BreadCrumb/Index';
import filters from '../../constants/filters';

import { BLOG_SECTION_BREADCRUMB } from '../../constants/breadcrumbs';

import './BlogPage.scss';

const BlogPageFeature = loadable(() =>
	import('./BlogPageFeature/BlogPageFeature')
);
const Filter = loadable(() => import('../../components/Filter'));
const BlogPage = ({ articles, headerText }) => (
	<div className='blog-page'>
		<Breadcrumb section={BLOG_SECTION_BREADCRUMB} />
		<div className='blog-page__header blog-page-header'>
			<div className='blog-page-header__container'>
				<h1>{headerText}</h1>
				<p>
					Explore our ‘how to’ guides with expert tips from Josh and
					the team; plus discover the latest hair trends and
					happenings in our community
				</p>
			</div>
			<LocalImage alt='Background' image='' />
		</div>
		<div className='container'>
			{(articles || []).length && (
				<>
					<BlogPageFeature featureArticle={articles[0].node} />
					<Filter blogFilters={filters.articles} type='blog' />
					<BlogPageGrid articles={articles} />
				</>
			)}
		</div>
	</div>
);
export default BlogPage;
