/* eslint-disable */
import React from 'react';
import { useForm } from '@formspree/react';

import { Link } from 'gatsby';

import './ContactForm.scss';

const ContactForm = () => {
	const [state, handleSubmit] = useForm('mgerbqgw');
	if (state.succeeded) {
		return (
			<div className='contact-text'>
				<p>Thank you for contacting us!</p>
			</div>
		);
	}
	return (
		<form
			className='contact-form'
			name='contact-form'
			method='POST'
			onSubmit={handleSubmit}
		>
			<div className='contact-form__header'>
				<h3 className='contact-form__title'>Get in touch</h3>
				<p className='contact-form__text'>
					From questions about your order to tailored product advice,
					we’re here to help. If you can’t find an answer to your
					question in our FAQs, please fill in the details below and
					we’ll get back to you in one business day.
				</p>
			</div>
			<div>
				{/*eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label>What do you need help with?</label>
				{/*eslint-disable-next-line jsx-a11y/no-onchange */}
				<select
					id='subject'
					name='subject'
					required
					// value={select}
					// onChange={e => setSelect(e.target.value)}
				>
					<option value='I’ve got a question about my order'>
						I’ve got a question about my order
					</option>
					<option value='I’m not sure which product or shade to choose'>
						I’m not sure which product or shade to choose
					</option>
					<option value='I’d like to share my feedback and suggestions'>
						I’d like to share my feedback and suggestions
					</option>
					<option value='I’d like to discuss an issue or complaint'>
						I’d like to discuss an issue or complaint
					</option>
					<option value='I’d like to talk to you about something else'>
						I’d like to talk to you about something else
					</option>
				</select>

				<div className='contact-form__row'>
					<input
						type='text'
						name='firstName'
						aria-required='true'
						required
						placeholder='First Name'
					/>
					<input
						type='text'
						name='lastName'
						aria-required='true'
						required
						placeholder='Last Name'
					/>
				</div>

				<input
					type='email'
					name='email'
					autoCorrect='off'
					autoCapitalize='off'
					aria-required='true'
					required
					placeholder='Email'
				/>
				<input
					type='tel'
					name='orderNumber'
					placeholder='Order number (if applicable)'
				/>
				<input name='main' type='hidden' value='true' />
				<textarea
					required
					rows='10'
					id='{{ formId }}-message'
					name='message'
					placeholder='Let us know how we can help'
				/>
				<label className='contact-form__checkbox'>
					<input required type='checkbox' />I consent to Josh Wood
					Colour storing and using my data for the requirements set
					out on the
					<Link to='/pages/privacy-policy'>privacy policy</Link>
					<span />
				</label>

				<button
					disabled={state.submitting}
					type='submit'
					className='contact-form__submit'
				>
					Submit your details
				</button>
			</div>
		</form>
	);
};

export default ContactForm;
