import React, { useState } from 'react';
import FAQQuestion from './faqQuestion';
import './style.scss';

const FAQSection = ({ blok }) => {
	const { title, questions } = blok;
	const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

	const handleToggle = (index) => {
		setOpenQuestionIndex(openQuestionIndex === index ? null : index);
	};
	return (
		<section className='faq__section'>
			<h2 className='faq__section-title'>{title}</h2>
			<ul className='faq__list'>
				{questions.map((item, index) => (
					<FAQQuestion
						key={item.question}
						item={item}
						isQuestionOpen={openQuestionIndex === index}
						onToggle={() => handleToggle(index)}
					/>
				))}
			</ul>
		</section>
	);
};

export default FAQSection;
